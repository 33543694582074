import { Col, Container, Row } from "react-bootstrap";
import { Card, Badge } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle, FaHourglassHalf, FaQuestionCircle } from 'react-icons/fa';
import { FaCalendarAlt } from "react-icons/fa";

import { Link } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import Loader from "../../global/Loader";
import { AuthenticationService } from "../../Services/AuthService";
import { IoPersonAddSharp } from "react-icons/io5";
import { ProjectService } from "../../Services/ProjectService";
import { Form, Select, Table, Space, Tag, Button, Modal, Input } from "antd";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { CommonServices } from "../../Services/CommonService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CGRCProjectsList = () => {
  const authService = new AuthenticationService();

  const commonService = useMemo(() => new CommonServices(), []);
  const projectService = useMemo(() => new ProjectService(), []);
  const currentUser = authService.getUserData?.role;

  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [commonListData, setCommonListData] = useState();
  const [loadingFilterList, setLoadingFilterList] = useState(false);
  const [actionStatusData, setActionStatusData] = useState();
  const [initialValues, setInitialValues] = useState({ statustype: "-1", projectAreas: "-1", projectType: "-1" });


  const [modal1Open, setModal1Open] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [projectAreasList, setProjectAreasList] = useState([]);
  const [loadingDDL, setLoadingDDL] = useState(true);
  const [pageLoad, setPageLoad] = useState(false);
  const [projectTypeList, setProjectTypeList] = useState([]);
  const [projectId, setProjectId] = useState();
  const [modalStatus, setModalStatus] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [formShowHide, setFormShowHide] = useState(false);
  const [projectListbyId, setOfficerListbyId] = useState(null);
  const navigate = useNavigate();

  const approveRejectConfirmModal = (type, value, projectId) => {
    setModal1Open(true);
    if (type === "projectList") {
      setModalTitle("Project Details");
      setOfficerListbyId([value]);
      setFormShowHide(false);
    } else if (type === "approveRejectModal") {
      setModalTitle("Verify");
      setFormShowHide(true);
      form.resetFields();
      setProjectId(projectId);

      const filterData = commonListData?.filter(item => {
        return item.value === value;
      });
      setModalStatus(filterData);
      form.setFieldValue('verificationStatus', value);
    }
  }

  const handleEdit = (data) => {
    navigate("/user/projects/projects-entry", { state: { data: data, EditFlag: true } });
  }

  const modalClose = () => {
    // form.resetFields();
    setModal1Open(false);
  };

  const [loadingModalSubmit, setLoadingModalSubmit] = useState(false);
  const modalSubmit = async () => {
    try {
      setLoadingModalSubmit(true);
      let payload = {
        projectId: projectId,
        verificationStatus: form.getFieldValue('verificationStatus'),
        verificationRemark: form.getFieldValue('verificationRemark')
      }
      const res = await projectService.VerifyProjectDetails(payload);
      if (res.status) {
        Swal.fire({
          html: res.data.message,
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: res.data.message,
        });
      }

      modalClose();

    } catch (error) {
      console.error(error)
      setLoadingModalSubmit(false);
    }
    finally {
      setLoadingModalSubmit(false);
    }
  }

  const viewDoc = async (doc) => {
    try {
      setPageLoad(true)
      if (doc.DocUniqueId !== null) {
        const res = await projectService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The document hasn't been uploaded yet. Please try again later.",
        });
      }
    }
    catch (error) {
      console.error("Error fetching documents:", error);
    }
    finally {
      setPageLoad(false);
    }


  };


  const columns = [

    {
      title: '#',
      align: 'center',
      render: (record) => record.key,
      width: '5%'
    },

    {
      title: 'Project Areas / Type',
      render: (row) => {
        return (
          <>
            <p>{row.projectAreasName}<span className="fw-bold">/<br />({row.projectTypeName} ) </span></p>

          </>
        )
      },
      width: '20%'
    },

    {
      title: 'Project Title (Principal Investigator)',
      render: (row) => {
        return (
          <>
            <Row>
              <Col md={12}>
                <div className="project-details">
                  <h5><span className="fw-bold">{row.titleEnglish} </span> </h5>
                  <p><span className="fw-bold">Principal Investigator: </span>{row.investigatorEnglish}</p>
                  <p><span className="fw-bold">Tenure of the Project : </span>{row.tenureEnglish}</p>
                </div>
              </Col>
            </Row>
          </>
        )
      },
    },
  
    {
      title: 'Status/IsActive',
      width: '21%',
      render: (row) => {
        return (
          <>
            <div style={{ lineHeight: '1.5' }}>

              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">S#:</span> {row.projectId}
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Is Visible:</span> &nbsp;
                <Tag color={row.isActive === 0 ? 'error' : 'success'}>{row.isActiveName}</Tag>
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Verification Status:</span>&nbsp;
                <Tag
                  color={
                    row.verificationStatus === 0
                      ? 'processing'
                      : row.verificationStatus === 1
                        ? 'success'
                        : 'error'
                  }
                >
                  {row.statusName}
                </Tag>
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Submit Date:</span> {row.submitDate}
              </p>
            </div>
          </>
        )
      }
    },
    currentUser === "2" ?
      {
        title: 'Action',
        align: 'center',
        width: '12%',
        render: (data, record) => (
          <>
            <Space direction="vertical" align="center">
              <Button onClick={() => approveRejectConfirmModal("projectList", data, data?.verificationStatus)}
                className='btn btn-sm btn-outline-primary'>View</Button>

              {data.verificationStatus === 0 && actionStatusData?.map((elm, id) => (
                <Button key={id} onClick={() => approveRejectConfirmModal("approveRejectModal", elm.value, data?.projectId)} className={'btn btn-sm ' + (elm.value === '3' ? 'btn-outline-danger' : (elm.value === '1' ? 'btn-outline-success' : ''))}>
                  {elm.label}
                </Button>
              ))}

            </Space>
          </>
        ),
      } :
      {
        title: 'Action',
        align: 'center',
        width: '12%',
        render: (data, record) => (
          <>
            <Space>
              <Button className="btn btn-sm btn-outline-primary"
                onClick={() => handleEdit(data)}>Edit</Button>
              <Button onClick={() => approveRejectConfirmModal("projectList", data, data?.verificationStatus)}
                className='btn btn-sm btn-outline-primary'>View</Button>
            </Space>
          </>
        ),
      }
  ];

  useEffect(() => {
    const getFilterList = async () => {
      try {
        setLoadingFilterList(true);
        let res = await commonService.getActionCommonStatus();
        const filterData = res.data?.filter(item => {
          return item.value === "0" || item.value === "1" || item.value === "3";
        })

        setCommonListData(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < filterData.length; i++) {
            data.push(
              {
                value: filterData[i].value,
                name: filterData[i].name,
                label: filterData[i].label,
                nameHindi: filterData[i].nameHindi,
                type: filterData[i].type,
                extraField1: filterData[i].extraField1
              }
            )
          }
          return data;
        });
      } catch (error) {
        setLoadingFilterList(false);
      }
      finally {
        setLoadingFilterList(false);
      }
    }

    const getActionStatus = async () => {
      try {

        let res = await commonService.getActionStatus();
        setActionStatusData(res.data);

      } catch (error) {

        console.log("Error at getActionStatus: ", error)
      }

    }

    const getProjectTypeAreas = async (type) => {
      try {
        setLoadingDDL(true);
        if (type === "ProjectAreas") {
          const res = await projectService.getCommonList(type);
          setProjectAreasList(() => {
            let data = [
              {
                value: '-1',
                name: 'All',
                label: 'All',
                nameHindi: '',
                type: '',
                extraField1: ''
              }
            ];
            for (let i = 0; i < res.data.length; i++) {
              data.push(
                {
                  value: res.data[i].value,
                  name: res.data[i].name,
                  label: res.data[i].label,
                  nameHindi: res.data[i].nameHindi,
                  type: res.data[i].type,
                  extraField1: res.data[i].extraField1
                }
              )
            }
            return data;
          })
        }
        else if (type === "ProjectType") {
          const res = await projectService.getCommonList(type);
          setProjectTypeList(() => {
            let data = [
              {
                value: '-1',
                name: 'All',
                label: 'All',
                nameHindi: '',
                type: '',
                extraField1: ''
              }
            ];
            for (let i = 0; i < res.data.length; i++) {
              data.push(
                {
                  value: res.data[i].value,
                  name: res.data[i].name,
                  label: res.data[i].label,
                  nameHindi: res.data[i].nameHindi,
                  type: res.data[i].type,
                  extraField1: res.data[i].extraField1
                }
              )
            }
            return data;
          })
        }

      }
      catch (error) {
        setLoadingDDL(false);
      }
      finally {
        setLoadingDDL(false);
      }
    }

    getProjectTypeAreas("ProjectType");
    getProjectTypeAreas("ProjectAreas");
    getFilterList();
    getActionStatus();

  }, [commonService, projectService]);

  useEffect(() => {
    const getProjectList = async (projectAreas, projectType, stausCode) => {
      try {
        setLoadingTblData(true);
        let payload = {
          isActive: -1,
          verificationStatus: stausCode,
          projectId: -1,
          projectAreas: projectAreas,
          projectType: projectType
        }
        const res = await projectService.GetProjectDetails(payload);

        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            projectTypeName: item.projectTypeName,
            projectAreasName: item.projectAreasName,
            projectType: item.projectType,
            projectAreas: item.projectAreas,
            projectId: item.projectId,
            titleEnglish: item.titleEnglish,
            titleHindi: item.titleHindi,
            tenureEnglish: item.tenureEnglish,
            tenureHindi: item.tenureHindi,
            investigatorEnglish: item.investigatorEnglish,
            investigatorHindi: item.investigatorHindi,
            verificationStatus: item.verificationStatus,
            statusName: item.statusName,
            isActive: item.isActive,
            isActiveName: item.activeName,
            verificationremark: item.verificationRemark,
            publishDate: item.publishDate,
            submitDate: item.submitDate,
            docEn: {
              DocUniqueId: item.enDocUniqueId,
              DocMimeType: item.enDocMimeType,

            },
            docHi: {
              DocMimeType: item.hiDocMimeType,
              DocUniqueId: item.hiDocUniqueId
            }



          }));
          return data;
        });

      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getProjectList(form2.getFieldValue("projectAreas"), form2.getFieldValue("projectType"), form2.getFieldValue("statustype"));
  }, [loadingModalSubmit, initialValues, projectService, form2])


  const loadTableFilter = (projectAreas, projectType, stausCode) => {

    setInitialValues({ statustype: stausCode, projectAreas: projectAreas, projectType: projectType })

  }





  return (
    <>
      {loadingTblData && <Loader />}
      {pageLoad && <Loader />}
      <Container>
        <Row>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Project List</h3>
              </Card.Header>
              <Card.Body>



                <Card>
                  <Card.Body>
                    <Row>
                      <Col className="mb-3" md={12}>
                        {currentUser === "3" && (
                          <Link className="btn btn-outline-primary mt-1" to="/user/projects/projects-entry">
                            <IoPersonAddSharp /> Add New
                          </Link>
                        )}
                      </Col>
                      <Col lg={5}>
                        <Form.Item name="projectAreas" label="Areas" rules={[
                          { required: true, message: '*Required.!' },
                        ]}>
                          <Select showSearch placeholder="Select Project Areas" optionFilterProp="children" // onSearch={onSearch}
                            options={projectAreasList} loading={loadingDDL}
                            onChange={(value) => loadTableFilter(value, form2.getFieldValue("projectType"), form2.getFieldValue("statustype"))}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={4}>
                        <Form.Item name="projectType" label="Type" rules={[
                          { required: true, message: 'Project Type Required' },
                        ]}>
                          <Select showSearch placeholder="Select Project Type" optionFilterProp="children"
                            options={projectTypeList} loading={loadingDDL}
                            onChange={(value) => loadTableFilter(form2.getFieldValue("projectAreas"), value, form2.getFieldValue("statustype"))}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={3}>
                        <Form.Item name="statustype" label="Status">
                          <Select
                            showSearch
                            placeholder="Select Filter Type"
                            optionFilterProp="children"
                            onChange={(value) => loadTableFilter(form2.getFieldValue("projectAreas"), form2.getFieldValue("projectType"), value)}
                            options={commonListData}
                            loading={loadingFilterList}
                            filterOption={(input, option) =>
                              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                    </Row>

                    <div className="tableList table-responsive-md">
                      <Table
                        bordered
                        columns={columns}
                        dataSource={tblData}
                        loading={loadingTblData}
                        scroll={{ x: 800, y: 600 }}
                        size="small"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Modal
              title={modalTitle}
              style={{ top: 20 }}
              open={modal1Open}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => setModal1Open(false)}
              width={formShowHide === false ? 900 : 450}
            >
              {formShowHide &&
                <Form
                  layout="vertical"
                  form={form}
                  name="boilerRegForm"
                  onFinish={modalSubmit}
                  scrollToFirstError
                >
                  <Col md={12}>
                    <hr></hr>
                  </Col>
                  <Col md={12}>
                    <Form.Item name="verificationStatus" label="Status">
                      <Select showSearch placeholder="Select Status"
                        optionFilterProp="children"
                        options={modalStatus} loading={loadingFilterList}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12}>
                    <Form.Item label="Remarks" name="verificationRemark" rules={[
                      { required: true, message: 'Remarks Required' },
                    ]}>
                      <Input.TextArea showCount rows={3} maxLength={300} />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <hr></hr>
                  </Col>
                  <Col md={12} className="text-center">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2"
                      disabled={loadingModalSubmit}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-sm btn-outline-danger"
                      disabled={loadingModalSubmit}
                      onClick={modalClose}
                    >
                      Cancel
                    </button>
                  </Col>

                </Form>
              }
              {formShowHide === false &&
                <>

                  {
                    projectListbyId !== null && (


                      <>
                        {/* English Project Details */}
                        <Row className="mt-4">
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <Card.Body>
                                <Card.Title>English</Card.Title>
                                <Row>
                                  <Col md={9}>
                                    <div className="project-details">
                                      <h5><span className="fw-bold">{projectListbyId[0].titleEnglish}</span></h5>
                                      <p><span className="fw-bold">Principal Investigator: </span>{projectListbyId[0].investigatorEnglish}</p>
                                      <p><span className="fw-bold">Tenure of the Project: </span>{projectListbyId[0].tenureEnglish}</p>
                                    </div>
                                  </Col>
                                  <Col md={3} className="align-self-center text-md-end text-start">
                                    <Button
                                      onClick={() => viewDoc(projectListbyId[0].docEn)}
                                      className="btn btn-sm btn-outline-primary mt-2"
                                    >
                                      <BsFileEarmarkPdfFill /> View Details
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        {/* Hindi Project Details */}
                        <Row className="mt-4">
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <Card.Body>
                                <Card.Title>Hindi</Card.Title>
                                <Row>
                                  <Col md={9}>
                                    <div className="project-details">
                                      <h5><span className="fw-bold">{projectListbyId[0].titleHindi}</span></h5>
                                      <p><span className="fw-bold">प्रधान अन्वेषक: </span>{projectListbyId[0].investigatorHindi}</p>
                                      <p><span className="fw-bold">परियोजना की अवधि: </span>{projectListbyId[0].tenureHindi}</p>
                                    </div>
                                  </Col>
                                  <Col md={3} className="align-self-center text-md-end text-start">
                                    <Button
                                      onClick={() => viewDoc(projectListbyId[0].docEn)}
                                      className="btn btn-sm btn-outline-primary mt-2"
                                    >
                                      <BsFileEarmarkPdfFill /> View Details
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        {/* Project Overview Details */}
                        <Row className="mt-4">
                          <Col md={6}>
                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>S#</Card.Title>
                                <Card.Text>{projectListbyId[0].projectId}</Card.Text>
                              </Card.Body>
                            </Card>

                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Project Areas</Card.Title>
                                <Card.Text>{projectListbyId[0].projectAreasName}</Card.Text>
                              </Card.Body>
                            </Card>

                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Project Type</Card.Title>
                                <Card.Text>{projectListbyId[0].projectTypeName}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>

                          <Col md={6}>
                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Is Active</Card.Title>
                                <Card.Text>

                                  {projectListbyId[0].isActive === 1 ? (
                                    <Badge bg="success"><FaCheckCircle /> Active</Badge>
                                  ) : (
                                    <Badge bg="danger"><FaTimesCircle /> Inactive</Badge>
                                  )}
                                </Card.Text>
                              </Card.Body>
                            </Card>

                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Status</Card.Title>
                                {projectListbyId[0].verificationStatus === 1 ? (
                                  <Badge bg="success"><FaCheckCircle /> {projectListbyId[0].statusName}</Badge>
                                ) : projectListbyId[0].verificationStatus === 0 ? (
                                  <Badge bg="warning"><FaHourglassHalf /> {projectListbyId[0].statusName}</Badge>
                                ) : projectListbyId[0].verificationStatus === 3 ? (
                                  <Badge bg="danger"><FaTimesCircle /> {projectListbyId[0].statusName}</Badge>
                                ) : (
                                  <Badge bg="secondary"><FaQuestionCircle /> Unknown Status</Badge>
                                )}

                              </Card.Body>
                            </Card>

                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Submit Date</Card.Title>
                                <Card.Text><FaCalendarAlt /> {projectListbyId[0].submitDate}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        {/* Verification Details */}
                        <Row className="mt-2">
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <Card.Body>
                                <Card.Title> {projectListbyId[0].verificationStatus !== 0 ? "Verification" : "Remark"}</Card.Title>
                                {projectListbyId[0].verificationStatus !== 0 ? (
                                  <>
                                    <Card.Text><span className="fw-bold"><FaCalendarAlt /> Date: </span>{projectListbyId[0].publishDate}</Card.Text>
                                    <Card.Text><span className="fw-bold">Remark: </span>{projectListbyId[0].verificationremark}</Card.Text>
                                  </>
                                ) : (
                                  <Card.Text>{projectListbyId[0].verificationremark}</Card.Text>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        {/* Close Button for Modal */}
                        <Row className="mt-4">
                          <Col md={12} className="text-center">
                            <button
                              className="btn btn-sm btn-outline-danger"
                              disabled={loadingModalSubmit}
                              onClick={modalClose}
                            >
                              Close
                            </button>
                          </Col>
                        </Row>
                      </>
                    )
                  }
                </>
              }
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CGRCProjectsList;


