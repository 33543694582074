import { Button, Divider, Input, Upload, message, List, Switch } from "antd";
import Form from "antd/es/form";
import React, { useEffect, useState } from "react";
import { Container, Row, Col,Card } from "react-bootstrap";
import { OfficerListService } from "../../Services/OfficerListService";
import { readFileDataAsBase64 } from "../../global/FileConverter";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { BiImage } from "react-icons/bi";
import { TbTrashX } from "react-icons/tb";
import { FileImageOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { TbArrowBackUp } from "react-icons/tb";
import { Link } from "react-router-dom";
import { AuthenticationService } from "../../Services/AuthService";


const HomePageMinisterEntry = () => {
  const [form] = Form.useForm();
  const [loadingFormSubmit, setLoadingFormSubmit] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const [officerId, setOfficerId] = useState(0);
  const values = Form.useWatch([], form);

  const [imgFileList, setImgFileList] = useState([]);
  
  const [title, setTitle] = useState("add")

  const [imgFileValue, setImgFileValue] = useState(null);
  const [imgFileExt, setImgFileExt] = useState({ imageExt: "", type: "" });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const authService = new AuthenticationService();
    if (authService.getUserData?.role !== "3") {
      navigate('/user/unauthorized'); // Redirect to error page if role is not admin
    }
  }, [navigate]);
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormDisabled(true);
      },
      () => {
        setFormDisabled(false);
      }
    );
  }, [values, form]);

  const officerListService = new OfficerListService();

  useEffect(() => {

    if (location?.state?.EditFlag === true) {
      form.setFieldsValue({
        nameHindi: location?.state?.data?.nameHindi,
        nameEnglish: location?.state?.data?.nameEnglish,
        postEnglish: location?.state?.data?.postEnglish,
        postHindi: location?.state?.data?.postHindi,
        designationEnglish: location?.state?.data?.designationEnglish,
        designationHindi: location?.state?.data?.designationHindi,
        isActive:(location?.state?.data?.isActive===1)?true:false
      })
      setOfficerId(location?.state?.data?.officerId)
      setTitle("edit")
    }
  }, [location?.state, form])




  useEffect(() => {
    const genRandomKey = async () => {
      if (imgFileList && imgFileList.length > 0) {
        try {
          let data = await readFileDataAsBase64(imgFileList[0]);
          setImgFileValue(() => ({
            documentExtension: imgFileExt.imageExt,
            documentMimeType: imgFileExt.type,
            documentTag: "OfficerDoc",
            documentType: 8,
            documentImageGroup: 2,
            stateId: 22,
            documentList: [
              {
                documentInByte: data,
                documentLabel: "OfficerDoc",
              },
            ],
          }));
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }
    };

    genRandomKey();
  }, [imgFileList, imgFileExt]);

  
  const onRemoveImg = (file) => {
    const index = imgFileList.indexOf(file);
    const newFileList = imgFileList.slice();
    newFileList.splice(index, 1);
    setImgFileList(newFileList);

  }


  const beforeUploadImg = (file, imgFileList) => {
    const ext = file.name.split('.').pop()

    setImgFileExt({ imageExt: ext, type: file.type })
    if (file.size / 1024 / 1024 > 10) {
      message.open({
        type: 'error',
        content: <> <strong>{file.name}</strong> is greater than 10MB.</>,
        duration: 4
      });
      return false;
    }
    if (!(file.type === 'image/png' || file.type === 'image/jpeg')) {
      message.open({
        type: 'error',
        content: <>Only .png and .jpeg files are allowed</>,
        duration: 4
      });
      return false;
    }
    if (imgFileList.findIndex((itm) => { return (itm.name === file.name && itm.size === file.size && itm.type === file.type) }) !== 0) {
      message.open({
        type: 'error',
        content: <><strong>{file.name} already added.</strong></>,
        duration: 4
      });
      return false;
    }
    setImgFileList([file]);
    return false;
  }


  const OfficersDetaileSubmit = async () => {
    try {
      setLoadingFormSubmit(true);
      const payload = {
        officerId: officerId,
        officersType: 2,
        nameEnglish: form.getFieldValue("nameEnglish"),
        nameHindi: form.getFieldValue("nameHindi"),
        postEnglish: form.getFieldValue("postEnglish"),
        postHindi: form.getFieldValue("postHindi"),
        designationEnglish: form.getFieldValue("designationEnglish"),
        designationHindi: form.getFieldValue("designationHindi"),
        resumeDocEn: [],
        resumeDocHi: [],
        imageDoc: imgFileValue ? [imgFileValue] : [],
        isActive: form.getFieldValue("isActive")===true?1:0,
        VerificationRemark:form.getFieldValue("verificationRemark")       
      };
      let res;

      if (location?.state?.EditFlag === true) {
        res = await officerListService.OfficerDetailsUpdate(payload);
      } else {
        res = await officerListService.OfficerDetailsInsert(payload);
      }


      if (res.data.status) {
        Swal.fire({
          html: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        navigate("/user/home-content-list");
      }
      else{
        Swal.fire({
          html: res.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000
        });
      }
    } catch (error) {
      console.error("Error submitting officer details:", error);
    } finally {
      setLoadingFormSubmit(false);
    }
  };

  return (
    <Container className="project-entry-form mt-4">
    <Row className="justify-content-center">
      <Col lg={11}>
        <Card className="mb-4 shadow-sm">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <h3>{title === "edit" ? "Edit Minister/Officer Details":"Add Minister/Officer Details"}</h3>        
            <Link className="btn btn-secondary" to="/user/home-content-list">
              <TbArrowBackUp /> Back
            </Link>
          </Card.Header>
          <Card.Body>
            <Form
                layout="vertical"
                form={form}
                initialValues={{isActive:true}}
                name="OfficersDetaileForm"
                onFinish={OfficersDetaileSubmit}
                scrollToFirstError
            >
              <Card className="mb-3">
                <Card.Header className="fw-bold">Full Name</Card.Header>
                <Card.Body>
                  <Row gutter={16}>
                  <Col md={6}>
                    <Form.Item
                      name="nameEnglish"
                      label="Full Name (English)"
                      rules={[
                        { required: true, message: "* Required!" },
                        {
                          type: "string",
                          max: 150,
                          message: "Max length exceeded"
                        }
                      ]}
                    >
                      <Input placeholder="Enter Full Name (English)" />
                    </Form.Item>
                  </Col>
                  <Col md={6}  >
                    <Form.Item
                      name="nameHindi"
                      label="Full Name (Hindi)"
                      rules={[
                        { required: true, message: "* Required!" },
                        {
                          type: "string",
                          max: 150,
                          message: "Max length exceeded"
                        }
                      ]}
                    >
                      <Input placeholder="Enter Full Name (Hindi)" />
                    </Form.Item>
                  </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Header className="fw-bold">Post Details</Card.Header>
                <Card.Body>
                  <Row gutter={16}>
                  <Col md={6}>
                    <Form.Item name="postEnglish" label="Post (English)" rules={[
                      { required: true, message: '* Required!' },
                      {
                        type: "string",
                        max: 150,
                        message: "Max length exceeded",
                      },
                    ]}>
                      <Input.TextArea showCount maxLength={150} placeholder="Enter Post (English)" autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item name="postHindi" label="Post (Hindi)" rules={[
                      { required: true, message: '* Required!' },
                      {
                        type: "string",
                        max: 150,
                        message: "Max length exceeded",
                      },
                    ]}>
                      <Input.TextArea showCount maxLength={150} placeholder="Enter Post (Hindi)" autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Header className="fw-bold">Designation Details</Card.Header>
                <Card.Body>
                  <Row gutter={16}>
                  <Col md={6}>
                    <Form.Item name="designationEnglish" label="Designation (English)" rules={[
                      { required: true, message: '* Required!' },
                      {
                        type: "string",
                        max: 150,
                        message: "Max length exceeded",
                      },
                    ]}>
                      <Input.TextArea showCount maxLength={150} placeholder="Enter designation(English)" autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item name="designationHindi" label="Designation (Hindi)" rules={[
                      { required: true, message: '* Required!' },
                      {
                        type: "string",
                        max: 150,
                        message: "Max length exceeded",
                      },
                    ]}>
                      <Input.TextArea showCount maxLength={150} placeholder="Enter Designation (Hindi)" autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Header className="fw-bold">Upload Image</Card.Header>
                <Card.Body>
                  <Row gutter={16}>
                 
                  <Col md={6}>
                    <Form.Item
                      name="OfficerImg"
                      label="Upload Minister/Officer Image"
                    rules={[
                      { required: !location?.state?.EditFlag, message: "Please upload Officer Img" }
                    ]}

                    >
                      <div>
                        <ImgCrop aspectSlider>
                          <Upload.Dragger name="imgfiles"
                            beforeUpload={beforeUploadImg}
                            fileList={imgFileList}
                            accept="image/png, image/jpeg"
                            disabled={imgFileList.length >= 1}
                            showUploadList={false}
                          >
                            <p className="ant-upload-drag-icon">
                              <FileImageOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Only Png, jpeg file with size less than 10MB is allowed</p>
                          </Upload.Dragger>
                        </ImgCrop>
                        
                      </div>
                    </Form.Item>
                  </Col>
                 
                      {(imgFileList.length > 0) &&
                        <Col md={6}>
                           <span className="fw-bold ">Upload Image List</span>
                          <List className="mt-2"
                            itemLayout="horizontal"
                            dataSource={imgFileList}
                            bordered
                            renderItem={(item, index) => (
                              <List.Item key={index}
                                actions={[<Button key="list-loadmore-edit" className="btn btn-outline-danger" onClick={() => { onRemoveImg(item) }}><TbTrashX /></Button>]}>
                                <List.Item.Meta
                                  avatar={<BiImage />}
                                  title={item.name}
                                  description={`File Type: ${item.type} | Size: ${(item.size / 1024).toFixed(2)}KB`}
                                />
                              </List.Item>
                            )}
                          />
                        </Col>
                      }
                      
                 

                  </Row>
                </Card.Body>
              </Card>

              

              <Card className="mb-3">
                <Card.Header className="fw-bold">Additional Details</Card.Header>
                <Card.Body>
                  <Row gutter={16}>
                  <Col md={6}>
                    <Form.Item name="verificationRemark" label="Remark" rules={[
                      { required: location?.state?.EditFlag, message: 'Required!' },
                      {
                        type: "string",
                        max: 250,
                        message: "Max length exceeded",
                      },
                    ]}>
                      <Input.TextArea showCount maxLength={250} placeholder="Enter Remark" autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item name="isActive" label="Is Active:" valuePropName="checked"
                      rules={[
                        { required: true, message: 'Required!' }

                      ]}>
                      <Switch />
                    </Form.Item>

                  </Col>
                  </Row>
                </Card.Body>
              </Card>




              <div className="text-center mt-2">
                  <Divider />
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!formDisabled}
                    loading={loadingFormSubmit}
                  >
                      {title==="add"?"Save":"Update"}
                  </Button>
                </div>

            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  );
};

export default HomePageMinisterEntry;
