import { Col, Row, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import Loader from "../../global/Loader";
import { AuthenticationService } from "../../Services/AuthService";
import { IoPersonAddSharp } from "react-icons/io5";
import { ImageGalleryService } from "../../Services/ImageGalleryService";
import { Form, Select, Table, Modal, Input, Image, Space,Button } from "antd";
import { CommonServices } from "../../Services/CommonService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import demoUser from "../../assets/images/demo-user.jpg"


const HomeSliderImageList = () => {
  const authService = new AuthenticationService();
  const commonService = useMemo(() => new CommonServices(), []);
  const imageGalleryService = useMemo(() => new ImageGalleryService(), []);
  const currentUser = authService.getUserData?.role;

  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [commonListData, setCommonListData] = useState();
  const [loadingFilterList, setLoadingFilterList] = useState(false);
  const [actionStatusData, setActionStatusData] = useState();
  const [modal1Open, setModal1Open] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [imageId, setPosterId] = useState();
  const [modalStatus, setModalStatus] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [formShowHide, setFormShowHide] = useState(false);

  const navigate = useNavigate();
  const [filterType, setFilterType] = useState({ filterType: "-1" });
  const approveRejectConfirmModal = (type, approveRejectValue, data) => {
    setModal1Open(true);
    if (type === "approveRejectModal") {
      setModalTitle("Verify");
      setFormShowHide(true);
      form.resetFields();
      setPosterId(data?.imageId);
      const filterData = commonListData?.filter(item => {
        return item.value === approveRejectValue;
      });
      setModalStatus(filterData);
      form.setFieldValue('verificationStatus', approveRejectValue);
    }
  }

  const handleEdit = (data) => {
    navigate("/user/home-slider-image-entry", { state: { data: data, EditFlag: true } });
  }

  const modalClose = () => {
    setModal1Open(false);
  };

  const [loadingModalSubmit, setLoadingModalSubmit] = useState(false);
  const modalSubmit = async () => {
    try {
      setLoadingModalSubmit(true);
      let payload = {
        imageId: imageId,
        verificationStatus: form.getFieldValue('verificationStatus'),
        verificationRemark: form.getFieldValue('verificationRemark')
      }
      const res = await imageGalleryService.VerifyImageDetails(payload);
      if (res.data.status) {
        Swal.fire({
          html: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message
        });
      }
      modalClose();
    }
    catch (error) {
      console.error(error);
      setLoadingModalSubmit(false);
    }
    finally {
      setLoadingModalSubmit(false);
    }
  }

  const columns = [
    {
      title: '#',
      align: 'center',
      dataIndex: 'key',
      width: '5%',
    },
    {
      title: 'Details',
      align: 'center',
      width: '85%',
      render: (row) => (
        <div className="text-start">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <Image
                  src={row.ImgUrl}
                  alt="Image"
                  className="img-fluid rounded"
                  style={{
                    width: '100%',
                    border: '1px solid #d9d9d9',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 col-sm-12">
                <p className="mb-2">
                  <strong>S# :</strong> {row.imageId}
                </p>

                <p className="mb-2">
                  <strong>Is Active:</strong>
                  <span className={`badge ms-2 bg-${row.isActive === 0 ? 'danger' : 'success'}`}>
                    {row.isActiveName}
                  </span>
                </p>
                <p className="mb-2">
                  <strong>Verification Status:</strong>
                  <span className={`badge ms-2 bg-${row.verificationStatus === 0 ? 'primary' : row.verificationStatus === 1 ? 'success' : 'danger'}`}>
                    {row.statusName}
                  </span>
                </p>
                {row.verificationStatus === 1 ? (
                  <>
                    <p className="mb-2">
                      <strong>Notes :</strong> {row.imagenotes}
                    </p>
                  </>
                ) : (
                  ''
                )}

              </div>
              <div className="col-md-6 col-sm-12">
                {row.verificationStatus !== 1 ? (
                  <>
                    <p className="mb-2">
                      <strong>Notes :</strong> {row.imagenotes}
                    </p>
                  </>
                ) : (
                  ''
                )}


                <p className="mb-2">
                  <strong>Submit Date:</strong> {row.submitDate}
                </p>
                {row.verificationStatus !== 0 ? (
                  <>
                    <p className="mb-2">
                      <strong>Verification Date:</strong> {row.publishDate}
                    </p>
                    <p className="mb-2">
                      <strong>Verification Remark:</strong> {row.verificationremark}
                    </p>
                  </>
                ) : (
                  row.actionId > 1 ? (
                    <p className="mb-2">
                      <strong>Edited Remark:</strong> {row.verificationremark}
                    </p>
                  )
                    : (
                      row.verificationremark !== null ?
                        (
                          <p className="mb-2">
                            <strong>Remark:</strong> {row.verificationremark}
                          </p>
                        ) : null)
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      width: '10%',
      render: (data) => (
        <div className="d-flex justify-content-center">
          {currentUser === '2' ? (
            // data.verificationStatus === 0 &&
            // actionStatusData?.map((elm, id) => (
            //   <button
            //     key={id}
            //     onClick={() =>
            //       approveRejectConfirmModal(
            //         'approveRejectModal',
            //         elm.value,
            //         data
            //       )
            //     }
            //     className={`btn btn-sm me-2 ${elm.value === '3' ? 'btn-outline-danger' : 'btn-outline-success'}`}
            //   >
            //     {elm.label}
            //   </button>
            // ))
            <Space direction="vertical" align="center">
            {data.verificationStatus === 0 && actionStatusData?.map((elm, id) => (
              <Button key={id} onClick={() => approveRejectConfirmModal("approveRejectModal", elm.value, data)} className={'btn btn-sm ' + (elm.value === '3' ? 'btn-outline-danger' : (elm.value === '1' ? 'btn-outline-success' : ''))}>
                {elm.label}
              </Button>
            ))}

          </Space>
          ) : (
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => handleEdit(data)}
            >
              Edit
            </button>
          )}
        </div>
      ),
    },
  ];


  useEffect(() => {

    const getFilterList = async () => {
      try {
        setLoadingFilterList(true);
        let res = await commonService.getActionCommonStatus();
        const filterData = res.data?.filter(item => {
          return item.value === "0" || item.value === "1" || item.value === "3";
        })

        setCommonListData(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < filterData.length; i++) {
            data.push(
              {
                value: filterData[i].value,
                name: filterData[i].name,
                label: filterData[i].label,
                nameHindi: filterData[i].nameHindi,
                type: filterData[i].type,
                extraField1: filterData[i].extraField1
              }
            )
          }
          return data;
        });
      } catch (error) {
        setLoadingFilterList(false);
      }
      finally {
        setLoadingFilterList(false);
      }
    }

    const getActionStatus = async () => {
      try {

        let res = await commonService.getActionStatus();
        setActionStatusData(res.data);

      } catch (error) {
        console.error(error);
      }

    }

    getFilterList();
    getActionStatus();

  }, [commonService]);

  useEffect(() => {
    const getImageById = async (imgDocUniqueId, imgDocMtype) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await imageGalleryService.ViewDocument(imgDocUniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: imgDocMtype });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }

      return demoUser;
    };
    const getImageGalleryList = async (stausCode) => {
      try {
        setLoadingTblData(true);
        const payload = { isActive: -1, verificationStatus: stausCode, imageId: 0, imageCategoryType: -1, galleryType: 4 };
        const res = await imageGalleryService.GetImageDetails(payload);
        const imgUrlPromises = res.data.table.map(item =>
          getImageById(item.docUniqueId, item.docMimeType)
        );
        const imgUrls = await Promise.all(imgUrlPromises);

        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            imageId: item.imageId,
            imagenotes: item.imagenotes,
            actionId: item.actionId,
            verificationStatus: item.verificationStatus,
            statusName: item.statusName,
            isActive: item.isActive,
            isActiveName: item.activeName,
            submitDate: item.submitDate,
            Img: {
              imgMimeType: item.docMimeType,
              imgUniqueId: item.docUniqueId,
            },
            ImgUrl: imgUrls[index],

            verificationremark: item.verificationRemark,
            publishDate: item.publishDate,
          }));
          return data;
        });
      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getImageGalleryList(form2.getFieldValue("filterType"));
  }, [loadingModalSubmit, filterType, imageGalleryService, form2])

  const loadTableFilter = (value) => {
    setFilterType({ filterType: value })
  }
  return (
    <>

      {loadingTblData && <Loader />}

      <Container style={{ marginTop: "-15px " }}>
        <Row style={{ marginTop: "-15px" }}>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Home Slider Image List</h3>
              </Card.Header>
              <Card.Body>



                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg={8} md={6} sm={12}>
                        {currentUser === "3" &&
                          <Link className="btn btn-outline-primary mt-1" to="/user/home-slider-image-entry">
                            <IoPersonAddSharp /> Add New
                          </Link>


                        }
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <Form form={form2} initialValues={filterType}>
                          <Form.Item name="filterType" label="Filter">
                            <Select showSearch placeholder="Select Filter Type"
                              optionFilterProp="children" onChange={loadTableFilter}
                              options={commonListData} loading={loadingFilterList}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>

                    <div className="tableList">
                      <Table
                        bordered
                        columns={columns}
                        dataSource={tblData}
                        loading={loadingTblData}
                        scroll={{ x: 800,y: 600  }}
                        size="small"
                        pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Modal
              title={modalTitle}
              style={{ top: 20 }}
              open={modal1Open}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => setModal1Open(false)}
              width={formShowHide === false ? 900 : 450}
            >
              {formShowHide &&
                <Form
                  layout="vertical"
                  form={form}
                  name="boilerRegForm"
                  onFinish={modalSubmit}
                  scrollToFirstError
                >
                  <Card>
                    <Card.Body>
                      <Col md={12}>
                        <Form.Item name="verificationStatus" label="Status">
                          <Select showSearch placeholder="Select Status"
                            optionFilterProp="children"
                            options={modalStatus} loading={loadingFilterList}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} className="clearfix">
                        <Form.Item label="Remarks" name="verificationRemark" rules={[
                          { required: true, message: 'Remarks Required' },
                        ]}>
                          <Input.TextArea showCount rows={3} maxLength={300} />
                        </Form.Item>
                      </Col>

                      <Col md={12}>
                        <hr></hr>
                      </Col>
                      <Col md={12} className="text-center">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary me-2"
                          disabled={loadingModalSubmit}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          disabled={loadingModalSubmit}
                          onClick={modalClose}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Card.Body>
                  </Card>
                </Form>
              }
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HomeSliderImageList;


