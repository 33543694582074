import React from "react";

import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import TitleList from "../../../component/TitleList";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
export default function SciencePark() {
  const { t } = useTranslation();
  const ScienceParksData = t("SciencePark", {
    returnObjects: true,
  });
  const menuData =
    t("menu.activities-of-council.popularisation-of-science", {
      returnObjects: true,
    }) || {};
 

  return (
    <>
      <BreadcrumbLayout title="Science Park" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
         <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{ScienceParksData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {ScienceParksData.content.map((items,i) => (
                  <HeadPara2 key={i} data={items} />
                ))}

                {ScienceParksData.titledList.map((item,i) => (
                  <Col key={i} lg={12} className="mb-1 mt-1">
                    <TitleList title={item.title} list={item.list} />
                  </Col>
                ))}

                <div className="heading-paragraph text-center">
                  <p
                    dangerouslySetInnerHTML={{ __html: ScienceParksData.paragraph }}
                  ></p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
