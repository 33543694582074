import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";

function Sitemap() {
  const { t } = useTranslation();
  const navigationData = t("sitemap.navigationData", { returnObjects: true });

  const renderSubMenu = (subItems) => {
    return (
      <ul>
        {subItems.map((subItem, index) => (
          <li key={index}>
            <a href={subItem.url}>{subItem.title}</a>
            {subItem.subItems && renderSubMenu(subItem.subItems)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <BreadcrumbLayout title="Sitemap" />
      <Container fluid >
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("sitemap.title")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-3 ">

                <div className="site-map">
                  <ul>
                    {navigationData.map((item, index) => (
                      <li key={index}>
                        <a href={item.url}>{item.title}</a>
                        {item.subItems && renderSubMenu(item.subItems)}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Sitemap;
