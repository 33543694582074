import { Col, Container, Row, Card, Badge } from "react-bootstrap";
import { FaCheckCircle, FaTimesCircle, FaHourglassHalf, FaQuestionCircle } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import Loader from "../../global/Loader";
import { AuthenticationService } from "../../Services/AuthService";
import { IoPersonAddSharp } from "react-icons/io5";
import { OfficerListService } from "../../Services/OfficerListService";
import { Form, Select, Table, Space, Tag, Button, Modal, Input, Image, Divider } from "antd";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { CommonServices } from "../../Services/CommonService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import demoUser from "../../assets/images/demo-user.jpg"
import { FaCalendarAlt } from "react-icons/fa";


const OfficersList = () => {
  const authService = new AuthenticationService();
  const commonService = useMemo(() => new CommonServices(), []);
  const officerListService = useMemo(() => new OfficerListService(), []);
  const currentUser = authService.getUserData?.role;

  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [commonListData, setCommonListData] = useState();
  const [loadingFilterList, setLoadingFilterList] = useState(false);
  const [actionStatusData, setActionStatusData] = useState();
  const [modal1Open, setModal1Open] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [officerId, setOfficerId] = useState();
  const [modalStatus, setModalStatus] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [formShowHide, setFormShowHide] = useState(false);
  const [officerListbyId, setOfficerListbyId] = useState(null);
  const [pageload, setPageLoad] = useState(false);
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState({ filterType: "-1" });
  const approveRejectConfirmModal = (type, approveRejectValue, data) => {
    setModal1Open(true);
    if (type === "officerList") {
      setModalTitle("Officer Details");
      setOfficerListbyId([data]);
      setFormShowHide(false);

    }
    else if (type === "approveRejectModal") {
      setModalTitle("Verify");
      setFormShowHide(true);
      form.resetFields();
      setOfficerId(data?.officerId);
      const filterData = commonListData?.filter(item => {
        return item.value === approveRejectValue;
      });
      setModalStatus(filterData);
      form.setFieldValue('verificationStatus', approveRejectValue);
    }
  }

  const handleEdit = (data) => {
    navigate("/user/whoswho/add-edit-officers", { state: { data: data, EditFlag: true } });
  }

  const modalClose = () => {
    setModal1Open(false);
  };

  const [loadingModalSubmit, setLoadingModalSubmit] = useState(false);
  const modalSubmit = async () => {
    try {
      setLoadingModalSubmit(true);
      let payload = {
        officerId: officerId,
        verificationStatus: form.getFieldValue('verificationStatus'),
        verificationRemark: form.getFieldValue('verificationRemark')
      }
      const res = await officerListService.VerifyOfficerDetails(payload);
      if (res.data.status) {
        Swal.fire({
          html: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message
        });
      }
      modalClose();
    }
    catch (error) {
      console.error(error);
      setLoadingModalSubmit(false);
    }
    finally {
      setLoadingModalSubmit(false);
    }
  }
  const viewDoc = async (resume) => {
    try {
      setPageLoad(true);
      if (resume.rsmDocUniqueId !== null) {
        const res = await officerListService.ViewDocument(resume.rsmDocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: resume.rsmDocMtype });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Resume has not been uploaded!",
        });
      }
    }
    catch (error) {
      console.error("Error fetching resume:", error);
    }
    finally {
      setPageLoad(false);
    }


  };



  const columns = [

    {
      title: '#',
      align: 'center',
      render: (record) => record.key,
      fixed: 'left',
      width: '5%'
    },
    {
      title: 'Image/Resume',
      render: (row) => {
        return (
          <div className="text-center">

            <Image
              src={row.ImgUrl}
              alt="Imagesdghsd"
              style={{ width: "115px", border: "1px solid black", padding: "5px", borderRadius: "5px" }}
            />
             <Button
              onClick={() => viewDoc(row.resumeHi)}
              className="btn btn-sm btn-outline-primary mt-2 "
            >
              <BsFileEarmarkPdfFill />
               Resume Hindi
            </Button>
            <Button
              onClick={() => viewDoc(row.resumeEn)}
              className="btn btn-sm btn-outline-primary mt-2 "
            >
              <BsFileEarmarkPdfFill />
               Resume English
            </Button>
           
          </div>
        )
      },
      width: '15%'
    },
    {
      title: 'Full Name/Post/Designation (English/Hindi)',
      render: (row) => {
        return (
          <>
            
            <p><span>{row.nameEnglish}</span> <br /><span className="fw-bold"> ({row.nameHindi})</span></p>

           
            <p><span>{row.postEnglish}</span><br /><span className="fw-bold"> ({row.postHindi})</span></p>

            <p><span>{row.designationEnglish}</span><br /><span className="fw-bold"> ({row.designationHindi})</span></p>
          </>
        );
      }

    },
    {
      title: 'Details',
      render: (row) => {
        return (
          <div style={{ display: 'grid', gridTemplateColumns: '120px auto' }}>
            <p>
              <span className="fw-bold">S#:</span>
            </p>
            <p>{row.officerId}</p>

            <p>
              <span className="fw-bold">Is Visible:</span>
            </p>
            <p>
              <Tag className="ms-2" color={row.isActive === 0 ? 'error' : (row.isActive === 1 ? 'success' : '')}>
                {row.isActiveName}
              </Tag>
            </p>

            <p>
              <span className="fw-bold">Verify Status:</span>
            </p>
            <p>
              <Tag className="ms-2"
                color={
                  row.verificationStatus === 0
                    ? "processing"
                    : row.verificationStatus === 1
                      ? "success"
                      : row.verificationStatus === 3
                        ? "error"
                        : ""
                }
              >
                {row.statusName}
              </Tag>
            </p>

            <p>
              <span className="fw-bold">Submit Date:</span>
            </p>
            <p>{row.submitDate}</p>

            {row.verificationStatus !== 0 && (
              <>
                <p>
                  <span className="fw-bold">Verify Date:</span>
                </p>
                <p>{row.publishDate}</p>

                <p>
                  <span className="fw-bold">Verify Remark:</span>
                </p>
                <p>{row.verificationremark}</p>
              </>
            )}

            {row.verificationStatus === 0 && row.actionId > 1 && (
              <>
                <p>
                  <span className="fw-bold">Edited Remark:</span>
                </p>
                <p>{row.verificationremark}</p>
              </>
            )}
          </div>
        );
      }

    },
    
    currentUser === "2" ?
      {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (data, record) => (
          <>
            <Space direction="vertical" align="center">
              <Button onClick={() => approveRejectConfirmModal("officerList", "", data)}
                className='btn btn-sm btn-outline-primary'>View</Button>

              {data.verificationStatus === 0 && actionStatusData?.map((elm, id) => (
                <Button key={id} onClick={() => approveRejectConfirmModal("approveRejectModal", elm.value, data)} className={'btn btn-sm ' + (elm.value === '3' ? 'btn-outline-danger' : (elm.value === '1' ? 'btn-outline-success' : ''))}>
                  {elm.label}
                </Button>
              ))}

            </Space>
          </>
        ),
      } :
      {
        title: 'Action',
        align: 'center',
        width: '15%',
        render: (data) => (
          <>
            <Space>
              <Button className="btn btn-sm btn-outline-primary"
                onClick={() => handleEdit(data)}>Edit</Button>
              {/* <Button onClick={() => approveRejectConfirmModal("officerList", data?.officerId, data?.verificationStatus)} */}
              <Button onClick={() => approveRejectConfirmModal("officerList", "", data)}
                className='btn btn-sm btn-outline-primary '>View</Button>
            </Space>
          </>
        ),
      }
  ];


  useEffect(() => {

    const getFilterList = async () => {
      try {
        setLoadingFilterList(true);
        let res = await commonService.getActionCommonStatus();
        const filterData = res.data?.filter(item => {
          return item.value === "0" || item.value === "1" || item.value === "3";
        })

        setCommonListData(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < filterData.length; i++) {
            data.push(
              {
                value: filterData[i].value,
                name: filterData[i].name,
                label: filterData[i].label,
                nameHindi: filterData[i].nameHindi,
                type: filterData[i].type,
                extraField1: filterData[i].extraField1
              }
            )
          }
          return data;
        });
      } catch (error) {
        setLoadingFilterList(false);
      }
      finally {
        setLoadingFilterList(false);
      }
    }

    const getActionStatus = async () => {
      try {

        let res = await commonService.getActionStatus();
        setActionStatusData(res.data);

      } catch (error) {
        console.error(error);
      }

    }

    getFilterList();
    getActionStatus();

  }, [commonService, officerListService]);

  useEffect(() => {
    const getImageById = async (imgDocUniqueId, imgDocMtype) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await officerListService.ViewDocument(imgDocUniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: imgDocMtype });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }

      return demoUser;
    };
    const getOfficersList = async (stausCode) => {
      try {
        setLoadingTblData(true);
        const payload = { isActive: -1, verificationStatus: stausCode, officerId: 0, officersType: 1 };
        const res = await officerListService.GetOfficerDetails(payload);

        // Fetch all image URLs concurrently
        const imgUrlPromises = res.data.table.map(item =>
          getImageById(item.imgDocUniqueId, item.imgDocMtype)
        );
        const imgUrls = await Promise.all(imgUrlPromises);

        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            officerId: item.officerId,
            nameEnglish: item.nameEnglish,
            nameHindi: item.nameHindi,
            postEnglish: item.postEnglish,
            postHindi: item.postHindi,
            designationEnglish: item.designationEnglish,
            designationHindi: item.designationHindi,
            verificationStatus: item.verificationStatus,
            statusName: item.statusName,
            isActive: item.isActive,
            isActiveName: item.activeName,
            submitDate: item.submitDate,
            resumeEn: {

              rsmDocMtype: item.rsmEnDocMtype,
              rsmDocUniqueId: item.rsmEnDocUniqueId,
            },
            resumeHi: {

              rsmDocMtype: item.rsmHiDocMtype,
              rsmDocUniqueId: item.rsmHiDocUniqueId,
            },
            officerImg: {

              imgDocMtype: item.imgDocMtype,
              imgDocUniqueId: item.imgDocUniqueId,
            },
            ImgUrl: imgUrls[index],

            verificationremark: item.verificationRemark,
            actionId: item.actionId,
            publishDate: item.publishDate,
          }));
          return data;
        });
      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getOfficersList(form2.getFieldValue("filterType"));
  }, [loadingModalSubmit, filterType, officerListService, form2])

  const loadTableFilter = (value) => {
    setFilterType({ filterType: value })
  }




  return (
    <>
      {pageload && <Loader />}
      {loadingTblData && <Loader />}
      <Container>
        <Row>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Officers List</h3>
              </Card.Header>
              <Card.Body>



                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg={8} md={6} sm={12}>
                        {currentUser === "3" && (
                          <Link className="btn btn-outline-primary mt-1" to="/user/whoswho/add-edit-officers">
                            <IoPersonAddSharp /> Add New
                          </Link>
                        )}
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <Form form={form2} initialValues={filterType}>
                          <Form.Item name="filterType" label="Filter">
                            <Select
                              showSearch
                              placeholder="Select Filter Type"
                              optionFilterProp="children"
                              onChange={loadTableFilter}
                              options={commonListData}
                              loading={loadingFilterList}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>

                    <div className="tableList">
                      <Table
                        bordered
                        columns={columns}
                        dataSource={tblData}
                        loading={loadingTblData}
                        scroll={{ x: 990,y:600 }}
                        size="small"
                        // pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: ['10', '20', '50', '100'],
                          showTotal: (total, range) => (
                            <span>
                              <strong>{range[0]}-{range[1]}</strong> of <strong>{total}</strong> items
                            </span>
                          ),
                          position: ['bottomRight']
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Modal
              title={modalTitle}
              style={{ top: 20 }}
              open={modal1Open}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => setModal1Open(false)}
              width={formShowHide === false ? 650 : 450}
            >
              {formShowHide && (
                <Form
                  layout="vertical"
                  form={form}
                  name="boilerRegForm"
                  onFinish={modalSubmit}
                  scrollToFirstError
                >
                  <Card className="mb-4">
                    <Card.Body>
                      <Form.Item name="verificationStatus" label="Status">
                        <Select
                          showSearch
                          placeholder="Select Status"
                          optionFilterProp="children"
                          options={modalStatus}
                          loading={loadingFilterList}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>

                      <Form.Item label="Remarks" name="verificationRemark" rules={[{ required: true, message: 'Remarks Required' }]}>
                        <Input.TextArea showCount rows={3} maxLength={300} />
                      </Form.Item>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary me-2"
                          disabled={loadingModalSubmit}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          disabled={loadingModalSubmit}
                          onClick={modalClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                </Form>
              )}

              {formShowHide === false && (


                officerListbyId !== null && (
                  <>

                    <Row className="mt-4">
                      <Col md={12}>
                        <Card className="shadow-sm">
                          <Card.Body>
                            <Card.Title>English/Hindi</Card.Title>

                            <Row className="align-items-center">
                              {/* First Column: Photo and Resume Button */}
                              <Col md={3} className="text-center">
                                <Image
                                  src={officerListbyId[0].ImgUrl}
                                  alt="Officer Image"
                                  style={{
                                    width: "100%",
                                    border: "0.5px solid black",

                                    borderRadius: "5px",
                                  }}
                                />
                                <div className="mt-1">
                                <Button
                                    onClick={() => viewDoc(officerListbyId[0].resumeHi)}
                                    className="btn btn-sm btn-outline-primary"
                                  >
                                    <BsFileEarmarkPdfFill /> Resume Hindi
                                  </Button>
                                  <Button
                                    onClick={() => viewDoc(officerListbyId[0].resumeEn)}
                                    className="btn btn-sm btn-outline-primary mt-1"
                                  >
                                    <BsFileEarmarkPdfFill /> Resume English
                                  </Button>
                                  
                                </div>
                              </Col>

                              {/* Second Column: Details */}
                              <Col md={9} className="">
                                <Card.Text className="">
                                  <span className="fw-bold">
                                    {officerListbyId[0].nameEnglish} <br />
                                    ({officerListbyId[0].nameHindi})
                                  </span>
                                </Card.Text>

                                <Card.Text className="">
                                  {officerListbyId[0].postEnglish} <br />
                                  ({officerListbyId[0].postHindi})
                                </Card.Text>

                                <Card.Text className="">
                                  {officerListbyId[0].designationEnglish} <br />
                                  ({officerListbyId[0].designationHindi})
                                </Card.Text>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    {/* Officer details Overview Details */}
                    <Row className="mt-4">
                      <Col md={6}>
                        <Card className="mb-3 shadow-sm">
                          <Card.Body>
                            <Card.Title>S#</Card.Title>
                            <Card.Text>{officerListbyId[0].officerId}</Card.Text>
                          </Card.Body>
                        </Card>
                        <Card className="mb-3 shadow-sm">
                          <Card.Body>
                            <Card.Title>Status</Card.Title>
                            {officerListbyId[0].verificationStatus === 1 ? (
                              <Badge bg="success"><FaCheckCircle /> {officerListbyId[0].statusName}</Badge>
                            ) : officerListbyId[0].verificationStatus === 0 ? (
                              <Badge bg="warning"><FaHourglassHalf /> {officerListbyId[0].statusName}</Badge>
                            ) : officerListbyId[0].verificationStatus === 3 ? (
                              <Badge bg="danger"><FaTimesCircle /> {officerListbyId[0].statusName}</Badge>
                            ) : (
                              <Badge bg="secondary"><FaQuestionCircle /> Unknown Status</Badge>
                            )}

                          </Card.Body>
                        </Card>

                      </Col>

                      <Col md={6}>
                        <Card className="mb-3 shadow-sm">
                          <Card.Body>
                            <Card.Title>Is Active</Card.Title>
                            <Card.Text>

                              {officerListbyId[0].isActive === 1 ? (
                                <Badge bg="success"><FaCheckCircle /> Active</Badge>
                              ) : (
                                <Badge bg="danger"><FaTimesCircle /> Inactive</Badge>
                              )}
                            </Card.Text>
                          </Card.Body>
                        </Card>



                        <Card className="mb-3 shadow-sm">
                          <Card.Body>
                            <Card.Title>Submit Date</Card.Title>
                            <Card.Text><FaCalendarAlt /> {officerListbyId[0].submitDate}</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    {/* Verification Details */}
                    <Row className="mt-2">
                      <Col md={12}>
                        <Card className="shadow-sm">
                          <Card.Body>
                            <Card.Title> {officerListbyId[0].verificationStatus !== 0 ? "Verification" : "Remark"}</Card.Title>
                            {officerListbyId[0].verificationStatus !== 0 ? (
                              <>
                                <Card.Text><span className="fw-bold"><FaCalendarAlt /> Date: </span>{officerListbyId[0].publishDate}</Card.Text>
                                <Card.Text><span className="fw-bold">Remark: </span>{officerListbyId[0].verificationremark}</Card.Text>
                              </>
                            ) : (
                              <Card.Text>{officerListbyId[0].verificationremark}</Card.Text>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Divider />
                    {/* Close Button for Modal */}
                    <Row className="mt-4">
                      <Col md={12} className="text-center">
                        <button
                          className="btn btn-sm btn-outline-danger"
                          disabled={loadingModalSubmit}
                          onClick={modalClose}
                        >
                          Close
                        </button>
                      </Col>
                    </Row>


                  </>
                )


              )}


            </Modal>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default OfficersList;


