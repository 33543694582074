import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
export default function QuickLinks() {
  const { t, i18n } = useTranslation();
  const QuickLinksData = t("QuickLinks", { returnObjects: true });
  const links = [
    // { href: "https://www.ccostgis.cg.nic.in/", text: "www.ccostgis.cg.nic.in" },
    { 
      href: "https://www.isro.gov.in/", 
      textEn: "Indian Space Research Organisation",
      textHi: "भारतीय अंतरिक्ष अनुसंधान संगठन"
  },
    { 
        href: "https://www.dst.gov.in/", 
        textEn: "Department of Science and Technology",
        textHi: "विज्ञान और प्रौद्योगिकी विभाग"
    },
    { 
        href: "https://www.iirs.gov.in/", 
        textEn: "Indian Institute of Remote Sensing",
        textHi: "भारतीय सुदूर संवेदन संस्थान"
    },
    {
         href: "https://www.nrsc.gov.in/", 
         textEn: "National Remote Sensing Centre",
         textHi: "राष्ट्रीय सुदूर संवेदन केन्द्र (एनआरएससी)" },
    { 
        href: "https://cgstate.gov.in/", 
        textEn: "Hamar Chhattisgarh",
        textHi: "हमर छत्तीसगढ़"
    },
    { 
      href: "https://dprcg.gov.in/", 
      textEn: "Chhattisgarh Public Relations Directorate",
      textHi: "छत्तीसगढ़ जनसंपर्क संचालनालय"
  },
  { 
    href: "https://cmo.cg.gov.in/", 
    textEn: "Chief Minister Office (C.M.O.) ",
    textHi: "मुख्यमंत्री कार्यालय (सी.एम्.ओ.)"
},
    { 
      href: "https://rsc.cgstate.gov.in/", 
      textEn: "Chhattisgarh Regional Science Centre",
      textHi: "छत्तीसगढ़ रीजनल साईंस सेन्टर"
  },
  { 
    href: "https://chhattisgarhmines.gov.in/", 
    textEn: "Chhattisgarh Directorate of Geology and Mining",
    textHi: "छत्तीसगढ़ भौमिकी तथा खनिकर्म संचालनालय"
}
  ];
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const handleClick = (url) => {
    Swal.fire({
      html: lang === 2 ? `
                This link shall take you to a page/ website outside this website. `
        : ` यह लिंक आपको इस वेबसाइट के बाहर एक पेज/वेबसाइट पर ले जाएगा।`,
      icon: "question",
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: lang === 2 ? "OK" : "हाँ",
      denyButtonText: lang === 2 ? "Cancel" : "नहीं"
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(url);
      }
    });
  };
  return (
    <>
      <BreadcrumbLayout title="Quick Links" />
      <Container fluid >
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{QuickLinksData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-3 home2">
                <div className="dash">
                  <Row >

                    {links.map((list, i) => (

                      <Link
                        to={list.href} // Keep the 'to' for routing if necessary, otherwise it can be omitted.
                        className="service"
                        key={i}
                        onClick={(e) => {
                          e.preventDefault(); // Prevents default link behavior
                          handleClick(list.href); // Trigger the confirmation and open the link
                        }}
                      >
                        <span>
                          <FaExternalLinkAlt />
                        </span>
                        <h5 className="mt-1">{
                        lang === 2 ? list.textEn : list.textHi}

                        </h5>
                      </Link>

                    ))}

                  </Row>
                </div>

                {/* <div className="downloadable-list2">

                  <ul className=" ms-4">
                    {links.map((list, i) => (
                      <li key={i}>
                        <div className="d-table-cell">
                        <FaExternalLinkAlt /></div>
                        <div className="d-table-cell">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default link behavior
                              handleClick(list.href);
                            }}

                          >
                            {list.text}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
