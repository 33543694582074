import React from 'react';
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PDFViewer from '../../component/PDFViewer';

function ArchiveNewsDetailsData(props) {
    const { t, i18n } = useTranslation();
    const Data = t("ArchiveNews", {
        returnObjects: true,
    }) || {};

    function getDataById(id) {
        const result = Data.content.find((item) => item.id === id);
        return result;
    }

    const dataFound = getDataById(props.id);

    return (
        <>
            {
                dataFound ?
                    <>
                        <Col xs={12} className="heading">
                            <h3>{dataFound.title}</h3>
                        </Col>
                        <Col xs={12} className="mb-1 mt-3">
                            {props.id === "1" && (
                                <>
                                    <p>Click on the following link to join the meeting: <a href='https://meet.google.com/bmf-ybcv-tcd'>https://meet.google.com/bmf-ybcv-tcd</a></p>
                                    <p>Registration Link: <a href='https://docs.google.com/forms/d/e/1FAIpQLScc5_HqgKqDP1Q2IWnHV1a5CQpIamJDTi-uVeu-vQs_fPah0A/viewform'>https://docs.google.com/forms/d/e/1FAIpQLScc5_HqgKqDP1Q2IWnHV1a5CQpIamJDTi-uVeu-vQs_fPah0A/viewform</a></p>
                                    <p>{i18n.language === "en" ? "Thursday, March 4, 2021" : "बृहस्पतिवार, मार्च 4, 2021"}</p>
                                </>
                            )}

                            {(props.id === "5" || props.id === "6") && (
                                <>
                                    {props.id === "5" && (
                                        <>
                                            <p>Join the meeting on your computer or mobile app through the following link:</p>
                                            <p><a href='https://teams.microsoft.com/_#/pre-join-calling/19:meeting_Y2QyNDU3NDEtZTI5Ny00YWFhLWJhOWUtZDc3Y2Y1ZWQ1Mzcx@thread.v2'>https://teams.microsoft.com/_#/pre-join-calling/19:meeting_Y2QyNDU3NDEtZTI5Ny00YWFhLWJhOWUtZDc3Y2Y1ZWQ1Mzcx@thread.v2</a></p>
                                            <p>{i18n.language === "en" ? "Thursday, February 11, 2021" : "बृहस्पतिवार, फरवरी 11, 2021"}</p>
                                        </>
                                    )}

                                    {props.id === "6" && (
                                        <>
                                            <p>Please register for webinar on Technology Village Programme / Need of technology training for empowering and additional income amongst rural population in Chhattisgarh on January 30, 2021, 12.00 PM to 1.30 PM</p>
                                            <p><a href='https://attendee.gotowebinar.com/register/243265161227645454'>https://attendee.gotowebinar.com/register/243265161227645454</a></p>
                                            <p>After registering, you will receive a confirmation email containing information about joining the webinar</p>
                                            <p>{i18n.language === "en" ? "Wednesday, January 27, 2021" : "बुधवार, जनवरी 27, 2021"}</p>
                                        </>
                                    )}
                                </>
                            )}

                            {(props.id === "9") && (
                                <>
                                    {dataFound.content.map((c, i) => (
                                        <div className="archive-news" key={i}>
                                            <h4 key={i}>{c.title}</h4>
                                            {c.updown_list.map((item, index) => (
                                                <p key={index}>
                                                    <a href={require(`${process.env.REACT_APP_filepath}/${item.fileName}`)} target="_blank" rel="noreferrer">{item.title}</a>
                                                </p>
                                            ))}
                                            {c.bwdown_list.map((item, index) => (
                                                <p key={index}>
                                                    <a href={require(`${process.env.REACT_APP_filepath}/${item.fileName}`)} target="_blank" rel="noreferrer">{item.title}</a>
                                                </p>
                                            ))}
                                            <ul>
                                                {c.down_list.map((item, index) => (
                                                    <li key={index}>
                                                        <a href={require(`${process.env.REACT_APP_filepath}/${item.fileName}`)} target="_blank" rel="noreferrer">{item.title}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                            <p><b>{c.noteDate}</b></p>
                                        </div>
                                    ))}
                                </>
                            )}

                            {(props.id === "11" || props.id === "12" || props.id === "13" || props.id === "14" || props.id === "15" || props.id === "16" || props.id === "18" || props.id === "19" || props.id === "20") && (
                                <PDFViewer fileName={require(`${process.env.REACT_APP_filepath}${dataFound.fileName}`)} />
                                
                            )}

                            {(props.id === "17" || props.id === "21") && (
                                <div className="fromDesk">
                                    {dataFound.paragraph.map((item, index) => (
                                        <p key={index}>{item}</p>
                                    ))}
                                </div>
                            )}
                        </Col>
                    </>
                :
                <>
                <Col xs={12} className="heading">
                            <h3>Not found archive news</h3>
                        </Col>
                </>
            }

        </>
    );
}

export default ArchiveNewsDetailsData;
