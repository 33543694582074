import { Container, Col, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import Loader from "../../global/Loader";
import { AuthenticationService } from "../../Services/AuthService";
import { IoPersonAddSharp } from "react-icons/io5";
import { ImageGalleryService } from "../../Services/ImageGalleryService";
import { Form, Select, Table, Space, Tag, Button, Modal, Input, Image } from "antd";
import { CommonServices } from "../../Services/CommonService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import demoUser from "../../assets/images/demo-user.jpg"
const MapGalleryList = () => {
  const authService = new AuthenticationService();
  const commonService = useMemo(() => new CommonServices(), []);
  const imageGalleryService = useMemo(() => new ImageGalleryService(), []);
  const currentUser = authService.getUserData?.role;

  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [commonListData, setCommonListData] = useState();
  const [loadingFilterList, setLoadingFilterList] = useState(false);
  const [actionStatusData, setActionStatusData] = useState();
  const [modal1Open, setModal1Open] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [imageId, setPosterId] = useState();
  const [modalStatus, setModalStatus] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [formShowHide, setFormShowHide] = useState(false);

  const navigate = useNavigate();
  const [filterType, setFilterType] = useState({ filterType: "-1" });
  const approveRejectConfirmModal = (type, approveRejectValue, data) => {
    setModal1Open(true);
    if (type === "approveRejectModal") {
      setModalTitle("Verify");
      setFormShowHide(true);
      form.resetFields();
      setPosterId(data?.imageId);
      const filterData = commonListData?.filter(item => {
        return item.value === approveRejectValue;
      });
      setModalStatus(filterData);
      form.setFieldValue('verificationStatus', approveRejectValue);
    }
  }

  const handleEdit = (data) => {
    navigate("/user/image-gallery-entry", { state: { data: data, EditFlag: true } });
  }

  const modalClose = () => {
    setModal1Open(false);
  };

  const [loadingModalSubmit, setLoadingModalSubmit] = useState(false);
  const modalSubmit = async () => {
    try {
      setLoadingModalSubmit(true);
      let payload = {
        imageId: imageId,
        verificationStatus: form.getFieldValue('verificationStatus'),
        verificationRemark: form.getFieldValue('verificationRemark')
      }
      const res = await imageGalleryService.VerifyImageDetails(payload);
      if (res.data.status) {
        Swal.fire({
          html: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message
        });
      }
      modalClose();
    }
    catch (error) {
      console.error(error);
      setLoadingModalSubmit(false);
    }
    finally {
      setLoadingModalSubmit(false);
    }
  }
  const columns = [
    {
      title: '#',
      align: 'center',
      render: (record) => record.key,
      width: '5%'
    },
    {
      title: 'Image/Doc',
      align: "center",
      render: (row) => {
        return (
          <>

            <Image
              src={row.ImgUrl}
              alt="Imagesdghsd"
              style={{ width: "100%", height: "", border: "1px solid black", padding: "5px", borderRadius: "5px" }}
            />

          </>
        )
      },
      width: '20%'
    },

    {
      title: 'Image Gallery Details',
      // align: 'center',
      // width: '20%',
      render: (row) => {
        return (
          <>
            <div style={{ lineHeight: '1.5' }}>

              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Image Category:</span> {row.imgCatTypeName}
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Desc. Hindi:</span> {row.descHindi}
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Desc. English:</span> {row.descEnglish}
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Poster Id:</span> {row.imageId}
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Is Visible:</span>
                &nbsp; <Tag color={row.isActive === 0 ? 'error' : 'success'}>{row.isActiveName}</Tag>
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Verification Status:</span> &nbsp;
                <Tag
                  color={
                    row.verificationStatus === 0
                      ? 'processing'
                      : row.verificationStatus === 1
                        ? 'success'
                        : 'error'
                  }
                >
                  {row.statusName}
                </Tag>
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Submit Date:</span> {row.submitDate}
              </p>
              {row.verificationStatus !== 0 ? (
                <>
                  <p style={{ marginBottom: '5px' }}>
                    <span className="fw-bold">Verification Date:</span> {row.publishDate}
                  </p>
                  <p style={{ marginBottom: '5px' }}>
                    <span className="fw-bold">Verification Remark:</span> {row.verificationremark}
                  </p>
                </>
              ) : row.actionId > 1 ? (
                <p style={{ marginBottom: '5px' }}>
                  <span className="fw-bold">Edited Remark:</span> {row.verificationremark}
                </p>
              ) : (
                row.verificationremark !== null ?
                  (
                    <p style={{ marginBottom: '5px' }}>
                      <span className="fw-bold">Remark:</span> {row.verificationremark}
                    </p>
                  ) : null)
              }

            </div>
          </>
        )
      }
    },
    currentUser === "2" ?
      {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (data, record) => (
          <>
            <Space direction="vertical" align="center">
              {data.verificationStatus === 0 && actionStatusData?.map((elm, id) => (
                <Button key={id} onClick={() => approveRejectConfirmModal("approveRejectModal", elm.value, data)} className={'btn btn-sm ' + (elm.value === '3' ? 'btn-outline-danger' : (elm.value === '1' ? 'btn-outline-success' : ''))}>
                  {elm.label}
                </Button>
              ))}

            </Space>
          </>
        ),
      } :
      {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (data) => (
          <>
            <Space>
              <Button className="btn btn-sm btn-outline-primary"
                onClick={() => handleEdit(data)}>Edit</Button>

            </Space>
          </>
        ),
      }
  ];

  useEffect(() => {
    const getFilterList = async () => {
      try {
        setLoadingFilterList(true);
        let res = await commonService.getActionCommonStatus();
        const filterData = res.data?.filter(item => {
          return item.value === "0" || item.value === "1" || item.value === "3";
        })

        setCommonListData(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < filterData.length; i++) {
            data.push(
              {
                value: filterData[i].value,
                name: filterData[i].name,
                label: filterData[i].label,
                nameHindi: filterData[i].nameHindi,
                type: filterData[i].type,
                extraField1: filterData[i].extraField1
              }
            )
          }
          return data;
        });
      } catch (error) {
        setLoadingFilterList(false);
      }
      finally {
        setLoadingFilterList(false);
      }
    }

    const getActionStatus = async () => {
      try {

        let res = await commonService.getActionStatus();
        setActionStatusData(res.data);

      } catch (error) {
        console.error(error);
      }

    }

    getFilterList();
    getActionStatus();

  }, [commonService]);

  useEffect(() => {
    const getImageById = async (imgDocUniqueId, imgDocMtype) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await imageGalleryService.ViewDocument(imgDocUniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: imgDocMtype });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }

      return demoUser;
    };
    const getImageGalleryList = async (stausCode) => {
      try {
        setLoadingTblData(true);
        const payload = { isActive: -1, verificationStatus: stausCode, imageId: 0, imageCategoryType: -1, galleryType: 1 };
        const res = await imageGalleryService.GetImageDetails(payload);
        const imgUrlPromises = res.data.table.map(item =>
          getImageById(item.docUniqueId, item.docMimeType)
        );
        const imgUrls = await Promise.all(imgUrlPromises);

        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            imageId: item.imageId,
            imagenotes: item.imagenotes,
            descHindi: item.descHindi,
            descEnglish: item.descEnglish,
            imgCatTypeName: item.imgCatTypeName,
            imageCategoryType: item.imageCategoryType,
            actionId: item.actionId,
            verificationStatus: item.verificationStatus,
            statusName: item.statusName,
            isActive: item.isActive,
            isActiveName: item.activeName,
            submitDate: item.submitDate,
            Img: {
              imgMimeType: item.docMimeType,
              imgUniqueId: item.docUniqueId,
            },
            ImgUrl: imgUrls[index],

            verificationremark: item.verificationRemark,
            publishDate: item.publishDate,
          }));
          return data;
        });
      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getImageGalleryList(form2.getFieldValue("filterType"));
  }, [loadingModalSubmit, filterType, imageGalleryService, form2])

  const loadTableFilter = (value) => {
    setFilterType({ filterType: value })
  }
  return (
    <>
      {loadingTblData && <Loader />}
      <Container style={{ marginTop: "-30px " }}>
        <Row style={{ marginTop: "-15px" }}>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Image Gallery List</h3>
              </Card.Header>
              <Card.Body>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg={8} md={6} sm={12}>
                        {currentUser === "3" &&
                          <Link className="btn btn-outline-primary mt-1" to="/user/image-gallery-entry">
                            <IoPersonAddSharp /> Add New
                          </Link>

                        }
                      </Col>
                      <Col lg={4} md={6} sm={12} className="float-end">
                        <Form form={form2} initialValues={filterType}>
                          <Form.Item name="filterType" label="Filter">
                            <Select showSearch placeholder="Select Filter Type"
                              optionFilterProp="children" onChange={loadTableFilter}
                              options={commonListData} loading={loadingFilterList}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>

                    <div className="tableList">
                      <Table
                        bordered
                        columns={columns}
                        dataSource={tblData}
                        loading={loadingTblData}
                        scroll={{ x: 800, y: 400 }}
                        size="small"
                        pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Modal
              title={modalTitle}
              style={{ top: 20 }}
              open={modal1Open}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => setModal1Open(false)}
              width={formShowHide === false ? 900 : 450}
            >
              {formShowHide &&
                <Form
                  layout="vertical"
                  form={form}
                  name="boilerRegForm"
                  onFinish={modalSubmit}
                  scrollToFirstError
                >
                  <Card>
                    <Card.Body>
                      <Col md={12}>
                        <Form.Item name="verificationStatus" label="Status">
                          <Select showSearch placeholder="Select Status"
                            optionFilterProp="children"
                            options={modalStatus} loading={loadingFilterList}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} className="clearfix">
                        <Form.Item label="Remarks" name="verificationRemark" rules={[
                          { required: true, message: 'Remarks Required' },
                        ]}>
                          <Input.TextArea showCount rows={3} maxLength={300} />
                        </Form.Item>
                      </Col>

                      <Col md={12}>
                        <hr></hr>
                      </Col>
                      <Col md={12} className="text-center">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary me-2"
                          disabled={loadingModalSubmit}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          disabled={loadingModalSubmit}
                          onClick={modalClose}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Card.Body>
                  </Card>
                </Form>
              }
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MapGalleryList;


