import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import HeadPara2 from "../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "./ATCSidebar";
import DownloadableList from "../../component/DownloadableList";
export default function ForestEcoPlanCentre() {
  const { t } = useTranslation();
  const ForestEcoPlanCentre = t("ForestEcoPlanCentre", { returnObjects: true, });
  const menuData = t("menu.activities-of-council", { returnObjects: true, }) || {};


  return (
    <>
    
      <BreadcrumbLayout title="Forest Eco-Plan Centre" />
      <Container fluid>
        <Row>
      {/* Sidebar */}
      <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{ForestEcoPlanCentre.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {ForestEcoPlanCentre.content.map((items, index) => (
                  <HeadPara2 key={index} data={items} />
                ))}
                
                    <div className="downloadable-list2">
                    <DownloadableList _contentType = "32" _className="ms-4"/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}