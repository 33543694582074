import { Col, Container, Nav, Row, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSitemap } from "react-icons/fa";
import { Tooltip } from "antd";
import SwitchTextSize from "./SwitchTextSize";
import { useState, useEffect } from "react";
import { GrYoutube } from "react-icons/gr";
import { FaFacebook } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { IoDownloadSharp } from "react-icons/io5";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { MdLightMode, MdDarkMode } from "react-icons/md";
import { AiFillSound } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { Link } from 'react-router-dom'; // Import the Link component

function SubHeader2() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'hi');
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') === 'dark');

  useEffect(() => {
    i18n.changeLanguage(language);
    document.body.classList.toggle('dark-mode', localStorage.getItem('theme') === 'dark');
  }, [language, i18n]);

  const toggleLanguage = () => {
    const newLanguage = language === 'hi' ? 'en' : 'hi';
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  const toggleTheme = () => {
   // const newTheme = isDarkMode ? 'light' : 'dark';
    setIsDarkMode(!isDarkMode);
   // localStorage.setItem('theme', newTheme);
   // document.body.classList.toggle('dark-mode', newTheme === 'dark');
  };

  return (
    <div className="sub-header2">
    
      <Container fluid="xxl" >
        <Row className="d-flex justify-content-center">
          <Col md={12} lg={12} className="mx-auto">
            <Nav as="ul" className="font-acc m-1 justify-content-evenly">
              <Nav.Item as="li">
                <Tooltip placement="bottom" title="Youtube">
                  <Nav.Link href="https://www.youtube.com/@ccostraipur8046"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GrYoutube style={{ color: "red" }} />
                  </Nav.Link>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li">
                <Tooltip placement="bottom" title="Facebook">
                  <Nav.Link href="https://www.facebook.com/profile.php?id=61556133871627"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook style={{ color: "#3b5998" }} />
                  </Nav.Link>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li">
                <Tooltip placement="bottom" title="X-Twitter">
                  <Nav.Link href="https://x.com/CcostRaipur"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaSquareXTwitter style={{ color: "#000000", backgroundColor: "white" }} />
                  </Nav.Link>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li">
                <Tooltip placement="bottom" title="Instagram">
                  <Nav.Link href="https://www.instagram.com/ccostraipur01/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagramSquare style={{ color: "rgb(228, 64, 95)" }} />
                  </Nav.Link>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li">
                <Tooltip placement="bottom" title="Gmail">
                  <Nav.Link href="mailto:dgccost@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BiLogoGmail style={{ color: "#dd4b39" }} />
                  </Nav.Link>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li">
                <Tooltip placement="bottom" title="Skip to main content">
                  <Nav.Link href="#main-content">
                    <span>
                      <IoDownloadSharp style={{ fontSize: "1.5rem" }} />
                    </span>
                    <span className="d-none d-lg-inline-block">
                      {t("subHeader.mainContent")}
                    </span>
                  </Nav.Link>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li"   className="mt-1">
                <Tooltip placement="bottom" title="Screen Reader Access">
                  <Link to="/screen-reader-access">
                    <span>
                      <AiFillSound />
                    </span>
                    <span className="d-none d-lg-inline-block">
                      {t("subHeader.screenReader")}
                    </span>
                  </Link>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li" className="mt-1">
                <Tooltip placement="bottom" title="Sitemap">
                  <Link to="site-map" >
                    <span>
                      <FaSitemap />
                    </span>
                    <span className="d-none d-lg-inline-block">
                      {t("subHeader.sitemap")}
                    </span>
                  </Link>
                </Tooltip>
              </Nav.Item>
              <SwitchTextSize />
              <Nav.Item as="li">
                <Tooltip placement="bottom" title={isDarkMode ? "Light Mode" : "Dark Mode"}>
                  <button
                    onClick={toggleTheme}
                  >
                    {isDarkMode ? <MdLightMode /> : <MdDarkMode />}
                  </button>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li">
                <Tooltip placement="bottom" title={language === 'hi' ? 'In English' : 'हिंदी में'}>
                  <button
                    onClick={toggleLanguage}
                  >
                    {language === 'hi' ? 'In English' : 'हिंदी में'}
                  </button>
                </Tooltip>
              </Nav.Item>
              <Nav.Item as="li">
                <div className="wrap">
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button
                      variant="outline-primary"
                      style={{
                        marginLeft: "-7px"
                      }}
                    >
                      <FaSearch />
                    </Button>
                  </Form>
                </div>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SubHeader2;
