import React from "react";
import { Container, Row } from "react-bootstrap";
import { FaFacebook } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { NavLink } from "react-router-dom";
// import BottomSlider from "./BottomSlider";
import { useTranslation } from "react-i18next";
import { GrYoutube } from "react-icons/gr";
import { MdMapsHomeWork } from "react-icons/md";
// import hitcounter from "../assets/images/hitcounter.png"
import { FaPhoneVolume } from "react-icons/fa6";
import { Tooltip } from "antd";


const Footer = () => {
  const [t] = useTranslation();
  const d = new Date();
  let year = d.getFullYear();
  return (
    <>
      {/* <BottomSlider /> */}
      
     

          <div className="container-fluid " style={{ backgroundColor: "#1a2038"}}>
            <div className="row">
              <footer className="text-center text-md-start text-white site-footer">
              <Container fluid="xxl">
              <Row className="">
                <div className="container-fluid">

                  <section className="">

                    <div className="row">

                      <div className="col-md-6 col-lg-4 col-xl-4 mx-auto mt-3">
                        <h5 className="text-uppercase mb-4 font-weight-bold section-title">{t('footer.keys.note')}
                       
                        </h5>
                        <p className="text-justify" dangerouslySetInnerHTML={{ __html: t('footer.note') }}></p>

                        <h6>{t('footer.keys.lastupdate')}: 18-Oct-2024 4:00 pm</h6>
                        <h6>{t('footer.keys.sitevisitors')}:

                          <img src="https://services.cg.nic.in/hitcounter/hitcounter.php?id=107"
                            className="img-fluid"  alt="" />

                          {/* <img src={hitcounter}
                          className="img-fluid" alt="sitevisitorsCountImg" width="150" /> */}

                        </h6>


                      </div>


                      <hr className="w-100 clearfix d-md-none" />



                      <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                        <h5 className="text-uppercase mb-4 font-weight-bold section-title">{t('footer.keys.usefullLink')}
                       
                        </h5>
                        <ul className="footer-links text-center">




                          <li>
                            <NavLink to="/terms-of-use">{t('footer.usefullLink.link9')}</NavLink>

                          </li>
                          <li>
                            <NavLink to="/privacy-policy">{t('footer.usefullLink.link7')}</NavLink>

                          </li>
                          <li>
                            <NavLink to="/hyperlink-policy">{t('footer.usefullLink.link6')}</NavLink>

                          </li>
                          <li>
                            <NavLink to="/copyright-policy">{t('footer.usefullLink.link3')}</NavLink>

                          </li>
                          <li>

                            <NavLink to="/terms-and-conditions">{t('footer.usefullLink.link8')}</NavLink>
                          </li>
                        </ul>
                      </div>
                      <hr className="w-100 clearfix d-md-none" />
                      <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                        <h5 className="text-uppercase mb-4 font-weight-bold section-title">{t('footer.keys.quicklink')}
                       
                        </h5>
                        <ul className="footer-links text-center">
                          <li>
                            <NavLink to="/help">{t('footer.usefullLink.link10')}</NavLink>
                          </li>

                          <li>

                            <NavLink to="/site-map">{t('footer.usefullLink.link4')}</NavLink>
                          </li>
                          <li>

                            <NavLink to="/Feedback">{t('footer.usefullLink.link5')}</NavLink>
                          </li>

                          <li>


                            <NavLink to="/disclaimer">{t('footer.usefullLink.link2')}</NavLink>
                          </li>
                          <li>

                            <NavLink to="/accessibility-statement">{t('footer.usefullLink.link1')}</NavLink>
                          </li>
                        </ul>
                      </div>

                      <hr className="w-100 clearfix d-md-none" />
                      <div className="col-md-12 col-lg-3 col-xl-3 mx-auto mt-3 text-center text-lg-start" >
                        <h5 className="text-uppercase mb-4 font-weight-bold section-title">{t("Contact.title")}
                       
                        </h5>

                        <div className="d-flex justify-content-sm-center align-items-sm-center mb-2">
                          <span className="d-table-cell"> <MdMapsHomeWork /></span>
                          <span className="d-table-cell mx-2"> </span>
                          <span className="d-table-cell">{t("Contact.address.data")}</span>
                        </div>

                        <div className="d-flex justify-content-sm-center align-items-sm-center mb-2">
                          <span className="d-table-cell"> <FaPhoneVolume /></span>
                          <span className="d-table-cell mx-2"> </span>
                          <span className="d-table-cell">{t("Contact.contactNo.data")}</span>
                        </div>

                        {/* <p><FaPhoneVolume /> <span className="ms-2">{t("Contact.contactNo.data")} </span></p> */}
                        <div className="text-left">
                          <Tooltip placement="bottom" title="Youtube">
                            <a className="btn btn-primary btn-floating m-1" style={{ backgroundColor: "#FF0606" }} href="https://www.youtube.com/@ccostraipur8046" role="button" target="_blank" rel="noopener noreferrer"><GrYoutube /></a>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Facebook">
                            <a className="btn btn-primary btn-floating m-1" style={{ backgroundColor: "#3b5998" }} href="https://www.facebook.com/profile.php?id=61556133871627" role="button" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                          </Tooltip>
                          <Tooltip placement="bottom" title="X-Twitter">
                            <a className="btn btn-primary btn-floating m-1" style={{ backgroundColor: "#000000" }} href="https://x.com/CcostRaipur" role="button" target="_blank" rel="noopener noreferrer"><FaSquareXTwitter /></a>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Instagram">
                            <a className="btn btn-primary btn-floating m-1" style={{ backgroundColor: "#f62782" }} href="https://www.instagram.com/ccostraipur01/?hl=en" role="button" target="_blank" rel="noopener noreferrer"><FaInstagramSquare /></a>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Gmail">
                            <a className="btn btn-primary btn-floating m-1" style={{ backgroundColor: "#dd4b39" }} href='mailto:dgccost@gmail.com' role="button" target="_blank" rel="noopener noreferrer"><BiLogoGmail /></a>
                          </Tooltip>






                        </div>
                      </div>

                      <hr className="w-100 clearfix d-md-none mt-2" />
                      <hr className="w-100 clearfix d-md-none" />
                    </div>
                  </section>
                </div>
               
                </Row>
                
                </Container>
                <div className="container-fluid text-center align-items-center justify-content-center p-3 mt-2" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                  {/* <p style={{textAlign:"center"}} >&copy; {year} {t('footer.copyright')}</p> */}
                  <p style={{textAlign:"center"}} dangerouslySetInnerHTML={{ __html: `© ${year} ${t('footer.copyright')}` }}></p>

                </div>
              </footer>
            </div>


          </div>



       

    </>
  );
};



export default Footer;
