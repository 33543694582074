
import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import HeadPara2 from "../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "./ATCSidebar";

export default function EstablishmentofSNRMS() {
  const { t } = useTranslation();
  const EstablishmentOfSNRMS = t("EstablishmentOfSNRMS", {
    returnObjects: true,
  });
  const menuData = t("menu.activities-of-council", {
    returnObjects: true,
  }) || {};

  return (
    <>
      <BreadcrumbLayout title="Establishment of SNRMS" />
      <Container fluid>
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{EstablishmentOfSNRMS.title}</h3>
              </Col>
              <Col xs={12}>
                {EstablishmentOfSNRMS.content.map((items, index) => (
                  <HeadPara2 key={index} data={items} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
