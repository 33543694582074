import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
import Loader from "../../global/Loader";
import { PresentationService } from "../../Services/PresentationService";
import { Form, Select, Table, Button } from "antd";
import Swal from "sweetalert2";

const PresentationPreview = () => {
  const presentationService = useMemo(() => new PresentationService(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [laguage, setLanguage] = useState([]);
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({ Language: "1" });

  const [pageLoad, setPageLoad] = useState(false);

  const viewDoc = async (doc) => {
    try {
      setPageLoad(true);
      if (doc.DocUniqueId !== null) {
        const res = await presentationService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The document hasn't been uploaded yet. Please try again later.",
        });
      }
    }
    catch (error) {
      console.error("Error fetching documents:", error);

    }
    finally {
      setPageLoad(false);
    }


  };

  const columns = [
    {
      title: "#",
      align:'center',
      render: (record) => record.key,
      width: "5%",
    },
    {
      title:form.getFieldValue("Language") === "1" ? "प्रेजेंटेशन शीर्षक (प्रेजेंटेशन दिनांक):" : "Presentation Title (Presentation Date)",
      render: (row) => {
        return (
          <>
            <div className="project-details">
              <h5><span className="fw-bold">{row.title} </span> </h5>
              <p><span className="fw-bold">{form.getFieldValue("Language") === "1" ? "प्रेजेंटेशन दिनांक:" : "Date of Presentation:"}   </span>{row.presentationDate}</p>
            </div>
          </>
        )
      }
    },
    {
      title: form.getFieldValue("Language") === "1" ? "विवरण देखें" : "View Details",
      render: (row) => {
        return (
          <>
          <div className="col text-center">
            <Button
              onClick={() => viewDoc(row.doc)}
              className="btn btn-sm btn-outline-primary mt-2 "
            >
              { form.getFieldValue("Language") === "1" ? "देखें" : "View "}
            </Button>
            </div>
          </>
          
        )
      },
      width: '10%'
    },
  ];

  useEffect(() => {
    setLanguage([
      {
        value: "1",
        name: "Hindi",
        label: "Hindi",
        nameHindi: "",
        type: "",
        extraField1: "",
      },
      {
        value: "2",
        name: "English",
        label: "English",
        nameHindi: "",
        type: "",
        extraField1: "",
      },
    ]);
  }, []);

  useEffect(() => {
    const getPresentationList = async (language) => {
      try {
        setLoadingTblData(true);
        const res = await presentationService.GetPresentationListPublic(language);
        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            contentTypeName: item.contentTypeName,
            contentId: item.contentId,
            title: item.title,
            presentationDate: item.presentationDate,
            verificationStatus: item.verificationStatus,
            statusName: item.statusName,
            isActive: item.isActive,
            isActiveName: item.activeName,
            verificationremark: item.verificationRemark,
            publishDate: item.publishDate,
            submitDate: item.submitDate,
            doc: {
              DocUniqueId: item.uniqueId,
              DocMimeType: item.documentMimeType,

            },
          }));
          return data;
        });
      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getPresentationList(form.getFieldValue("Language"));
  }, [initialValues,form, presentationService]);



  const loadTableFilter = (language) => {
    setInitialValues({ Language: language });
  };



  return (
    <>
      {loadingTblData && <Loader />}
      {pageLoad && <Loader />}
      <Container>
        <Row>
          <Col className="dashboard">
            <div className="title">
              <div className="title">
                <h3>Presentation Preview</h3>
              </div>
            </div>
            <div className="content">
              <Row>
                <Col lg={8} md={6} className="float-start">
                  {/* <Link className="title-btn" to="/user/manage/content-list">
                  <TbArrowBackUp /> Back
                </Link> */}
                </Col>
                <Col  lg={4} md={6} className="float-end">
                <Form form={form} initialValues={initialValues}>
                 
                    <Form.Item name="Language" label="Language:">
                      <Select
                        showSearch
                        placeholder="Select Language"
                        optionFilterProp="children"
                        onChange={(value) => loadTableFilter(value)}
                        options={laguage}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(
                            input.toLowerCase()
                          )
                        }
                      />
                    </Form.Item>
                 
                </Form>
                </Col>
              </Row>
              <Row>
                <div className="tableList table-responsive-md">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={tblData}
                    loading={loadingTblData} //
                    scroll={{ x: 700 }}
                    size="small"
                  />
                </div>
              </Row>

            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PresentationPreview;



