import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { AuthenticationService } from "../Services/AuthService";
import { Button, Modal, Input, Form } from "antd";
import { set } from "../global/Encryption";
import { UserService } from "../Services/UserService";
import Loader from "../global/Loader";
import Swal from "sweetalert2";
import DemoUser from "../assets/images/demo-user.jpg";

const ChangePassword = () => {
    const authService = useMemo(() => new AuthenticationService(), []);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loadingModalSubmit, setLoadingModalSubmit] = useState(false);
    const userService = useMemo(() => new UserService(), []);
    const [tblData, setTblData] = useState([]);
    const [loadingTblData, setLoadingTblData] = useState(false);
    const userId = authService.getUserData?.userId;



    useEffect(() => {
        const getUserDetailsById = async (userId) => {
            try {
                setLoadingTblData(true);
                const res = await userService.GetUserDetailsById(userId);
                let data = res.data.table.map((item, index) => ({
                    key: index + 1,
                    userId: item.userId,
                    emailId: item.emailId,
                    oldPassword: item.oldPassword,
                    userRole: item.userRole,
                    userName: item.userName
                }));
                setTblData(data);
            } catch (error) {
                console.error("Error fetching GetUserDetailsById:", error);
            } finally {
                setLoadingTblData(false);
            }
        };

        if (userId) {
            getUserDetailsById(userId);
        }
    }, [userService, userId]);

    const modalSubmit = async () => {
        try {
            setLoadingModalSubmit(true);

            if (tblData[0].oldPassword === set(form.getFieldValue('oldPassword'))) {

                if (tblData[0].oldPassword !== set(form.getFieldValue('newPassword'))) {

                    let payload = {
                        userId: authService.getUserData?.userId,
                        newPassword: set(form.getFieldValue('newPassword'))
                    };
                    console.log(payload);
                    const res = await userService.ChageUserPassword(payload);
                    if (res.data.status) {
                        Swal.fire({
                            html: res.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 2000
                        });
                        authService.logout();

                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res.data.message
                        });
                    }
                    modalClose();
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: 'New password must be different. Try again..'
                    });
                    form.resetFields();
                }




            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: 'Old password does not match. Try again..'
                });
            }


        } catch (error) {
            console.error(error);
            setLoadingModalSubmit(false);
        } finally {
            setLoadingModalSubmit(false);
        }
    };

    const showModal = () => {
        form.resetFields();
        setIsModalVisible(true);
    };

    const modalClose = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    return (
        <>
            {loadingTblData && <Loader />}
            <Container>
                <Row>
                    <Col className="dashboard">
                        <Card className="">
                            <Card.Header>
                                <h3>Change Password</h3>
                            </Card.Header>
                            <div className="password-change mb-5">
                                <div className="wrapper">
                                    <div className="user-card">
                                        <div className="user-card-img">
                                            <img src={DemoUser} alt="User" />
                                        </div>
                                        {tblData.length > 0 ? (
                                            <div className="user-card-info">
                                                <h2>{tblData[0].userName}</h2>
                                                <p><span>Email:</span> {tblData[0].emailId}</p>
                                                <p><span>User S#:</span>{tblData[0].userId}</p>
                                                <p><span>User Role:</span> {tblData[0].userRole}</p>
                                                <Button className='btn btn-sm btn-outline-primary mt-3' onClick={showModal}>
                                                    Change Password
                                                </Button>
                                            </div>
                                        ) : ""}
                                    </div>
                                </div>
                            </div>
                        </Card>


                        <Modal
                            title="Change Password"
                            style={{ top: 20 }}
                            open={isModalVisible}
                            okButtonProps={{ hidden: true }}
                            cancelButtonProps={{ hidden: true }}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            <Form
                                layout="vertical"
                                form={form}
                                name="passwordChangeForm"
                                onFinish={modalSubmit}
                                scrollToFirstError
                            >

                                <Card>
                                    <Card.Body>
                                        <Col md={12}>
                                            <Form.Item
                                                label="Old Password"
                                                name="oldPassword"
                                                rules={[{ required: true, message: 'Please input your old password!' }]}
                                            >
                                                <Input.Password autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Item
                                                label="New Password"
                                                name="newPassword"
                                                rules={[
                                                    { required: true, message: 'Please input your new password!' },
                                                    {
                                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                                        message: 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.',
                                                    },
                                                ]}
                                            >
                                                <Input.Password autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Item
                                                label="Confirm Password"
                                                name="confirmPassword"
                                                dependencies={['newPassword']}
                                                rules={[
                                                    { required: true, message: 'Please confirm your new password!' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('newPassword') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('The two passwords do not match!'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                            <hr />
                                        </Col>
                                        <Col md={12} className="text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-sm btn-primary me-2"
                                                disabled={loadingModalSubmit}
                                            >
                                                Submit
                                            </button>
                                            <button
                                                className="btn btn-sm btn-outline-danger"
                                                disabled={loadingModalSubmit}
                                                onClick={modalClose}
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Form>
                        </Modal>

                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ChangePassword;
