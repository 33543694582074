import React from 'react'
import { TbRefresh } from "react-icons/tb";
import { Form, Input, Image, Spin } from "antd";

const Captcha = function({ imgData, refresh, minLength, maxLength, isLoading, rules }) {
    return (
        React.createElement(React.Fragment, null,
            isLoading &&
            React.createElement(Spin, { tip: "Loading Captcha" },
                React.createElement("div", { className: "py-5 bg-white" })
            ),
            !isLoading &&
            React.createElement(React.Fragment, null,
                React.createElement(Image, {
                    src: `data:image/png;base64,${imgData}`,
                    alt: "captcha",
                    className: "captch-image",
                    preview: false,
                    width: '80%'
                }),
                React.createElement(TbRefresh, { onClick: refresh, title: "Refresh Captcha", role: "button", className: "ms-2" })
            ),
            React.createElement(Form.Item, { name: "captcha", rules: rules },
                React.createElement(Input, { placeholder: "Enter captcha Here", className: "rounded-pill", minLength: minLength, maxLength: maxLength })
            )
        )
    );
};

export default Captcha;

