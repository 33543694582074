
import { Button, Divider, Input, Upload, message, Select, List, Switch } from "antd";
import Form from "antd/es/form";
import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ProjectService } from "../../Services/ProjectService";
import { readFileDataAsBase64 } from "../../global/FileConverter";
import Loader from "../../global/Loader";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TbTrashX } from "react-icons/tb";
import { FilePdfOutlined } from '@ant-design/icons';
import { TbArrowBackUp } from "react-icons/tb";
import { Link } from "react-router-dom";
import { AuthenticationService } from "../../Services/AuthService";

const CGRCProjectEntry = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [loadingFormSubmit, setLoadingFormSubmit] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const [docEnglishFileList, setDocEnglishFileList] = useState([]);
  const [DocEnglishFileValue, setDocEnglishFileValue] = useState(null);


  const [docHindiFileList, setDocHindiFileList] = useState([]);
  const [DocHindiFileValue, setDocHindiFileValue] = useState(null);
  const [projectId, setProjectId] = useState();
  const [title, setTitle] = useState("Add");

  const projectService = useMemo(() => new ProjectService(), []);

  const [projectAreasList, setProjectAreasList] = useState([]);
  const [loadingDDL, setLoadingDDL] = useState(true);
  const [projectTypeList, setProjectTypeList] = useState([]);


  const navigate = useNavigate();
  const values = Form.useWatch([], form);
  useEffect(() => {
    const authService = new AuthenticationService();
    if (authService.getUserData?.role !== "3") {
      navigate('/user/unauthorized'); // Redirect to error page if role is not admin
    }
  }, [navigate]);
  useEffect(() => {
    const getContentTypes = async (type) => {
      try {
        setLoadingDDL(true);
        if (type === "ProjectAreas") {
          const res = await projectService.getCommonList(type);
          setProjectAreasList(res.data)

        }
        else if (type === "ProjectType") {
          const res = await projectService.getCommonList(type);
          setProjectTypeList(res.data)
        }

      }
      catch (error) {
        setLoadingDDL(false);
      }
      finally {
        setLoadingDDL(false);
      }
    }

    getContentTypes("ProjectType");
    getContentTypes("ProjectAreas");

  }, [projectService]);


  useEffect(() => {


    if (location?.state?.EditFlag === true) {


      form.setFieldsValue({
        projectAreas: location?.state?.data?.projectAreas.toString(),
        projectType: location?.state?.data?.projectType.toString(),
        titleEnglish: location?.state?.data?.titleEnglish,
        titleHindi: location?.state?.data?.titleHindi,
        investigatorEnglish: location?.state?.data?.investigatorEnglish,
        investigatorHindi: location?.state?.data?.investigatorHindi,
        tenureEnglish: location?.state?.data?.tenureEnglish,
        tenureHindi: location?.state?.data?.tenureHindi,
        isActive: (location?.state?.data?.isActive === 1) ? true : false

      })
      setProjectId(location?.state?.data?.projectId)
      setTitle("edit")
    }
  }, [location?.state, form])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormDisabled(true);
      },
      () => {
        setFormDisabled(false);
      }
    );
  }, [values, form]);








  useEffect(() => {
    const genRandomKey = async () => {
      if (docEnglishFileList && docEnglishFileList.length > 0) {
        try {
          let data = await readFileDataAsBase64(docEnglishFileList[0]);
          setDocEnglishFileValue(() => ({
            documentExtension: ".pdf",
            documentMimeType: "application/pdf",
            documentTag: "ProjectDocEnglish",
            documentType: 5,
            documentImageGroup: 2,
            stateId: 22,
            documentList: [
              {
                documentInByte: data,
                documentLabel: "ProjectDocEnglish",
              },
            ],
          }));
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }
    };

    genRandomKey();
  }, [docEnglishFileList]);

  useEffect(() => {
    const genRandomKey = async () => {
      if (docHindiFileList && docHindiFileList.length > 0) {
        try {
          let data = await readFileDataAsBase64(docHindiFileList[0]);
          setDocHindiFileValue(() => ({
            documentExtension: ".pdf",
            documentMimeType: "application/pdf",
            documentTag: "ProjectDocHindi",
            documentType: 6,
            documentImageGroup: 2,
            stateId: 22,
            documentList: [
              {
                documentInByte: data,
                documentLabel: "ProjectDocHindi",
              },
            ],
          }));
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }
    };

    genRandomKey();
  }, [docHindiFileList]);



  const onRemoveEnglishDoc = (file) => {
    const index = docEnglishFileList.indexOf(file);
    const newFileList = docEnglishFileList.slice();
    newFileList.splice(index, 1);
    setDocEnglishFileList(newFileList);

  }

  const onRemoveHindiDoc = (file) => {
    const index = docHindiFileList.indexOf(file);
    const newFileList = docHindiFileList.slice();
    newFileList.splice(index, 1);
    setDocHindiFileList(newFileList);

  }

  const beforeUploadEnglishDoc = (file, docEnglishFileList) => {
    if (file.size / 1024 / 1024 > 10) {
      message.open({
        type: 'error',
        content: <> <strong>{file.name}</strong> is greater than 10MB.</>,
        duration: 4
      });
      return false;
    }
    if (file.type !== 'application/pdf') {
      message.open({
        type: 'error',
        content: <>Only .pdf files are allowed</>,
        duration: 4
      });
      return false;
    }

    if (docEnglishFileList.findIndex((itm) => { return (itm.name === file.name && itm.size === file.size && itm.type === file.type) }) !== 0) {
      message.open({
        type: 'error',
        content: <><strong>{file.name} already added.</strong></>,
        duration: 4
      });
      return false;
    }
    setDocEnglishFileList([file]);
    return false;
  }

  const beforeUploadHindiDoc = (file, docHindiFileList) => {
    if (file.size / 1024 / 1024 > 10) {
      message.open({
        type: 'error',
        content: <> <strong>{file.name}</strong> is greater than 10MB.</>,
        duration: 4
      });
      return false;
    }
    if (file.type !== 'application/pdf') {
      message.open({
        type: 'error',
        content: <>Only .pdf files are allowed</>,
        duration: 4
      });
      return false;
    }

    if (docHindiFileList.findIndex((itm) => { return (itm.name === file.name && itm.size === file.size && itm.type === file.type) }) !== 0) {
      message.open({
        type: 'error',
        content: <><strong>{file.name} already added.</strong></>,
        duration: 4
      });
      return false;
    }
    setDocHindiFileList([file]);
    return false;
  }




  const SubmitProjectDetails = async () => {
    try {
      setLoadingFormSubmit(true);
      const payload = {
        projectId: projectId,
        projectAreas: form.getFieldValue("projectAreas"),
        projectType: form.getFieldValue("projectType"),
        projectAreasName: form.getFieldValue("projectAreasName"),
        projectTypeName: form.getFieldValue("projectTypeName"),
        titleEnglish: form.getFieldValue("titleEnglish"),
        titleHindi: form.getFieldValue("titleHindi"),
        investigatorEnglish: form.getFieldValue("investigatorEnglish"),
        investigatorHindi: form.getFieldValue("investigatorHindi"),
        tenureEnglish: form.getFieldValue("tenureEnglish"),
        tenureHindi: form.getFieldValue("tenureHindi"),
        docEnglish: DocEnglishFileValue ? [DocEnglishFileValue] : [],
        docHindi: DocHindiFileValue ? [DocHindiFileValue] : [],
        isActive: form.getFieldValue("isActive") === true ? 1 : 0,
        VerificationRemark: form.getFieldValue("verificationRemark")

      };
      let res;
      if (location?.state?.EditFlag === true) {
        res = await projectService.ProjectDetailsUpdate(payload);
      }
      else {
        res = await projectService.ProjectDetailsInsert(payload);
      }

      if (res.data.status) {
        Swal.fire({
          html: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        navigate("/user/projects/projects-list");
      }

      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An unexpected error occurred. Please try again.",
        });
      }

    } catch (error) {
      console.error("Error at submitting Project details:", error);
    } finally {
      setLoadingFormSubmit(false);
    }
  };

  return (

    <Container className="project-entry-form mt-4">
      {loadingFormSubmit && <Loader />}
      <Row className="justify-content-center">
        <Col lg={11}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h3>{title === "edit" ? "Edit Project Details" : "Add Project"}</h3>
              <Link className="btn btn-secondary" to="/user/projects/projects-list">
                <TbArrowBackUp /> Back
              </Link>
            </Card.Header>
            <Card.Body>
              <Form
                layout="vertical"
                form={form}
                name="OfficersDetaileForm"
                initialValues={{ isActive: true }}
                onFinish={SubmitProjectDetails}
                scrollToFirstError
              >
                <Card className="mb-3">
                  <Card.Header>Project Information</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="projectAreas"
                          label="Select Project Areas"
                          rules={[{ required: true, message: '*Required.!' }]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Project Areas"
                            optionFilterProp="children"
                            options={projectAreasList}
                            loading={loadingDDL}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="projectType"
                          label="Select Project Type"
                          rules={[{ required: true, message: 'Project Type Required' }]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Project Type"
                            optionFilterProp="children"
                            options={projectTypeList}
                            loading={loadingDDL}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-3">
                  <Card.Header>Project Titles</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="titleEnglish"
                          label="Project Title (English)"
                          rules={[
                            { required: true, message: '*Required!' },
                            { type: "string", max: 500, message: "Max length exceeded" },
                          ]}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={500}
                            placeholder="Enter Project Title (English)"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="titleHindi"
                          label="Project Title (Hindi)"
                          rules={[
                            { required: true, message: '*Required!' },
                            { type: "string", max: 500, message: "Max length exceeded" },
                          ]}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={500}
                            placeholder="Enter Project Title (Hindi)"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-3">
                  <Card.Header>Investigator Information</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="investigatorEnglish"
                          label="Principal Investigator (English)"
                          rules={[
                            { required: true, message: "*Required!" },
                            { type: "string", max: 150, message: "Max length exceeded" },
                          ]}
                        >
                          <Input placeholder="Enter Principal Investigator (English)" />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="investigatorHindi"
                          label="Principal Investigator (Hindi)"
                          rules={[
                            { required: true, message: "*Required!" },
                            { type: "string", max: 150, message: "Max length exceeded" },
                          ]}
                        >
                          <Input placeholder="Enter Principal Investigator (Hindi)" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-3">
                  <Card.Header>Project Tenure</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="tenureEnglish"
                          label="Tenure of the Project (English)"
                          rules={[
                            { required: true, message: "*Required!" },
                            { type: "string", max: 150, message: "Max length exceeded" },
                          ]}
                        >
                          <Input placeholder="Enter Tenure of the Project (English)" />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="tenureHindi"
                          label="Tenure of the Project (Hindi)"
                          rules={[
                            { required: true, message: "*Required!" },
                            { type: "string", max: 150, message: "Max length exceeded" },
                          ]}
                        >
                          <Input placeholder="Enter Tenure of the Project (Hindi)" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-3">
                  <Card.Header>Project Documentation</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="projectDocEnglish"
                          label="Upload Doc (English)"
                          rules={[
                            { required: !location?.state?.EditFlag, message: "*Required!" }
                          ]}
                        >
                          <Upload.Dragger
                            name="files"
                            beforeUpload={beforeUploadEnglishDoc}
                            fileList={docEnglishFileList}
                            accept="application/pdf"
                            disabled={docEnglishFileList.length >= 1}
                            showUploadList={false}
                          >
                            <p className="ant-upload-drag-icon">
                              <FilePdfOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Only PDF file with size less than 10MB is allowed</p>
                          </Upload.Dragger>
                          {docEnglishFileList.length > 0 && (
                            <List
                              itemLayout="horizontal"
                              dataSource={docEnglishFileList}
                              bordered
                              renderItem={(item, index) => (
                                <List.Item key={index}
                                  actions={[<Button key="list-loadmore-edit" className="btn btn-outline-danger" onClick={() => onRemoveEnglishDoc(item)}><TbTrashX /></Button>]}>
                                  <List.Item.Meta
                                    avatar={<AiOutlineFilePdf />}
                                    title={item.name}
                                    description={`File Type: ${item.type} | Size: ${(item.size / 1024).toFixed(2)}KB`}
                                  />
                                </List.Item>
                              )}
                            />
                          )}
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="projectDocHindi"
                          label="Upload Doc (Hindi)"
                          rules={[
                            { required: !location?.state?.EditFlag, message: "*Required!" }
                          ]}
                        >
                          <Upload.Dragger
                            name="files"
                            beforeUpload={beforeUploadHindiDoc}
                            fileList={docHindiFileList}
                            accept="application/pdf"
                            disabled={docHindiFileList.length >= 1}
                            showUploadList={false}
                          >
                            <p className="ant-upload-drag-icon">
                              <FilePdfOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Only PDF file with size less than 10MB is allowed</p>
                          </Upload.Dragger>
                          {docHindiFileList.length > 0 && (
                            <List
                              itemLayout="horizontal"
                              dataSource={docHindiFileList}
                              bordered
                              renderItem={(item, index) => (
                                <List.Item key={index}
                                  actions={[<Button key="list-loadmore-edit" className="btn btn-outline-danger" onClick={() => onRemoveHindiDoc(item)}><TbTrashX /></Button>]}>
                                  <List.Item.Meta
                                    avatar={<AiOutlineFilePdf />}
                                    title={item.name}
                                    description={`File Type: ${item.type} | Size: ${(item.size / 1024).toFixed(2)}KB`}
                                  />
                                </List.Item>
                              )}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-3">
                  <Card.Header>Addtionan Inforation</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="verificationRemark"
                          label="Remark"
                          rules={[
                            { required: location?.state?.EditFlag, message: 'Required!' },
                            { type: "string", max: 250, message: "Max length exceeded" },
                          ]}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={250}
                            placeholder="Enter Remark"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="isActive"
                          label="Is Active"
                          valuePropName="checked"
                          rules={[{ required: true, message: 'Required!' }]}
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>




                <div className="text-center mt-4">
                  <Divider />
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!formDisabled}
                    loading={loadingFormSubmit}
                  >
                    {title === "edit" ? "Submit (Update)" : "Submit (Save)"}
                  </Button>
                </div>

              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>



  );
};

export default CGRCProjectEntry;
