import React from "react";

import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
import DownloadableList from "../../../component/DownloadableList";


export default function InnovationFund() {
  const { t } = useTranslation();
  const innovationFundData = t("InnovationFundprogrammes", {returnObjects: true,});
  const menuData = t("menu.activities-of-council.intellectual_property_rights_centre", { returnObjects: true,  }) || {};
  return (
    <>

      <BreadcrumbLayout title="Innovation Fund Programmes Functionality" />
      <Container fluid>
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="IntellectualPropertyRightsCentre" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{innovationFundData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {innovationFundData.content.map((item, index) => (
                  <HeadPara2 key={index} data={item} />
                ))}


                    <div className="downloadable-list2 ms-4">
                    <DownloadableList _contentType = "31" _className=""/>
                </div>
                   
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
