import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ATCSidebar from "../ATCSidebar";

export default function ChhattisgarhSpaceApplicationsCentre() {
  const { t } = useTranslation();
  const CGSACdata = t("ChhattisgarhSpaceApplicationsCentre", {
    returnObjects: true,
  })||{};
  const menuData =
    t("menu.activities-of-council.cg-space-applications-centre", {
      returnObjects: true,
    }) || {};
  return (
    <div>
      <BreadcrumbLayout title="Activities of the Council" />
      <Container fluid>
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="CGSpaceApplicationsCentre" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
              <h3>{CGSACdata.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1 p-4">
            <div className="heading-paragraph">
              {CGSACdata.paragraphList.map((p, i) => (
                <p key={i}>{p}</p>
              ))}
              <ul className="ms-5">
                {CGSACdata.objectives.map((l, i) => (
                  <li key={i}>{l}</li>
                ))}
              </ul>
              <p>{CGSACdata.paragraph}</p>
              <ul className="ms-5">
                {CGSACdata.navlist.map((n,i) => (
                  <li key={i}>
                    <NavLink to={n.to}>{n.title}</NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
            </Row>
          </Col>
        </Row>
      </Container>

     
    </div>
  );
}
