import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Divider
} from "antd";
import { FaCheckCircle, FaTimesCircle, FaHourglassHalf, FaQuestionCircle } from 'react-icons/fa';
import { FaCalendarAlt } from "react-icons/fa";
import { useEffect, useState, useMemo } from "react";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import { NoticeServices } from "../../Services/NoticeService";
import { Link } from "react-router-dom";
import { CommonServices } from "../../Services/CommonService";
import { FaFilePdf } from "react-icons/fa6";
import { IoPersonAddSharp } from "react-icons/io5";
import { ImLink } from "react-icons/im";
import Swal from "sweetalert2";
import { AuthenticationService } from "../../Services/AuthService";
import Loader from "../../global/Loader";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";


dayjs.locale("en");
// const disabledStartDate = (current) => {
//   return current && current < dayjs().startOf("day");
// };

const NoticeList = () => {
  const noticeService = useMemo(() => new NoticeServices(), []);
  const commonService = useMemo(() => new CommonServices(), []);
  const authService = new AuthenticationService();
  const currentUser = authService.getUserData;

  const [tblData, setTblData] = useState();
  const [loadingTblData, setLoadingTblData] = useState(false);

  const [actionStatusData, setActionStatusData] = useState();
  const [loadingActionStatusData, setLoadingActionStatusData] = useState(false);
  const [tblNoticeDetailsById, setTblNoticeDetailsById] = useState(null);

  const [commonListData, setCommonListData] = useState();
  const [loadingCommonListData, setLoadingCommonListData] = useState(false);
  const [formShowHide, setFormShowHide] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [initialValues, setInitialValues] = useState({ fiterType: "-1", noticeType: "-1" });
  const navigate = useNavigate();
  const [modal1Open, setModal1Open] = useState(false);
  const [noticeId, setNotificationId] = useState();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [modalStatus, setModalStatus] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [loadingModalSubmit, setLoadingModalSubmit] = useState(false);
  const [noticeTypeList, setNoticeTypeList] = useState([]);

  const columns = [
    {
      title: '#',
      align: 'center',
      render: (_id, _record, index) => (
        <>
          {index + 1}

        </>
      ),
      width: '5%'
    },
    {
      title: "Notice Type",
      align: 'center',
      width: "12%",
      dataIndex: "noticeTypeName"
    },
    {
      title: "Subject",
      render: (data) => {
        return (
          <>
            <p><span className="fw-bold">English :</span> {data?.subjectEnglish}</p>
            <p><span className="fw-bold">Hindi :</span> {data?.subjectHindi}</p>
            {
              data.hyperLink.isHyperlink !== 0 ?
                <>

                  <p>
                    <span className="fw-bold">Is URL/PDF:</span> {data.hyperLink.hyperlinkType === 1 ? "Document" : "URL"}
                  </p>
                  <Button
                    icon={data.hyperlinkType === 1 ? <FaFilePdf /> : <ImLink />}
                    onClick={() => viewDocURL(data.hyperLink)}
                    className="btn btn-sm btn-outline-primary"
                  >
                    View {data.hyperLink.hyperlinkType === 1 ? "Document" : "URL"}
                  </Button>
                </>
                : 'No Document/ No Url'
            }
          </>
        );
      }
    },
    {
      title: "Date/Status",
      dataIndex: "date",
      render: (date) => {
        return (
          <>
            {date.publishDate && (
              <p>
                <span className="fw-bold"> Publish: </span> {date.publishDate}
              </p>
            )}
            <p>
              <span className="fw-bold">Submission: </span> {date.submitDate}
            </p>
            <p>
              <span className="fw-bold">verification Status: </span>
              <Space className="ms-2">
                <Tag
                  color={
                    date.verificationStatus === 0
                      ? "processing"
                      : date.verificationStatus === 1
                        ? "success"
                        : date.verificationStatus === 3
                          ? "error"
                          : ""
                  }
                >
                  {date.statusName}
                </Tag>
              </Space>
            </p>
            <p>
              <span className="fw-bold">Is Permanent:{" "} </span>

              <span
                className={
                  "fw-bold" +
                  " " +
                  (date.isPermanent === 0
                    ? "text-danger"
                    : "text-success")
                }
              >
                {date.isPermanent === 0 ? "No" : "Yes"}
              </span>
            </p>
            <p>
              <span className="fw-bold"> From:  </span> {date.validityFrom}
            </p>
            {date.isPermanent === 0 && (
              <p>
                <span className="fw-bold">To: </span> {date.validityTo}
              </p>
            )}
          </>
        );
      }
    },
    currentUser.role === "2"
      ? {
        title: "Action",
        align: "center",
        width: "12%",
        render: (data, record) => (
          <>
            <Space direction="vertical" align="center">
              <>
                <Button onClick={() => approveRejectConfirmModal("NoticeDetails", data, data?.verificationStatus)}
                  className='btn btn-sm btn-outline-primary'>View</Button>

                {data.date.verificationStatus === 0 && (
                  <>
                    {actionStatusData?.map((elm, id) => {
                      return (
                        <>

                          <Button
                            key={id}
                            onClick={() =>
                              approveRejectConfirmModal(
                                "approveRejectModal",
                                elm.value,
                                record.noticeId
                              )
                            }
                            className={
                              "btn btn-sm " +
                              " " +
                              (elm.value === "3"
                                ? "btn-outline-danger"
                                : elm.value === "1"
                                  ? "btn-outline-success"
                                  : "")
                            }
                          >
                            {elm.label}
                          </Button>
                        </>
                      );
                    })}
                  </>
                )}
              </>
            </Space>
          </>
        )
      }
      : {
        title: "Action",
        align: "center",
        width: "12%",
        render: (data, record) => (
          <>
            <Space>
              <Button className="btn btn-sm btn-outline-primary"
                onClick={() => handleEdit(data)}>Edit</Button>
              <Button onClick={() => approveRejectConfirmModal("NoticeDetails", data, data?.verificationStatus)}
                className='btn btn-sm btn-outline-primary'>View</Button>
            </Space>
          </>
        )
      },
  ];
  // const NoticeDetailColumnsById = [

  //   {
  //     title: "Subject English",
  //     dataIndex: "subjectEnglish",

  //   },
  //   {
  //     title: "Subject Hindi",
  //     dataIndex: "subjectHindi",

  //   },
  //   {
  //     title: "URL/Document",
  //     dataIndex: "hyperLink",
  //     align: 'center',
  //     render: (hyperLink) => {
  //       return (
  //         <>
  //           {
  //             hyperLink.isHyperlink !== 0 ?
  //               <>
  //                 <p>
  //                   Is URL/PDF: <span className="fw-bold">{hyperLink.hyperlinkType === 1 ? "Document" : "URL"}</span>
  //                 </p>
  //                 <Button
  //                   icon={hyperLink.hyperlinkType === 1 ? <FaFilePdf /> : <ImLink />}
  //                   onClick={() => viewDocURL(hyperLink)}
  //                   className="btn btn-sm btn-outline-primary"
  //                 >
  //                   View {hyperLink.hyperlinkType === 1 ? "Document" : "URL"}
  //                 </Button>
  //               </>
  //               : 'No Document/ No Url'
  //           }
  //         </>
  //       );
  //     },
  //     width: '20%'
  //   }

  // ];
  const handleEdit = (data) => {
    navigate("/user/notice/notice-entry", { state: { data: data, EditFlag: true } });
  }



  useEffect(() => {

    const getNoticeTypes = async (type) => {
      try {
        setLoadingCommonListData(true);
        const res = await commonService.getCommonList(type);
        setNoticeTypeList(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < res.data.length; i++) {
            data.push(
              {
                value: res.data[i].value,
                name: res.data[i].name,
                label: res.data[i].label,
                nameHindi: res.data[i].nameHindi,
                type: res.data[i].type,
                extraField1: res.data[i].extraField1
              }
            )
          }
          return data;
        })
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCommonListData(false);
      }
    };



    const getFilterStatusList = async () => {
      try {
        setLoadingCommonListData(true);
        let res = await commonService.getActionCommonStatus();
        const filterData = res.data?.filter((item) => {
          return item.value === "0" || item.value === "1" || item.value === "3";
        });

        setCommonListData(() => {
          let data = [
            {
              value: "-1",
              name: "All",
              label: "All",
              nameHindi: "",
              type: "",
              extraField1: "",
            },
          ];
          for (let i = 0; i < filterData.length; i++) {
            data.push({
              value: filterData[i].value,
              name: filterData[i].name,
              label: filterData[i].label,
              nameHindi: filterData[i].nameHindi,
              type: filterData[i].type,
              extraField1: filterData[i].extraField1,
            });
          }
          return data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCommonListData(false);
      }
    };
    const getActionStatus = async () => {
      try {
        setLoadingActionStatusData(true);
        let res = await commonService.getActionStatus();
        setActionStatusData(res.data);
      } catch (error) {
        setLoadingActionStatusData(false);
      } finally {
        setLoadingActionStatusData(false);
      }
    };

    getNoticeTypes("NoticeType");
    getFilterStatusList();
    getActionStatus();
  }, [commonService, noticeService]);

  useEffect(() => {
    const getListofNotice = async (stausCode, noticeType) => {
      try {
        setLoadingTblData(true);
        let payload = {
          isActive: -1,
          verificationStatus: stausCode,
          noticeId: -1,
          noticeType: noticeType,

        }
        let res = await noticeService.GetNoticeList(payload);

        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            id: index + 1,
            key: index,
            noticeId: item.noticeId,
            noticeType: item.noticeType,
            noticeTypeName: item.noticeTypeName,

            subjectEnglish: item.subjectEnglish,
            subjectHindi: item.subjectHindi,
            isActive: item.isActive,
            isStatusName: item.isStatusName,
            keyword: item.keyword,


            date: {
              publishDate: item.publishDate,
              submitDate: item.submitDate,
              isPermanent: item.isPermanent,
              validityFrom: item.validityFrom,
              validityTo: item.validityTo,
              verificationStatus: item.verificationStatus,
              statusName: item.statusName,
              verificationRemark: item.verificationRemark
            },

            hyperLink: {
              hyperlinkType: item.hyperlinkType,
              isHyperlink: item.isHyperlink,
              // hyperlinkTypeName: item.hyperlinkTypeName,
              url: item.url,
              uniqueId: item.uniqueId,
              documentMimeType: item.documentMimeType,
            },
          }));
          console.log(data);
          return data;
        });

      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getListofNotice(form2.getFieldValue("fiterType"), form2.getFieldValue("noticeType"));
  }, [noticeService, initialValues, form2, loadingModalSubmit])



  const viewDocURL = async (doc) => {



    try {
      setPageLoad(true);

      if (doc.hyperlinkType === 1) {
        if (doc.uniqueId !== null) {
          const res = await noticeService.ViewNotificationDocument(doc.uniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: doc.documentMimeType });
            let dataUrl = window.URL.createObjectURL(fileBlob);
            window.open(dataUrl, "_blank");
          }
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "The document hasn't been uploaded yet. Please try again later.",
          });
        }
      }
      else if (doc.hyperlinkType === 2) {
        window.open(doc.url, "_blank");
      }

    }
    catch (error) {
      console.error("Error fetching documents:", error);
    }
    finally {
      setPageLoad(false);
    }





  };
  const loadTableFilter = (status, noticeType) => {
    setInitialValues({ fiterType: status, noticeType: noticeType })
  };


  const approveRejectConfirmModal = (type, value, notificeId) => {

    setModal1Open(true);
    if (type === "NoticeDetails") {
      setFormShowHide(false);
      setModalTitle("Notice Details");
      setTblNoticeDetailsById([value]);

    }
    else if (type === "approveRejectModal") {
      setModalTitle("Verify");
      setFormShowHide(true);
      form.resetFields();
      setNotificationId(notificeId);
      const filterData = commonListData?.filter(item => {
        return item.value === value;
      });
      setModalStatus(filterData);
      form.setFieldValue("noticeStatus", value);
    }
  };


  const modalSubmit = async () => {
    try {
      setLoadingModalSubmit(true);
      let payload = {
        noticeId: noticeId,
        verificationStatus: form.getFieldValue("noticeStatus"),
        verificationRemark: form.getFieldValue("remarks"),
      };
      const res = await noticeService.VerifyNoticeBoard(payload);
      if (res.status) {
        Swal.fire({
          html: res.data.message,
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An unexpected error occurred. Please try again.",
        });
      }
      modalClose();
    } catch (error) {
      Swal.fire({
        html: error.message,
        icon: "error",
      });
      setLoadingModalSubmit(false);
    } finally {
      setLoadingModalSubmit(false);
    }
  };

  const modalClose = () => {
    form.resetFields();
    setModal1Open(false);
  };

  return (
    <>
      {loadingActionStatusData && <Loader />}
      {pageLoad && <Loader />}
      {loadingTblData && <Loader />}
      <Container>
        <Row>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Manage Notice</h3>
              </Card.Header>
              <Card.Body>



                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg={2} md={3} sm={12} >
                        {currentUser.role === "3" && (
                          <Link className="btn btn-outline-primary mt-1" to="/user/notice/notice-entry">
                            <IoPersonAddSharp /> Add New
                          </Link>
                        )}
                      </Col>
                      <Col lg={10} md={9} className="float-end">
                        <Form form={form2} initialValues={initialValues}>
                          <Row>
                            <Col md={6}>
                              <Form.Item name="noticeType" label="Select Notice Type:">
                                <Select
                                  showSearch
                                  placeholder="Select Notice Type"

                                  optionFilterProp="children"
                                  onChange={(value) => loadTableFilter(form2.getFieldValue("fiterType"), value)}
                                  options={noticeTypeList}
                                  loading={loadingCommonListData}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col md={6}>
                              <Form.Item name="fiterType" label="Filter">
                                <Select
                                  showSearch
                                  placeholder="Select Filter Type"
                                  optionFilterProp="children"
                                  onChange={(value) => loadTableFilter(value, form2.getFieldValue("noticeType"))}
                                  options={commonListData}
                                  loading={loadingCommonListData}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>

                      </Col>
                    </Row>

                    <div className="tableList table-responsive-md">
                      <Table
                        bordered
                        columns={columns}
                        dataSource={tblData}
                        loading={loadingTblData}
                        scroll={{ x: 800, y: 500 }}
                        size="small"
                        className="notice-table"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Modal
              title={modalTitle}
              style={{ top: 20 }}
              open={modal1Open}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => setModal1Open(false)}
              width={formShowHide === false ? 900 : 450}
            >
              {formShowHide && (
                <Form
                  layout="vertical"
                  form={form}
                  name="boilerRegForm"
                  onFinish={modalSubmit}
                  scrollToFirstError
                >
                  <Card className="">
                    <Card.Body>
                      <Col md={12}>
                        <Form.Item name="noticeStatus" label="verificationStatus">
                          <Select
                            showSearch
                            placeholder="Select verificationStatus"
                            optionFilterProp="children"
                            options={modalStatus}
                            loading={loadingCommonListData}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Remarks"
                          name="remarks"
                          rules={[{ required: true, message: "Remarks Required" }]}
                        >
                          <Input.TextArea showCount rows={3} maxLength={300} />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <hr></hr>
                      </Col>
                      <Col md={12} className="text-center">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary me-2"
                          disabled={loadingModalSubmit}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          disabled={loadingModalSubmit}
                          onClick={modalClose}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Card.Body>
                  </Card>
                </Form>
              )}

              {formShowHide === false &&
                <>

                  {
                    tblNoticeDetailsById !== null && (
                      <>

                        <Row className="mt-4">
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <Card.Body>
                                <Card.Title> Subject(English/Hindi)</Card.Title>

                                <Row className="align-items-center">
                                  {/* First Column: Photo and Resume Button */}
                                  <Col md={3} className="text-center">
                                    {
                                      tblNoticeDetailsById[0].isHyperlink !== 0 ?
                                        <>
                                          <p>
                                            Is URL/PDF: <span className="fw-bold">{tblNoticeDetailsById[0].hyperlinkType === 1 ? "Document" : "URL"}</span>
                                          </p>
                                          <Button
                                            icon={tblNoticeDetailsById[0].hyperlinkType === 1 ? <FaFilePdf /> : <ImLink />}
                                            onClick={() => viewDocURL(tblNoticeDetailsById[0].hyperLink)}
                                            className="btn btn-sm btn-outline-primary"
                                          >
                                            View {tblNoticeDetailsById[0].hyperlinkType === 1 ? "Document" : "URL"}
                                          </Button>
                                        </>
                                        : 'No Document/ No Url'
                                    }
                                  </Col>

                                  {/* Second Column: Details */}
                                  <Col md={9} className="">
                                    <Card.Text className="">
                                      <span className="fw-bold">
                                        {tblNoticeDetailsById[0].subjectEnglish}

                                      </span>
                                    </Card.Text>

                                    <Card.Text className="">
                                      {tblNoticeDetailsById[0].subjectHindi}
                                    </Card.Text>


                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        {/* Officer details Overview Details */}
                        <Row className="mt-4">
                          <Col md={6}>
                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>S#</Card.Title>
                                <Card.Text>{tblNoticeDetailsById[0].noticeId}</Card.Text>
                              </Card.Body>
                            </Card>
                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Status</Card.Title>
                                {tblNoticeDetailsById[0].date.verificationStatus === 1 ? (
                                  <Badge bg="success"><FaCheckCircle /> {tblNoticeDetailsById[0].date.statusName}</Badge>
                                ) : tblNoticeDetailsById[0].date.verificationStatus === 0 ? (
                                  <Badge bg="warning"><FaHourglassHalf /> {tblNoticeDetailsById[0].date.statusName}</Badge>
                                ) : tblNoticeDetailsById[0].date.verificationStatus === 3 ? (
                                  <Badge bg="danger"><FaTimesCircle /> {tblNoticeDetailsById[0].date.date.statusName}</Badge>
                                ) : (
                                  <Badge bg="secondary"><FaQuestionCircle /> Unknown Status</Badge>
                                )}

                              </Card.Body>
                            </Card>
                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Is Permanent</Card.Title>
                                {tblNoticeDetailsById[0].date.isPermanent === 1 ? (
                                  <Badge bg="success"><FaCheckCircle /> Yes</Badge>
                                ) : tblNoticeDetailsById[0].date.isPermanent === 0 ? (
                                  <Badge bg="danger"><FaTimesCircle /> No</Badge>
                                ) : (
                                  <Badge bg="secondary"><FaQuestionCircle /> Unknown isPermanent</Badge>
                                )}

                              </Card.Body>
                            </Card>
                            {
                              tblNoticeDetailsById[0].date.isPermanent === 0 && (
                                <Card className="mb-3 shadow-sm">
                                  <Card.Body>
                                    <Card.Title>validity From Date</Card.Title>
                                    <Card.Text><FaCalendarAlt /> {tblNoticeDetailsById[0].date.validityFrom}</Card.Text>
                                  </Card.Body>
                                </Card>
                              )
                            }

                          </Col>

                          <Col md={6}>
                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Is Active</Card.Title>
                                <Card.Text>

                                  {tblNoticeDetailsById[0].isActive === 1 ? (
                                    <Badge bg="success"><FaCheckCircle /> Active</Badge>
                                  ) : (
                                    <Badge bg="danger"><FaTimesCircle /> Inactive</Badge>
                                  )}
                                </Card.Text>
                              </Card.Body>
                            </Card>



                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Submit Date</Card.Title>
                                <Card.Text><FaCalendarAlt /> {tblNoticeDetailsById[0].date.submitDate}</Card.Text>
                              </Card.Body>
                            </Card>

                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Publish Date</Card.Title>
                                <Card.Text><FaCalendarAlt /> {tblNoticeDetailsById[0].date.publishDate}</Card.Text>
                              </Card.Body>
                            </Card>

                            {
                              tblNoticeDetailsById[0].date.isPermanent === 0 && (
                                <Card className="mb-3 shadow-sm">
                                  <Card.Body>
                                    <Card.Title>validity To Date</Card.Title>
                                    <Card.Text><FaCalendarAlt /> {tblNoticeDetailsById[0].date.validityTo}</Card.Text>
                                  </Card.Body>
                                </Card>
                              )
                            }

                          </Col>
                        </Row>
                        {/* Verification Details */}
                        <Row className="mt-2">
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <Card.Body>
                                <Card.Title> {tblNoticeDetailsById[0].date.verificationStatus !== 0 ? "Verification" : "Remark"}</Card.Title>
                                {tblNoticeDetailsById[0].date.verificationStatus !== 0 ? (
                                  <>
                                    <Card.Text><span className="fw-bold"><FaCalendarAlt /> Date: </span>{tblNoticeDetailsById[0].date.publishDate}</Card.Text>
                                    <Card.Text><span className="fw-bold">Remark: </span>{tblNoticeDetailsById[0].date.verificationRemark}</Card.Text>
                                  </>
                                ) : (
                                  <Card.Text>{tblNoticeDetailsById[0].date.verificationRemark}</Card.Text>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        <Divider />
                        {/* Close Button for Modal */}
                        <Row className="mt-4">
                          <Col md={12} className="text-center">
                            <button
                              className="btn btn-sm btn-outline-danger"
                              disabled={loadingModalSubmit}
                              onClick={modalClose}
                            >
                              Close
                            </button>
                          </Col>
                        </Row>


                      </>
                    )
                  }
                </>
              }

            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NoticeList;
