import React from 'react';
import { RouterProvider } from 'react-router-dom';
import Router from './routes/AppRouting'
import './i18n/i18n';
function App() {
  return (
    <div >  
      <RouterProvider router={Router} />
    </div>
  );
}

export default App;
