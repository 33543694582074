import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
export default function IntellectualPropertyRightsCentre() {
  const { t } = useTranslation();
  const Data = t("IntellectualPropertyRightsCentre", {
    returnObjects: true,
  });
  const menuData =
  t("menu.activities-of-council", {
    returnObjects: true,
  }) || {};
  return (
    <>
      <BreadcrumbLayout title="Intellectual Property Rights Centre" />
      <Container fluid>
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
              <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
            <div className="heading-paragraph">
              <p>
              {Data.content.paragraph}
              </p>

              <ul className="ms-5 nav-list">
                <li>
                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/patent-information-center">
                  {Data.content.link1}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre">
                  {Data.content.link2}
                  </NavLink>
                  <ul>
                  <li>
                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre/aspirational-block-project">
                  {Data.content.link2_1}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre/IP-yatra-program ">
                  {Data.content.link2_2}
                  </NavLink>
                </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/grassroot-innovation-augmentation-network">
                  {Data.content.link3}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/innovation-fund">
                  {Data.content.link4}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/world-intellectual-property-organization">
                  {Data.content.link5}
                  </NavLink>
                </li>
              </ul>

              <p>
              {Data.content.paragraph2}
              </p>

            </div>
            <div className='heading-paragraph'>
        <h3>{Data.content.title}</h3>
        {
         
            <p dangerouslySetInnerHTML={{ __html: Data.content.paragraph3 }}></p>
        
        }
       
      </div>

          </Col>
            </Row>
          </Col>
        </Row>
      </Container>
     
    </>
  );
}
