// import i18next from 'i18next';
// import { initReactI18next } from "react-i18next";
// // import LanguageDetector from "i18next-browser-languagedetector";
// // import Backend from "i18next-http-backend";

// import English from "../Translation/en.json";
// import Hindi from "../Translation/hi.json";

// const resources = {
//     en: {
//         translation: English,
//     },
//     hi: {
//         translation: Hindi,
//     },
// }

// i18next.on('languageChanged', (lng) => {
//     document.documentElement.setAttribute('lang', lng);
//   });

// i18next
//     .use(initReactI18next)
//     .init({
//         resources,
//         returnEmptyString: false,
//         debug: false,
//         lng: "hi",
//         fallbackLng: 'hi',
//         interpolation: {
//             escapeValue: false
//         }
//     });

// export default i18next;

import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import English from "../Translation/en.json";
import Hindi from "../Translation/hi.json";

const storedLanguage = localStorage.getItem('language') || 'hi'; // Retrieve stored language or default to 'hi'

const resources = {
    en: {
        translation: English,
    },
    hi: {
        translation: Hindi,
    },
}

i18next.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
    localStorage.setItem('language', lng); 
});

i18next
    .use(initReactI18next)
    .init({
        resources,
        returnEmptyString: false,
        debug: false,
        lng: storedLanguage, 
        fallbackLng: storedLanguage, 
        interpolation: {
            escapeValue: false
        }
    });

export default i18next;
