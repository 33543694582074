import React from "react";

import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";

import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Upload
} from "antd";
const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    md: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    md: {
      span: 14,
    },
  },
};
function DossierofYoungScientist() {
  return (
    <>
      <BreadcrumbLayout title="Feedback" />
      <Container className="outer-border">
        <Row>
          <Col xs={12} className="inner-border">
            <h3>Dossier of Young Scientist Congress Awardees</h3>
          </Col>
          <Col xs={12} className="mb-1 mt-1">
            <p><b>Young Scientist Congress Awardees are requested to provide their information.</b></p>
            <Form
              {...formItemLayout}
              variant="filled"
            //   style={{
            //     maxWidth: 600,
            //   }}
            >
              <Form.Item
                label="Name of Awardee "
                name="NameOfAwardee "
                rules={[
                  {
                    required: true,
                    message: "Please Enter Name of Awardee !",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Year of Award "
                name="YearOfAward "
                rules={[
                  {
                    required: true,
                    message: "Please Enter Year of Award!",
                  },
                ]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Title of Research Paper for Which Award is given "
                name="TitleofResearch "
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Address for Communication with PIN Code"
                name="Address"
                rules={[
                  {
                    required: true,
                    message: "Reqired!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                label="Phone No./Mobile No."
                name="Phone No./Mobile No."
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="Email "
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Institutional Address"
                name="Institutional Address"
                rules={[
                  {
                    required: true,
                    message: "Reqired!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                label="Did avail the facility of visiting National Laboratory/Research Centre/Recognized University engaged in advanced research in India"
                name="Email "
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Educational Qualification from Graduate level"
                name="Educational"
                rules={[
                  {
                    required: true,
                    message: "Reqired!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                label="Brief description of work after receiving Young Scientist Award"
                name="Brief description"
                rules={[
                  {
                    required: true,
                    message: "Reqired!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                label="Benefit in carrier /job of being a Young Scientist Awardee"
                name="Benefit in carrier"
                rules={[
                  {
                    required: true,
                    message: "Reqired!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                label="Year of working with present Institution"
                name="Year of working"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Current Status "
                name="Current Status  "
                rules={[
                  {
                    required: true,
                    message: "Please Enter Current Status !",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Nature of Work "
                name="Nature of Work  "
                rules={[
                  {
                    required: true,
                    message: "Please Enter Nature of Work !",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Is research work continued "
                name="Is research work continued  "
                rules={[
                  {
                    required: true,
                    message: "Please Enter Is research work continued !",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Area of Research "
                name="Area of Research  "
                rules={[
                  {
                    required: true,
                    message: "Please Enter Area of Research !",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Area of Specialization "
                name="Area of Specialization  "
                rules={[
                  {
                    required: true,
                    message: "Please Enter Area of Specialization !",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Any other information you would like to provide"
                name="Any Other Information"
                rules={[
                  {
                    required: true,
                    message: "Reqired!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label="Upload Signature"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload action="/upload.do" listType="picture-card">
                  <button
                    style={{
                      border: 0,
                      background: "none",
                    }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Date"
                name="Date"
                rules={[
                  {
                    required: true,
                    message: "Please Date!",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 6,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DossierofYoungScientist;
