import React from 'react'

function Unauthorized() {
  return (
    <div className="unauthorized-page">
        <div className="text-wrapper">
    <div className="title" data-content="404">
        403 - ACCESS DENIED
    </div>

    <div className="subtitle">
        Oops, You don't have permission to access this page.
    </div>
   

    
</div>
{/* <div class="w3-display-middle">
  <h1 class="w3-jumbo w3-animate-top w3-center"><code>Access Denied</code></h1>
  <hr class="w3-border-white w3-animate-left" style={{margin:"auto",width:"50%"}} />
  <h3 class="w3-center w3-animate-right">You dont have permission to view this site.</h3>
  <h3 class="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
  <h6 class="w3-center w3-animate-zoom"><strong>Error Code</strong>: 403 forbidden</h6>
</div> */}
    </div>
    
  )
}

export default Unauthorized