import React, { useEffect, useState, useMemo, useCallback } from "react";
import { PageContentService } from "../../../Services/PageContentService";
import Loader from "../../../global/Loader";
import Swal from "sweetalert2";
import { FaRegFilePdf } from "react-icons/fa6";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";

export default function CentralLaboratoryFacility() {
  const { t, i18n } = useTranslation();
  const Data = t("CentralLaboratoryFacility", { returnObjects: true });
  const pageContentService = useMemo(() => new PageContentService(), []);
  const [tblDataSampleDetail, setTblDataSampleDetail] = useState([]);
  const [tblDataListofTests, setTblDataListofTests] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const viewDoc = useCallback(async (doc) => {
    try {
      setPageLoading(true);
      if (doc.DocUniqueId !== null) {
        const res = await pageContentService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Document has not been uploaded!",
        });
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!!",
      });
    } finally {
      setPageLoading(false);
    }
  }, [pageContentService]);

  const getPageContetsList = useCallback(async (contentType, language, Type) => {
    try {
      setLoadingTblData(true);
      const res = await pageContentService.GetPageContetListPublic(contentType, language);
      let data = res.data.table.map((item, index) => ({
        key: index + 1,
        
        contentId: item.contentId,
        title: item.title,

        doc: {
          DocUniqueId: item.uniqueId,
          DocMimeType: item.documentMimeType,
        },
      }));

      if (Type === "SampleDetail") {
        setTblDataSampleDetail(data);
      } else if (Type === "ListofTests") {
        setTblDataListofTests(data);
      }

    } catch (error) {
      setLoadingTblData(false);
    } finally {
      setLoadingTblData(false);
    }
  }, [pageContentService]);

  useEffect(() => {
    getPageContetsList(36, lang, "SampleDetail");
    getPageContetsList(35, lang, "ListofTests");
  }, [lang, getPageContetsList]);

  return (
    <>
      {pageLoading && <Loader />}
      {loadingTblData && <Loader />}

      <BreadcrumbLayout title="Central Laboratory Facility" />
      <Container fluid>
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1"></Col>
              <div className="heading-paragraph">
                {Data.content.map((d, i) => (
                  <div key={i}>
                    {d.paragraph.map((items, idx) => (
                      <p key={idx} dangerouslySetInnerHTML={{ __html: items }}></p>
                    ))}

                    <div className="leader_intro container">
                      <div className="row justify-content-end">
                        <div className="col-lg-6 text-center">
                          {d.leader_intro.map((i, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: i }}></p>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="downloadable-list2">
                      <h4>{Data["test-available-title"]}</h4>
                      {
                        tblDataListofTests.length > 0 ?
                          <>
                            <ul className="ms-4">
                              {tblDataListofTests.map((list, i) => (
                                <li key={i}>
                                  <div className="d-table-cell">
                                    <FaRegFilePdf />
                                  </div>
                                  <div className="d-table-cell">
                                    <a
                                      href="/"
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevents the default link behavior
                                        viewDoc(list.doc);
                                      }}
                                    >
                                      {list.title}
                                    </a>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </>
                          : <>
                            <Row>
                              <Col>
                                <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                                  {lang === 1 ? "वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।" : "Currently, There are no data to display."}
                                </p>
                              </Col>
                            </Row>
                          </>
                      }

                    </div>

                    <div className="downloadable-list2">
                      <h4>{Data["sample"]}</h4>
                      {tblDataSampleDetail.length > 0 ?
                        <>
                          <ul className="ms-4">
                            {tblDataSampleDetail.map((list, i) => (
                              <li key={i}>
                                <div className="d-table-cell">
                                  <FaRegFilePdf />
                                </div>
                                <div className="d-table-cell">
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevents the default link behavior
                                      viewDoc(list.doc);
                                    }}
                                  >
                                    {list.title}
                                  </a>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </>
                        : <>
                          <Row>
                            <Col>
                              <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                                {lang === 1 ? "वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।" : "Currently, There are no data to display."}
                              </p>
                            </Col>
                          </Row>
                        </>
                      }

                    </div>

                  </div>
                ))}
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
