import { Col, Card, Container, Row } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
import { FeedBackService } from "../Services/FeedBackService";
import { Table } from "antd";

const VisitorFeedbackList = () => {
  const feedBackService = useMemo(() => new FeedBackService(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);

  const columns = [
    {
      title: '#',
      align: 'center',
      dataIndex: 'key',
      width: '5%',
    },
    {
      title: 'Details',
      render: (row) => (
        <div style={{ textAlign: "left" }}>
          <p>
            <span className="fw-bold">S#:</span> {row.feedbackid}
          </p>
          <p style={{ textAlign: "justify" }}>
            <span className="fw-bold">Message:</span> {row.message}
          </p>
          <p>
            <span className="fw-bold">From:</span> {row.name},
            <span className="fw-bold"> Email Id:</span> <a href={`mailto:${row.email}`}>{row.email}</a>,
            <span className="fw-bold"> Submit Date:</span> {row.createdate}
          </p>
        </div>
      ),
      width: '95%',
    },
  ];

  useEffect(() => {
    const getOfficersList = async () => {
      try {
        setLoadingTblData(true);
        const res = await feedBackService.GetFeedBackList();
        console.log(res);

        if (res.status) {
          setTblData(() => {
            let data = res.data.table.map((item, index) => ({
              key: index + 1,
              createdate: item.createdate,
              email: item.email,
              feedbackid: item.feedbackid,
              message: item.message,
              name: item.name,
            }));
            return data;
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTblData(false);
      }
    };
    getOfficersList();
  }, [feedBackService]);

  return (
    <Container>
      <Row>
        <Col className="dashboard">
          <Card>
            <Card.Header>
              <h3>Visitor Feedback List</h3>
            </Card.Header>
            <div className="tableList p-3">
              <Table
                bordered
                columns={columns}
                dataSource={tblData}
                loading={loadingTblData}
                scroll={{ x: 800, y: 600 }}
                size="middle"
                pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default VisitorFeedbackList;
