
import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function Advertisement() {
  const { t } = useTranslation();
  // const Data = t("Advertisement", {
  //   returnObjects: true,
  // });
  const menuData = t("menu.e-citizens", {
    returnObjects: true,
  }) || {};

  return (
    <>
      <BreadcrumbLayout title="Advertisement" />
      <Container>
        <Row>
          <Col md={3} className="sidebar order-last order-md-first">
            <Row>
              <Col className="menu-list">
                <ul>
                  <NavLink to="/e-citizens/right-to-information">
                    <li>{menuData["right-to-information"]}</li>
                  </NavLink>

                  <NavLink to="/e-citizens/download-pdf">
                    <li>{menuData["download-pdf"]}</li>
                  </NavLink>

                  <NavLink to="/e-citizens/advertisement">
                    <li>{menuData["advertisement"]}</li>
                  </NavLink>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col md={9} className="content order-md-last order-first">
            {/* <Row>
              <Col xs={12} className="heading">
                <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {Data.content.map((d, index) => (
                  <div className="container border border-danger mt-3" key={index}>
                    <div className="row">
                      <div className="col advertisement-list">
                        {d.topHeadList && (
                          <>
                            {d.topHeadList.map((l, i) => (
                              <a
                                key={i}
                                style={{ color: "rgba(13, 110, 253)", "::hover": "#FAA916" }}
                                href={require(`${process.env.REACT_APP_filepath}/${l.fileName}`)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <br /> {l.title}
                              </a>
                            ))}
                          </>
                        )}

                        {d.fileName && (
                          <a
                            href={require(`${process.env.REACT_APP_filepath}/${d.fileName}`)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {d.mainHead}
                          </a>
                        )}

                        <br />
                        {d.title && <h6>{d.title}</h6>}
                       
                        {d.downHeadList && (
                          <>
                            {d.downHeadList.map((l, i) => (
                              <React.Fragment key={i}>
                                {l.fileName && (
                                  <a
                                    style={{ color: "rgba(13, 110, 253)" }}
                                    href={require(`${process.env.REACT_APP_filepath}/${l.fileName}`)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {l.title}
                                  </a>
                                )}
                                <br />
                              </React.Fragment>
                            ))}
                          </>
                        )}

                        <ul>
                          {d.list &&
                            d.list.map((list, i) => (
                              <li key={i}>
                                <a
                                  href={require(`${process.env.REACT_APP_filepath}/${list.fileName}`)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {list.title}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}
