import axiosInstance from "../global/AxiosInterceptor";

export class PresentationService {
   

    PresentationInsert = async (payload) => {
        return await axiosInstance.post('/Presentation/presentationdetailsinsert', payload)
    }

    PresentationUpdate = async (payload) => {
        return await axiosInstance.post('/Presentation/presentationdetailsupdate', payload)
    }

    GetPresentationList = async (payload) => {
        return await axiosInstance.post('Presentation/getpresentationslist', payload);
    }

    GetPresentationListPublic = async ( language) => {
        return await axiosInstance.get(`/Presentation/getpresentationlistpublic/${language}`); // 1: Hindi, 2: English
    }

    VerifyPresentationDetails = async (payload) => {
        return await axiosInstance.post('/Presentation/presentationtdetailsverify', payload)
    }

    ViewDocument = async (uniqeId) => {
        return await axiosInstance.get('/Documents/getdocumentbyuniqueid/' + uniqeId, {
            responseType: 'blob'
        });
    };

}