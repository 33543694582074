import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";

export default function TechnologyVillageProgramme() {
  const { t } = useTranslation();
  const TVPData = t('TechnologyVillageProgramme', { returnObjects: true }) || {};
  const menuData = t('ScienceAndTechnologyInterventionCell', { returnObjects: true }) || {};
  return (
    <>
      <BreadcrumbLayout title="Technology Village Programme" />
      <Container fluid>
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="ScienceAndTechnologyInterventionCell" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{TVPData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div>
                  {TVPData.data.map((d,i) => (
                    <div key={i} className="heading-paragraph">
                      <h3>{d.heading}</h3>
                      {d.paragraph.map((items, i) => (
                        <p key={i} dangerouslySetInnerHTML={{ __html: items }}></p>
                      ))}
                      <ul className="ms-5">
                        {d.para_list.map((list,i) => (
                          <li key={i}>{list}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <div>

                  <div className="table-responsive">
                    <table className="table border">

                      <thead>
                        <tr>
                          <th scope="col">{TVPData.srn}</th>
                          <th scope="col">{TVPData.centres}	</th>
                          <th scope="col">{TVPData.available}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          TVPData.tabData.map((item,i) => (
                            <tr key={i}>
                              <th scope="row">{item.sno}</th>
                              <td>{item.centers}</td>
                              <td>
                                <ul>
                                  {
                                    item.Infrastructure.map((d,i) => (
                                      <li key={i}>{d}</li>
                                    ))
                                  }
                                </ul>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
