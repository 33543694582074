import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const ATCSidebar = ({ menuData, type }) => {
  const { t } = useTranslation();
  let _menuData;
  if (type==="actofthecouncil") {
    _menuData = [
      { path: "/activities-of-council", label: menuData["title"] },
      { path: "/activities-of-council/cg-research-centre", label: menuData['cg-research-centre']['title'] },
      { path: "/activities-of-council/research-development-promotional-cell", label: menuData["research-development-promotional-cell"]["title"] },
      { path: "/activities-of-council/cg-space-applications-centre", label: menuData["cg-space-applications-centre"]["title"] },
      { path: "/activities-of-council/popularisation-of-science", label: menuData["popularisation-of-science"]["title"] },
      { path: "/activities-of-council/research-and-publication", label: menuData['research_and_Publication'] },
      { path: "/activities-of-council/science-technology-intervention-cell", label: menuData.science_technology_intervention_cell.title },
      { path: "/activities-of-council/activities-coordinator-cell", label: menuData['activities_coordinator_cell'] },
      { path: "/activities-of-council/documentation-communication-centre", label: menuData['documentation_communication_centre'] },
      { path: "/activities-of-council/intellectual-property-rights-centre", label: menuData.intellectual_property_rights_centre.title },
      { path: "/activities-of-council/forest-eco-plan-centre", label: menuData['forest_eco_plan_centre'] },
      { path: "/activities-of-council/ccost-activity-profile", label: menuData['ccost_activity_profile'] },
      { path: "/activities-of-council/establishment-of-snrms", label: menuData['establishment_of_snrms'] },
      { path: "/activities-of-council/services-provided-by-the-council", label: menuData.services_provided_by_the_council.title },
      { path: "/activities-of-council/other-projects", label: menuData.other_projects.title }
      // { path: "", label: menuData },
      // { path: "", label: menuData },
      // { path: "", label: menuData },
      // { path: "", label: menuData },
      // { path: "", label: menuData },
    ]
  }
  else  if (type==="CgResearchCentre") {
    _menuData = [
      { path: "/activities-of-council", label: t("menu.activities-of-council.title")},
      { path: "/activities-of-council/cg-research-centre", label: menuData['title'] },
      { path: "/activities-of-council/cg-research-centre/completed-project", label: menuData['completed-project'] },
      { path: "/activities-of-council/cg-research-centre/ongoing-projects", label: menuData['ongoing-projects'] },
    ]
  }
  else  if (type==="ResearchDevelopmentPromotionalCell") {
    _menuData = [
      
      { path: "/activities-of-council", label: t("menu.activities-of-council.title")},
      { path: "/activities-of-council/research-development-promotional-cell", label: t("menu.activities-of-council.research-development-promotional-cell.title")},
      { path: "/activities-of-council/research-development-promotional-cell/mini-research-project", label: menuData['mini-research-project'] },
      { path: "/activities-of-council/research-development-promotional-cell/international-travel-grant", label: menuData['international-travel-grant'] },
      { path: "/activities-of-council/research-development-promotional-cell/seminar-symposium-workshop-conference", label: menuData['seminar-symposium-workshop-conference'] },
      { path: "/activities-of-council/research-development-promotional-cell/publication-grant", label: menuData['publication-grant'] },
      { path: "/activities-of-council/research-development-promotional-cell/identification-new-areas", label: menuData['identification-new-areas'] },
      { path: "/activities-of-council/research-development-promotional-cell/r-d-centres", label: menuData['r-d-centres'] },
    ]
  }
  else if (type==="CGSpaceApplicationsCentre"){
    _menuData = [
      { path: "/activities-of-council", label: t("menu.activities-of-council.title")},
      { path: "/activities-of-council/cg-space-applications-centre", label: menuData['title'] },
      { path: "/activities-of-council/cg-space-applications-centre/completed-projects", label: menuData['completed-projects'] },
      { path: "/activities-of-council/cg-space-applications-centre/ongoing-projects", label: menuData['ongoing-projects'] },
      { path: "/activities-of-council/cg-space-applications-centre/resource-atlas-of-chhattisgarh", label: menuData['resource-atlas-of-chhattisgarh'] },
      { path: "/activities-of-council/cg-space-applications-centre/raigarh-district-resource-atlas", label: menuData['raigarh-district-resource-atlas'] },
      { path: "/activities-of-council/cg-space-applications-centre/map-gallery", label: menuData['map-gallery'] },
    ]
  }
  else if (type==="PopularisationOfScience"){
    _menuData = [
      { path: "/activities-of-council", label: t("menu.activities-of-council.title")},
      { path: "/activities-of-council/popularisation-of-science", label: t("menu.activities-of-council.popularisation-of-science.title") },
      { path: "/activities-of-council/popularisation-of-science/cg-young-scientist-congress", label: menuData['cg_young_scientist_congress'] },
      { path: "/activities-of-council/popularisation-of-science/national-children-science-congress", label: menuData['national_children_science_congress'] },
      { path: "/activities-of-council/popularisation-of-science/western-india-science-fair", label: menuData['western_india_science_fair'] },
      { path: "/activities-of-council/popularisation-of-science/national-science-seminar", label: menuData['national_science_seminar'] },
      { path: "/activities-of-council/popularisation-of-science/science-quiz-competitions", label: menuData['science_quiz_competitions'] },
      { path: "/activities-of-council/popularisation-of-science/community-science-club", label: menuData['community_science_club'] },
      { path: "/activities-of-council/popularisation-of-science/popular-science-book-corner", label: menuData['popular_science_book_corner'] },
      { path: "/activities-of-council/popularisation-of-science/science-park", label: menuData['science_park'] },
      { path: "/activities-of-council/popularisation-of-science/mathematical-olympiad", label: menuData['mathematical_olympiad'] },
      { path: "/activities-of-council/popularisation-of-science/national-science-day", label: menuData['national_science_day'] },
      { path: "/activities-of-council/popularisation-of-science/national-mathematics-day", label: menuData['national_mathematics_day'] },
      { path: "/activities-of-council/popularisation-of-science/mobile-science-lab", label: menuData['mobile_science_lab'] },
      { path: "/activities-of-council/popularisation-of-science/vigyan-prasar-edusat-program", label: menuData['vigyan_prasar_edusat_program'] },
      { path: "/activities-of-council/popularisation-of-science/science-society-programme", label: menuData['science_society_programme'] },
      { path: "/activities-of-council/popularisation-of-science/mobile-planetarium", label: menuData['mobile_planetarium'] },
      { path: "/activities-of-council/popularisation-of-science/world-space-week", label: menuData['world_space_week'] },
    ]
  }
  else if (type==="ScienceAndTechnologyInterventionCell"){
    _menuData = [
      { path: "/activities-of-council", label: t("menu.activities-of-council.title")},
      { path: "/activities-of-council/science-technology-intervention-cell", label: menuData['title'] },
      { path: "/activities-of-council/science-technology-intervention-cell/technology-village-programme", label: menuData['link1'] },
      { path: "/activities-of-council/science-technology-intervention-cell/technology-transfer", label: menuData['link2'] },
      { path: "/activities-of-council/science-technology-intervention-cell/information-technology-centre-for-s-t", label: menuData['link3'] },
    ]
  }
  else if (type==="OtherProjects"){
    _menuData = [
      { path: "/activities-of-council", label: t("menu.activities-of-council.title")},
      { path: "/activities-of-council/other-projects", label: menuData.title },
      { path: "/activities-of-council/other-projects/completedp-projects", label: menuData.completed_projects },
      { path: "/activities-of-council/other-projects/ongoing-projects", label: menuData.ongoing_projects },
    ]
  }
  else if (type==="IntellectualPropertyRightsCentre"){
    _menuData = [
      { path: "/activities-of-council", label: t("menu.activities-of-council.title")},
      { path: "/activities-of-council/intellectual-property-rights-centre", label: menuData.title },
      { path: "/activities-of-council/intellectual-property-rights-centre/patent-information-center", label: menuData.patent_information_center },
      { path: "/activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre", label: menuData.intellectual_property_facilitation.title },

      { path: "/activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre/aspirational-block-project", label: menuData.intellectual_property_facilitation.aspirational_block_project },
      { path: "/activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre/IP-yatra-program", label: menuData.intellectual_property_facilitation.ip_yatra_program },

      { path: "/activities-of-council/intellectual-property-rights-centre/grassroot-innovation-augmentation-network", label: menuData.grassroot_innovation_augmentation_network_cell },
      { path: "/activities-of-council/intellectual-property-rights-centre/innovation-fund", label: menuData.innovation_fund },
      { path: "/activities-of-council/intellectual-property-rights-centre/world-intellectual-property-organization", label: menuData.world_intellectual_property_organization },
      
    ]
  }
  return (
    <Col
      md={3}
      className="sidebar order-last order-md-first p-3"
      style={{
        borderRight: '1px solid #dee2e6',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Row>
        <Col className="menu-list d-none">
          <ul className="list-unstyled">
            {_menuData.map((item, index) => (
              <NavLink
                end
                key={index}
                to={item.path}
                className="d-block py-2 px-3 mb-1"
                // style={{
                //   color: '#495057',
                //   borderRadius: '4px',
                //   textDecoration: 'none',
                //   transition: 'background-color 0.2s',
                // }}
                // activeStyle={{ backgroundColor: '#007bff', color: 'white' }}
              >
                <li>{item.label}</li>
              </NavLink>
            ))}
          </ul>
        </Col>
        <Col sm={12} className='list-container'>
          
          <ul className="list-unstyled">
          <li>
            <div className="QL">  {t("menu.quickLinks")}</div></li>
            {_menuData.map((item, index) => (
              <NavLink
              end
                key={index}
                to={item.path}
                className=""
                // style={{
                //   color: '#495057',
                //   borderRadius: '4px',
                //   textDecoration: 'none',
                //   transition: 'background-color 0.2s',
                // }}
                // activeStyle={{ backgroundColor: '#007bff', color: 'white' }}
              >
                <li><div className="list-item">{item.label}</div></li>
              </NavLink>
            ))}
          </ul>           
        </Col>
      </Row>
    </Col>
  );
};

export default ATCSidebar;
