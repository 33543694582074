import React, { useEffect, useState, useMemo } from "react";
import { PageContentService } from "../../../Services/PageContentService";
import Animation from "../../../assets/images/Animation.gif";
import { Col, Row, Container } from "react-bootstrap";
import PDFViewer from '../../../component/PDFViewer';
import BreadcrumbLayout from '../../../component/Breadcrumb';
import ATCSidebar from "../ATCSidebar";
import { useTranslation } from "react-i18next";

export default function ResourceAtlasOfChhattisgarh() {
  const { t, i18n } = useTranslation();
  const menuData = t("menu.activities-of-council.cg-space-applications-centre", { returnObjects: true }) || {};

  const pageContentService = useMemo(() => new PageContentService(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);

  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);

  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  useEffect(() => {
    const getPdfById = async (imgDocUniqueId, imgDocType) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await pageContentService.ViewDocument(imgDocUniqueId);
          if (res.status === 200) {
            const fileBlob = new Blob([res.data], { type: imgDocType });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
        return "error"; // Return a specific string to indicate an error
      }
      return "";
    };

    const getPageContetsList = async (contentType, language, Type) => {
      try {
        setLoadingTblData(true);
        const res = await pageContentService.GetPageContetListPublic(contentType, language);
        const pdfUrlPromises = res.data.table.map((item) =>
          getPdfById(item.uniqueId, item.documentMimeType)
        );
        const pdfUrls = await Promise.all(pdfUrlPromises);
        let data = res.data.table.map((item, index) => ({
          key: index + 1,
          doc: {
            DocUniqueId: item.uniqueId,
            DocMimeType: item.documentMimeType,
          },
          pdfUrl: pdfUrls[index],
        }));

        if (Type === "RightToInformation") {
          setTblData(data);
        }
      } catch (error) {
        console.error("Error fetching page contents:", error);
      } finally {
        setLoadingTblData(false);
      }
    };

    getPageContetsList(34, lang, "RightToInformation");
  }, [lang, pageContentService]);

  return (
    <>
      <BreadcrumbLayout title="ResourceAtlasOfChhattisgarh" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <ATCSidebar menuData={menuData} type="CGSpaceApplicationsCentre" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{menuData['resource-atlas-of-chhattisgarh']}</h3>
              </Col>
              <Col xs={12} className='mb-1 mt-1'>
                {loadingTblData && <img src={Animation} alt="Animation gif" />}
                {tblData.length > 0 ? (
                  tblData.map((row, index) => (
                    row.pdfUrl === "error" ? (
                      <Row key={row.key}>
                        <Col>
                          {!loadingTblData &&
                            <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                              {lang === 1 ? "वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।" : "Currently, There are no data to display."}
                            </p>
                          }
                        </Col>
                      </Row>
                    ) : (
                      <PDFViewer key={row.key} fileName={row.pdfUrl} />
                    )
                  ))
                ) : (
                  <Row>
                    <Col>
                    {!loadingTblData &&
                        <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                          {lang === 1 ? "वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।" : "Currently, There are no data to display."}
                        </p>
                      }
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
