import axiosInstance from "../global/AxiosInterceptor";

export class CommonServices {

    getCommonList = async (Type) => {
        return await axiosInstance.get(`/Common/CommonList/${Type}/2`); // 1: Hindi, 2: English
    }

    getActionCommonStatus = async () => {
        return await axiosInstance.get(`/Common/CommonList/actionStatus/2`);
    }

    getActionStatus = async () => {
        return await axiosInstance.post(`/Common/getActionStatus`, {});
    }
}