import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara from "../../../component/HeadPara";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";

export default function IdentificationofNewAreas() {
  const { t } = useTranslation();
  const menuData =
    t("menu.activities-of-council.research-development-promotional-cell", {
      returnObjects: true,
    }) || {};
  return (
    <>
      <BreadcrumbLayout title="Completed Project" />
      <Container fluid>
        <Row>
                {/* Sidebar */}
                <ATCSidebar menuData={menuData} type="ResearchDevelopmentPromotionalCell" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("identificationNewAreas.title")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <HeadPara paragraph={t("identificationNewAreas.content")} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
