import { Col, Container, Row, Card, Badge } from "react-bootstrap";
import { FaCheckCircle, FaTimesCircle, FaHourglassHalf, FaQuestionCircle } from 'react-icons/fa';
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import Loader from "../../global/Loader";
import { AuthenticationService } from "../../Services/AuthService";
import { IoPersonAddSharp } from "react-icons/io5";
import { PresentationService } from "../../Services/PresentationService";
import { Form, Select, Table, Space, Tag, Button, Modal, Input } from "antd";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { CommonServices } from "../../Services/CommonService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";


const PresentationList = () => {
  const authService = new AuthenticationService();
  const navigate = useNavigate();
  const commonService = useMemo(() => new CommonServices(), []);
  const presentationService = useMemo(() => new PresentationService(), []);

  const currentUser = authService.getUserData?.role;
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [commonListData, setCommonListData] = useState();
  const [loadingFilterList, setLoadingFilterList] = useState(false);
  const [actionStatusData, setActionStatusData] = useState();

  const [modal1Open, setModal1Open] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [presentationId, setProjectId] = useState();
  const [modalStatus, setModalStatus] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [formShowHide, setFormShowHide] = useState(false);
  const [tblPresentationById, setTblPresentationById] = useState(null);

  const [initialValues, setInitialValues] = useState({ filterStatusType: "-1" });

  const [pageLoad, setPageLoad] = useState(false);

  const approveRejectConfirmModal = (type, value, presentationId) => {
    setModal1Open(true);
    if (type === "PresentationList") {
      setModalTitle("Presentation Details");
      setTblPresentationById([value])
      setFormShowHide(false);
    } else if (type === "approveRejectModal") {
      setModalTitle("Verify");
      setFormShowHide(true);
      form.resetFields();
      setProjectId(presentationId);
      const filterData = commonListData?.filter(item => {
        return item.value === value;
      });
      setModalStatus(filterData);
      form.setFieldValue('verificationStatus', value);
    }
  }

  const handleEdit = (data) => {
    navigate("/user/presentation/presentation-entry", { state: { data: data, EditFlag: true } });
  }

  const modalClose = () => {
    setModal1Open(false);
  };

  const [loadingModalSubmit, setLoadingModalSubmit] = useState(false);
  const modalSubmit = async () => {
    try {
      setLoadingModalSubmit(true);
      let payload = {
        presentationId: presentationId,
        verificationStatus: form.getFieldValue('verificationStatus'),
        verificationRemark: form.getFieldValue('verificationRemark')
      }

      const res = await presentationService.VerifyPresentationDetails(payload);
      if (res.status) {
        Swal.fire({
          html: res.data.message,
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message
        });
      }
      modalClose();

    } catch (error) {
      console.error(error);

    }
    finally {
      setLoadingModalSubmit(false);
    }
  }
  const viewDoc = async (doc) => {
    try {
      setPageLoad(true);
      if (doc.DocUniqueId !== null) {
        const res = await presentationService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The document hasn't been uploaded yet. Please try again later.",
        });
      }
    }
    catch (error) {
      console.error("Error fetching documents:", error);
    }
    finally {
      setPageLoad(false);
    }
  };

  const columns = [
    {
      title: '#',
      align: 'center',
      render: (record) => record.key,
      width: '5%'
    },
    {
      title: 'Presentation Title (Presentation Date)',
      render: (row) => {
        return (
          <>
            <div className="project-details">
              <h5><span className="fw-bold">{row.titleEnglish} </span> </h5>
              <p><span className="fw-bold">Date of Presentation: </span>{row.presentationDate}</p>
            </div>
          </>
        )
      }

    },
   
    {
      title: 'View Details',
      render: (row) => {
        return (
          <>
            <Button
              onClick={() => viewDoc(row.doc)}
              className="btn btn-sm btn-outline-primary mt-2 "
            >
              <BsFileEarmarkPdfFill />
              View Details
            </Button>
          </>
        )
      },
      width: '13%'
    },
    {
      title: 'Details',
      width: '25%',
      render: (row) => {
        return (
          <>

            <div style={{ lineHeight: '1.5' }}>

              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">S#:</span> {row.presentationId}
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Is Visible:</span> &nbsp;
                <Tag color={row.isActive === 0 ? 'error' : 'success'}>{row.isActiveName}</Tag>
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Verification Status:</span>&nbsp;
                <Tag
                  color={
                    row.verificationStatus === 0
                      ? 'processing'
                      : row.verificationStatus === 1
                        ? 'success'
                        : 'error'
                  }
                >
                  {row.statusName}
                </Tag>
              </p>
              <p style={{ marginBottom: '5px' }}>
                <span className="fw-bold">Submit Date:</span> {row.submitDate}
              </p>
            </div>
          </>
        )
      }
    },
    currentUser === "2" ?
      {
        title: 'Action',
        align: 'center',
        width: '15%',
        render: (data) => (
          <>
            <Space direction="vertical" align="center">
              <Button onClick={() => approveRejectConfirmModal("PresentationList", data, data?.verificationStatus)}
                className='btn btn-sm btn-outline-primary'>View</Button>
              {data.verificationStatus === 0 && actionStatusData?.map((elm, id) => (
                <Button key={id} onClick={() => approveRejectConfirmModal("approveRejectModal", elm.value, data?.presentationId)} className={'btn btn-sm ' + (elm.value === '3' ? 'btn-outline-danger' : (elm.value === '1' ? 'btn-outline-success' : ''))}>
                  {elm.label}
                </Button>
              ))}
            </Space>
          </>
        ),
      } :
      {
        title: 'Action',
        align: 'center',
        width: '15%',
        render: (data) => (
          <>
            <Space>
              <Button className="btn btn-sm btn-outline-primary"
                onClick={() => handleEdit(data)}>Edit</Button>
              <Button onClick={() => approveRejectConfirmModal("PresentationList", data, data?.verificationStatus)}
                className='btn btn-sm btn-outline-primary'>View</Button>
            </Space>
          </>
        ),
      }
  ];

  useEffect(() => {
    const getFilterList = async () => {
      try {
        setLoadingFilterList(true);
        let res = await commonService.getActionCommonStatus();
        const filterData = res.data?.filter(item => {
          return item.value === "0" || item.value === "1" || item.value === "3";
        })

        setCommonListData(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < filterData.length; i++) {
            data.push(
              {
                value: filterData[i].value,
                name: filterData[i].name,
                label: filterData[i].label,
                nameHindi: filterData[i].nameHindi,
                type: filterData[i].type,
                extraField1: filterData[i].extraField1
              }
            )
          }
          return data;
        });
      } catch (error) {
        setLoadingFilterList(false);
      }
      finally {
        setLoadingFilterList(false);
      }
    }

    const getActionStatus = async () => {
      try {
        let res = await commonService.getActionStatus();
        setActionStatusData(res.data);

      } catch (error) {

        console.log("Error at getActionStatus: ", error)
      }

    }
    getFilterList();
    getActionStatus();
  }, [commonService, presentationService])

  useEffect(() => {
    const getPresentationList = async (stausCode) => {
      try {
        setLoadingTblData(true);
        let payload = {
          isActive: -1,
          verificationStatus: stausCode,
          presentationId: -1,
        }

        const res = await presentationService.GetPresentationList(payload);



        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            contentTypeName: item.contentTypeName,
            presentationId: item.presentationId,
            titleEnglish: item.titleEnglish,
            titleHindi: item.titleHindi,
            presentationDateWC: item.presentationDateWC,
            presentationDate: item.presentationDate,
            verificationStatus: item.verificationStatus,
            statusName: item.statusName,
            isActive: item.isActive,
            isActiveName: item.activeName,
            verificationremark: item.verificationRemark,
            publishDate: item.publishDate,
            submitDate: item.submitDate,
            doc: {
              DocUniqueId: item.uniqueId,
              DocMimeType: item.documentMimeType,

            },
          }));
          return data;
        });

      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getPresentationList(form2.getFieldValue("filterStatusType"));
  }, [initialValues, presentationService, form2, loadingModalSubmit]);


  const loadTableFilter = (stausCode) => {
    setInitialValues({ filterStatusType: stausCode })
  }


  return (
    <>
      {pageLoad && <Loader />}
      {loadingTblData && <Loader />}
      <Container>
        <Row>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Presentation List</h3>
              </Card.Header>
              <Card.Body>



                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        {currentUser === "3" && (
                          <Link className="btn btn-outline-primary mt-1" to="/user/presentation/presentation-entry">
                            <IoPersonAddSharp /> Add New
                          </Link>
                        )}
                      </Col>
                      <Col md={6}>
                        <Form form={form2} initialValues={initialValues}>
                          <Form.Item name="filterStatusType" label="Filter">
                            <Select
                              showSearch
                              placeholder="Select Filter Type"
                              optionFilterProp="children"
                              onChange={(value) => loadTableFilter(value)}
                              options={commonListData}
                              loading={loadingFilterList}
                              filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>


                        </Form>

                      </Col>
                    </Row>

                    <div className="tableList table-responsive-md">
                      <Table
                        bordered
                        columns={columns}
                        dataSource={tblData}
                        loading={loadingTblData}
                        scroll={{ x: 800, y: 500 }}
                        size="small"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Modal
              title={modalTitle}
              style={{ top: 20 }}
              open={modal1Open}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => setModal1Open(false)}
              width={formShowHide === false ? 900 : 450}
            >
              {formShowHide && (
                <Form
                  layout="vertical"
                  form={form}
                  name="boilerRegForm"
                  onFinish={modalSubmit}
                  scrollToFirstError
                >
                  <Card className="">
                    <Card.Body>
                      <Col md={12}>
                        <Form.Item name="verificationStatus" label="Status">
                          <Select showSearch placeholder="Select Status"
                            optionFilterProp="children"
                            options={modalStatus} loading={loadingFilterList}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12}>
                        <Form.Item label="Remarks" name="verificationRemark" rules={[
                          { required: true, message: 'Remarks Required' },
                        ]}>
                          <Input.TextArea showCount rows={3} maxLength={300} />
                        </Form.Item>
                      </Col>
                      {/* <Col md={12}>
                          <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loadingModalSubmit}>
                              Submit
                            </Button>
                          </Form.Item>
                        </Col> */}
                      <Col md={12}>
                        <hr></hr>
                      </Col>
                      <Col md={12} className="text-center">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary me-2"
                          disabled={loadingModalSubmit}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          disabled={loadingModalSubmit}
                          onClick={modalClose}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Card.Body>
                  </Card>
                </Form>
              )}

              {formShowHide === false &&
                <>
                  {
                    tblPresentationById !== null && (
                      <>
                        {/* English Project Details */}
                        <Row className="mt-4">
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <Card.Body>
                                <Card.Title>English</Card.Title>
                                <Row>
                                  <Col md={9}>
                                    <div className="project-details">
                                      <h5><span className="fw-bold">{tblPresentationById[0].titleEnglish}</span></h5>
                                      <p><span className="fw-bold">Presentation Date: </span>{tblPresentationById[0].presentationDate}</p>
                                    </div>
                                  </Col>
                                  <Col md={3} className="align-self-center text-md-end text-start">
                                    <Button
                                      onClick={() => viewDoc(tblPresentationById[0].doc)}
                                      className="btn btn-sm btn-outline-primary mt-2"
                                    >
                                      <BsFileEarmarkPdfFill /> View Details
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        {/* Hindi Prajentation Details */}
                        <Row className="mt-4">
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <Card.Body>
                                <Card.Title>Hindi</Card.Title>
                                <Row>
                                  <Col md={9}>
                                    <div className="project-details">
                                      <h5><span className="fw-bold">{tblPresentationById[0].titleHindi}</span></h5>
                                      <p><span className="fw-bold">प्रेजेंटेशन दिनांक:: </span>{tblPresentationById[0].presentationDate}</p>
                                    </div>
                                  </Col>
                                  <Col md={3} className="align-self-center text-md-end text-start">
                                    <Button
                                      onClick={() => viewDoc(tblPresentationById[0].doc)}
                                      className="btn btn-sm btn-outline-primary mt-2"
                                    >
                                      <BsFileEarmarkPdfFill /> View Details
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        {/* Project Overview Details */}
                        <Row className="mt-4">
                          <Col md={6}>
                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>S#</Card.Title>
                                <Card.Text>{tblPresentationById[0].presentationId}</Card.Text>
                              </Card.Body>
                            </Card>

                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Status</Card.Title>
                                {tblPresentationById[0].verificationStatus === 1 ? (
                                  <Badge bg="success"><FaCheckCircle /> {tblPresentationById[0].statusName}</Badge>
                                ) : tblPresentationById[0].verificationStatus === 0 ? (
                                  <Badge bg="warning"><FaHourglassHalf /> {tblPresentationById[0].statusName}</Badge>
                                ) : tblPresentationById[0].verificationStatus === 3 ? (
                                  <Badge bg="danger"><FaTimesCircle /> {tblPresentationById[0].statusName}</Badge>
                                ) : (
                                  <Badge bg="secondary"><FaQuestionCircle /> Unknown Status</Badge>
                                )}

                              </Card.Body>
                            </Card>
                          </Col>

                          <Col md={6}>
                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Is Active</Card.Title>
                                <Card.Text>

                                  {tblPresentationById[0].isActive === 1 ? (
                                    <Badge bg="success"><FaCheckCircle /> Active</Badge>
                                  ) : (
                                    <Badge bg="danger"><FaTimesCircle /> Inactive</Badge>
                                  )}
                                </Card.Text>
                              </Card.Body>
                            </Card>



                            <Card className="mb-3 shadow-sm">
                              <Card.Body>
                                <Card.Title>Submit Date</Card.Title>
                                <Card.Text><FaCalendarAlt /> {tblPresentationById[0].submitDate}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        {/* Verification Details */}
                        <Row className="mt-2">
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <Card.Body>
                                <Card.Title> {tblPresentationById[0].verificationStatus !== 0 ? "Verification" : "Remark"}</Card.Title>
                                {tblPresentationById[0].verificationStatus !== 0 ? (
                                  <>
                                    <Card.Text><span className="fw-bold"><FaCalendarAlt /> Date: </span>{tblPresentationById[0].publishDate}</Card.Text>
                                    <Card.Text><span className="fw-bold">Remark: </span>{tblPresentationById[0].verificationremark}</Card.Text>
                                  </>
                                ) : (
                                  <Card.Text>{tblPresentationById[0].verificationremark}</Card.Text>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        {/* Close Button for Modal */}
                        <Row className="mt-4">
                          <Col md={12} className="text-center">
                            <button
                              className="btn btn-sm btn-outline-danger"
                              disabled={loadingModalSubmit}
                              onClick={modalClose}
                            >
                              Close
                            </button>
                          </Col>
                        </Row>
                      </>
                    )
                  }
                </>

              }
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PresentationList;


