import { Button, Divider, Input, Upload, message, DatePicker, List, Select, Switch } from "antd";
import Form from "antd/es/form";
import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { NoticeServices } from "../../Services/NoticeService";
import { AuthenticationService } from "../../Services/AuthService";
import { readFileDataAsBase64 } from "../../global/FileConverter";
import { CommonServices } from "../../Services/CommonService";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TbTrashX } from "react-icons/tb";
import { FilePdfOutlined } from '@ant-design/icons';
import { TbArrowBackUp } from "react-icons/tb";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
// const dateFormat = 'DD/MM/YYYY';
// // const dateFormat = 'D MMMM YYYY';
const NoticeEntry = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingFormSubmit, setLoadingFormSubmit] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const values = Form.useWatch([], form);
  const [docFileList, setDocFileList] = useState([]);
  const [docFileValue, setDocFileValue] = useState(null);
  const noticeService = new NoticeServices();
  const commonService = useMemo(() => new CommonServices(), []);
  const [title, setTitle] = useState("Add");
  const [noticeTypeList, setNoticeTypeList] = useState([]);
  const [loadingNoticeTypes, setLoadingNoticeTypes] = useState(true);
  const [yesNoList, setYesNoListList] = useState([]);
  const [referenceDocTypeList, setReferenceDocTypeList] = useState([]);
  const [ishyperLink, setIshyperLink] = useState(false);
  const [ishyperLinkUrl, setIshyperLinkTypeUrl] = useState(false);
  const [ishyperLinkDoc, setIshyperLinkTypeDoc] = useState(false);
  const [isDisplayPermanat, setIsDisplayPermanat] = useState(true);
  const [noticeId, setNoticeId] = useState(0);
  const onActionChangeDP = (value) => {
    if (value === "1") {
      setIsDisplayPermanat(true);
      form.setFieldsValue({ endDate: null });
    } else {
      setIsDisplayPermanat(false);
    }
  };
  const disabledStartDate = (current) => {
    return current && current < dayjs().startOf("day");
  };
  useEffect(() => {
    const authService = new AuthenticationService();
    if (authService.getUserData?.role !== "3") {
      navigate('/user/unauthorized'); // Redirect to error page if role is not admin
    }
  }, [navigate]);
  useEffect(() => {
    const getNoticeTypes = async (type) => {
      try {
        setLoadingNoticeTypes(true);
        const res = await commonService.getCommonList(type);
        if (type === "yesNo") {
          setYesNoListList(res.data);
        } else if (type === "ReferenceDocumentType") {
          setReferenceDocTypeList(res.data);
        } else if (type === "NoticeType") {
          setNoticeTypeList(res.data);
        }
      } catch (error) {
        setLoadingNoticeTypes(false);
      } finally {
        setLoadingNoticeTypes(false);
      }
    };
    getNoticeTypes("NoticeType");
    getNoticeTypes("ReferenceDocumentType");
    getNoticeTypes("yesNo");
    form.setFieldValue("hyeperLink", "0");
    form.setFieldValue("pinTop", "0");
    form.setFieldValue("displayPermanently", "1");
  }, [commonService, form]);
  useEffect(() => {
    if (location?.state?.EditFlag === true) {

      if (location?.state?.data?.hyperLink?.isHyperlink.toString() === "1") {
        setIshyperLink(true);
      }
      else {
        setIshyperLink(false);
      }
      if (location?.state?.data?.hyperLink?.hyperlinkType.toString() === "2") {
        setIshyperLinkTypeUrl(true);
        setIshyperLinkTypeDoc(false);
      }
      else {
        setIshyperLinkTypeDoc(true);
        setIshyperLinkTypeUrl(false);
      }
      if (location?.state?.data?.date?.isPermanent.toString() === "1") {
        setIsDisplayPermanat(true);
        form.setFieldsValue({ endDate: null });
      }
      else {
        setIsDisplayPermanat(false);
        form.setFieldsValue({ endDate: dayjs(location?.state?.data?.date?.validityTo, 'DD/MM/YYYY') });
      }
      form.setFieldsValue({
        noticeType: location?.state?.data?.noticeType.toString(),
        subjectEnglish: location?.state?.data?.subjectEnglish,
        subjectHindi: location?.state?.data?.subjectHindi,
        keyword: location?.state?.data?.keyword,
        url: location?.state?.data?.hyperLink?.url,
        hyeperLink: location?.state?.data?.hyperLink?.isHyperlink.toString(),
        hyeperLinkType: location?.state?.data?.hyperLink?.hyperlinkType.toString(),
        displayPermanently: location?.state?.data?.date?.isPermanent.toString(),
        startDate: dayjs(location?.state?.data?.date?.validityFrom, 'DD/MM/YYYY'),
        isActive: (location?.state?.data?.isActive === 1) ? true : false
      })
      setTitle("edit")
      setNoticeId(location?.state?.data?.noticeId);
    }
  }, [location?.state, form])
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormDisabled(true);
      },
      () => {
        setFormDisabled(false);
      }
    );
  }, [values, form]);
  useEffect(() => {
    const genRandomKey = async () => {
      if (docFileList && docFileList.length > 0) {
        try {
          let data = await readFileDataAsBase64(docFileList[0]);
          setDocFileValue(() => ({
            documentExtension: ".pdf",
            documentMimeType: "application/pdf",
            documentTag: "NoticeBoard",
            documentType: 1,
            documentImageGroup: 1,
            stateId: 22,
            documentList: [
              {
                documentInByte: data,
                documentLabel: "NoticeBoard",
              },
            ],
          }));
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }
    };
    genRandomKey();
  }, [docFileList]);
  const onRemoveDoc = (file) => {
    const index = docFileList.indexOf(file);
    const newFileList = docFileList.slice();
    newFileList.splice(index, 1);
    setDocFileList(newFileList);
  }
  const beforeUploadDoc = (file, docFileList) => {
    if (file.size / 1024 / 1024 > 10) {
      message.open({
        type: 'error',
        content: <> <strong>{file.name}</strong> is greater than 10MB.</>,
        duration: 4
      });
      return false;
    }
    if (file.type !== 'application/pdf') {
      message.open({
        type: 'error',
        content: <>Only .pdf files are allowed</>,
        duration: 4
      });
      return false;
    }
    if (docFileList.findIndex((itm) => { return (itm.name === file.name && itm.size === file.size && itm.type === file.type) }) !== 0) {
      message.open({
        type: 'error',
        content: <><strong>{file.name} already added.</strong></>,
        duration: 4
      });
      return false;
    }
    setDocFileList([file]);
    return false;
  }
  const SubmitDetails = async () => {
    try {
      setLoadingFormSubmit(true);
      if (form.getFieldValue("displayPermanently") === "1") {
        form.setFieldsValue({ endDate: null });
      }
      if (form.getFieldValue("hyeperLink") === "0") {
        form.setFieldsValue({ url: null });
        setDocFileValue(null);
      }
      if (form.getFieldValue("hyeperLinkType") === "1") {
        form.setFieldsValue({ url: null });
      } else if (form.getFieldValue("hyeperLinkType") === "2") {
        setDocFileValue(null);
      }
      const payload = {
        noticeId: noticeId,
        noticeType: form.getFieldValue("noticeType"),
        subjectEnglish: form.getFieldValue("subjectEnglish"),
        subjectHindi: form.getFieldValue("subjectHindi"),
        isHyperlink: form.getFieldValue("hyeperLink"),
        hyperlinkType: form.getFieldValue("hyeperLinkType") === undefined ? null : form.getFieldValue("hyeperLinkType"),
        url: form.getFieldValue("url") === undefined ? null : form.getFieldValue("url"),
        isPermanent: form.getFieldValue("displayPermanently"),
        validityFrom: dayjs(form.getFieldValue("startDate")).format("YYYY-MM-DD"),
        validityTo: form.getFieldValue("endDate") === null ? null : dayjs(form.getFieldValue("endDate")).format("YYYY-MM-DD"),
        keyword: "",
        blDocument: docFileValue ? [docFileValue] : [],
        isActive: form.getFieldValue("isActive") === true ? 1 : 0,
        VerificationRemark: form.getFieldValue("verificationRemark")
      };
      console.log(payload);
      let res;
      if (location?.state?.EditFlag === true) {
        res = await noticeService.UpdateNoticeDetails(payload);
      }
      else {
        res = await noticeService.InsertNoticeDetails(payload);
      }
      if (res.data.status) {
        Swal.fire({
          html: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        navigate("/user/notice/notice-list");
      }
      else {
        Swal.fire({
          html: res.data.message,
          icon: "error"
        });
      }
    } catch (error) {
      console.error("Error at submitting Presentation details:", error);
    } finally {
      setLoadingFormSubmit(false);
    }
  };
  const onActionChangeLinkType = (value) => {
    if (value === "2") {
      setIshyperLinkTypeUrl(true);
      setIshyperLinkTypeDoc(false);
    } else {
      setIshyperLinkTypeDoc(true);
      setIshyperLinkTypeUrl(false);
    }
  };
  const onActionChange = (value) => {

    if (value === "1") {
      setIshyperLink(true);
    } else {
      setIshyperLink(false);
    }
  };
  return (
    <Container className="project-entry-form mt-4">
      <Row className="justify-content-center">
        <Col lg={11}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h3>{title === "edit" ? "Edit Notice Details" : "Add Notice"}</h3>
              <Link className="btn btn-secondary" to="/user/notice/notice-list">
                <TbArrowBackUp /> Back
              </Link>
            </Card.Header>
            <Card.Body>
              <Form
                layout="vertical"
                form={form}
                name="PresentationForm"
                onFinish={SubmitDetails}
                scrollToFirstError
                initialValues={{ presentationDate: dayjs(), isActive: true }}
              >
                <Card className="mb-3">
                  <Card.Header className="fw-bold">Category</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="noticeType"
                          label="Category"
                          rules={[{ required: true, message: 'Category Required' }]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Notice Type"
                            optionFilterProp="children"
                            options={noticeTypeList}
                            loading={loadingNoticeTypes}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Header className="fw-bold">Subject/Title</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>

                      <Col md={6}>
                        <Form.Item
                          name="subjectEnglish"
                          label="Subject English"
                          rules={[
                            { required: true, message: "Subject Required" },
                            { type: "string", max: 500, message: "Max length exceeded" },
                          ]}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={500}
                            placeholder="Enter Subject English"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="subjectHindi"
                          label="Subject Hindi"
                          rules={[
                            { required: true, message: "Subject Required" },
                            { type: "string", max: 500, message: "Max length exceeded" },
                          ]}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={500}
                            placeholder="Enter Subject Hindi"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col  md={6}>
                        <Form.Item
                          name="keyword"
                          label="Keyword"
                          rules={[
                            { required: true, message: "Keyword Required" },
                            { type: "string", max: 300, message: "Max length exceeded" },
                          ]}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={300}
                            placeholder="Enter Keyword"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                          />
                        </Form.Item>
                      </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Header className="fw-bold">HyperLink Type</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="hyeperLink"
                          label="Is HyperLink"
                          rules={[{ required: true, message: "Selection Required" }]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Notice Type"
                            optionFilterProp="children"
                            onChange={onActionChange}
                            options={yesNoList}
                            loading={loadingNoticeTypes}
                            filterOption={(input, option) =>
                              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                      {ishyperLink && (
                        <>
                          <Col md={6}>
                            <Form.Item
                              name="hyeperLinkType"
                              label="URL / Document"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select
                                showSearch
                                placeholder="Select Notice Type"
                                optionFilterProp="children"
                                onChange={onActionChangeLinkType}
                                options={referenceDocTypeList}
                                loading={loadingNoticeTypes}
                                filterOption={(input, option) =>
                                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          </Col>
                          {ishyperLinkUrl && (
                            <Col md={6}>
                              {/* <Form.Item
                                name="url"
                                label="Url"
                                rules={[{ required: true, message: "Url Required" }]}
                              >
                                <Input placeholder="Please Enter Your URL" />
                              </Form.Item> */}
                              <Form.Item
                                name="url"
                                label="Url"
                                rules={[
                                  { required: true, message: "Url Required" },
                                  {
                                    validator: (_, value) => {
                                      if (!value || /^(https?:\/\/)(?!localhost|127\.0\.0\.1)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}/.test(value)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error('Please enter a valid external URL'));
                                    },
                                  },
                                ]}
                              >
                                <Input placeholder="Please Enter Your URL" />
                              </Form.Item>

                            </Col>
                          )}
                          {ishyperLinkDoc && (
                            <>
                              <Col md={6}>
                                <Form.Item
                                  label="File Upload"
                                  name="filesNoticeType"
                                  rules={[
                                    { required: location?.state ? false : true, message: "Please upload a file!" },
                                  ]}
                                >
                                  <>
                                    <Upload.Dragger
                                      name="files"
                                      beforeUpload={beforeUploadDoc}
                                      fileList={docFileList}
                                      accept="application/pdf"
                                      disabled={docFileList.length >= 1}
                                      showUploadList={false}
                                    >
                                      <p className="ant-upload-drag-icon">
                                        <FilePdfOutlined />
                                      </p>
                                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                      <p className="ant-upload-hint">Only PDF file with size less than 10MB is allowed</p>
                                    </Upload.Dragger>

                                  </>
                                </Form.Item>

                              </Col>
                              <Col md={6}>
                                {docFileList.length > 0 && (
                                  <>
                                    <span className="fw-bold ">Doc List</span>
                                    <List
                                      className="mt-2"
                                      itemLayout="horizontal"
                                      dataSource={docFileList}
                                      bordered
                                      renderItem={(item, index) => (
                                        <List.Item
                                          key={index}
                                          actions={[
                                            <Button
                                              key="list-loadmore-edit"
                                              className="btn btn-outline-danger"
                                              onClick={() => { onRemoveDoc(item) }}
                                            >
                                              <TbTrashX />
                                            </Button>
                                          ]}
                                        >
                                          <List.Item.Meta
                                            avatar={<AiOutlineFilePdf />}
                                            title={item.name}
                                            description={`File Type: ${item.type} | Size: ${(item.size / 1024).toFixed(2)}KB`}
                                          />
                                        </List.Item>
                                      )}
                                    />
                                  </>
                                )}
                              </Col>
                            </>
                          )}
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Header className="fw-bold">Duration of Notice Entry</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col lg={!isDisplayPermanat ? 4 : 6} md={6}>
                        <Form.Item
                          name="displayPermanently"
                          label="Display Permanently"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Notice Type"
                            optionFilterProp="children"
                            onChange={onActionChangeDP}
                            options={yesNoList}
                            loading={loadingNoticeTypes}
                            filterOption={(input, option) =>
                              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={!isDisplayPermanat ? 4 : 6} md={6}>
                        <Form.Item
                          name="startDate"
                          label="Start Date/Publish Date"
                          rules={[{ required: true, message: "Required" }]}
                          initialValue={dayjs()}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format="DD/MM/YYYY"
                            disabledDate={disabledStartDate}
                          />
                        </Form.Item>
                      </Col>
                      {!isDisplayPermanat && (
                        <Col lg={4} md={6}>
                          <Form.Item
                            name="endDate"
                            label="End Date"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              format="DD/MM/YYYY"
                              disabledDate={(d) => !d || d.isBefore(form.getFieldValue("startDate"))}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Header className="fw-bold">Additional Details</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item name="verificationRemark" label="Remark" rules={[
                          { required: location?.state?.EditFlag, message: 'Required!' },
                          {
                            type: "string",
                            max: 250,
                            message: "Max length exceeded",
                          },
                        ]}>
                          <Input.TextArea showCount maxLength={250} placeholder="Enter Remark" autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item name="isActive" label="Is Active:" valuePropName="checked"
                          rules={[
                            { required: true, message: 'Required!' }

                          ]}>
                          <Switch />
                        </Form.Item>

                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <div className="text-center mt-2">
                  <Divider />
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!formDisabled}
                    loading={loadingFormSubmit}
                  >
                    Submit ({title === "edit" ? "Update" : "Save"})
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NoticeEntry;
