import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
import DownloadableList from "../../../component/DownloadableList";


export default function NationalScienceSeminar() {
  const { t } = useTranslation();
  const NationalScienceSeminarData = t("NationalScienceSeminar", { returnObjects: true, }) || {};
  const menuData = t("menu.activities-of-council.popularisation-of-science", { returnObjects: true, }) || {};

  return (
    <>
      <BreadcrumbLayout title="National Science Seminar" />
      <Container fluid>
        <Row>
           {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{NationalScienceSeminarData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {NationalScienceSeminarData.content.map((items, i) => (
                  <HeadPara2 key={i} data={items} />
                ))}


<div className="downloadable-list2">
                  <h4>{NationalScienceSeminarData.participantsTitle}</h4>
                  <DownloadableList _contentType = "19" _className="ms-4"/>
                </div>
                {/* <div className="downloadable-list2 ms-4">
                  <DownloadableList _contentType="19" _className="" />
                </div> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
