import React from 'react';
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../component/Breadcrumb";
import { useTranslation } from "react-i18next";

export default function BlankPage(props) {
  const { t } = useTranslation();
  const Data = t("breadcrumb", {
      returnObjects: true,
  });
  return (
   <>
     <BreadcrumbLayout title={props.title} />
     <Container className='outer-border'>
      <Row>
        <Col xs={12} className='inner-border'>
           <h3>{t(Data[props.title])}</h3>   
        </Col>
        <Col xs={12} className='ml-1 mt-1' style={{minHeight:"70vh"}}>
          
          <h4>Coming Soon</h4>
       </Col>
        
        
      </Row>
    </Container>
   </>
  )
}
