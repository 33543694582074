import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Container, Table } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import Swal from "sweetalert2";

function ScreenReaderAccess() {
  const { t, i18n } = useTranslation();
  const screenReadersData = t("screenReaders", { returnObjects: true });
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);
  const handleClick = (url) => {
    Swal.fire({
      html: lang === 2 ? `
                This link shall take you to a page/ website outside this website. `
        : ` यह लिंक आपको इस वेबसाइट के बाहर एक पेज/वेबसाइट पर ले जाएगा।`,
      icon: "question",
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: lang === 2 ? "OK" : "हाँ",
      denyButtonText: lang === 2 ? "Cancel" : "नहीं"
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(url);
      }
    });
  };


  return (
    <>
      <BreadcrumbLayout title="Privacy Policy" />
      <Container fluid>
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.screen-reader-access")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <h5>{screenReadersData.paragraph}</h5>
                <Table striped bordered hover className="officers-list mt-3">
                  <thead>
                    <tr>
                      <th>{screenReadersData.heading.screenReader}</th>
                      <th>{screenReadersData.heading.websites}</th>
                      <th>{screenReadersData.heading.free}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {screenReadersData.tblData.map((d, i) => (
                      <tr key={i}>
                        <td>{d.screenReader}</td>
                        <td>

                          <a target="_blank" rel="noopener noreferrer"
                            href="/"
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default link behavior
                              handleClick(d.websiteLink);
                            }}

                          >
                            {d.websiteLink}
                          </a>
                        </td>
                        <td>{d.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ScreenReaderAccess;

