import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import DownloadableList from '../../../component/DownloadableList';
import ATCSidebar from "../ATCSidebar";
export default function PopularScienceBookCorner() {
  const { t } = useTranslation();
  const PopularScienceBookCornerData = t("PopularScienceBookCorner", { returnObjects: true, }) ||{};
  const menuData =  t("menu.activities-of-council.popularisation-of-science", { returnObjects: true, }) || {};

  return (
    <>
      <BreadcrumbLayout title="Popular Science Book Corner" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{PopularScienceBookCornerData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {PopularScienceBookCornerData.content.map((items, i) => (
                  <HeadPara2 key={i} data={items} />
                ))}
                
                 <div className="downloadable-list2 ms-4">
                 
                 <DownloadableList _contentType = "23" _className=""/>
               </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

