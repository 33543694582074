import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BreadcrumbLayout from "../component/Breadcrumb";

export default function Services() {
  const { t } = useTranslation();
  return (
    <>
      <BreadcrumbLayout title="Services" />
      <Container fluid >
        <Row>
        
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
              <h3>{t("Services.title")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-3">
            <ul className="ms-3 services-list">
              {t("Services.data", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
          </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <Container className="outer-border">
        <Row>
          <Col xs={12} className="inner-border">
            <h3>{t("Services.title")}</h3>
          </Col>
          <Col xs={12} className="mb-1 mt-3">
            <ul className="ms-3 services-list">
              {t("Services.data", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
          </Col>
        </Row>
      </Container> */}
    </>
  );
}
