

export const menuItemsData = [
  {
    title: "menu.home",
    url: "/",
  },
  {
    title: "menu.about.title",
    url: "about",
    submenu: [
      {
        title: "menu.about.introduction",
        url: "about/introduction",
      },
      {
        title: "menu.about.missionObjectives",
        url: "about/mission-objectives",
      },
      {
        title: "menu.about.fromDirectorGeneral",
        url: "about/from-director-general",
      },
      {
        title: "menu.about.generalBody",
        url: "about/general-body",
      },
      {
        title: "menu.about.executiveCommittee",
        url: "about/executive-committee",
      },
      {
        title: "menu.about.annualReport",
        url: "about/annual-report",
      },
      {
        title: "menu.about.WhosWho.title",
        url: "",
        submenu: [
          {
            title: "menu.about.WhosWho.link",
            url: "about/whos-who/list-of-officers",
          }
        ]
      },

      {
        title: "menu.about.organizationalChart",
        url: "about/organizational-chart",
      }
    ]
  },
  {
    title: "menu.services",
    url: "services"
  },
  {
    title: "menu.activities-of-council.title",
    url: "activities-of-council",
    submenu: [
      {
        title: "menu.activities-of-council.cg-research-centre.title",
        url: "activities-of-council/cg-research-centre",
        submenu: [
          {
            title: "menu.activities-of-council.cg-research-centre.completed-project",
            url: "activities-of-council/cg-research-centre/completed-project",
          },
          {
            title: "menu.activities-of-council.cg-research-centre.ongoing-projects",
            url: "activities-of-council/cg-research-centre/ongoing-projects",
          }
        ]
      },
      {
        title: "menu.activities-of-council.research-development-promotional-cell.title",
        url: "activities-of-council/research-development-promotional-cell",
        submenu: [
          {
            title: "menu.activities-of-council.research-development-promotional-cell.mini-research-project",
            url: "activities-of-council/research-development-promotional-cell/mini-research-project",
          },
          {
            title: "menu.activities-of-council.research-development-promotional-cell.international-travel-grant",
            url: "activities-of-council/research-development-promotional-cell/international-travel-grant",
          },
          {
            title: "menu.activities-of-council.research-development-promotional-cell.seminar-symposium-workshop-conference",
            url: "activities-of-council/research-development-promotional-cell/seminar-symposium-workshop-conference",
          },
          {
            title: "menu.activities-of-council.research-development-promotional-cell.publication-grant",
            url: "activities-of-council/research-development-promotional-cell/publication-grant",
          },
          {
            title: "menu.activities-of-council.research-development-promotional-cell.identification-new-areas",
            url: "activities-of-council/research-development-promotional-cell/identification-new-areas",
          },
          {
            title: "menu.activities-of-council.research-development-promotional-cell.r-d-centres",
            url: "activities-of-council/research-development-promotional-cell/r-d-centres"
          }

        ]
      },
      {
        title: "menu.activities-of-council.cg-space-applications-centre.title",
        url: "activities-of-council/cg-space-applications-centre",
        submenu: [
          {
            title: "menu.activities-of-council.cg-space-applications-centre.completed-projects",
            url: "activities-of-council/cg-space-applications-centre/completed-projects",
          },
          {
            title: "menu.activities-of-council.cg-space-applications-centre.ongoing-projects",
            url: "activities-of-council/cg-space-applications-centre/ongoing-projects",
          },
          {
            title: "menu.activities-of-council.cg-space-applications-centre.atlas",
            url: "",
            submenu: [
              {
                title: "menu.activities-of-council.cg-space-applications-centre.resource-atlas-of-chhattisgarh",
                url: "activities-of-council/cg-space-applications-centre/resource-atlas-of-chhattisgarh",
              },
              {
                title: "menu.activities-of-council.cg-space-applications-centre.raigarh-district-resource-atlas",
                url: "activities-of-council/cg-space-applications-centre/raigarh-district-resource-atlas",
              }


            ]
          },
          {
            title: "menu.activities-of-council.cg-space-applications-centre.map-gallery",
            url: "activities-of-council/cg-space-applications-centre/map-gallery"
          }


        ]
      },
      {
        title: "menu.activities-of-council.popularisation-of-science.title",
        url: "activities-of-council/popularisation-of-science",
        submenu: [
          {

            title: "menu.activities-of-council.popularisation-of-science.cg_young_scientist_congress",
            url: "activities-of-council/popularisation-of-science/cg-young-scientist-congress"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.national_children_science_congress",
            url: "activities-of-council/popularisation-of-science/national-children-science-congress"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.western_india_science_fair",
            url: "activities-of-council/popularisation-of-science/western-india-science-fair"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.national_science_seminar",
            url: "activities-of-council/popularisation-of-science/national-science-seminar"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.science_quiz_competitions",
            url: "activities-of-council/popularisation-of-science/science-quiz-competitions"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.community_science_club",
            url: "activities-of-council/popularisation-of-science/community-science-club"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.popular_science_book_corner",
            url: "activities-of-council/popularisation-of-science/popular-science-book-corner"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.science_park",
            url: "activities-of-council/popularisation-of-science/science-park"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.mathematical_olympiad",
            url: "activities-of-council/popularisation-of-science/mathematical-olympiad"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.national_science_day",
            url: "activities-of-council/popularisation-of-science/national-science-day"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.national_mathematics_day",
            url: "activities-of-council/popularisation-of-science/national-mathematics-day"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.mobile_science_lab",
            url: "activities-of-council/popularisation-of-science/mobile-science-lab"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.vigyan_prasar_edusat_program",
            url: "activities-of-council/popularisation-of-science/vigyan-prasar-edusat-program"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.science_society_programme",
            url: "activities-of-council/popularisation-of-science/science-society-programme"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.mobile_planetarium",
            url: "activities-of-council/popularisation-of-science/mobile-planetarium"
          },
          {

            title: "menu.activities-of-council.popularisation-of-science.world_space_week",
            url: "activities-of-council/popularisation-of-science/world-space-week"
          },

        ]
      },
      {
        title: "menu.activities-of-council.science_technology_intervention_cell.title",
        url: "activities-of-council/science-technology-intervention-cell",
        submenu: [
          {
            title: "menu.activities-of-council.science_technology_intervention_cell.technology_village_programme",
            url: "activities-of-council/science-technology-intervention-cell/technology-village-programme",
          },
          {
            title: "menu.activities-of-council.science_technology_intervention_cell.technology_transfer",
            url: "activities-of-council/science-technology-intervention-cell/technology-transfer",
          },
          {
            title: "menu.activities-of-council.science_technology_intervention_cell.information_technology_centre_for_s_t",
            url: "activities-of-council/science-technology-intervention-cell/information-technology-centre-for-s-t",
          }


        ]
      },
      {
        title: "menu.activities-of-council.research_and_Publication",
        url: "/activities-of-council/research-and-publication"
      },
      {
        title: "menu.activities-of-council.activities_coordinator_cell",
        url: "activities-of-council/activities-coordinator-cell"
      },
      {
        title: "menu.activities-of-council.documentation_communication_centre",
        url: "activities-of-council/documentation-communication-centre"
      },
      {
        title: "menu.activities-of-council.intellectual_property_rights_centre.title",
        url: "activities-of-council/intellectual-property-rights-centre",
        submenu: [
          {
            title: "menu.activities-of-council.intellectual_property_rights_centre.patent_information_center",
            url: "activities-of-council/intellectual-property-rights-centre/patent-information-center",
          },
          {
            title: "menu.activities-of-council.intellectual_property_rights_centre.intellectual_property_facilitation.title",
            url: "activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre",
            submenu: [
              {
                title: "menu.activities-of-council.intellectual_property_rights_centre.intellectual_property_facilitation.aspirational_block_project",
                url: "activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre/aspirational-block-project",
              },
              {
                title: "menu.activities-of-council.intellectual_property_rights_centre.intellectual_property_facilitation.ip_yatra_program",
                url: "activities-of-council/intellectual-property-rights-centre/intellectual-property-facilitation-centre/IP-yatra-program ",
              }

            ]
          },
          {
            title: "menu.activities-of-council.intellectual_property_rights_centre.grassroot_innovation_augmentation_network_cell",
            url: "activities-of-council/intellectual-property-rights-centre/grassroot-innovation-augmentation-network",
          },
          {
            title: "menu.activities-of-council.intellectual_property_rights_centre.innovation_fund",
            url: "activities-of-council/intellectual-property-rights-centre/innovation-fund",
          },
          {
            title: "menu.activities-of-council.intellectual_property_rights_centre.world_intellectual_property_organization",
            url: "activities-of-council/intellectual-property-rights-centre/world-intellectual-property-organization",
          }

        ]
      },
      {
        title: "menu.activities-of-council.forest_eco_plan_centre",
        url: "activities-of-council/forest-eco-plan-centre",

      },
      {
        title: "menu.activities-of-council.ccost_activity_profile",
        url: "activities-of-council/ccost-activity-profile",

      },
      {
        title: "menu.activities-of-council.establishment_of_snrms",
        url: "activities-of-council/establishment-of-snrms",

      },
      {
        title: "menu.activities-of-council.services_provided_by_the_council.title",
        url: "activities-of-council/services-provided-by-the-council",
        submenu: [
          {
            title: "menu.activities-of-council.services_provided_by_the_council.central_laboratory_facility",
            url: "activities-of-council/services-provided-by-the-council/central-laboratory-facility",
          }

        ]
      },
      {
        title: "menu.activities-of-council.other_projects.title",
        url: "activities-of-council/other-projects",
        submenu: [
          {
            title: "menu.activities-of-council.other_projects.completed_projects",
            url: "activities-of-council/other-projects/completedp-projects",
          }
        ]
      }
    ]
  },
  {
    title: "menu.e-citizens.title",
    url: "e-citizens",
    submenu: [
      {
        title: "menu.e-citizens.right-to-information",
        url: "e-citizens/right-to-information",
      },
      {
        title: "menu.e-citizens.download-pdf",
        url: "e-citizens/download-pdf",
      },
      {
        title: "menu.e-citizens.circulars",
        url: "e-citizens/circulars",
      },
      {
        title: "menu.e-citizens.council-magazine",
        url: "/e-citizens/council-magazine",
      }

  
]
  },
  {
    title: "menu.media-gallery.title",
    url: "media-gallery",
    submenu: [
      {
        title: "menu.media-gallery.photo-gallery",
        url: "media-gallery/photo-gallery",
      },
      {
        title: "menu.media-gallery.poster-gallery",
        url: "media-gallery/poster-gallery",
      },
      {
        title: "menu.media-gallery.video-gallery",
        url: "media-gallery/video-gallery",
      },
      {
        title: "menu.media-gallery.press-gallery",
        url: "media-gallery/press-gallery",
      },
      {
        title: "menu.media-gallery.presentation-gallery",
        url: "media-gallery/presentation-gallery",
      }
    ]
  },
  {
    title: "menu.achievements",
    url: "achievements"
  },
  {
    title: "menu.noticeboard.title",
    url: "notice-board",
    submenu: [
      {
        title: "menu.noticeboard.news-events",
        url: "notice-board/news-events",
      },
      {
        title: "menu.noticeboard.tenders",
        url: "notice-board/tenders",
      },
      {
        title: "menu.noticeboard.advertisement",
        url: "notice-board/advertisement",
      }

  
]
  },
  {
    title: "menu.quickLinks",
    url: "quick-links"
  },
  {
    title: "menu.contactUs",
    url: "contact-us"
  }
];