import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import ATCSidebar from "../ATCSidebar";
import TitleList from "../../../component/TitleList";
import { useTranslation } from "react-i18next";
import { Layout } from 'antd';
import ApplyOnline from '../../../component/ApplyOnline';
import DownloadableList from "../../../component/DownloadableList";

export default function MiniResearchProject() {
  const { t } = useTranslation();
  const menuData = t("menu.activities-of-council.research-development-promotional-cell", { returnObjects: true }) || {};
 
  const { Content } = Layout;

  return (
    <div>

      <BreadcrumbLayout title="Mini Research Project" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
       <ATCSidebar menuData={menuData} type="ResearchDevelopmentPromotionalCell" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("miniresearchprojects.title")}</h3>
              </Col>

              <Col xs={12} className="mb-1 mt-1">
                <Layout>
                  <Content style={{ padding: '0 25px', marginTop: '10px' }}>
                    <ApplyOnline />
                  </Content>
                </Layout>
              </Col>
              <Col xs={12} className="mt-3">
                <div className="heading-paragraph">
                  <h3 style={{ color: "white", padding: "0.5rem", fontWeight: "bold", fontSize: "1.2rem", backgroundColor: "#1985A1" }}>{t("miniresearchprojects.paragraph1.title")}</h3>
                  <p className="ms-3">{t("miniresearchprojects.paragraph1.content")}</p>
                </div>
              </Col>

              <Col xs={12} className="mb-1 mt-1">
                <TitleList
                  title={t("miniresearchprojects.content1.title")}
                  list={t("miniresearchprojects.content1.data", { returnObjects: true })}
                />
              </Col>
              <Col xs={12}>
                <h3 style={{ color: "white", padding: "0.5rem", fontWeight: "bold", fontSize: "1.2rem", backgroundColor: "#1985A1" }}>{t("miniresearchprojects.broad_disciplines")}</h3>
              </Col>
              {t("miniresearchprojects.MiniProData", { returnObjects: true }).map(
                (i, index) => (
                  <Col lg={4} className="mb-1 mt-1" key={index}>
                    <div className="title-list">
                      <h3 style={{ color: "black", padding: "0.5rem", fontWeight: "bold", fontSize: "1.2rem", backgroundColor: "#7c7c7c" }}>{i.title}</h3>
                      <ul>
                        {i.list.map((list, i) => (
                          <li key={i}>{list}</li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                )
              )}

              <Col xs={12} className="mb-1 mt-1">
                <TitleList
                  title={t("miniresearchprojects.content2.title")}
                  list={t("miniresearchprojects.content2.data", { returnObjects: true })}
                />
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <TitleList
                  title={t("miniresearchprojects.content3.title")}
                  list={t("miniresearchprojects.content3.data", { returnObjects: true })}
                />
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div className="downloadable-list2">
                <DownloadableList _contentType = "4" _className="ms-4"/>
                </div>

                <div className="downloadable-list2">
                  <h4>{t("miniresearchprojects.Sanctionedtile")}</h4>
                  <DownloadableList _contentType = "5" _className="ms-4"/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
