
import { Button, Divider, Input, Upload, message, List, Switch } from "antd";
import Form from "antd/es/form";
import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col,Card } from "react-bootstrap";
import { ImageGalleryService } from "../../Services/ImageGalleryService";
import { readFileDataAsBase64 } from "../../global/FileConverter";
import Loader from "../../global/Loader";
import Swal from "sweetalert2";
import { AuthenticationService } from "../../Services/AuthService";
import { useNavigate, useLocation } from "react-router-dom";
import { TbTrashX, TbArrowBackUp } from "react-icons/tb";
import { Link } from "react-router-dom";
import { FileImageOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { BiImage } from "react-icons/bi";

const HomeSliderImageEntry = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [loadingFormSubmit, setLoadingFormSubmit] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [imgFileExt, setImgFileExt] = useState({ imageExt: "", type: "" });
  const [imgFileList, setImgFileList] = useState([]);
  const [imgFileValue, setImgFileValue] = useState(null);

  const [imageId, setImageId] = useState();
  const [title, setTitle] = useState("Add");

  const imageGalleryService = useMemo(() => new ImageGalleryService(), []);

  const navigate = useNavigate();
  const values = Form.useWatch([], form);

  useEffect(() => {
    const authService = new AuthenticationService();
    if (authService.getUserData?.role !== "3") {
      navigate('/user/unauthorized'); // Redirect to error page if role is not admin
    }
  }, [navigate]);
  
  useEffect(() => {
    if (location?.state?.EditFlag === true) {
      form.setFieldsValue({
        imagenotes: location?.state?.data?.imagenotes,
        isActive: (location?.state?.data?.isActive === 1) ? true : false
      });
      setImageId(location?.state?.data?.imageId);
      setTitle("edit");
    }
  }, [location?.state, form]);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormDisabled(true);
      },
      () => {
        setFormDisabled(false);
      }
    );
  }, [values, form]);

  const onRemoveImg = (file) => {
    const index = imgFileList.indexOf(file);
    const newFileList = imgFileList.slice();
    newFileList.splice(index, 1);
    setImgFileList(newFileList);
  };

  const beforeUploadImg = (file) => {
    const ext = file.name.split('.').pop();
    setImgFileExt({ imageExt: ext, type: file.type });

    if (file.size / 1024 / 1024 > 10) {
      message.error(`${file.name} is greater than 10MB.`);
      return false;
    }
    if (!(file.type === 'image/png' || file.type === 'image/jpeg')) {
      message.error("Only .png and .jpeg files are allowed");
      return false;
    }
    if (imgFileList.length > 0) {
      message.error(`${file.name} already added.`);
      return false;
    }
    setImgFileList([file]);
    return false;
  };

  useEffect(() => {
    const genRandomKey = async () => {
      if (imgFileList && imgFileList.length > 0) {
        try {
          let data = await readFileDataAsBase64(imgFileList[0]);
          setImgFileValue({
            documentExtension: imgFileExt.imageExt,
            documentMimeType: imgFileExt.type,
            documentTag: "HomeSliderImage",
            documentType: 16,
            documentImageGroup: 2,
            stateId: 22,
            documentList: [
              {
                documentInByte: data,
                documentLabel: "HomeSliderImage",
              },
            ],
          });
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }
    };

    genRandomKey();
  }, [imgFileList, imgFileExt]);

  const SubmitProjectDetails = async () => {
    try {
      setLoadingFormSubmit(true);
      const payload = {
        imageId: imageId,
        galleryType: 4,
        imageCategoryType: "",
        descHindi: "",
        descEnglish: "",
        imagenotes: form.getFieldValue("imagenotes"),
        blDocuments: imgFileValue ? [imgFileValue] : [],
        isActive: form.getFieldValue("isActive") === true ? 1 : 0,
        VerificationRemark: form.getFieldValue("VerificationRemark")
      };
      let res;
      if (location?.state?.EditFlag === true) {
        res = await imageGalleryService.ImageDetailsUpdate(payload);
      } else {
        res = await imageGalleryService.InsertNewImage(payload);
      }

      if (res.data.status) {
        Swal.fire({
          html: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        navigate("/user/home-content-list");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An unexpected error occurred. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error at submitting Project details:", error);
    } finally {
      setLoadingFormSubmit(false);
    }
  };

  return (
    <Container className="project-entry-form mt-4">
      {loadingFormSubmit && <Loader />}
    <Row className="justify-content-center">
      <Col lg={11}>
        <Card className="mb-4 shadow-sm">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <h3>{title === "edit" ? "Update Home Slider Image":"Add Home Slider Image"}</h3>        
            <Link className="btn btn-secondary" to="/user/home-content-list">
              <TbArrowBackUp /> Back
            </Link>
          </Card.Header>
          <Card.Body>
            <Form
                layout="vertical"
                form={form}
                name="OfficersDetaileForm"
                initialValues={{ isActive: true }}
                onFinish={SubmitProjectDetails}
                scrollToFirstError
            >

              <Card className="mb-3">
                <Card.Header className="fw-bold">Upload Image</Card.Header>
                <Card.Body>
                  <Row gutter={16}>
                 
                  <Col md={6}>
                    <Form.Item
                      name="thumbnailId"
                      label="Upload Slider Image"
                      rules={[{ required: !location?.state?.EditFlag, message: "*Required!" }]}
                    >
                      <div>
                        <ImgCrop aspect={1920 / 444}>
                          <Upload.Dragger
                            name="imgfiles"
                            beforeUpload={beforeUploadImg}
                            fileList={imgFileList}
                            accept="image/png, image/jpeg"
                            disabled={imgFileList.length >= 1}
                            showUploadList={false}
                          >
                            <p className="ant-upload-drag-icon">
                              <FileImageOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Only Png, jpeg file with size less than 10MB is allowed</p>
                          </Upload.Dragger>
                        </ImgCrop>
                        {imgFileList.length > 0 && (
                          <List
                            className="mt-1"
                            itemLayout="horizontal"
                            dataSource={imgFileList}
                            bordered
                            renderItem={(item, index) => (
                              <List.Item
                                key={index}
                                actions={[
                                  <Button
                                    key="list-loadmore-edit"
                                    className="btn btn-outline-danger"
                                    onClick={() => { onRemoveImg(item); }}
                                  >
                                    <TbTrashX />
                                  </Button>
                                ]}
                              >
                                <List.Item.Meta
                                  avatar={<BiImage />}
                                  title={item.name}
                                  description={`File Type: ${item.type} | Size: ${(item.size / 1024).toFixed(2)}KB`}
                                />
                              </List.Item>
                            )}
                          />
                        )}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      name="imagenotes"
                      label="Slider Image Notes"
                      rules={[
                        { required: true, message: '*Required!' },
                        { type: "string", max: 500, message: "Max length exceeded" },
                      ]}
                    >
                      <Input.TextArea showCount maxLength={250} placeholder="Enter Slider Image Notes" autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>

                  </Row>
                </Card.Body>
              </Card>

              

              <Card className="mb-3">
                <Card.Header className="fw-bold">Additional Details</Card.Header>
                <Card.Body>
                  <Row gutter={16}>
                 
                  <Col md={6}>
                      <Form.Item
                        name="VerificationRemark"
                        label="Remark"
                        rules={[
                          { required: location?.state?.EditFlag, message: '*Required!' },
                          { type: "string", max: 500, message: "Max length exceeded" },
                        ]}
                      >
                        <Input.TextArea showCount maxLength={250} placeholder="Enter Remark" autoSize={{ minRows: 3, maxRows: 5 }} />
                      </Form.Item>
                    </Col>
                  <Col md={6}>
                    <Form.Item
                      name="isActive"
                      label="Is Active:"
                      valuePropName="checked"
                      rules={[{ required: true, message: 'Required!' }]}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  </Row>
                </Card.Body>
              </Card>




              <div className="text-center mt-2">
              <Divider />
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!formDisabled}
                    loading={loadingFormSubmit}
                  >
                    {title === "edit" ? "Submit (Update)" : "Submit (Save)"}
                  </Button>
                </div>

            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  );
};

export default HomeSliderImageEntry;
