import CryptoJS from 'crypto-js';

// Example Key and IV
const key = CryptoJS.enc.Utf8.parse('3691010966581067');
const iv = CryptoJS.enc.Utf8.parse('6658106736910109');

export const encrypt = (plainText) => {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
};

export const decrypt = (cipherText) => {
    const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
};
