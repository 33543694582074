import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ProjectTable from '../../../../component/ProjectTable';

export default function OngoingProjects() {
  const { t } = useTranslation();
  const Data = t("PIC-OngoingProjects", { returnObjects: true, });
  const menuData = t("menu.activities-of-council.intellectual_property_rights_centre", { returnObjects: true, }) || {};
  return (
    <>
      <BreadcrumbLayout title="Ongoing Projects (PIC)" />
      <Container>
        <Row>
          <Col md={3} className="sidebar order-last order-md-first">
            <Row>
              <Col className="menu-list">
                <ul>

                  {/* <NavLink to="/activities-of-council/intellectual-property-rights-centre">
                     <li>  {menuData["title"]} </li>
                    </NavLink>
                  
                  
                    <NavLink to="/activities-of-council/intellectual-property-rights-centre/patent-information-center">
                     <li>  {menuData.patent_information_center.title} </li>
                    </NavLink> */}

                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/patent-information-center/completed-project">

                    <li>  {menuData.patent_information_center.completed_projects} </li>
                  </NavLink>
                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/patent-information-center/ongoing-projects">

                    <li>  {menuData.patent_information_center.ongoing_projects} </li>
                  </NavLink>


                  <NavLink to="/activities-of-council/intellectual-property-rights-centre/innovation-fund">
                    <li>  {menuData["innovation_fund"]} </li>
                  </NavLink>

                </ul>
              </Col>
            </Row>
          </Col>
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className='mb-1 mt-1'>

                <ProjectTable projectAreas="3" projectType="2" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
