import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import ATCSidebar from "../ATCSidebar";
import { useTranslation } from "react-i18next";
import ProjectTable from '../../../component/ProjectTable';
export default function OngoingProjects() {
  const { t } = useTranslation();
  const Data = t("CGSAC-OngoingProject", { returnObjects: true, });
  const menuData = t("menu.activities-of-council.cg-space-applications-centre", { returnObjects: true, }) || {};

  return (
    <>
      <BreadcrumbLayout title="Completed Project" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="CGSpaceApplicationsCentre" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className='mb-1 mt-1'>
                <ProjectTable projectAreas="2" projectType="2"/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
