import React, { useState } from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import { Tabs } from 'antd';
import { IoMdImages } from "react-icons/io";
import { TiNews } from "react-icons/ti";
import { FaMapMarkerAlt } from "react-icons/fa";
import PressGalleryList from './PressGalleryList';
import MapGalleryList from './MapGalleryList';
import ImageGalleryList from './ImageGalleryList';

const ManageGalleryContainer = () => {
  
  const [defaultActiveKey, setDefaultActiveKey] = useState(localStorage.getItem('galleryActiveTabKey') !== null ? localStorage.getItem('galleryActiveTabKey') : 1);

  
  const items1 = [
    {
      key: '1',
      label: 'Photo Gallery',
      icon: <IoMdImages />,
      children: <>
       <ImageGalleryList/>
      </>,
    },
    {
      key: '2',
      label: 'Press Gallery',
      icon: <TiNews />,
      children: <>
        <PressGalleryList/>
      </>,
    },
    {
      key: '3',
      label: 'Map Gallery',
      icon: <FaMapMarkerAlt />,
      children: <>
      <MapGalleryList/>
      </>,
    } 
  ];

  const onChange = (key) => {
    localStorage.setItem('galleryActiveTabKey', key);
    setDefaultActiveKey(key);
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Manage Photos</h3>
              </Card.Header>
              <Card.Body>





              <div className="custum-antd-tab">
              <Tabs defaultActiveKey={defaultActiveKey} type="card" items={items1} onChange={onChange} />
            </div>

              </Card.Body>
            </Card>


          </Col>
        </Row>
      </Container>

      {/* <Container>
        <Row>
          <Col className="dashboard ">
            <div className="title d-none">
              <h3>Manage Photos</h3>
            </div>
            <div className="content custum-antd-tab">
              <Tabs defaultActiveKey={defaultActiveKey} type="card" items={items1} onChange={onChange} />
            </div>
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default ManageGalleryContainer;

