import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { NavLink } from "react-router-dom";
import ATCSidebar from "../ATCSidebar";
import { useTranslation } from "react-i18next";
export default function OtherProjects() {
  const { t,i18n} = useTranslation();
  const menuData =
  t("menu.activities-of-council", {
    returnObjects: true,
  }) || {};
  return (
    <>
      <BreadcrumbLayout title="Other Projects" />
      <Container fluid >
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                {i18n.language === "en" && ( <h3>Other Projects</h3>)}
                {i18n.language === "hi" && ( <h3>अन्य परियोजनाए</h3>)}
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <ul className="nav-list">
                  <li>
                    <NavLink to="/activities-of-council/other-projects/completedp-projects">
                    {i18n.language === "en" && ( <>Completed Projects</>)}
                {i18n.language === "hi" && ( <>संपन्न परियोजनाए</>)}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/activities-of-council/other-projects/ongoing-projects">
                    {i18n.language === "en" && ( <>Ongoing Projects</>)}
                {i18n.language === "hi" && ( <>वर्तमान में चल रही परियोजनाएं</>)}
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
