import { useEffect, useRef, useCallback } from 'react';
import axiosInstance from "../global/AxiosInterceptor";
import Cookies from "js-cookie";

const useAutoLogout = (timeout = 300000) => { // 5 minutes in milliseconds
 

  const handleLogout = useCallback(async() => {
    try {
      const res = await axiosInstance.get('/Authentication/logout');
      if (res.data.status) {
          Cookies.remove("authToken");
          Cookies.remove("refreshToken");
          Cookies.remove("loginTimeStamp");
          localStorage.removeItem('loginTime');
          window.location.href = `/login?err=sto`;  
           
                       
      }
  } catch (err) {
      console.log(err);
  }
  }, []);

  const timer = useRef(null);

  const resetTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      handleLogout();
    }, timeout);
  }, [handleLogout, timeout]);

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'click'];
    const resetTimerHandler = () => resetTimer();

    events.forEach(event => window.addEventListener(event, resetTimerHandler));

    resetTimer(); // Initialize the timer

    return () => {
      events.forEach(event => window.removeEventListener(event, resetTimerHandler));
      if (timer.current) clearTimeout(timer.current);
    };
  }, [resetTimer]);

  return null; // No need to return anything from the hook
};

export default useAutoLogout;
