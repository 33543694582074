import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { FaRegFilePdf } from "react-icons/fa6";
import { useEffect, useState, useMemo } from "react";
import { PageContentService } from "../Services/PageContentService";
import Loader from "../global/Loader";
import Animation from "../assets/images/Animation.gif";
import Swal from "sweetalert2";
import { Empty, Pagination } from "antd";

export default function Achievements() {
  const { t, i18n } = useTranslation();

  const pageContentService = useMemo(() => new PageContentService(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  const [pageLoading, setPageLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Set as per your requirement

  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const viewDoc = async (doc) => {
    try {
      setPageLoading(true);
      if (doc.DocUniqueId !== null) {
        const res = await pageContentService.ViewDocument(doc.DocUniqueId);
        if (res.status === 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The document hasn't been uploaded yet. Please try again later.",
        });
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    const getPageContetsList = async (contentType, language) => {
      try {
        setLoadingTblData(true);
        const res = await pageContentService.GetPageContetListPublic(contentType, language);

        if (res.data && Array.isArray(res.data.table)) {
          setTblData(() => {
            let data = res.data.table.map((item, index) => ({
              key: index + 1,
              contentTypeName: item.contentTypeName,
              contentId: item.contentId,
              title: item.title,
              verificationStatus: item.verificationStatus,
              statusName: item.statusName,
              isActive: item.isActive,
              isActiveName: item.activeName,
              verificationremark: item.verificationRemark,
              publishDate: item.publishDate,
              submitDate: item.submitDate,
              doc: {
                DocUniqueId: item.uniqueId,
                DocMimeType: item.documentMimeType,
              },
            }));
            return data;
          });
        } else {
          setTblData([]); // Set to empty array if data is not available
        }
      } catch (error) {
        console.error("Error fetching page contents:", error);
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };

    getPageContetsList(3, lang);
  }, [lang, pageContentService]);

  // Calculate the index of the first and last item to be displayed based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currenttblData = tblData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle pagination change
  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };

  return (
    <>
      {pageLoading && <Loader />}
      <BreadcrumbLayout title="Achievements" />
      <Container fluid>
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("Achievements.title")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <Col xl={12}>
                  {loadingTblData && <img src={Animation} alt="Animation gif" width={200} />}
                  <Row>

                    {currenttblData.length > 0 ? (
                      currenttblData.map((list, i) => (
                        <div key={i}>
                          <a
                            style={{ textDecoration: "none" }}
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              viewDoc(list.doc);
                            }}
                          >
                            <div className="current-box">
                              <Col className="icon">
                                <FaRegFilePdf />
                              </Col>
                              <div className="text-content">
                                <Row className="d-flex justify-content-between">
                                  <Col className="info_head">
                                    {list.title}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))
                    ) : (
                      !loadingTblData &&
                      <Empty
                        description={
                          lang === 1
                            ? <span style={{ fontWeight: 'bold' }}>वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।</span>
                            : <span style={{ fontWeight: 'bold' }}>Currently, There are no data to display.</span>
                        }
                      />

                    )}
                  </Row>
                  {currenttblData.length > 0 && (
                    <Row justify="center">
                      <Col>
                        <div className="img-pagination" style={{ paddingBottom: "20px" }}>
                          <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={tblData.length}
                            onChange={handlePaginationChange}
                            showSizeChanger={true}
                            pageSizeOptions={['10', '20', '40', '80', '100']}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
