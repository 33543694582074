import axiosInstance from "../global/AxiosInterceptor";

export class PageContentService {


    PageContentInsert = async (payload) => {
        return await axiosInstance.post('/PageContent/pagecontentsinsert', payload)
    }
    PageContentUpdate = async (payload) => {
        return await axiosInstance.post('/PageContent/pagecontentupdate', payload)
    }


    GetPageContentsList = async (payload) => {
        return await axiosInstance.post('PageContent/getpagecontentsList', payload);
    }

    GetPageContetListPublic = async (contentTypeId, language) => {
        return await axiosInstance.get(`/PageContent/getpagecontentspublic/${contentTypeId}/${language}`); // 1: Hindi, 2: English
    }

    VerifyPageContent = async (payload) => {
        return await axiosInstance.post('/PageContent/pagecontentsverify', payload)
    }

    ViewDocument = async (uniqeId) => {
        return await axiosInstance.get('/Documents/getdocumentbyuniqueid/' + uniqeId, {
            responseType: 'blob'
        });
    };

    getCommonList = async (Type) => {
        return await axiosInstance.get(`/Common/CommonList/${Type}/2`); // 1: Hindi, 2: English
    }

}