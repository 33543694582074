import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import TitleList from "../../../component/TitleList";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
import DownloadableList from "../../../component/DownloadableList";


export default function NationalChildrenScienceCongress() {
  const { t } = useTranslation();
  const NationalChildrenScienceData = t("nationalChildrenScienceCongress", { returnObjects: true, }) || {};
  const menuData = t("menu.activities-of-council.popularisation-of-science", { returnObjects: true, }) || {};

  return (
    <>
      <BreadcrumbLayout title="National Children Science Congress" />
      <Container fluid>
        <Row>
           {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{NationalChildrenScienceData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div className="heading-paragraph">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: NationalChildrenScienceData.paragraph,
                    }}
                  ></p>
                </div>

                {NationalChildrenScienceData.ProgrammesData.map((item, i) => (
                  <Col key={i} lg={12} className="mb-1 mt-1">
                    <TitleList title={item.title} list={item.list} />
                  </Col>
                ))}



                {
                  NationalChildrenScienceData.content.map((i, index) => (
                    <div key={index} className='heading-paragraph'>
                      <h3>{i.heading}</h3>
                      {
                        i.paragraph.map((items, i) => (
                          <p key={i} dangerouslySetInnerHTML={{ __html: items }}></p>
                        ))
                      }


                    </div>
                  ))
                }
                {NationalChildrenScienceData.ActivityScreeningData.map((item, i) => (
                  <Col key={i} lg={12} className="mb-1 mt-1">
                    <TitleList title={item.title} list={item.list} />
                  </Col>
                ))}

                

                <div className="downloadable-list2 ">
                  <h4>{NationalChildrenScienceData.AwardeesTitle}</h4>
                  <DownloadableList _contentType = "14" _className="ms-4"/>
                </div>
                {/* <div className="downloadable-list2">
                  <h4>{NationalChildrenScienceData.JudgesTitle}</h4>
                  <DownloadableList _contentType = "15" _className="ms-4"/>
                </div> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
