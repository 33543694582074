import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import ATCSidebar from "../ATCSidebar";
import { useTranslation } from "react-i18next";
import DownloadableList from "../../../component/DownloadableList";

export default function ScienceAndSocietyProgramme() {
  const { t } = useTranslation();
  const ScienceAndSocietyProgrammeData = t("ScienceAndSocietyProgramme", { returnObjects: true, }) || {};
  const menuData = t("menu.activities-of-council.popularisation-of-science", { returnObjects: true, }) || {};

  return (
    <>
      <BreadcrumbLayout title="Science & Society Programme" />
      <Container fluid>
        <Row>
           {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{ScienceAndSocietyProgrammeData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {ScienceAndSocietyProgrammeData.content.map((d, i) => (
                  <div key={i} className="heading-paragraph">
                    {d.paragraph.map((items, i) => (
                      <p key={i} dangerouslySetInnerHTML={{ __html: items }}></p>
                    ))}
                    <ul className="ms-4">
                      {d.para_list.map((list, i) => (
                        <li key={i}>{list}</li>
                      ))}
                    </ul>

                    <div className="downloadable-list2 ms-4">
                    <DownloadableList _contentType = "26" _className=""/>
                    </div>
                    <div className="downloadable-list2">
                      <h4>{d.Sanctionedtile}</h4>
                      <DownloadableList _contentType = "27" _className="ms-4"/>
                    </div>

                  </div>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

    </>
  );
}
