import React from "react";
import Cookies from 'js-cookie';
import { createBrowserRouter, redirect } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Layout from "../layout/Layout";
import ErrorPage from "../error/Error";
import Contact from "../pages/Contact";
import { AuthenticationService } from "../Services/AuthService.js";
// import PageUnderConstruction from "../pages/PageUnderConstruction";
import Introduction from "../pages/about/Introduction";
import MissionObjectives from "../pages/about/MissionObjectives";
import FromDeskOfDirectorGeneral from "../pages/about/FromDeskOfDirectorGeneral";
import Login from "../admin/Login.js";
import AdminLayout from "../layout/AdminLayout.js";
import AuthLayout from "../layout/AuthLayout.js";
import LogInLayout from "../layout/LogInLayout.js";
import Services from "../pages/Services.js";
import RightToInformation from "../pages/e-citizens/RightToInformation.js";
// import PhotoGallery from "../pages/photo-gallery/PhotoGallery.js";
import BlankPage from "../component/BlankPage.js";
// import ListOfDirectorGenerals from "../pages/about/ListOfDirectorGenerals.js";
import GeneralBody from "../pages/about/GeneralBody.js";
import ExecutiveCommittee from "../pages/about/ExecutiveCommittee.js";
import OrganizationalChart from "../pages/about/OrganizationalChart.js";
import CGRCCompletedProject from "../pages/activities-of-the-council/chhattisgarh-research-centre/CompletedProject.js";
import MiniResearchProject from "../pages/activities-of-the-council/research-development-promotional-cell/MiniResearchProject.js";
import InternationalTravelGrant from "../pages/activities-of-the-council/research-development-promotional-cell/InternationalTravelGrant.js";
import SeminarSymposiumWorkshopConference from "../pages/activities-of-the-council/research-development-promotional-cell/SeminarSymposiumWorkshopConference.js";
import PosterGallery from "../pages/photo-gallery/PosterGallery";
import PhotoGallery from "../pages/photo-gallery/PhotoGallery.js";
import PresentationGallery from "../pages/photo-gallery/PresentationGallery.js";
import VideoGallery from "../pages/photo-gallery/VideoGallery.js";
// import KnowYourDistrict from "../pages/photo-gallery/KnowYourDistrict.js";
import PressGallery from "../pages/photo-gallery/PressGallery.js";
import EstablishmentofSNRMS from "../pages/activities-of-the-council/EstablishmentofSNRMS.js";
import CCOSTActivityProfile from "../pages/activities-of-the-council/CCOSTActivityProfile.js";
import CGRCOngoingProjects from "../pages/activities-of-the-council/chhattisgarh-research-centre/OngoingProjects.js";
import RAndDCentres from "../pages/activities-of-the-council/research-development-promotional-cell/RandDcentres.js";
import IdentificationofNewAreas from "../pages/activities-of-the-council/research-development-promotional-cell/IdentificationofNewAreas.js";
import CompletedProject from "../pages/activities-of-the-council/chhattisgarh-space-applications-centre/CompletedProject.js";
import OngoingProjects from "../pages/activities-of-the-council/chhattisgarh-space-applications-centre/OngoingProjects.js";
import ResourceAtlasOfChhattisgarh from "../pages/activities-of-the-council/chhattisgarh-space-applications-centre/ResourceAtlasOfChhattisgarh.js";
import RaigarhDistrictResourceAtlas from "../pages/activities-of-the-council/chhattisgarh-space-applications-centre/RaigarhDistrictResourceAtlas.js";
import DGPSGuidelines from "../pages/activities-of-the-council/chhattisgarh-space-applications-centre/DGPSGuidelines.js";
//import IPROngoingProjects from "../pages/activities-of-the-council/intellectual-property-rights-centre/patent_information_center/OngoingProjects.js";
import OthCompletedProjects from "../pages/activities-of-the-council/other-projects/CompletedProjects.js";
import OthOngoingProjects from "../pages/activities-of-the-council/other-projects/OngoingProjects.js";
import DownloadPDF from "../pages/e-citizens/DownloadPDF.js";
import Circulars from "../pages/e-citizens/Circulars.js";
import PublicationGrant from "../pages/activities-of-the-council/research-development-promotional-cell/PublicationGrant.js";
import ChhattisgarhYoungScientistCongress from "../pages/activities-of-the-council/popularisation-of-science/ChhattisgarhYoungScientistCongress.js";
import NationalChildrenScienceCongress from "../pages/activities-of-the-council/popularisation-of-science/NationalChildrenScienceCongress.js";
import WesternIndiaScienceFair from "../pages/activities-of-the-council/popularisation-of-science/WesternIndiaScienceFair.js";

import NationalScienceSeminar from "../pages/activities-of-the-council/popularisation-of-science/NationalScienceSeminar.js";
import CommunityScienceClub from "../pages/activities-of-the-council/popularisation-of-science/CommunityScienceClub.js";
import PopularScienceBookCorner from "../pages/activities-of-the-council/popularisation-of-science/PopularScienceBookCorner.js";
import SciencePark from "../pages/activities-of-the-council/popularisation-of-science/SciencePark.js";
import MathematicalOlympiad from "../pages/activities-of-the-council/popularisation-of-science/MathematicalOlympiad.js";

import NationalMathematicsDay from "../pages/activities-of-the-council/popularisation-of-science/NationalMathematicsDay.js";
import ScienceTechnologyCommunicationCentre from "../pages/activities-of-the-council/popularisation-of-science/ScienceTechnologyCommunicationCentre.js";
import NationalScienceDay from "../pages/activities-of-the-council/popularisation-of-science/NationalScienceDay.js";
import MobileScienceLab from "../pages/activities-of-the-council/popularisation-of-science/MobileScienceLab.js";
import ScienceQuizCompetitions from "../pages/activities-of-the-council/popularisation-of-science/ScienceQuizCompetitions.js";
import MobileScienceExhibitionVan from "../pages/activities-of-the-council/popularisation-of-science/MobileScienceExhibitionVan.js";
import VigyanPrasarEdusatProgram from "../pages/activities-of-the-council/popularisation-of-science/VigyanPrasarEdusatProgram.js";
import ScienceAndSocietyProgramme from "../pages/activities-of-the-council/popularisation-of-science/ScienceAndSocietyProgramme.js";
import MobilePlanetarium from "../pages/activities-of-the-council/popularisation-of-science/MobilePlanetarium.js";
import WorldSpaceWeek from "../pages/activities-of-the-council/popularisation-of-science/WorldSpaceWeek.js";
import PopularisationOfScience from "../pages/activities-of-the-council/popularisation-of-science/PopularisationOfScience.js";

import InformationTechnologyCentreForSAndT from "../pages/activities-of-the-council/science-and-technology-intervention-cell/InformationTechnologyCentreForSAndT.js";
import ScienceAndTechnologyInterventionCell from "../pages/activities-of-the-council/science-and-technology-intervention-cell/ScienceAndTechnologyInterventionCell.js";
import TechnologyTransfer from "../pages/activities-of-the-council/science-and-technology-intervention-cell/TechnologyTransfer.js";
import TechnologyVillageProgramme from "../pages/activities-of-the-council/science-and-technology-intervention-cell/TechnologyVillageProgramme.js";

import ActivitiesCoordinatorCell from "../pages/activities-of-the-council/ActivitiesCoordinatorCell.js";
import DocumentationAndCommunicationCentre from "../pages/activities-of-the-council/DocumentationAndCommunicationCentre.js";
import ForestEcoPlanCentre from "../pages/activities-of-the-council/ForestEcoPlanCentre.js";
import MapGallery from "../pages/activities-of-the-council/chhattisgarh-space-applications-centre/MapGallery.js";

import AccessibilityOptions from "../pages/Footer/AccessibilityOptions.js";
import AccessibilityStatement from "../pages/Footer/AccessibilityStatement.js";
import CopyrightPolicy from "../pages/Footer/CopyrightPolicy.js";
import Disclaimer from "../pages/Footer/Disclaimer.js";
import Feedback from "../pages/Footer/Feedback.js";
import Help from "../pages/Footer/Help.js";
import HyperlinkPolicy from "../pages/Footer/HyperlinkPolicy.js";
import Sitemap from "../pages/Footer/Sitemap.js";
import TermsAndCondition from "../pages/Footer/TermsAndCondition.js";
import TermsofUse from "../pages/Footer/TermsofUse.js";
import PrivacyPolicy from "../pages/Footer/PrivacyPolicy.js";
import ServicesProvidedByTheCouncil from "../pages/activities-of-the-council/services-provided-by-the-council/ServicesProvidedByTheCouncil.js";
import CentralLaboratoryFacility from "../pages/activities-of-the-council/services-provided-by-the-council/CentralLaboratoryFacility.js";
import IntellectualPropertyRightsCentre from "../pages/activities-of-the-council/intellectual-property-rights-centre/IntellectualPropertyRightsCentre.js";
import PatentInformationCenter from "../pages/activities-of-the-council/intellectual-property-rights-centre/patent_information_center/PatentInformationCenter.js";
import PICOngoingProjects from "../pages/activities-of-the-council/intellectual-property-rights-centre/patent_information_center/OngoingProjects.js";
//import IPRCompletedProjects from "../pages/activities-of-the-council/intellectual-property-rights-centre/patent_information_center/CompletedProjects.js";
import InnovationFund from "../pages/activities-of-the-council/intellectual-property-rights-centre/InnovationFund.js";
import OtherProjects from "../pages/activities-of-the-council/other-projects/OtherProjects.js";
import GrassrootInnovationAugmentationNetwork from "../pages/activities-of-the-council/intellectual-property-rights-centre/GrassrootInnovationAugmentationNetwork.js";
import QuickLinks from "../pages/QuickLinks.js";
import Achievements from "../pages/Achievements.js";
import Advertisement from "../pages/e-citizens/Advertisement.js";
import NewsAndEvents from "../pages/news-events/NewsAndEvents.js";
import Tenders from "../pages/news-events/Tenders.js";
import ArchiveTender from "../pages/news-events/ArchiveTender.js";
import ListOfOfficers from "../pages/about/ListOfOfficers.js";
import ActivitiesoftheCouncil from "../pages/activities-of-the-council/ActivitiesoftheCouncil.js";
import ResearchAndDevelopmentPromotionalCell from "../pages/activities-of-the-council/research-development-promotional-cell/ResearchAndDevelopmentPromotionalCell.js";
import CGResearchCentre from "../pages/activities-of-the-council/chhattisgarh-research-centre/CGResearchCentre.js";
import ChhattisgarhSpaceApplicationsCentre from "../pages/activities-of-the-council/chhattisgarh-space-applications-centre/ChhattisgarhSpaceApplicationsCentre.js";
import Test from "../component/Test.js";
import NewsAndEventsDetails from "../pages/news-events/NewsAndEventsDetails.js";
import DossierofYoungScientist from "../pages/activities-of-the-council/popularisation-of-science/DossierofYoungScientist.js";
import Dashboard from "../user/Dashboard.js";
import OfficersList from "../user/whos-who/OfficersList.js";
import AddEditOfficer from "../user/whos-who/AddEditOfficer.js";
import OfficersListPreview from "../user/whos-who/OfficersListPreview.js";
import ProjectsList from "../user/Projects/ProjectsList.js";
import ProjectEntry from "../user/Projects/ProjectEntry.js";
import ProjectPreview from "../user/Projects/ProjectPreview.js";
import ContentList from "../user/ManageContent/ContentList.js";
import ContentEntry from "../user/ManageContent/ContentEntry.js";
import ContentPreview from "../user/ManageContent/ContentPreview.js";
import PresentationEntry from "../user/Presentation/PresentationEntry.js";
import PresentationPreview from "../user/Presentation/PresentationPreview.js";
import PresentationList from "../user/Presentation/PresentationList.js";
import ArchiveNews from "../pages/news-events/ArchiveNews.js";
import ArchiveNewsDetails from "../pages/news-events/ArchiveNewsDetails.js";
import NoticeList from "../user/Notice/NoticeList.js";
import NoticeEntry from "../user/Notice/NoticeEntry.js";
import ManageGalleryContainer from "../user/ImageGallery/ManageGalleryContainer.js";
import PosterGalleryList from "../user/PosterGallery/PosterGalleryList.js";

import Advertisement2 from "../pages/news-events/Advertisement.js";

import ArchiveCirculars from "../pages/news-events/ArchiveCirculars.js";
import ArchiveAdvertisement from "../pages/news-events/ArchiveAdvertisement.js";
import PosterGalleryEntry from "../user/PosterGallery/PosterGalleryEntry.js";
import PressGalleryEntry from "../user/ImageGallery/PressGalleryEntry.js";
import ImageGalleryEntry from "../user/ImageGallery/ImageGalleryEntry.js";
import MapGalleryEntry from "../user/ImageGallery/MapGalleryEntry.js";
import HomeContentContainer from "../user/Home/HomeContentContainer.js";
import HomeSliderImageEntry from "../user/Home/HomeSliderImageEntry.js";
import HomePageMinisterEntry from "../user/Home/HomePageMinisterEntry.js";
import AnnualReport from "../pages/about/AnnualReport.js";
import ChangePassword from "../user/ChangePassword.js";
import AspirationalBlockProject from "../pages/activities-of-the-council/intellectual-property-rights-centre/AspirationalBlockProject.js";
import IPYatraProgram from "../pages/activities-of-the-council/intellectual-property-rights-centre/IP_YatraProgram.js";
import WorldIntellectualPropertyOrganization from "../pages/activities-of-the-council/intellectual-property-rights-centre/WorldIntellectualPropertyOrganization.js";
import IntellectualPropertyFacilitationcentre from "../pages/activities-of-the-council/intellectual-property-rights-centre/IntellectualPropertyFacilitationcentre.js";
import VisitorFeedbackList from "../user/VisitorFeedbackList.js";
import Unauthorized from "../user/Unauthorized.js";
import CurtainEffect from "../component/CurtainEffect.js";
import CouncilMagazine from "../pages/e-citizens/CouncilMagazine.js";
import ResearchAndPublication from "../pages/activities-of-the-council/ResearchAndPublication.js";
import ScreenReaderAccess from "../pages/Footer/ScreenReaderAccess.js";
const HomePage = React.lazy(() => import("../pages/Home"));

const redirectIfUser = () => {
  const authService = new AuthenticationService();
  if (authService.getUserData) {
    const userData = authService.getUserData; 
    if (userData.role === '3') { 
      return redirect('/user/home');
    } else if (userData.role === '2') { 
      return redirect('/user/home');
    }
  }
  return null;
}


// const redirectIfNotLoggedIn = () => {
//   if (!sessionStorage.getItem("authToken")) {
//     return redirect('/login');
//   }
//   return null;
// }

const redirectIfNotLoggedIn = () => {

  const authToken = Cookies.get("authToken");
  if (!authToken) {
    return redirect('/login');
  }
  return null;
}

const router =createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "cgcost", element: <Navigate to="/" replace /> }, // Redirect /cgcost to /
      { path: "home", element: <HomePage /> },
      { path: "test", element: <Test /> },
      { path: "Test", element: <Test /> },
      { path: "contact-us", element: <Contact /> },
      { path: "accessibility-statement", element: <AccessibilityStatement /> },
      { path: "accessibility-statement/accessibility-options", element: <AccessibilityOptions /> },
      { path: "disclaimer", element: <Disclaimer /> },
      { path: "copyright-policy", element: <CopyrightPolicy /> },
      { path: "site-map", element: <Sitemap /> },
      { path: "feedback", element: <Feedback /> },
      { path: "hyperlink-policy", element: <HyperlinkPolicy /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "terms-and-conditions", element: <TermsAndCondition /> },
      { path: "terms-of-use", element: <TermsofUse /> },
      { path: "help", element: <Help /> },
      { path: "screen-reader-access", element: <ScreenReaderAccess /> },
      
      // { path: "/notice-board/tenders/archive-tender", element: <ArchiveTender /> },

      {
        path: "achievements",
        element: <Achievements />,
      },
      {
        path: "quick-links",
        element: <QuickLinks />,
      },
      { path: "services", element: <Services /> },

      {
        path: "about",
        children: [
          { index: true, element: <Introduction /> },

          {
            path: "memorandum-of-understanding",
            children: [
              { index: true, element: <BlankPage title="memorandum-of-understanding" /> },
              {
                path: "mou-with-nitrr",
                element: <BlankPage title="mou-with-nitrr" />,
              },
              {
                path: "mou-with-other-institutes",
                element: <BlankPage title="mou-with-other-institutes" />,
              },
              {
                path: "mou-with-ssn-ayurved-college",
                element: <BlankPage title="mou-with-ssn-ayurved-college" />,
              },
              {
                path: "mou-with-kalinga-university",
                element: <BlankPage title="mou-with-kalinga-university" />,
              },
            ],
          },
          {
            path: "whos-who",
            children: [
              {
                index: true,
                element: <ListOfOfficers />,
              },
              {
                path: "list-of-officers",
                element: <ListOfOfficers />,
              },
            ],
          },
          { path: "introduction", element: <Introduction /> },
          { path: "executive-committee", element: <ExecutiveCommittee /> },
          { path: "mission-objectives", element: <MissionObjectives /> },
          {
            path: "from-director-general",
            element: <FromDeskOfDirectorGeneral />,
          },
          { path: "general-body", element: <GeneralBody /> },
          {
            path: "list-of-director-generals",
            element: <BlankPage title="list-of-director-generals" />,
          },
          {
            path: "annual-report",
            element: <AnnualReport/>,
          },
          { path: "organizational-chart", element: <OrganizationalChart /> },
          {
            path: "work-allotment-of-scientists",
            element: <BlankPage title="work-allotment-of-scientists" />,
          },
          {
            path: "purchase-committee",
            element: <BlankPage title="purchase-committee" />,
          },
          { path: "acts-rules", element: <BlankPage title="acts-rules" /> },
          {
            path: "instructions-for-medical-bills",
            element: <BlankPage title="instructions-for-medical-bills" />,
          },
          {
            path: "terms-conditions-PhD-Course",
            element: <BlankPage title="terms-conditions-PhD-Course" />,
          },
          {
            path: "process-to-sanction-earned-leave",
            element: <BlankPage title="process-to-sanction-earned-leave" />,
          },
          {
            path: "performance-appraisal-report",
            element: <BlankPage title="performance-appraisal-report" />,
          },
        ],
      },
      {
        path: "activities-of-council",
        children: [
          {
            index: true,
            element: <ActivitiesoftheCouncil />,
          },

          {
            path: "activities-coordinator-cell",
            element: (
              <ActivitiesCoordinatorCell />
            ),
          },
          {
            path: "research-and-publication",
            element: (
              <ResearchAndPublication />
            ),
          },
          {
            path: "documentation-communication-centre",
            element: (
              <DocumentationAndCommunicationCentre />
            ),
          },
          {
            path: "forest-eco-plan-centre",
            element: <ForestEcoPlanCentre />,
          },

          { path: "ccost-activity-profile", element: <CCOSTActivityProfile /> },
          { path: "establishment-of-snrms", element: <EstablishmentofSNRMS /> },
          {
            path: "ccost-as-knowledge-partner",
            element: (
              <BlankPage title="ccost_as_knowledge_partner" />
            ),
          },
          {
            path: "18th-cg-young-scientist-congress",
            element: (
              <BlankPage title="18th_cg_young_scientist_congress" />

            ),
          },

          {
            path: "cg-research-centre",
            children: [
              {
                index: true,
                element: (
                  <CGResearchCentre />
                ),
              },
              { path: "completed-project", element: <CGRCCompletedProject /> },
              { path: "ongoing-projects", element: <CGRCOngoingProjects /> },
            ],
          },

          {
            path: "research-development-promotional-cell",
            children: [
              {
                index: true,
                element: (
                  <ResearchAndDevelopmentPromotionalCell />
                ),
              },
              {
                path: "mini-research-project",
                element: <MiniResearchProject />,
              },
              {
                path: "international-travel-grant",
                element: <InternationalTravelGrant />,
              },
              {
                path: "seminar-symposium-workshop-conference",
                element: <SeminarSymposiumWorkshopConference />,
              },
              {
                path: "publication-grant",
                element: <PublicationGrant />,
              },
              {
                path: "identification-new-areas",
                element: <IdentificationofNewAreas />,
              },
              { path: "r-d-centres", element: <RAndDCentres /> },
            ],
          },
          {
            path: "cg-space-applications-centre",
            children: [
              {
                index: true,
                element: (
                  <ChhattisgarhSpaceApplicationsCentre />
                ),
              },
              { path: "completed-projects", element: <CompletedProject /> },
              { path: "ongoing-projects", element: <OngoingProjects /> },
              {
                path: "atlas-village-info-charra-kurud",
                element: (
                  <BlankPage title="atlas-village-info-charra-kurud" />
                ),
              },
              {
                path: "atlas-village-info-giraud-dharsiwa",
                element: (
                  <BlankPage title="atlas-village-info-giraud-dharsiwa" />
                ),
              },
              {
                path: "resource-atlas-of-chhattisgarh",
                element: <ResourceAtlasOfChhattisgarh />,
              },
              {
                path: "raigarh-district-resource-atlas",
                element: <RaigarhDistrictResourceAtlas />,
              },
              {
                path: "map-gallery",
                element: <MapGallery />,
              },
              {
                path: "dgps-guidelines",
                element: <DGPSGuidelines />,
              },
              {
                path: "facility-in-the-cgsac",
                element: (
                  <BlankPage title="facility-in-the-cgsac" />
                ),
              },
            ],
          },
          {
            path: "popularisation-of-science",
            children: [
              {
                index: true,
                element: (
                  <PopularisationOfScience />
                ),
              },
              {
                path: "cg-young-scientist-congress",
                element: (
                  <ChhattisgarhYoungScientistCongress />
                ),
              },
              {
                path: "cg-young-scientist-congress/dossier-of-young-scientist",
                element: (
                  <DossierofYoungScientist />
                ),
              },
              {
                path: "national-children-science-congress",
                element: (
                  <NationalChildrenScienceCongress />
                ),
              },
              {
                path: "western-india-science-fair",
                element: (
                  <WesternIndiaScienceFair />
                ),
              },
              {
                path: "national-science-seminar",
                element: (
                  <NationalScienceSeminar />
                ),
              },
              {
                path: "science-quiz-competitions",
                element: (
                  <ScienceQuizCompetitions />
                ),
              },
              {
                path: "community-science-club",
                element: (
                  <CommunityScienceClub />
                ),
              },
              {
                path: "popular-science-book-corner",
                element: (
                  <PopularScienceBookCorner />
                ),
              },
              {
                path: "science-park",
                element: <SciencePark />,
              },
              {
                path: "mathematical-olympiad",
                element: (
                  <MathematicalOlympiad />
                ),
              },
              {
                path: "science-technology-communication-centre",
                element: (
                  <ScienceTechnologyCommunicationCentre />
                ),
              },
              {
                path: "national-science-day",
                element: <NationalScienceDay />,
              },
              {
                path: "national-mathematics-day",
                element: (
                  <NationalMathematicsDay />
                ),
              },
              {
                path: "mobile-science-lab",
                element: <MobileScienceLab />,
              },
              {
                path: "mobile-science-cxhibition-van",
                element: (
                  <MobileScienceExhibitionVan />
                ),
              },
              {
                path: "vigyan-prasar-edusat-program",
                element: (
                  <VigyanPrasarEdusatProgram />
                ),
              },
              {
                path: "science-society-programme",
                element: (
                  <ScienceAndSocietyProgramme />
                ),
              },
              {
                path: "mobile-planetarium",
                element: <MobilePlanetarium />,
              },
              {
                path: "world-space-week",
                element: <WorldSpaceWeek />,
              },
            ],
          },
          {
            path: "science-technology-intervention-cell",
            children: [
              {
                index: true,
                element: (
                  <ScienceAndTechnologyInterventionCell />
                ),
              },
              {
                path: "technology-village-programme",
                element: (
                  <TechnologyVillageProgramme />
                ),
              },
              {
                path: "technology-transfer",
                element: <TechnologyTransfer />,
              },
              {
                path: "information-technology-centre-for-s-t",
                element: (
                  <InformationTechnologyCentreForSAndT />
                ),
              },
            ],
          },
          {
            path: "intellectual-property-rights-centre",
            children: [
              {
                index: true,
                element: (
                  <IntellectualPropertyRightsCentre />
                ),
              },
              {
                path: "patent_information_center",
                children: [
                  {
                    index: true,
                    element: (
                      <PatentInformationCenter />
                    ),
                  },
                  {
                    path: "ongoing_projects",
                    element: <PICOngoingProjects />,
                  },
                 
                ],
              },

              {
                path: "innovation_fund",
                element: <InnovationFund />,
              },
            ],
          },
          {
            path: "other-projects",
            children: [
              {
                index: true,
                element: <OtherProjects />,
              },
              {
                path: "completedp-projects",
                element: <OthCompletedProjects />,
              },
              {
                path: "ongoing-projects",
                element: <OthOngoingProjects />,
              },
            ],
          },

          {
            path: "intellectual-property-rights-centre",
            children: [
              {
                index: true,
                element: <IntellectualPropertyRightsCentre />,
              },
              {
                path: "innovation-fund",
                element: <InnovationFund />,
              },
              {
                path: "grassroot-innovation-augmentation-network",
                element: <GrassrootInnovationAugmentationNetwork />,
              },
              {
                path: "world-intellectual-property-organization",
                element: <WorldIntellectualPropertyOrganization/>,
              },
              {
                path: "patent-information-center",
                element: <PatentInformationCenter />,
              },
              {
                path: "intellectual-property-facilitation-centre",
                children: [
                  {
                    index: true,
                    element: (
                    <IntellectualPropertyFacilitationcentre/>
                    ),
                  },

                  { path: "aspirational-block-project", element:<AspirationalBlockProject/> },
                  {
                    path: "IP-yatra-program",
                    element: <IPYatraProgram/>,
                  },
                ],
              }
            ],
          },
          {
            path: "services-provided-by-the-council",
            children: [
              {
                index: true,
                element: <ServicesProvidedByTheCouncil />,
              },
              {
                path: "central-laboratory-facility",
                element: <CentralLaboratoryFacility />,
              }

            ],
          },
        ],
      },
      {
        path: "e-citizens",
        children: [
          {
            index: true,
            element: <RightToInformation />,
          },
          { path: "right-to-information", element: <RightToInformation /> },
          {
            path: "download-pdf",
            element: <DownloadPDF />,
          },
          {
            path: "advertisement",
            element: <Advertisement />,
          },
          {
            path: "circulars",
            element: <Circulars />,
          },
          {
            path: "council-magazine",
            element: <CouncilMagazine />,
          },
        ],
      },
      {
        path: "notice-board",
        children: [
          {
            index: true,
            element: <NewsAndEvents />,
          },
          {
            path: "news-events",
            element: <NewsAndEvents />,
          },
          { path: "news-and-event-details", element: <NewsAndEventsDetails /> },
          { path: "archive-news-details", element: <ArchiveNewsDetails /> },
          { path: "archive-news", element: <ArchiveNews /> },
          {
            path: "tenders",
            element: <Tenders />,
          },
          {
            path: "circulars",
            element: <Circulars/>,
          },
          {
            path: "advertisement",
            element: <Advertisement2 />,
          },
          {
            path: "archive-circulars",
            element: <ArchiveCirculars />,
          },
          {
            path: "archive-advertisement",
            element: <ArchiveAdvertisement />,
          },
          {
            path: "archive-tender",
            element: <ArchiveTender />,
          },
        ],
      },
      {
        path: "media-gallery",
        children: [
          { index: true, element: <PhotoGallery />},
          { path: "photo-gallery", element: <PhotoGallery /> },
          { path: "poster-gallery", element: <PosterGallery /> },
          { path: "video-gallery", element: <VideoGallery /> },
          { path: "press-gallery", element: <PressGallery /> },
          { path: "presentation-gallery", element: <PresentationGallery /> },
          { path: "know-your-district", element: <BlankPage title="know-your-district" /> },
        ],
      },
    ],
  },
  {
    element: <LogInLayout />, loader: redirectIfUser, errorElement: <ErrorPage />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'curtain-effect', element: <CurtainEffect /> },
    ]
  },
  {

    element: <AuthLayout />, errorElement: <ErrorPage />, id: 'Dashboard', loader: redirectIfNotLoggedIn,
    children: [
      {
        path: 'nodal', element: <AdminLayout />,
        children: [
          { path: 'dashboard', element: <Dashboard type="Nodal" />, index: true },
          { path: 'about-us', element: <Dashboard type="Nodal" /> },

        ]
      },
      {
        path: 'user', element: <AdminLayout />,
        children: [
          { path: "unauthorized", element: <Unauthorized /> },
          { path: 'home', element: <Dashboard menu="Dashboard" />,index: true },

          {path: 'projects/projects-list', element: <ProjectsList/>},
          {path: 'projects/projects-entry', element: <ProjectEntry/>},
          {path: 'projects/projects-preview', element: <ProjectPreview/>},

          { path: 'whoswho/officers-list', element: <OfficersList/> },
          { path: 'whoswho/add-edit-officers', element: <AddEditOfficer/> },
          { path: 'whoswho/preview-officers', element: <OfficersListPreview /> },

          { path: 'manage/content-list', element: <ContentList/> },
          { path: 'manage/content-entry', element: <ContentEntry/> },
          { path: 'manage/preview-content', element: <ContentPreview /> },

          { path: 'presentation/presentation-list', element: <PresentationList /> },
          { path: 'presentation/presentation-entry', element: <PresentationEntry /> },
          { path: 'presentation/preview-presentation', element: <PresentationPreview /> },

          { path: 'notice/notice-list', element: <NoticeList/> },
          { path: 'notice/notice-entry', element: <NoticeEntry /> },
          { path: 'notice/preview-notice', element: <PresentationPreview /> },

          { path: 'image-gallery-list', element: <ManageGalleryContainer/> },
          { path: 'press-gallery-entry', element: <PressGalleryEntry/> },
          { path: 'map-gallery-entry', element: <MapGalleryEntry/> },
          { path: 'image-gallery-entry', element: <ImageGalleryEntry/> },
          
          { path: 'poster-gallery-list', element: <PosterGalleryList/> },
          { path: 'poster-gallery-entry', element: <PosterGalleryEntry /> },

          { path: 'home-content-list', element: <HomeContentContainer /> },
          { path: 'home-slider-image-entry', element: <HomeSliderImageEntry /> },
          { path: 'minister-entry', element: <HomePageMinisterEntry /> },
          { path: 'change-password', element: <ChangePassword /> },

          { path: 'visitor-feedback', element: <VisitorFeedbackList/> },

         
        ]
      }
    ]
  },
]
, {
  basename: process.env.PUBLIC_URL,
  
}
);


export default router;
