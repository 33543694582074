import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
import DownloadableList from '../../../component/DownloadableList';

export default function ScienceQuizCompetitions() {
  const { t } = useTranslation();
  const ScienceQuizCompetitionsData = t("ScienceQuizCompetitions", { returnObjects: true, }) || {};
  const menuData = t("menu.activities-of-council.popularisation-of-science", { returnObjects: true, }) || {};

  return (
    <>

      <BreadcrumbLayout title="Science Quiz Competitions" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{ScienceQuizCompetitionsData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {ScienceQuizCompetitionsData.content.map((items, i) => (
                  <HeadPara2 key={i} data={items} />
                ))}

                <ul style={{ marginLeft: "30px" }}>

                  {
                    ScienceQuizCompetitionsData.ZonesList.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))
                  }
                </ul>
                {ScienceQuizCompetitionsData.content2.map((items, i) => (
                  <HeadPara2 key={i} data={items} />
                ))}

                {/* <div className="downloadable-list2 ms-4">
                <DownloadableList _contentType = "20" _className=""/>
                </div> */}
                {/* <div className="downloadable-list2">
                  <h4>{ScienceQuizCompetitionsData.participantsTitle}</h4>
                  <DownloadableList _contentType = "21" _className="ms-4"/>
                </div> */}
                <br/>

{ScienceQuizCompetitionsData.content3.map((items, i) => (
                  <>
                   <Col xs={12} className="heading">
                <h3>{items.heading}</h3>
              </Col>
                  <div className='heading-paragraph'>
                   
        {/* <h3>{items.heading}</h3> */}
        {
          items.paragraph.map((items,i)=>(
            <p key={i} dangerouslySetInnerHTML={{ __html: items }}></p>
          ))
        }
       {/* <HeadPara2 key={i} data={items} /> */}
      </div>
      </>
                ))}



{/* <div className="downloadable-list2 ms-4">
                <DownloadableList _contentType = "20" _className=""/>
                </div> */}
                <div className="downloadable-list2">
                  <h4>{ScienceQuizCompetitionsData.participantsTitle2}</h4>
                  <DownloadableList _contentType = "44" _className="ms-4"/>
                </div>
                <br/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>


    </>
  )
}
