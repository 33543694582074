import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import DownloadableList from "../../../component/DownloadableList";
import ATCSidebar from "../ATCSidebar";

export default function PublicationGrant() {
  const { t } = useTranslation();
  const menuData =
    t("menu.activities-of-council.research-development-promotional-cell", {
      returnObjects: true,
    }) || {};
  return (
    <>

      <BreadcrumbLayout title="Publication Grant" />
      <Container fluid>
        <Row>
                  {/* Sidebar */}
       <ATCSidebar menuData={menuData} type="ResearchDevelopmentPromotionalCell" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("publicationgrant.title")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div className="heading-paragraph">
                  {t("publicationgrant.paragraph", {
                    returnObjects: true,
                  }).map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
                </div>
                <div className="downloadable-list2 ms-4">
                 <DownloadableList _contentType = "10" _className=""/>
                </div>
                <div className="downloadable-list2">
                  <h4>{t("publicationgrant.Sanctionedtile")}</h4>
                  <DownloadableList _contentType = "11" _className="ms-4"/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
