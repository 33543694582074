import React from 'react';
import { pdfjs} from 'react-pdf'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function PDFViewer(props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const viewerStyle = {
    width: '100%',
    height: '600px',
  };

  return (
    <div style={viewerStyle}>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
        <Viewer fileUrl={props.fileName} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </div>
  );
}
