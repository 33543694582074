import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
export default function ServicesProvidedByTheCouncil() {
  const { t } = useTranslation();
  const Data = t("ServicesProvidedByTheCouncil", {
    returnObjects: true,
  });
  const menuData =
  t("menu.activities-of-council", {
    returnObjects: true,
  }) || {};
  return (
    <>
      <BreadcrumbLayout title="Services Provided By The Council" />
      <Container fluid>
        <Row>
       {/* Sidebar */}
       <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <ul className="nav-list">
                  <li>
                    <NavLink to="/activities-of-council/services-provided-by-the-council/central-laboratory-facility">
                      {Data.link}
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
