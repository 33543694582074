import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import HeadPara2 from "../../component/HeadPara2";
import { useTranslation } from "react-i18next";
function TermsofUse() {
  const { t } = useTranslation();
  const Termsdata = t("TermsofUse", { returnObjects: true });

  return (
    <>
      <BreadcrumbLayout title="Terms of Use" />
      <Container fluid>
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{Termsdata.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-3">
                {Termsdata.data.map((items,i) => (
                  <HeadPara2 key={i} data={items} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TermsofUse
