import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import AboutSidebar from "./AboutSideBar";
import { useTranslation } from "react-i18next";
export default function FromDeskOfDirectorGeneral() {
  const { t } = useTranslation();
  const menuData =
    t("menu", {
      returnObjects: true,
    }) || {};
  return (
    <>
      <BreadcrumbLayout title="FromDeskOfDirectorGeneral" />
      <Container fluid >
        <Row>
         {/* Sidebar */}
      <AboutSidebar menuData={menuData} />
      {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("deskofdirectorgeneral.title")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1 fromDesk">
                <h5 className="text-center head-message">
                  {t("deskofdirectorgeneral.massage")}
                </h5>
                {t("deskofdirectorgeneral.data", { returnObjects: true }).map(
                  (item, index) => (
                    <p key={index}>{item}</p>
                  )
                )}
                <br />
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={5}>
                <p className="text-center fw-b" style={{ fontSize: "1.5rem",color:"#4B006E" }}>
                  {t("deskofdirectorgeneral.name")}
                </p>
                <p className="text-center fw-b" style={{ marginTop: "-15px", color:"#003366" }}>
                  {t("deskofdirectorgeneral.dg")} <br />
                  {t("deskofdirectorgeneral.ccost")}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>    </>
  );
}
