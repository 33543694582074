import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
export default function GrassrootInnovationAugmentationNetwork() {
  const { t } = useTranslation();
  const Data = t("GrassrootInnovationAugmentationNetwork", {
    returnObjects: true,
  });
  const menuData =
    t("menu.activities-of-council.intellectual_property_rights_centre", {
      returnObjects: true,
    }) || {};
  return (
    <>
      <BreadcrumbLayout title="" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
         <ATCSidebar menuData={menuData} type="IntellectualPropertyRightsCentre" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className='mb-1 mt-1'>
                <div className='heading-paragraph'>
                  <p>{Data.content.paragraph}</p>


                </div>

              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
