import React, {  useState,useMemo,useEffect } from 'react';
import { Col, Row, Container } from "react-bootstrap";
import {  Pagination,Empty } from "antd";
import { PosterGalleryService } from "../Services/PosterGalleryService";
import Swal from "sweetalert2";
import Loader from "../global/Loader";
import { useTranslation } from "react-i18next";


const PDFGallery = ({ galleryItems,loadingTblData }) => {
  const { i18n } = useTranslation();
  const [pageLoading, setPageLoading] = useState(false);
  const posterGalleryService = useMemo(() => new PosterGalleryService(), []);
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const viewDoc = async (doc) => {
    try {
      setPageLoading(true);
      if (doc.docUniqueId !== null) {
        const res = await posterGalleryService.ViewDocument(doc.docUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.docMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The poster document has not been uploaded!",
        });
      }
    }
    catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "The poster document has been deleted!",
      });
      console.error("Error fetching resume:", error);
    }
    finally {
      setPageLoading(false);
    }


  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9); // Set as per your requirement

  // Calculate the index of the first and last item to be displayed based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentImages = galleryItems.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle pagination change
  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };

  return (
    
    <Container fluid>
       {pageLoading && <Loader />}
       {
        currentImages.length > 0 ?

        <>
          <Row className='galleryWrap'>
        {currentImages.map((item, index) => (
          <Col lg={4} className='ant-image' key={index}>
            {/* <a href={require(`${process.env.REACT_APP_filepath}/${item.pdfUrl}`)} target="_blank" rel="noopener noreferrer" className="pdfItem">
              <img src={require(`${process.env.REACT_APP_filepath}/${item.imageUrl}`)} alt={item.title} />
            </a> */}
            <a href="/"  onClick={(e) => {
                                  e.preventDefault();
                                  viewDoc(item.doc);
                                }} target="_blank" rel="noopener noreferrer" className="pdfItem">
              <img src={item.ImgUrl} alt={item.title} />
            </a>
          </Col>
        ))}
      </Row>
      <Row justify="center mt-3">
                  <Col>
                  <div className='img-pagination' style={{paddingBottom:"20px"}}>
                    <Pagination
                      current={currentPage}
                      pageSize={itemsPerPage}
                      total={galleryItems.length}
                      onChange={handlePaginationChange}
                      showSizeChanger={true} 
                      pageSizeOptions={['9', '18', '36', '72']}
                    />
                    </div>
                  </Col>
                </Row>

        </> 

        :<>
            <Row>
              <Col>
              {!loadingTblData &&
                      <Empty
                        description={
                          lang === 1
                            ? <span style={{ fontWeight: 'bold' }}>वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।</span>
                            : <span style={{ fontWeight: 'bold' }}>Currently, There are no data to display.</span>
                        }
                      />}
              </Col>
            </Row>
        </>
       }
      
    </Container>
  );
};

export default PDFGallery;
