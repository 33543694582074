
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { PageContentService } from "../Services/PageContentService";
import Loader from "../global/Loader";
import Animation from "../assets/images/Animation.gif";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { FaRegFilePdf } from "react-icons/fa6";


function DownloadableList({_contentType,_className}) {
    const {  i18n } = useTranslation();
    const pageContentService = useMemo(() => new PageContentService(), []);
    const [tblData, setTblData] = useState([]);
    const [loadingTblData, setLoadingTblData] = useState(false);
  
    const [lang, setLang] = useState(
      localStorage.getItem("language") === "en" ? 2 : 1
    );
  
    useEffect(() => {
      setLang(localStorage.getItem("language") === "en" ? 2 : 1);
    }, [i18n.language]);
  
    const [pageLoading, setPageLoading] = useState(false);
  
    const viewDoc = useCallback(
      async (doc) => {
        try {
          setPageLoading(true);
          if (doc.DocUniqueId !== null) {
            const res = await pageContentService.ViewDocument(doc.DocUniqueId);
            if (res.status=== 200) {
              const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
              let dataUrl = window.URL.createObjectURL(fileBlob);
              window.open(dataUrl, "_blank");
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "The document hasn't been uploaded yet. Please try again later.",
            });
          }
        } catch (error) {
          console.error("Error fetching documents:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "An unexpected error occurred. Please try again.",
          });
        } finally {
          setPageLoading(false);
        }
      },
      [pageContentService]
    );
  
    const getPageContetsList = useCallback(
      async (contentType, language) => {
        try {
          setLoadingTblData(true);
          const res = await pageContentService.GetPageContetListPublic(
            contentType,
            language
          );
          let data = res.data.table.map((item, index) => ({
            key: index + 1,      
            contentId: item.contentId,
            title: item.title,
            doc: {
              DocUniqueId: item.uniqueId,
              DocMimeType: item.documentMimeType,
            },
          }));
  
         
            setTblData(data);
          
        } catch (error) {
          setLoadingTblData(false);
        } finally {
          setLoadingTblData(false);
        }
      },
      [pageContentService]
    );
  
    useEffect(() => {
      getPageContetsList(_contentType, lang);
    }, [lang, getPageContetsList,_contentType]);
  return (
    <>
          {pageLoading && <Loader />}
          {loadingTblData && <img src={Animation} alt="Animation gif"/>}
     <ul className={_className}>
                    {tblData.map((list, i) => (
                      <li key={i}>
                        <div className="d-table-cell">
                          <FaRegFilePdf />
                        </div>
                        <div className="d-table-cell">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default link behavior
                              viewDoc(list.doc);
                            }}
                          >
                            {list.title}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
    
    </>
  )
}

export default DownloadableList