import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
import DownloadableList from "../../../component/DownloadableList";


export default function CGResearchCentre() {
  const { t } = useTranslation();

  const CGRCdata =
    t("CGResearchCentre", {
      returnObjects: true,
    }) || {};
  const menuData =
    t("menu.activities-of-council.cg-research-centre", {
      returnObjects: true,
    }) || {};

  


  const renderSubMenu = (subItems) => {
    return (
      <ul>
        {subItems.map((subItem, index) => (
          <li key={index}>
            {subItem.title}
            {subItem.subItems && renderSubMenu(subItem.subItems)}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div>
      <BreadcrumbLayout title="Activities of the Council" />
      <Container fluid >
        <Row>
          {/* Sidebar */}
          <ATCSidebar menuData={menuData} type="CgResearchCentre" />
          {/* Content Area */}

          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{CGRCdata.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {CGRCdata.content.map((c, i) => (
                  <div key={i} className="heading-paragraph">
                    <h3>{c.heading}</h3>
                    <p className="ms-3">{c.paragraph}</p>
                    <div className="render-list">
                      <ul style={{ marginTop: "-25px" }}>
                        {c.listItems.map((l, i) => (
                          <li key={i}>{l}</li>
                        ))}
                      </ul>
                    </div>

                    {c.paragraphList.map((p, i) => (
                      <p key={i}>{p}</p>
                    ))}

                    <div className="container">
                  <div className="row justify-content-center pic-dec-wrapper">
                    {c.FacilityImgWithDesc.map((i, index) => (
                      <div key={index} className="col-lg-6 text-center mt-3">
                        <img
                          src={require(`../../../assets/images/CG-Research-Centre/${i.file}`)}
                          alt={i.file}
                          className="img-fluid"
                        />
                        <p className="text-center">{i.desc}</p>
                      </div>
                    ))}
                  </div>
                </div>
                  </div>
                ))}
               
                <div className="heading-paragraph">
                  <h3>{CGRCdata.Domains.title}</h3>
                  <p>{CGRCdata.Domains.paragraph}</p>
                </div>

                <div className="heading-paragraph">
                  <h3>{CGRCdata.activities.title}</h3>
                  <ul>
                    {CGRCdata.activities.ActivitiesList.map((l, i) => (
                      <li key={i}>{l.title}
                        {l.subItems && renderSubMenu(l.subItems)}
                      </li>
                    ))}

                  </ul>
                 

                  <div className="downloadable-list2">
                    
                    <DownloadableList _contentType = "41" _className="ms-4"/>
                  </div>
                </div>
                <div className="heading-paragraph">
                  <h3>{CGRCdata.partnership.title}</h3>
                  <p>{CGRCdata.partnership.paragraph}</p>
                  <ul>
                    {CGRCdata.partnership.partnershipList.map((l, i) => (
                      <li key={i}>{l}</li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
