import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
// import DownloadableList from '../../../component/DownloadableList';


export default function MobileScienceLab() {
  const { t } = useTranslation();
  const MobileScienceLabData = t("MobileScienceLab", { returnObjects: true, }) || {};
  const menuData =
    t("menu.activities-of-council.popularisation-of-science", { returnObjects: true, }) || {};
  return (
    <>
      <BreadcrumbLayout title="Mobile Science Lab" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{MobileScienceLabData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {MobileScienceLabData.content.map((items, i) => (
                  <HeadPara2 key={i} data={items} />
                ))}

                {/* <div className="downloadable-list2">
                  <h4>{MobileScienceLabData.Sanctionedtile}</h4>
                  <DownloadableList _contentType = "25" _className="ms-4"/>
                </div> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}