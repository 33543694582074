import React from 'react'
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Loader from "../global/Loader";

export default function LogInLayout() {
  return (
   <>
         <Suspense fallback={<Loader />} >
                <Outlet />
            </Suspense>
   </>
  )
}
