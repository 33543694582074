import CryptoJS from "crypto-js";
const encKey = "CGCOST04";

/**
   * This method is used for encrypting the given value
   * 
   * @param value 
   * @returns 
   */
export const set = (value) => {
    var key = CryptoJS.enc.Utf8.parse(encKey);
    var iv = CryptoJS.enc.Utf8.parse(encKey);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    // console.log("encrypted: ", encrypted.toString());
    let base64Enc = btoa(encrypted.toString());
    // console.log("base64Enc: ", encodeURIComponent(base64Enc));
    return encodeURIComponent(base64Enc);
}
/**
 * The get method is used for decrypting the given encrypted value.
 * 
 * @param value 
 * @returns 
 */
export const get = (value) => {
    value = decodeURIComponent(value);
    value = atob(value);
    var key = CryptoJS.enc.Utf8.parse(encKey);
    var iv = CryptoJS.enc.Utf8.parse(encKey);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    // console.log("decrypted: ", decrypted.toString(CryptoJS.enc.Utf8));
    return decrypted.toString(CryptoJS.enc.Utf8);
}