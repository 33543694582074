import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import TitleList from "../../../component/TitleList";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";

import DownloadableList from "../../../component/DownloadableList";

export default function ChhattisgarhYoungScientistCongress() {
  const { t } = useTranslation();
  const menuData = t("menu.activities-of-council.popularisation-of-science", { returnObjects: true, }) || {};
  const YoungScientistData = t("cgYoungScientistCongress", { returnObjects: true, }) || {};

  
  return (
    <>
      <BreadcrumbLayout title="Chhattisgarh Young Scientist Congress" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{YoungScientistData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div className="heading-paragraph">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: YoungScientistData.paragraph,
                    }}
                  ></p>
                </div>
                {YoungScientistData.ResearchPapersData.map((item, i) => (
                  <Col key={i} lg={12} className="mb-1 mt-1">
                    <TitleList title={item.title} list={item.list} />
                  </Col>
                ))}

               
                <div className="downloadable-list2 ms-4">
                  {/* <ul>
                    {tblDataProforma.map((list, i) => (
                      <li key={i}>
                        <div className="d-table-cell">
                          <FaLink /></div>
                        <div className="d-table-cell">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default link behavior
                              viewDoc(list.doc);
                            }}

                          >
                            {list.title}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul> */}

                  <DownloadableList _contentType = "12" _className=""/>

                </div>
                <div className="downloadable-list2">
                  <h4>{YoungScientistData.AwardeesTitle}</h4>
                  {/* <ul className=" ms-4">
                    {tblDataSanctioned.map((list, i) => (
                      <li key={i}>
                        <div className="d-table-cell">
                          <FaLink /></div>
                        <div className="d-table-cell">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default link behavior
                              viewDoc(list.doc);
                            }}

                          >
                            {list.title}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul> */}
                   <DownloadableList _contentType = "13" _className="ms-4"/>
                </div>

               
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

    </>
  );
}
