import React from 'react'

const renderList = (item) => {
    return (
      <li key={item.title}>
        {item.title}
        {item.subcategories && (
          <ul>
            {item.subcategories.map((subcategory,i) => (
              <li key={i}>{subcategory}</li>
            ))}
          </ul>
        )}
      </li>
    );
  };

export default function RenderList(props) {
  return (
    <>
    
        <div  className='render-list'>
            <h3>{props.title}</h3>
           
        <ul>{props.data.map(renderList)}</ul>
        </div>
        

        
    </>
  )
}
