
import { TbHomeUp } from "react-icons/tb";
import { FaUsersCog } from "react-icons/fa";
import { TbHomeStar } from "react-icons/tb";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { RiPresentationFill } from "react-icons/ri";
import { AiFillNotification } from "react-icons/ai";
import { TbPasswordUser } from "react-icons/tb";
import { FaFilePdf } from "react-icons/fa6";
import { GrGallery } from "react-icons/gr";
import { VscFeedback } from "react-icons/vsc";
import { LuGalleryThumbnails } from "react-icons/lu";
export const getNavItems = (name) => {
    let navItems = [];

    if (name === "Dashboard") {
        navItems = [
            {
                key: '0',
                label: "Back To Home",
                icon: <TbHomeUp />,
                to: "/"
            },
            {
                key: '22',
                label: "Manage Home",
                icon:  <TbHomeStar />,
                to: "/user/home-content-list"
            },
            {
                key: '2',
                label: "Who's Who",
                icon:  <FaUsersCog />,
                to: "/user/whoswho/officers-list"
            },
            {
                key: '5',
                label: "Manage Project",
                icon: <AiOutlineFundProjectionScreen />,
                to: "/user/projects/projects-list"
            },
            {
                key: '8',
                label: "Manage Doc",
                icon:  <FaFilePdf />,
                to: "/user/manage/content-list"
            },
            {
                key: '11',
                label: "Manage Presentation",
                icon:  <RiPresentationFill />,
                to: "/user/presentation/presentation-list"
            },
            {
                key: '17',
                label: "Manage Notice",
                icon:  <AiFillNotification />,
                to: "/user/notice/notice-list"
            },
            {
                key: '15',
                label: "Manage Photos",
                icon: <GrGallery />,
                to: "/user/image-gallery-list"
            },
            {
                key: '21',
                label: "Manage Poster",
                icon:  <LuGalleryThumbnails />,
                to: "/user/poster-gallery-list"
            },
            {
                key: '25',
                label: "Visitor Feedback",
                icon:   <VscFeedback />,
                to: "/user/visitor-feedback"
            },
            {
                key: '23',
                label: "Change Password",
                icon:   <TbPasswordUser />,
                to: "/user/change-password"
            },
           
        ]
    }
    
    return navItems;
}
