import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import HeadPara2 from "../../../../component/HeadPara2";
import TitleList from "../../../../component/TitleList";
import ATCSidebar from "../../ATCSidebar";
import RenderList from "../../../../component/RenderList";
import Swal from "sweetalert2";
import { FaLink } from "react-icons/fa6";

export default function PatentInformationCenter() {
  const { t, i18n } = useTranslation();
  const PICData = t("PatentInformationCenter", {
    returnObjects: true,
  }) || {};
  const menuData =
    t("menu.activities-of-council.intellectual_property_rights_centre", {
      returnObjects: true,
    }) || {};
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);

  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const handleClick = (url) => {
    Swal.fire({
      html: lang === 2 ? `
                This link shall take you to a page/ website outside this website. `
        : ` यह लिंक आपको इस वेबसाइट के बाहर एक पेज/वेबसाइट पर ले जाएगा।`,
      icon: "warning",
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: lang === 2 ? "OK" : "",
      denyButtonText: lang === 2 ? "Cancel" : ""
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(url);
      }
    });
  };

  return (
    <>
      <BreadcrumbLayout title="" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="IntellectualPropertyRightsCentre" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{PICData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {PICData.content.map((items, index) => (
                  <HeadPara2 key={index} data={items} />
                ))}

                <TitleList
                  title={PICData.listTitle.title}
                  list={PICData.listTitle.list}
                />
                <RenderList data={PICData.list2} title={PICData.listTitle2} />
                <div className="heading-paragraph">
                  {PICData.down_list.map((i, index) => (
                    <p key={index} className="ms-5 mb-3">
                      {/* Add your link or other content here */}
                    </p>
                  ))}
                </div>

                {PICData.content2.map((items, index) => (
                  <HeadPara2 key={index} data={items} />
                ))}

                <div className="downloadable-list2">
                  <h4>{PICData.IPenablementSite.title}</h4>
                  <ul className="ms-4">
                    {PICData.IPenablementSite.list.map((list, i) => (
                      <li key={i}>
                        <div className="d-table-cell">
                          <FaLink />
                        </div>
                        <div className="d-table-cell">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default link behavior
                              handleClick(list.to);
                            }}
                          >
                            {list.linkName}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
