import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BreadcrumbLayout({ title }) {
    const { t } = useTranslation();
    const Data = t("breadcrumb", {
        returnObjects: true,
    });
    var urlArray = useLocation().pathname.split('/');

    const pathMaker = (index) => {
        let path = '';
        for (let i = 1; i <= index; i++) {
            path = path + '/' + urlArray[i];
        }
        return path;
    };

    return (
        // <>
        //     <Container className="header-Breadcrumb">
        //         <Row>
        //             <Col>
        //                 <ul>
        //                     <li>
        //                         <Link to="/">{Data.home}</Link>
        //                     </li>
        //                     {urlArray.map((elm, i) => {
        //                         if (i > 0) {
        //                             return (
        //                                 <li key={i}>
        //                                     <Link to={pathMaker(i)}>
        //                                         {t(Data[elm])}
        //                                     </Link>
        //                                     <span></span>
        //                                 </li>
        //                             );
        //                         }
        //                         return null;
        //                     })}
        //                 </ul>
        //             </Col>
        //         </Row>
        //     </Container>
        // </>
        <>
        {/* style={{ backgroundColor: '#f8f9fa', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} */}
  <Container fluid className="header-Breadcrumb py-2" style={{ borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
    <Row>
      <Col>
        <ul className="breadcrumb d-flex align-items-center mb-0" style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
          <li style={{ marginRight: '8px' }}>
            <Link to="/" style={{ textDecoration: 'none', color: '#007bff' }}>
              {Data.home}
            </Link>
          </li>
          {urlArray.map((elm, i) => {
            if (i > 0) {
              return (
                <li key={i} className="d-flex align-items-center" style={{ marginRight: '8px' }}>
                  <span style={{ margin: '0 8px', color: '#6c757d' }}>&gt;</span>
                  <Link to={pathMaker(i)} style={{ textDecoration: 'none', color: i === urlArray.length - 1 ? '#495057' : '#007bff' }}>
                    {t(Data[elm])}
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </Col>
    </Row>
  </Container>
</>

    );
}

export default BreadcrumbLayout;

