import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

// import { useTranslation } from "react-i18next";
// import logoEn from "../../assets/images/logo 3.png";
// import logoHi from "../../assets/images/logo 3.png";

import FullSizeLogo from "../../assets/images/FullSizeLogo.png";


const Header = () => {
  // const {  i18n } = useTranslation();
  
   return (
    <>
    <div className="logo-bg">
      <Container fluid="xxl">
        <Row>
        {/* <Col className="mx-auto p-1">
        <img
              src={logoLight}
              className="d-inline img-fluid"
              alt="Site Logo"
              id="main-logo"
            />
        </Col> */}
          <Col className="mx-auto p-1 kishan">
         
{/* {i18n.language === "en" && ( */}
                     <a href="/"
                     target="_self" rel="noopener noreferrer">
                    <img
                      src={FullSizeLogo}
                      className="d-inline img-fluid"
                      alt="Site Logo"
                      id="main-logo"
                    />
                      </a>
                  {/* )} */}
                  {/* {i18n.language === "hi" && (
                     <a href="/"
                     target="_self" rel="noopener noreferrer">
                    <img
                      src={logo3}
                      className="d-inline img-fluid"
                      alt="Site Logo"
                      id="main-logo"
                    />
                      </a>
                  )} */}
                  
                  
                 
          </Col>
        </Row>
      </Container>
      </div>
      <header>
      <Container fluid="xxl">
        <Row>
          <Col md={12} lg={12} className="mx-auto nav-area">
            <div className="logo d-lg-none"></div>
            {/* for large screens */}
            <Navbar />
            {/* for small screens */}
            <MobileNav />
          </Col>
        </Row>
        </Container>
      </header>
    </>
  );
};

export default Header;


// import React, { useState, useEffect } from "react";
// import MobileNav from "./MobileNav";
// import Navbar from "./Navbar";
// import { Row, Col, Container } from "react-bootstrap";
// import logoLight from "../../assets/images/New Logo.png";
// import logoDark from "../../assets/images/New Logo (1).png";

// const Header = () => {
//   const [isDarkMode, setIsDarkMode] = useState(false);

//   useEffect(() => {
//     const savedTheme = localStorage.getItem('theme');
//     if (savedTheme) {
//       setIsDarkMode(savedTheme === 'dark');
//       document.body.classList.toggle('dark-mode', savedTheme === 'dark');
//     }
//   }, []);

//    return (
//     <header>
//       <Container fluid>
//         <Row>
//           <Col className="text-center mx-auto p-2">
//             <img
//               src={isDarkMode ? logoDark : logoLight}
//               className="d-inline img-fluid"
//               alt="Site Logo"
//               id="main-logo"
//             />
//           </Col>
//         </Row>
//         <Row>
//           <Col md={12} lg={12} className="mx-auto nav-area">
//             <div className="logo d-lg-none"></div>

//             {/* for large screens */}
//             <Navbar />

//             {/* for small screens */}
//             <MobileNav />
//           </Col>
//         </Row>
//       </Container>
//     </header>
//   );
// };

// export default Header;

