import React, { useState } from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import { Tabs } from 'antd';
import { FaUserCog } from "react-icons/fa";
import { IoImagesSharp } from "react-icons/io5";
import HomeSliderImageList from './HomeSliderImageList';
import MinisterList from './MinisterList';

const HomeContentContainer = () => {

  const [defaultActiveKey, setDefaultActiveKey] = useState(localStorage.getItem('homeActiveTabKey') !== null ? localStorage.getItem('homeActiveTabKey') : 1);


  const items1 = [
    {
      key: '1',
      label: 'Minister/Officer',
      icon: <FaUserCog />,
      children: <>
        <MinisterList />
      </>,
    },
    {
      key: '2',
      label: 'Home Slider Image',
      icon: <IoImagesSharp />,
      children: <>
        <HomeSliderImageList />
      </>,
    }
  ];

  const onChange = (key) => {
    localStorage.setItem('homeActiveTabKey', key);
    setDefaultActiveKey(key);
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Manage Home</h3>
              </Card.Header>
              <Card.Body>





                <div className="custum-antd-tab">
                  <Tabs defaultActiveKey={defaultActiveKey} type="card" items={items1} onChange={onChange} />
                </div>

              </Card.Body>
            </Card>


          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeContentContainer;

