import axiosInstance from "../global/AxiosInterceptor";

export class FeedBackService {
   

    FeedBackInsert = async (payload) => {
        return await axiosInstance.post('/FeedBack/visitorfeedbackinsert', payload)
    }

    GetFeedBackList = async () => {
        return await axiosInstance.get(`/FeedBack/getfeedbacklist`);
    }
}