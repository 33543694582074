export const fileConverter = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const base64Data = event.target.result; // The file data as Base64 encoded string

      // Split the Base64 string to remove the "base64," prefix
      const base64WithoutPrefix = base64Data.split('base64,')[1];
      resolve(base64WithoutPrefix);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const readFileDataAsBase64 = async (files) => {
  const file = files;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64Data = event.target.result; // The file data as Base64 encoded string

      // Split the Base64 string to remove the "base64," prefix
      const base64WithoutPrefix = base64Data.split("base64,")[1];

      resolve(base64WithoutPrefix);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
};
