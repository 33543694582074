import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "./ATCSidebar";

import DownloadableList from "../../component/DownloadableList";

export default function ActivitiesCoordinatorCell() {
  const { t } = useTranslation();
  const ActivitiesCoordinatorCellData = t("ActivitiesCoordinatorCell", {
    returnObjects: true,
  });
  const menuData =
    t("menu.activities-of-council", {
      returnObjects: true,
    }) || {};


  return (
    <>

      <BreadcrumbLayout title="Activities Coordinator Cell" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{ActivitiesCoordinatorCellData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div>
                  {ActivitiesCoordinatorCellData.content.map((d, index) => (
                    <div key={index} className="heading-paragraph">
                      <h3>{d.heading}</h3>
                      {d.paragraph.map((items, i) => (
                        <p key={i} dangerouslySetInnerHTML={{ __html: items }}></p>
                      ))}

                    </div>

                  ))}
                </div>



                <div className="downloadable-list2">
                  <DownloadableList _contentType="42" _className="" />
                </div>

              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

    </>
  )
}
