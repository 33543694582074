
import { Col, Container, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import Loader from "../../global/Loader";
import { AuthenticationService } from "../../Services/AuthService";
import { IoPersonAddSharp } from "react-icons/io5";

import { Form, Select, Table, Space, Tag, Button, Modal, Input } from "antd";
import { CommonServices } from "../../Services/CommonService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PageContentService } from "../../Services/PageContentService";


const ContentList = () => {
  const authService = new AuthenticationService();
  const commonService = useMemo(() => new CommonServices(), []);
  const pageContentService = useMemo(() => new PageContentService(), []);

  const currentUser = authService.getUserData?.role;
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [commonListData, setCommonListData] = useState();
  const [loadingFilterList, setLoadingFilterList] = useState(false);
  const [actionStatusData, setActionStatusData] = useState();

  const [modal1Open, setModal1Open] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [contentId, setProjectId] = useState();
  const [modalStatus, setModalStatus] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [formShowHide, setFormShowHide] = useState(false);
  // const [tblContentListById, setTblContentListById] = useState(null);

  const [contentTypeList, setContentTypeList] = useState([]);
  const [loadingNoticeTypes, setLoadingNoticeTypes] = useState(true);
  const [pageLoad, setPageLoad] = useState(false);
  const [initialValues, setInitialValues] = useState({ filterStatusType: "-1", contentType: "-1" })

  const navigate = useNavigate();

  const approveRejectConfirmModal = (type, value, contentId) => {
    setModal1Open(true);
    if (type === "ViewContentDetails") {
      setModalTitle("Content Details");
      // setTblContentListById([value])
      setFormShowHide(false);
    } else if (type === "approveRejectModal") {
      setModalTitle("Verify");
      setFormShowHide(true);
      form.resetFields();
      setProjectId(contentId);
      const filterData = commonListData?.filter(item => {
        return item.value === value;
      });
      setModalStatus(filterData);
      form.setFieldValue('verificationStatus', value);
    }
  }

  const handleEdit = (data) => {
    navigate("/user/manage/content-entry", { state: { data: data, EditFlag: true } });
  }

  const modalClose = () => {

    setModal1Open(false);
  };

  const [loadingModalSubmit, setLoadingModalSubmit] = useState(false);

  const modalSubmit = async () => {
    try {
      setLoadingModalSubmit(true);
      let payload = {
        contentId: contentId,
        verificationStatus: form.getFieldValue('verificationStatus'),
        verificationRemark: form.getFieldValue('verificationRemark')
      }

      const res = await pageContentService.VerifyPageContent(payload);

      if (res.status) {
        Swal.fire({
          html: res.data.message,
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An unexpected error occurred. Please try again.",
        });
      }
      modalClose();

    } catch (error) {
      console.error(error);
    }
    finally {
      setLoadingModalSubmit(false);
    }
  }
  const viewDoc = async (doc) => {
    try {
      setPageLoad(true);
      if (doc.DocUniqueId !== null) {
        const res = await pageContentService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The document hasn't been uploaded yet. Please try again later.",
        });
      }
    }
    catch (error) {
      console.error("Error fetching documents:", error);
    }
    finally {
      setPageLoad(false);
    }

  };


  const columns = [

    {
      title: '#',
      render: (record) => record.key,
      width: '5%'
    },



    {
      title: 'Title(English/Hindi)/Doc Type',
      render: (row) => {
        return (
          <>

            <div className="downloadPdf">


              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault(); // Prevents the default link behavior
                  viewDoc(row.doc);
                }}
                className="ant-btn ant-btn-link"
              >
                {row.titleEnglish}
              </a>
              <br />
              <br />

              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault(); // Prevents the default link behavior
                  viewDoc(row.doc);
                }}
                className="ant-btn ant-btn-link"
              >
                {row.titleHindi}
              </a>

            </div>

            <br />
            <p><span className="fw-bold">Doc Type: </span><span style={{ color: "#DC143C", fontSize: "1.1rem" }}>{row.contentTypeName}</span></p>

          </>
        )
      },
      width: '40%'
    },




    {
      title: 'Details',
      width: '40%',
      align: 'left',
      render: (row) => {
        return (
          <>

            <div style={{ display: 'grid', gridTemplateColumns: '120px auto' }}>
              <p>
                <span className="fw-bold">S#:</span>
              </p>
              <p>{row.contentId}</p>

              <p>
                <span className="fw-bold">Is Visible:</span>
              </p>
              <p>
                <Tag className="ms-2" color={row.isActive === 0 ? 'error' : (row.isActive === 1 ? 'success' : '')}>
                  {row.isActiveName}
                </Tag>
              </p>

              <p>
                <span className="fw-bold">Verify Status:</span>
              </p>
              <p>
                <Tag className="ms-2"
                  color={
                    row.verificationStatus === 0
                      ? "processing"
                      : row.verificationStatus === 1
                        ? "success"
                        : row.verificationStatus === 3
                          ? "error"
                          : ""
                  }
                >
                  {row.statusName}
                </Tag>
              </p>

              <p>
                <span className="fw-bold">Submit Date:</span>
              </p>
              <p>{row.submitDate}</p>

              {row.verificationStatus !== 0 && (
                <>
                  <p>
                    <span className="fw-bold">Verify Date:</span>
                  </p>
                  <p>{row.publishDate}</p>

                  <p>
                    <span className="fw-bold">Verify Remark:</span>
                  </p>
                  <p>{row.verificationremark}</p>
                </>
              )}

              {row.verificationStatus === 0 && row.actionId > 1 ? (
                <>
                  <p>
                    <span className="fw-bold">Edited Remark:</span>
                  </p>
                  <p>{row.verificationremark}</p>
                </>
              ):(
                <>
                  <p>
                    <span className="fw-bold">Remark:</span>
                  </p>
                  <p>{row.verificationremark}</p>
                </>
              )}
            </div>


          </>
        )
      }
    },
    currentUser === "2" ?
      {
        title: 'Action',
        width: '15%',
        align: 'center',
        render: (data) => (
          <>
            <Space direction="vertical" align="center">
              <Button onClick={() => approveRejectConfirmModal("ViewContentDetails", data, "")}
                className='btn btn-sm btn-outline-primary d-none'>View</Button>

              {data.verificationStatus === 0 && actionStatusData?.map((elm, id) => (
                <Button key={id} onClick={() => approveRejectConfirmModal("approveRejectModal", elm.value, data?.contentId)} className={'btn btn-sm ' + (elm.value === '3' ? 'btn-outline-danger' : (elm.value === '1' ? 'btn-outline-success' : ''))}>
                  {elm.label}
                </Button>
              ))}

            </Space>
          </>
        ),
      } :
      {
        title: 'Action',
        align: 'center',
        width: '15%',
        render: (data) => (
          <>
            <Space>
              <Button className="btn btn-sm btn-outline-primary"
                onClick={() => handleEdit(data)}>Edit</Button>
              <Button onClick={() => approveRejectConfirmModal("ViewContentDetails", data, "")}
                className='btn btn-sm btn-outline-primary d-none'>View</Button>
            </Space>
          </>
        ),
      }
  ];

  // const officerColumnsById = [

  //   {
  //     title: 'English',
  //     render: (row) => {
  //       return (
  //         <>

  //           <a
  //             href="/"
  //             onClick={(e) => {
  //               e.preventDefault(); // Prevents the default link behavior
  //               viewDoc(row.doc);
  //             }}
  //             className="ant-btn ant-btn-link"
  //           >
  //             {row.titleEnglish}
  //           </a>

  //         </>
  //       )
  //     },
  //   },

  //   {
  //     title: 'Hindi',
  //     render: (row) => {
  //       return (
  //         <>
  //           <a
  //             href="/"
  //             onClick={(e) => {
  //               e.preventDefault(); // Prevents the default link behavior
  //               viewDoc(row.doc);
  //             }}
  //             className="ant-btn ant-btn-link"
  //           >
  //             {row.titleHindi}
  //           </a>

  //         </>
  //       )
  //     },

  //   },

  //   {
  //     title: 'Status/IsActive',
  //     align: 'center',
  //     width: '10%',
  //     render: (row) => {
  //       return (
  //         <>
  //           <Space className="ms-2">
  //             <Tag color={row.verificationStatus === 0 ? 'processing' : (row.verificationStatus === 1 ? 'success' : (row.verificationStatus === 3 ? 'error' : (row.verificationStatus === 5 ? 'warning' : '')))}>
  //               {row.statusName}
  //             </Tag>
  //             <Tag color={row.isActive === 0 ? 'error' : (row.isActive === 1 ? 'success' : '')}>
  //               {row.isActiveName}
  //             </Tag>
  //           </Space>
  //         </>
  //       )
  //     }
  //   },
  // ];

  useEffect(() => {
    const getContentTypes = async (type) => {
      try {
        setLoadingNoticeTypes(true);
        const res = await pageContentService.getCommonList(type);
        setContentTypeList(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < res.data.length; i++) {
            data.push(
              {
                value: res.data[i].value,
                name: res.data[i].name,
                label: res.data[i].label,
                nameHindi: res.data[i].nameHindi,
                type: res.data[i].type,
                extraField1: res.data[i].extraField1
              }
            )
          }
          return data;
        })
      }
      catch (error) {
        setLoadingNoticeTypes(false);
      }
      finally {
        setLoadingNoticeTypes(false);
      }
    }
    getContentTypes("PageContentType");

  }, [pageContentService]);

  useEffect(() => {

    const getFilterList = async () => {
      try {
        setLoadingFilterList(true);
        let res = await commonService.getActionCommonStatus();
        const filterData = res.data?.filter(item => {
          return item.value === "0" || item.value === "1" || item.value === "3";
        })

        setCommonListData(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < filterData.length; i++) {
            data.push(
              {
                value: filterData[i].value,
                name: filterData[i].name,
                label: filterData[i].label,
                nameHindi: filterData[i].nameHindi,
                type: filterData[i].type,
                extraField1: filterData[i].extraField1
              }
            )
          }
          return data;
        });
      } catch (error) {
        setLoadingFilterList(false);
      }
      finally {
        setLoadingFilterList(false);
      }
    }

    const getActionStatus = async () => {
      try {

        let res = await commonService.getActionStatus();
        setActionStatusData(res.data);

      } catch (error) {

        console.log("Error at getActionStatus: ", error)
      }

    }
    getFilterList();
    getActionStatus();

  }, [pageContentService, commonService]);

  useEffect(() => {
    const getPageContentList = async (contentTypeId, stausCode) => {
      try {
        setLoadingTblData(true);
        let payload = {
          isActive: -1,
          verificationStatus: stausCode,
          contentId: -1,
          contentTypeId: contentTypeId,

        }
        const res = await pageContentService.GetPageContentsList(payload);



        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            contentTypeName: item.contentTypeName,
            contentTypeId: item.contentTypeId,
            contentId: item.contentId,
            titleEnglish: item.titleEnglish,
            titleHindi: item.titleHindi,
            verificationStatus: item.verificationStatus,
            statusName: item.statusName,
            isActive: item.isActive,
            actionId: item.actionId,
            isActiveName: item.activeName,
            verificationremark: item.verificationRemark,
            publishDate: item.publishDate,
            submitDate: item.submitDate,
            doc: {
              DocUniqueId: item.uniqueId,
              DocMimeType: item.documentMimeType,

            },




          }));
          return data;
        });

      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getPageContentList(form2.getFieldValue("contentType"), form2.getFieldValue("filterStatusType"));
  }, [loadingModalSubmit, initialValues, form2, pageContentService])


  const loadTableFilter = (contentTypeId, stausCode) => {
    setInitialValues({ filterStatusType: stausCode, contentType: contentTypeId })
  }




  return (
    <>
      {pageLoad && <Loader />}
      {loadingTblData && <Loader />}
      <Container>
        <Row>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Content/Page Doc List</h3>
              </Card.Header>
              <Card.Body>



                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg={2} md={3} sm={12}>
                        {currentUser === "3" && (
                          <Link className="btn btn-outline-primary mt-1" to="/user/manage/content-entry">
                            <IoPersonAddSharp /> Add New
                          </Link>
                        )}
                      </Col>
                      <Col lg={10} md={9} className="float-end">
                        <Form form={form2} initialValues={initialValues}>
                          <Row>
                            <Col md={12} lg={8}>
                              <Form.Item name="contentType" label="Select Doc Type" rules={[
                                { required: true, message: 'Category Required' },
                              ]}>
                                <Select showSearch placeholder="Select Content Type" optionFilterProp="children" // onSearch={onSearch}
                                  options={contentTypeList} loading={loadingNoticeTypes} onChange={(value) => loadTableFilter(value, form2.getFieldValue("filterStatusType"))}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12} lg={4}>
                              <Form.Item name="filterStatusType" label="Filter">
                                <Select
                                  showSearch
                                  placeholder="Select Filter Type"
                                  optionFilterProp="children"
                                  onChange={(value) => loadTableFilter(form2.getFieldValue("contentType"), value)}
                                  options={commonListData}
                                  loading={loadingFilterList}
                                  filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>

                      </Col>
                    </Row>

                    <div className="tableList">
                      <Table
                        bordered
                        columns={columns}
                        dataSource={tblData}
                        loading={loadingTblData}
                        scroll={{ x: 800, y: 500 }}
                        size="small"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>

            <Modal
              title={modalTitle}
              style={{ top: 20 }}
              open={modal1Open}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => setModal1Open(false)}
              width={formShowHide === false ? 900 : 450}
            >
              {formShowHide && (
                <Form
                  layout="vertical"
                  form={form}
                  name="boilerRegForm"
                  onFinish={modalSubmit}
                  scrollToFirstError
                >
                  <Card className="">
                    <Card.Body>
                      <Col md={12}>
                        <Form.Item name="verificationStatus" label="Status">
                          <Select showSearch placeholder="Select Status"
                            optionFilterProp="children"
                            options={modalStatus} loading={loadingFilterList}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12}>
                        <Form.Item label="Remarks" name="verificationRemark" rules={[
                          { required: true, message: 'Remarks Required' },
                        ]}>
                          <Input.TextArea showCount rows={3} maxLength={300} />
                        </Form.Item>
                      </Col>

                      <Col md={12} className="text-center">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary me-2"
                          disabled={loadingModalSubmit}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          disabled={loadingModalSubmit}
                          onClick={modalClose}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Card.Body>
                  </Card>
                </Form>
              )}


            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContentList;


