import React, { useEffect, useState } from "react";
import { AuthenticationService } from "../../Services/AuthService";
import Swal from "sweetalert2";
import { Button, Form, Input, Divider } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import Captcha from "../../component/Captcha";
import { FeedBackService } from "../../Services/FeedBackService";


const authService = new AuthenticationService();

function Feedback() {

  const feedBackService = new FeedBackService();
  const { t,i18n } = useTranslation();
  const Data = t("Feedback", { returnObjects: true, });
  useEffect(() => {  getUpdateCaptcha(); }, []);

  const [form] = Form.useForm();
  const [captchaObj, setCaptchaObj] = useState();
  const [loadingCaptcha, setLoadingCaptcha] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [loadingFormSubmit, setLoadingFormSubmit] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);
  
  const values = Form.useWatch([], form);
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormDisabled(true);
      },
      () => {
        setFormDisabled(false);
      }
    );
  }, [values, form]);
  const getUpdateCaptcha = async () => {
    try {
      setLoadingCaptcha(true);
      let captchaObjData = await authService.getCaptcha();
      setCaptchaObj(captchaObjData.data);
    } catch (error) {
      
       console.log(error);
      setLoadingCaptcha(false);
    } finally {
      setLoadingCaptcha(false);
    }
   
  };
  const FeedBackSubmit = async () => {
    try {
      setLoadingFormSubmit(true);
      const payload = {
        name: form.getFieldValue("name"),
        email: form.getFieldValue("email"),
        message: form.getFieldValue("feedback"),
        captchaId: captchaObj?.captchaID,
        userEnteredCaptcha: form.getFieldValue("captcha"),
      };
      const res =await feedBackService.FeedBackInsert(payload);
      if (res.data.status) {
        Swal.fire({
          html: lang===1?"फीबैक के लिए धन्यवाद ! आपकी फीडबैक सफलतापूर्वक सेव कर लिया गया है।":"Thanks for the feedback! Your feedback has been successfully saved.!!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        form.resetFields();
      }
      else {
        if(res.data.message===  "Invalid Captcha"){
          Swal.fire({
            html: lang===1?" कैप्चा गलत है, कृपया पुन: प्रयास करें!!":"Invalid Captcha, Please try again!!",
            icon: "error"
          });
        }else{
          Swal.fire({
            html: lang===1?"कृपया पुन: प्रयास करें!!":"Please try again!!",
            icon: "error"
          });
         
        }
       
      }
      
    } catch (error) {
      console.error("Error submitting Feedback:", error);
    } finally {
      setLoadingFormSubmit(false);
      getUpdateCaptcha();
    }
  };
  return (
    <>
      <BreadcrumbLayout title="Feedback" />
      <Container fluid >
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <p>
                  {Data.paragraph[0]}
                </p>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: Data.paragraph[1] }}></p>
                </div>

                <Form
                  layout="vertical"
                  form={form}
                  name="feedBackForm"
                  onFinish={FeedBackSubmit}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={6}>
                      <Form.Item
                        name="name"
                        label="Name:"
                        rules={[
                          { required: true, message: "Required!" },
                          {
                            type: "string",
                            max: 150,
                            message: "Max length exceeded"
                          }
                        ]}
                      >
                        <Input placeholder="Enter Your Name" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}  >
                      <Form.Item
                        name="email"
                        label="Email:"
                        rules={[
                          { required: true, message: "Required!" },
                          { type: 'email', message: 'Please enter a valid email address' },
                          {
                            type: "string",
                            max: 150,
                            message: "Max length exceeded"
                          }
                        ]}
                      >
                        <Input placeholder="Enter Your Email Id." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Item name="feedback" label="Feedback:" rules={[
                        { required: true, message: 'Required!' },
                        {
                          type: "string",
                          max: 1000,
                          message: "Max length exceeded",
                        },
                      ]}>
                        <Input.TextArea showCount maxLength={1000} placeholder="Enter Your Feedback." autoSize={{ minRows: 3, maxRows: 5 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="feedback-captcha">
                        <Captcha
                          imgData={
                            captchaObj?.captchaData ? captchaObj?.captchaData : ""
                          }
                          refresh={getUpdateCaptcha}
                          minLength={6}
                          maxLength={6}
                          isLoading={loadingCaptcha}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the captcha shown in the image",
                            }
                          ]}
                        />
                      </div>
                    </Col>




                  </Row>
                  <div className="text-center mt-2">
                    <Divider />
                    <Button
                      type="primary"
                      htmlType="submit"
                    disabled={!formDisabled}
                    loading={loadingFormSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Feedback;
