import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const PhotoGallerySidebar = ({ menuData }) => {
  const { t } = useTranslation();
  return (
    <Col
      md={3}
      className="sidebar order-last order-md-first p-3"
      style={{
        borderRight: '1px solid #dee2e6',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Row>
        {/* <Col className="menu-list">
          <ul className="list-unstyled">
            {[
               
             
              
           
              { path: "/media-gallery/photo-gallery", label: menuData["photo-gallery"] },
              { path: "/media-gallery/poster-gallery", label:  menuData["poster-gallery"]},
              { path: "/media-gallery/video-gallery", label: menuData["video-gallery"] },
              { path: "/media-gallery/press-gallery", label: menuData["press-gallery"] },
              { path: "/media-gallery/presentation-gallery",label: menuData["presentation-gallery"] },
             

              
              



            ].map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                className="d-block py-2 px-3 mb-1"
                style={{
                  color: '#495057',
                  borderRadius: '4px',
                  textDecoration: 'none',
                  transition: 'background-color 0.2s',
                }}
                activeStyle={{ backgroundColor: '#007bff', color: 'white' }}
              >
                <li>{item.label}</li>
              </NavLink>
            ))}
          </ul>
        </Col> */}
        <Col sm={12} className='list-container'>

          <ul className="list-unstyled">
            <li>
              <div className="QL">{t("menu.media-gallery.title")}</div></li>
            {[
              { path: "/media-gallery/photo-gallery", label: menuData["photo-gallery"] },
              { path: "/media-gallery/poster-gallery", label: menuData["poster-gallery"] },
              { path: "/media-gallery/video-gallery", label: menuData["video-gallery"] },
              { path: "/media-gallery/press-gallery", label: menuData["press-gallery"] },
              { path: "/media-gallery/presentation-gallery", label: menuData["presentation-gallery"] },
            ].map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                className=""
                style={{
                  color: '#495057',
                  borderRadius: '4px',
                  textDecoration: 'none',
                  transition: 'background-color 0.2s',
                }}
                activeStyle={{ backgroundColor: '#007bff', color: 'white' }}
              >
                <li><div className="list-item">{item.label}</div></li>
              </NavLink>
            ))}
          </ul>
        </Col>
      </Row>
    </Col>
  );
};

export default PhotoGallerySidebar;
