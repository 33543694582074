
import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "./ATCSidebar";

import DownloadableList from "../../component/DownloadableList";

export default function DocumentationAndCommunicationCentre() {
  const { t } = useTranslation();
  const DocumentationAndCommunicationCentre = t("DocumentationAndCommunicationCentre", { returnObjects: true,  });
  const menuData = t("menu.activities-of-council", { returnObjects: true,  }) || {};

  return (
    <>
      <BreadcrumbLayout title="Documentation & Communication Centre" />
      <Container fluid>
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{DocumentationAndCommunicationCentre.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div className='heading-paragraph'>
                  <p>
                    {DocumentationAndCommunicationCentre.paragraph}
                  </p>
                </div>
                <div className="table-responsive">
                  <table className="table border">
                    <thead>
                      <tr>
                        <th scope="col">{DocumentationAndCommunicationCentre.srn}</th>
                        <th scope="col">{DocumentationAndCommunicationCentre.sub}</th>
                        <th scope="Numbers">{DocumentationAndCommunicationCentre.qnt}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DocumentationAndCommunicationCentre.tabData.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{item.sno}</th>
                          <td>{item.subjects}</td>
                          <td>{item.numbers}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
               
                 
                    <div className="downloadable-list2 ms-4">
                    <DownloadableList _contentType = "30" _className=""/>
                </div>
                    
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}