import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import HeadPara2 from "../../component/HeadPara2";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
function AccessibilityStatement() {
  const { t } = useTranslation();
  const AccessibilityStatementData = t("AccessibilityStatement", { returnObjects: true });
  return (
    <>
      <BreadcrumbLayout title="Accessibility Statement" />
      <Container fluid >
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{AccessibilityStatementData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {AccessibilityStatementData.data.map((items,i) => (
                  <HeadPara2 key={i} data={items} />
                ))}
                <ul className="ms-5">
                  <li>
                    <NavLink to="/accessibility-statement/accessibility-options">{AccessibilityStatementData.link}</NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AccessibilityStatement;
