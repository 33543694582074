import { NavLink } from "react-router-dom";
import { TbHomeUp } from "react-icons/tb";
import { MdDashboard } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { TbHomeStar } from "react-icons/tb";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { RiPresentationFill } from "react-icons/ri";
import { AiFillNotification } from "react-icons/ai";
import { TbPasswordUser } from "react-icons/tb";
import { FaFilePdf } from "react-icons/fa6";
import { GrGallery } from "react-icons/gr";
import { LuGalleryThumbnails } from "react-icons/lu";
import { AuthenticationService } from "../Services/AuthService";
import { VscFeedback } from "react-icons/vsc";
export const getNavItems = () => {
    let navItems = [];
    const authService = new AuthenticationService();
    const setKey = (key) => {
        sessionStorage.setItem('selectedNavKey', key);
    }
     

    if (authService.getUserData?.role === "3" || authService.getUserData?.role === "2") {
        navItems = [
            {
                key: '0',
                label: <NavLink to={'/'} className={'text-decoration-none'}>Back To Home</NavLink>,
                icon: <TbHomeUp />,
                onClick: () => {
                    setKey('0');
                }
            },
            {
                key: '1',
                label: <NavLink to={'/user/home'} className={'text-decoration-none'}>Dashboard</NavLink>,
                icon:<MdDashboard />,
                onClick: () => {
                    setKey('1');
                }
            },
            {
                key: '22',
                label: <NavLink to={'/user/home-content-list'} className={'text-decoration-none'}>Manage Home </NavLink>,
                icon: <TbHomeStar />,
                onClick: () => {
                    setKey('22');
                }
            },
            {
                key: '2',
                label:  <NavLink to={'/user/whoswho/officers-list'} className={'text-decoration-none'}>Who's Who</NavLink>,
                icon:<FaUsersCog />,
                onClick: () => {
                    setKey('2');
                }
               
            },

            {
                key: '5',
                label: <NavLink to={'/user/projects/projects-list'} className={'text-decoration-none'}>Manage Project</NavLink>,
                icon: <AiOutlineFundProjectionScreen />,
                onClick: () => {
                    setKey('5');
                }
                                
            },

            {
                key: '8',
                label: <NavLink to={'/user/manage/content-list'} className={'text-decoration-none'}>Manage Doc</NavLink>,
                icon: <FaFilePdf />,
                onClick: () => {
                    setKey('8');
                }
            },

            {
                key: '11',
                label: <NavLink to={'/user/presentation/presentation-list'} className={'text-decoration-none'}>Manage Presentation</NavLink>,
                icon: <RiPresentationFill />,   
                onClick: () => {
                    setKey('11');
                }            
            },

            
           
            {
                key: '17',
                label: <NavLink to={'/user/notice/notice-list'} className={'text-decoration-none'}>Manage Notice </NavLink>,
                icon: <AiFillNotification />,
                onClick: () => {
                    setKey('17');
                }
            },

            {
                key: '15',
                label: <NavLink to={'/user/image-gallery-list'} className={'text-decoration-none'}>Manage Photos </NavLink>,
                icon: <GrGallery />,
                onClick: () => {
                    setKey('15');
                }
            },

            {
                key: '21',
                label: <NavLink to={'/user/poster-gallery-list'} className={'text-decoration-none'}>Manage Poster </NavLink>,
                icon: <LuGalleryThumbnails />,
                onClick: () => {
                    setKey('21');
                }
            },
            {
                key: '25',
                label: <NavLink to={'/user/visitor-feedback'} className={'text-decoration-none'}>Visitor Feedback </NavLink>,
                icon: <VscFeedback />,
                onClick: () => {
                    setKey('25');
                }
            }  ,

            {
                key: '23',
                label: <NavLink to={'/user/change-password'} className={'text-decoration-none'}>Change Password </NavLink>,
                icon: <TbPasswordUser />,
                onClick: () => {
                    setKey('23');
                }
            }          

            
        ]
    }

    return navItems;
}




















// import { NavLink } from "react-router-dom";
// import { TbHomeUp } from "react-icons/tb";
// import { GrDocumentUpdate } from "react-icons/gr";
// // import { LuLayoutDashboard } from "react-icons/lu";
// // import { FaFileCircleExclamation, FaBars, FaBarsProgress, FaListCheck } from "react-icons/fa6";
// import { AuthenticationService } from "../Services/AuthService";
// // Kk test
// export const getNavItems = () => {
//     let navItems = [];
//     const authService = new AuthenticationService();

//     // const setKey = (key) => {
//     //     sessionStorage.setItem('selectedKey', key);
//     // }

//     // if (authService.getUserData?.role === "2") {
//     //     navItems = [
//     //         {
//     //             key: '0',
//     //             label: <NavLink to={'/'} className={'text-decoration-none'}>Back To Home</NavLink>,
//     //             icon: <IoHomeOutline />,
//     //         },
         
//     //     ]
//     // }

//     if (authService.getUserData?.role === "3" || authService.getUserData?.role === "2") {
//         navItems = [
//             {
//                 key: '0',
//                 label: <NavLink to={'/'} className={'text-decoration-none'}>Back To Home</NavLink>,
//                 icon: <TbHomeUp />,
//             },
//             {
//                 key: '1',
//                 label: <NavLink to={'/user/home'} className={'text-decoration-none'}>Dashboard</NavLink>,
//                 icon: <GrDocumentUpdate />,
//                 // onClick: () => {
//                 //     setKey('1');
//                 // }
//             },
//             {
//                 key: '2',
//                 label: "Who's Who",
//                 icon: <GrDocumentUpdate />,
//                 // onClick: () => {
//                 //     setKey('1');
//                 // }
//                 children: [
                   
//                     {
//                         key: '3',
//                         label: <NavLink to={'/user/whoswho/officers-list'} className={'text-decoration-none'}>Officers List</NavLink>,
//                         icon: <GrDocumentUpdate />,
//                         // onClick: () => {
//                         //     setKey('31');
//                         // },
//                     },

//                     {
//                         key: '4',
//                         label: <NavLink to={'/user/whoswho/preview-officers'} className={'text-decoration-none'}>Officers Preview</NavLink>,
//                         icon: <GrDocumentUpdate />,
//                         // onClick: () => {
//                         //     setKey('31');
//                         // },
//                     },
//                 ]
//             },

//             {
//                 key: '5',
//                 label: "Projects",
//                 icon: <GrDocumentUpdate />,
//                 // onClick: () => {
//                 //     setKey('1');
//                 // }
//                 children: [
                   
//                     {
//                         key: '6',
//                         label: <NavLink to={'/user/projects/projects-list'} className={'text-decoration-none'}>Project List</NavLink>,
//                         icon: <GrDocumentUpdate />,
//                         // onClick: () => {
//                         //     setKey('31');
//                         // },
//                     },

//                     {
//                         key: '7',
//                         label: <NavLink to={'/user/projects/projects-preview'} className={'text-decoration-none'}>Project Preview</NavLink>,
//                         icon: <GrDocumentUpdate />,
//                         // onClick: () => {
//                         //     setKey('31');
//                         // },
//                     },
//                 ]
//             },

//             {
//                 key: '8',
//                 label: "Manage Content",
//                 icon: <GrDocumentUpdate />,
//                 // onClick: () => {
//                 //     setKey('1');
//                 // }
//                 children: [
                   
//                     {
//                         key: '9',
//                         label: <NavLink to={'/user/manage/content-list'} className={'text-decoration-none'}>Content List</NavLink>,
//                         icon: <GrDocumentUpdate />,
//                         // onClick: () => {
//                         //     setKey('31');
//                         // },
//                     },

//                     {
//                         key: '10',
//                         label: <NavLink to={'/user/manage/preview-content'} className={'text-decoration-none'}>Content Preview</NavLink>,
//                         icon: <GrDocumentUpdate />,
//                         // onClick: () => {
//                         //     setKey('31');
//                         // },
//                     },
//                 ]
//             },

//             {
//                 key: '11',
//                 label: "Presentation",
//                 icon: <GrDocumentUpdate />,
//                 // onClick: () => {
//                 //     setKey('1');
//                 // }
//                 children: [
                   
//                     {
//                         key: '12',
//                         label: <NavLink to={'/user/presentation/presentation-list'} className={'text-decoration-none'}>Presentation List</NavLink>,
//                         icon: <GrDocumentUpdate />,
//                         // onClick: () => {
//                         //     setKey('31');
//                         // },
//                     },

//                     {
//                         key: '13',
//                         label: <NavLink to={'/user/presentation/preview-presentation'} className={'text-decoration-none'}>Presentation Preview</NavLink>,
//                         icon: <GrDocumentUpdate />,
//                         // onClick: () => {
//                         //     setKey('31');
//                         // },
//                     },
//                 ]
//             },

//             // {
//             //     key: '14',
//             //     label: "Image Gallery",
//             //     icon: <GrDocumentUpdate />,
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             //     children: [
                   
//             //         {
//             //             key: '15',
//             //             label: <NavLink to={'/user/presentation/presentation-list'} className={'text-decoration-none'}>Image Gallery List</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },

//             //         {
//             //             key: '16',
//             //             label: <NavLink to={'/user/presentation/preview-presentation'} className={'text-decoration-none'}>Image Gallery Preview</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },
//             //     ]
//             // },
           
//             {
//                 key: '17',
//                 label: <NavLink to={'/user/notice/notice-list'} className={'text-decoration-none'}>Notice Board</NavLink>,
//                 icon: <GrDocumentUpdate />,
//                 // onClick: () => {
//                 //     setKey('1');
//                 // }
//                // children: [
                   
//                     // {
//                     //     key: '18',
//                     //     label: <NavLink to={'/user/notice/notice-list'} className={'text-decoration-none'}>Notice List</NavLink>,
//                     //     icon: <GrDocumentUpdate />,
//                     //     // onClick: () => {
//                     //     //     setKey('31');
//                     //     // },
//                     // },

//                     // {
//                     //     key: '19',
//                     //     label: <NavLink to={'/user/notice/preview-notice'} className={'text-decoration-none'}>Notice Preview</NavLink>,
//                     //     icon: <GrDocumentUpdate />,
//                     //     // onClick: () => {
//                     //     //     setKey('31');
//                     //     // },
//                     // },
//                // ]
//             },
//             // {
//             //     key: '1007',
//             //     label: <NavLink to={'/user/manage/content-list'} className={'text-decoration-none'}>Manage Content</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },
//             // {
//             //     key: '2',
//             //     label: <NavLink to={'/user/about-us'} className={'text-decoration-none'}>About Us</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },

//             // {
//             //     key: '4',
//             //     label: <NavLink to={'/user/activities-of-the-council'} className={'text-decoration-none'}>Activities of the Council</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     children: [
//             //         {
//             //             key: '10',
//             //             label: <NavLink to={'/user/cg-research-centre'} className={'text-decoration-none'}>Chhattisgarh Research Centre</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },
//             //         {
//             //             key: '11',
//             //             label: <NavLink to={'/user/research-development-promotional-cell'} className={'text-decoration-none'}>Research & Development Promotional Cell</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },

//             //         {
//             //             key: '12',
//             //             label: <NavLink to={'/user/cg-space-applications-centre'} className={'text-decoration-none'}>Chhattisgarh Space Applications Centre</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },

//             //         {
//             //             key: '13',
//             //             label: <NavLink to={'/user/popularisation-of-science'} className={'text-decoration-none'}>Popularisation Of Science</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },
//             //         {
//             //             key: '14',
//             //             label: <NavLink to={'/user/science-technology-intervention-cell'} className={'text-decoration-none'}>Science & Technology Intervention Cell</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },
//             //         {
//             //             key: '15',
//             //             label: <NavLink to={'/user/intellectual-property-rights-centre'} className={'text-decoration-none'}>Intellectual Property Rights Centre</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },
//             //         {
//             //             key: '16',
//             //             label: <NavLink to={'/user/services-provided-by-the-council'} className={'text-decoration-none'}>Services Provided by the Council</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },
//             //         {
//             //             key: '17',
//             //             label: <NavLink to={'/user/other-projects'} className={'text-decoration-none'}>Other Projects</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('31');
//             //             // },
//             //         },

//             //     ]
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },
//             // {
//             //     key: '5',
//             //     label: <NavLink to={'/user/e-citizens'} className={'text-decoration-none'}>E-Citizens</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     children: [
//             //         {
//             //             key: '18',
//             //             label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Advertisement</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //         }
//             //     ]
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },
//             // {
//             //     key: '6',
//             //     label: <NavLink to={'/user/photo-gallery'} className={'text-decoration-none'}>Photo Gallery</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     children: [
//             //         {
//             //             key: '19',
//             //             label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Poster Gallery</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //         },
//             //         {
//             //             key: '20',
//             //             label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Video Gallery</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //         },
//             //         {
//             //             key: '21',
//             //             label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Press Gallery</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //         },
//             //         {
//             //             key: '22',
//             //             label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Presentation Gallery</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //         }
//             //     ]
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },
//             // {
//             //     key: '7',
//             //     label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Achievements</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },
//             // {
//             //     key: '3',
//             //     label: <NavLink to={'/user/notice-board'} className={'text-decoration-none'}>Notice Board</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     children:[
//             //         {
//             //             key: '23',
//             //             label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>News & Events</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('1');
//             //             // }
//             //         },
//             //         {
//             //             key: '24',
//             //             label: <NavLink to={'/user/notice-board/tender-list'} className={'text-decoration-none'}>Tenders</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('1');
//             //             // }
//             //         },
//             //         {
//             //             key: '25',
//             //             label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Archive Tender</NavLink>,
//             //             icon: <GrDocumentUpdate />,
//             //             // onClick: () => {
//             //             //     setKey('1');
//             //             // }
//             //         },
//             //     ]
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },
//             // {
//             //     key: '8',
//             //     label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Quick Links</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },
//             // {
//             //     key: '9',
//             //     label: <NavLink to={'/user/dashboard'} className={'text-decoration-none'}>Contact Us</NavLink>,
//             //     icon: <GrDocumentUpdate />,
//             //     // onClick: () => {
//             //     //     setKey('1');
//             //     // }
//             // },

            
//         ]
//     }

//     return navItems;
// }
