import React from 'react'
import { Col, Row, Container, Table } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";

export default function AspirationalBlockProject() {
  const { t } = useTranslation();
  const menuData = t("menu.activities-of-council.intellectual_property_rights_centre", { returnObjects: true, }) || {};
  return (
    <>
      <BreadcrumbLayout title="Executive Committee" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="IntellectualPropertyRightsCentre" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("AspirationalBlockProject.title")}</h3>
              </Col>
              <Col xs={12} className='mb-1 mt-1'>
                <p style={{ textAlign: "justify" }} className='fw-b'>
                  {t("AspirationalBlockProject.paragraph")}
                </p>
                <div className='ml-2 mr-2 responsive-table'>
                  <Table bordered striped hover className="officers-list mt-3">
                    <thead>
                      <tr>
                        <th>{t('AspirationalBlockProject.sn')}</th>
                        <th>{t('AspirationalBlockProject.department')}</th>
                        <th>{t('AspirationalBlockProject.designation')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {t('AspirationalBlockProject.data', { returnObjects: true }).map(item => (
                        <tr key={item.ID}>
                          <td style={{textAlign:"center"}}>{item.ID}.</td>
                          <td>{item.District}</td>
                          <td>{item.Block}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <p style={{ textAlign: "justify" }} className='fw-b'>
                  {t("AspirationalBlockProject.paragraph2")}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
