import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ATCSidebar from "../ATCSidebar";
function ResearchAndDevelopmentPromotionalCell() {
  const { t } = useTranslation();
  const data = t("ResearchAndDevelopmentPromotionalCell", {
    returnObjects: true,
  });
  const menuData = t("menu.activities-of-council.research-development-promotional-cell", { returnObjects: true }) || {};
 
  return (
    <>
      <BreadcrumbLayout title="Activities of the Council" />
      <Container fluid>
        <Row>
       {/* Sidebar */}
       <ATCSidebar menuData={menuData} type="ResearchDevelopmentPromotionalCell" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{data.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1 p-4">
                <div className="heading-paragraph">
                <p  dangerouslySetInnerHTML={{ __html: data.paragraph }}></p>

                  <ul className="ms-1 nav-list">
                    {data.navlist.map((n, index) => (
                      <li key={index}>
                        <NavLink to={n.to}>{n.title}</NavLink>
                      </li>
                    ))}
                  </ul>

                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResearchAndDevelopmentPromotionalCell;
