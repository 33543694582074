import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import AboutSidebar from "./AboutSideBar";
export default function MissionObjectives() {
  const { t } = useTranslation();
  const menuData =
    t("menu", {
      returnObjects: true,
    }) || {};
  return (
    <>
      <BreadcrumbLayout title="Mission Objectives" />
      <Container fluid >
        <Row>
           {/* Sidebar */}
      <AboutSidebar menuData={menuData} />
      {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("missionobjectives.title")}</h3>
              </Col>
              <Col xs={12} className="mt-3">
                <div className="heading-paragraph">
                  <h3>{t("missionobjectives.paragraph1.title")}</h3>
                  <p>{t("missionobjectives.paragraph1.content")}</p>
                </div>
              </Col>
              <Col xs={12}>
                <div className="render-list">
                  <h3 >{t("missionobjectives.paragraph2.title")}</h3>
                  <ul>
                    {t('missionobjectives.paragraph2.data', { returnObjects: true }).map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
