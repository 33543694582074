// import React, { useEffect } from 'react';
// import { Col, Row, Container } from 'react-bootstrap';
// import { XEmbed } from 'react-social-media-embed';

// function Test() {
//   useEffect(() => {
//     // Dynamically load the Twitter widget script
//     const script = document.createElement('script');
//     script.src = "https://platform.twitter.com/widgets.js";
//     script.async = true;
//     script.charset = "utf-8";
//     document.body.appendChild(script);
//   }, []);
//   return (
//     <Container>
//       <Row>
//         <Col xs={12} className="content">
//           <Row>
//             <Col xs={12} className="heading">
//               <h3>Test</h3>
//             </Col>
//           </Row>
//         </Col>
//       </Row>

//       <Row>
//         <Col md={4}>
//           <iframe
//             src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61556133871627&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
//             width="340"
//             height="500"
//             style={{ border: 'none', overflow: 'hidden' }}
//             scrolling="no"
//             frameBorder="0"
//             allowFullScreen={true}
//             allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
//           ></iframe>
//         </Col>
//         <Col md={4}>
//           {/* <a
//             className="twitter-timeline"
//             href="https://twitter.com/CcostRaipur?ref_src=twsrc%5Etfw"
//           >
//             Tweets by CcostRaipur
//           </a> */}

// <a
//             className="twitter-timeline" 
//             data-width="340" data-height="500" 
//             href="https://twitter.com/PMOIndia?ref_src=twsrc%5Etfw"
//           >
//             Tweets By PMO India
//           </a> 
//    </Col> 
//         <Col md={4}>
//                 <a
//                  data-width="340" data-height="500" 
//             className="twitter-timeline"
//             href="https://twitter.com/CcostRaipur?ref_src=twsrc%5Etfw"
//           >
//             Tweets by CcostRaipur
//           </a>
//         </Col>
//         <Col md={4}>
//                 <a
//                  data-width="340" data-height="500" 
//             className="twitter-timeline"
//             href="https://twitter.com/kishanvermakcs?ref_src=twsrc%5Etfw"
//           >
//             Tweets by kishanvermakcs
//           </a>
//         </Col>
//       </Row>
//       <Row>

//       <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=XS2kyYPZ4TWyv7Hg&amp;list=PLfzADqz75lhxXWczVSDqQaagjjNy79zrV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

//       </Row>
//       <Row>
//       <div style={{ display: 'flex', justifyContent: 'center' }}>
//   <XEmbed url="https://twitter.com/CcostRaipur/status/1762763618569826427" width={325} />
// </div>
//       </Row>
//     </Container>
//   );
// }

// export default Test;

// import { InstagramEmbed } from 'react-social-media-embed';
import React, { useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import fb from "../assets/images/fb-img.png"
import yt from "../assets/images/yt-img.png"
import xt from "../assets/images/x-img.png"
import { encrypt,decrypt } from "../global/EncryptionASE";
// https://www.youtube.com/channel/UCM_oY6l0Xu3K5AS5rAyrbVw

// import { Timeline } from 'react-twitter-widgets'
// import { XEmbed } from 'react-social-media-embed';
// import { FacebookEmbed } from 'react-social-media-embed';


function Test() {
  useEffect(() => {
    // Dynamically load the Twitter widget script
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charset = "utf-8";
    document.body.appendChild(script);
  }, []);

  const plainText = "Nodal";
const encrypted = encrypt(plainText);
console.log("Encrypted:", encrypted);

const decrypted = decrypt(encrypted);
console.log("Decrypted:", decrypted);

  return (
    <>
      <Container fluid>
         <Row>
        <Col xs={12} className="content">
          <Row>
            <Col xs={12} className="heading">
              <h3>Test</h3>
            </Col>
          </Row>
        </Col>
      </Row>
      </Container>
      <section className="team section-padding mt-3">
        <Container fluid>
          <Row>
            <h1 className="text-center" style={{ fontWeight: "700", color: "red", fontFamily: "cursive" }}>SOCIAL ENGAGEMENT</h1>
            <Col md={4}>
              <div className="video-box" >
                <div className="video-icon"><img src={fb} alt="fb" /></div>
                {/* <iframe
                      key="facebookIframe"
                      title="Facebook Page"
                      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61556133871627&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                      width="340"
                      height="500"
                      style={{ border: "none", overflow: "hidden" }}
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe> */}
                <iframe
                  key="facebookIframe"
                  title="Facebook Page"
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61556133871627&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                  width="340"
                  height="500"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen={true}
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />

              </div>
            </Col>
            <Col md={4}>
              <div className="video-box" >
                <div className="video-icon"><img src={yt} alt="youtube" /></div>
                <iframe
                  key="youtubeIframe"
                  title="YouTube Video Series"
                  width="100%"
                  height="493"
                  src="https://www.youtube.com/embed/videoseries?si=XS2kyYPZ4TWyv7Hg&list=PLfzADqz75lhxXWczVSDqQaagjjNy79zrV"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col md={4}>
              <div className="video-box" >
                <div className="video-icon"><img src={xt} alt="twitter" /></div>
                <a class="twitter-timeline" href="https://twitter.com/CcostRaipur?ref_src=twsrc%5Etfw"
                 data-width="100%"
                  data-height="500"
                >Tweets by CcostRaipur</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container fluid>
        {/* <Row>
        <Col xs={12} className="content">
          <Row>
            <Col xs={12} className="heading">
              <h3>Test</h3>
            </Col>
          </Row>
        </Col>
      </Row>
*/}
      {/* <Row>
       
        <Col md={4} className='mt-2'>
          <a
            className="twitter-timeline"
            data-width="340"
            data-height="500"
            href="https://twitter.com/PMOIndia?ref_src=twsrc%5Etfw"
          >
            Tweets by PMO India
          </a>
        </Col>
       
        <Col md={4} className='mt-2'>
          <a
            className="twitter-timeline"
            data-width="340"
            data-height="500"
            href="https://twitter.com/kishanvermakcs?ref_src=twsrc%5Etfw"
          >
            Tweets by kishanvermakcs
          </a>
        </Col>

        <Col md={4} className='mt-2'>
          <a
            className="twitter-timeline"
            data-width="340"
            data-height="500"
            href="https://twitter.com/NICMeity?ref_src=twsrc%5Etfw"
          >
            Tweets by NICMeity
          </a>
        </Col>

        <Col md={4} className='mt-2'>
          <a
            className="twitter-timeline"
            data-width="340"
            data-height="500"
            href="https://twitter.com/aajtak?ref_src=twsrc%5Etfw"
          >
            Tweets by aajtak
          </a>
        </Col>

        <Col md={4} className='mt-2'>
          <a
            className="twitter-timeline"
            data-width="340"
            data-height="500"
            href="https://twitter.com/manojmeena?ref_src=twsrc%5Etfw"
          >
            Tweets by manojmeena
          </a>
        </Col>

        <Col md={4} className='mt-2'>
          <a
            className="twitter-timeline"
            data-width="340"
            data-height="500"
            href="https://twitter.com/khansir83?ref_src=twsrc%5Etfw"
          >
            Tweets by khansir83
          </a>
        </Col>
      </Row> */}
     
      {/* <Row>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <XEmbed url="https://twitter.com/CcostRaipur/status/1762763618569826427" width={325} />
        </div>
      </Row>  */}

        <Row>

          {/* <Col md={4}>
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: 'aajtak'
            }}
            options={{
              height: '700'
            }}
          />
        </Col>
        <Col md={4}>
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: 'NICMeity'
            }}
            options={{
              height: '700'
            }}
          />
        </Col>
        <Col md={4}>
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: 'JaikyYadav16'
            }}
            options={{
              height: '700'
            }}
          />
        </Col>
        <Col md={4}>
          instragram
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InstagramEmbed url="https://www.instagram.com/p/C4kTaW8pU1d/" height={700} />
          </div>
        </Col>
        <Col md={4}>


          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <XEmbed url="https://twitter.com/CcostRaipur/status/1762763618569826427" width={700} />
          </div>


        </Col>

        */}

          {/* <Col md={12}>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FacebookEmbed url="https://www.facebook.com/photo/?fbid=122128696862204462&set=pb.61556133871627.-2207520000" width={550} />
            </div>

          </Col> */}





        </Row>


      </Container>

    </>
  );
}

export default Test;
