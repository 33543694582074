// src/components/ApplyOnline.js

import React, { useEffect, useState } from "react";
import { Button, Typography, Card } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import './ApplyOnline.css'; 
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Tooltip } from "antd";

const { Title, Text } = Typography;

const ApplyOnline = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
    useEffect(() => {
      setLang(localStorage.getItem('language') === "en" ? 2 : 1);
    }, [i18n.language]);
    const handleClick = (url) => {
        Swal.fire({
            html: lang === 2 ? `
                    This link shall take you to a page/ website outside this website. `
                : ` यह लिंक आपको इस वेबसाइट के बाहर एक पेज/वेबसाइट पर ले जाएगा।`,
            icon: "warning",
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: "OK",
            denyButtonText: "Cancel"
        }).then((result) => {
            if (result.isConfirmed) {
                window.open(url);
            }
        });
    };
    return (
        <div className="apply-online-container">
            <Tooltip placement="bottom" title={"https://ims.iiitnr.edu.in/DST/MRP.aspx"} >
                <a target="_blank" rel="noopener noreferrer"
                    href="/"
                    onClick={(e) => {
                        e.preventDefault(); // Prevents the default link behavior
                        handleClick("https://ims.iiitnr.edu.in/DST/MRP.aspx");
                    }}

                >
                    <Card className="apply-online-card">
                        <Button type="primary" shape="round" icon={<ArrowRightOutlined />} size="large" className="apply-online-button">
                            Apply Now
                        </Button>
                        <Title level={3}>Submit Online</Title>
                        <Text className="apply-online-description ps-2 pe-2 pb-3">
                            Summary of mini research projects(MRP) is mandatory.
                        </Text>

                    </Card>
                </a></Tooltip>
        </div>
    );
};

export default ApplyOnline;
