import React from 'react'
import { Link } from "react-router-dom";
// import { AiOutlineProfile } from "react-icons/ai";
import { Container, Row, Col, Card } from "react-bootstrap";
import { getNavItems } from "../user/DashborardNavData"
import { AuthenticationService } from "../Services/AuthService";
import { useEffect, useMemo } from "react";

function Dashboard(props) {
  const authService = useMemo(() => new AuthenticationService(), []);

  useEffect(() => {
    const checkSessionValidity = async () => {
      try {
        const response = await authService.checkSession();
        if (!response.status) {
        }
      } catch (error) {
        console.error('Session validation failed:', error);
      }
    };

    checkSessionValidity();
  }, [authService]);
  const tabItem = getNavItems(props.menu);
  const setKey = (key) => {
    sessionStorage.setItem('selectedNavKey', key);
  };
  return (
    <>
      <Container>
        <Row>
          <Col className="dashboard">
            <Card className="">
              <Card.Header>
                <h3>Dashboard</h3>
              </Card.Header>
              <Row>
                <Col xs={12} className="mb-1 ">
                  <div className="container-fluid main-div text-center">

                    <ul className="row grid cs-style-1 ">
                      {
                        tabItem.map((l, i) => (

                          <li key={i} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 primary">
                            <Link to={l.to} onClick={() => setKey(l.key)}>
                              <figure className="btnBoxFig">
                                <span>
                                  {l.icon}
                                </span>
                                <h5>{l.label}</h5>
                              </figure>
                            </Link>
                          </li>

                        ))
                      }
                    </ul>

                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default Dashboard