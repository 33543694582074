import axiosInstance from "../global/AxiosInterceptor";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { decrypt } from "../global/EncryptionASE";

const captchaUrl = process.env.REACT_APP_captchaUrl;

class AuthenticationService {
    constructor() {
        this.userData = undefined;
    }

    get getUserData() {
        const authToken = Cookies.get("authToken");
        if (authToken) {
            const decoded = jwtDecode(authToken);

            this.userData = {
                exp: decoded.exp,
                iat: decoded.iat,
                iss: decoded.iss,
                name: decrypt(decoded.unique_name),
                nbf: decoded.nbf,
                role: decrypt(decoded.role),
                userId: decrypt(decoded.userId),
                userTypeCode: decrypt(decoded.userTypeCode),
                sessionId: decrypt(decoded.sessionId)
            };
            return this.userData;
        } else {
            return undefined;
        }
    }

    getCaptcha = async () => {
        return await axiosInstance.get(captchaUrl, { headers: { authkey: '679915', 'Accept': 'text/html' } });
    }

    userLogin = async (payload) => {
        return await axiosInstance.post('/Authentication/authenticate', payload);
    }

    terminatePreviousSession = async (payload) => {
        return await axiosInstance.post('/Authentication/terminatePreviousSession', payload);
    }

    refreshAuthToken = async (payload) => {
        return await axiosInstance.post('/Authentication/refreshwebtoken', payload);
    }

    logout = async () => {
        try {
            const res = await axiosInstance.get('/Authentication/logout');
            if (res.data.status) {
                Cookies.remove("authToken");
                Cookies.remove("refreshToken");
                Cookies.remove("loginTimeStamp");
                localStorage.removeItem('loginTime');
                window.location.href = `/login`;  
                 
                             
            }
        } catch (err) {
            console.log(err);
        }
    }

    checkSession = async()=>{
        return await axiosInstance.get('/Authentication/checksession');
    
    }
}

export { AuthenticationService };
