import React, { Suspense, useState, useEffect } from "react";
import { Layout, Menu, theme, Dropdown, Space, Divider, Button, Avatar } from "antd";
import Swal from "sweetalert2";
import logoEn from "../assets/images/logo-en.png";
import { AiOutlineUser, AiOutlineLogout } from "react-icons/ai";
import { AuthenticationService } from "../Services/AuthService";
import { Outlet, useLocation } from "react-router-dom";
import { getNavItems } from "./SideNavData";
import Loader from "../global/Loader";
import { Col, Row } from "react-bootstrap";
import useAutoLogout from "../component/useAutoLogout";


const { Header, Content, Sider } = Layout;
const authService = new AuthenticationService();

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { token } = theme.useToken();
  const userMenuItems = [];
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("1");

  useAutoLogout(300000); // 5 minutes in milliseconds

  window.addEventListener('storage', (event) => {
    if (event.key === 'loginTime') {
      window.location.reload();
    }
  });
  useEffect(() => {
    const pathToKey = {
      '/': '0',
      '/user/home': '1',
      '/user/whoswho/officers-list': '2',
      '/user/whoswho/add-edit-officers': '2',

      '/user/projects/projects-list': '5',
      '/user/projects/projects-entry':'5',

      '/user/manage/content-list': '8',
      '/user//manage/content-entry':'8',

      '/user/presentation/presentation-list': '11',
      '/user/presentation/presentation-entry':'11',

      '/user/notice/notice-list': '17',
      '/user/notice/notice-entry':'17',

      '/user/image-gallery-list':'15',
      '/user/press-gallery-entry':'15',
      '/user/map-gallery-entry':'15',
      '/user/image-gallery-entry':'15',
      '/user/poster-gallery-list':'21',
      '/user/poster-gallery-entry':'21',
      '/user/home-content-list':'22',
      '/user/home-slider-image-entry':'22',
      '/user/minister-entry':'22',
      '/user/change-password':'23',
      '/user/visitor-feedback':'25'
    };
    const currentKey = pathToKey[location.pathname] || '1';
    setSelectedKey(currentKey);
  }, [location.pathname]);

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  const logoutBtnClicked = () => {
    Swal.fire({
      html: 'Are you sure you want to logout?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!',
    }).then((result) => {
      if (result.isConfirmed) {
        authService.logout();
      }
    });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header>
        <Col lg={11} md={10} sm={9} xs={9}>
          <img src={logoEn} className="img-fluid" alt="Site Logo" />
        </Col>
        <Col lg={1} md={2} sm={3} xs={3}>
          <Dropdown
            className="ms-auto"
            menu={{ items: userMenuItems }}
            dropdownRender={(menu) =>
              React.createElement(
                "div",
                { style: contentStyle },
                React.cloneElement(menu, { style: menuStyle }),
                React.createElement(Divider, { style: { margin: 0 } }),
                React.createElement(
                  Space,
                  {
                    direction: "vertical",
                    style: { padding: 8, width: "100%" },
                  },
                  React.createElement(
                    Button,
                    {
                      block: true,
                      type: "primary",
                      onClick: logoutBtnClicked,
                      icon: React.createElement(AiOutlineLogout, null),
                    },
                    "Logout"
                  )
                )
              )
            }
          >
            <a href="/" onClick={(e) => e.preventDefault()} className="headerMenu">
              <Row>
                <div className="user-logo">
                  <p className="mb-0 float-right">
                    <Avatar icon={React.createElement(AiOutlineUser, null)} />
                    {authService.getUserData?.name}
                  </p>
                </div>
              </Row>
            </a>
          </Dropdown>
        </Col>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          onBreakpoint={() => {}}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={210}
          className="sider-scroll"
          style={{ overflow: 'auto', height: '82vh' }}  // Added styles for scroll
        >
          <Menu
            theme="dark"
            selectedKeys={[selectedKey]}
            mode="inline"
            items={getNavItems()}
          />
        </Sider>
        <Layout
          className="site-layout"
          style={{ marginLeft: collapsed ? 90 : 210, transition: "0.2s ease" }}
        >
          <Content style={{ margin: "0 8px" }}>
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;


