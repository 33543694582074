import React, { useEffect, useState, useMemo } from "react";
import { Image, Pagination, Empty } from 'antd';
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import Animation from "../../assets/images/Animation.gif";
import { ImageGalleryService } from "../../Services/ImageGalleryService";
import demoUser from "../../assets/images/broken-img.jpg";
import loadingImg from "../../assets/images/loading-img.gif";
import PhotoGallerySidebar from "./PhotoGallerySidebar";

export default function PressGallery() {
  const { t, i18n } = useTranslation();
  const menuData = t("menu.media-gallery", { returnObjects: true }) || {};
  const imageGalleryService = useMemo(() => new ImageGalleryService(), []);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [tblData, setTblData] = useState([]);
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);

  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  useEffect(() => {
    const getImageById = async (imgDocUniqueId, imgDocMtype) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await imageGalleryService.ViewDocument(imgDocUniqueId);
          if (res.status === 200) {
            const fileBlob = new Blob([res.data], { type: imgDocMtype });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }

      return demoUser; // Return demo image if actual image fails to load
    };

    const getGalleryData = async (galleryTypeId, language, imageCategoryType) => {
      try {
        setLoadingTblData(true);
        const res = await imageGalleryService.GetImageListPublic(galleryTypeId, language, imageCategoryType);

        // Load table with demo images initially
        const initialData = res.data.table.map((item, index) => ({
          key: index + 1,
          imageId: item.imageId,
          description: item.description,
          imagenotes: item.imagenotes,
          doc: {
            docMimeType: item.docMimeType,
            docUniqueId: item.docUniqueId,
          },
          ImgUrl: loadingImg, // Set demo image initially
        }));

        setTblData(initialData);

        // Replace each demo image with the actual image asynchronously
        initialData.forEach(async (item, index) => {
          const actualImgUrl = await getImageById(item.doc.docUniqueId, item.doc.docMimeType);
          setTblData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = { ...updatedData[index], ImgUrl: actualImgUrl };
            return updatedData;
          });
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTblData(false);
      }
    };

    getGalleryData(2, 2, -1); // Example: galleryTypeId = 2, language = 2, imageCategoryType = -1
  }, [imageGalleryService]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9); // Set as per your requirement

  // Calculate the index of the first and last item to be displayed based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentImages = tblData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle pagination change
  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };

  return (
    <>
      <BreadcrumbLayout title="Press Gallery" />
      <Container fluid>
        <Row>
          <PhotoGallerySidebar menuData={menuData} />
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.press-gallery")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
              {loadingTblData && <img src={Animation} alt="Animation gif" />}
                {currentImages.length > 0 ? (
                  <>
                    <Row className="galleryWrap">
                      <Image.PreviewGroup>
                        {currentImages.map((slide, index) => (
                          <Col lg={4} key={index}>
                            <Image
                              src={slide.ImgUrl}
                              alt={"Image-" + slide.imageId}
                            />
                          </Col>
                        ))}
                      </Image.PreviewGroup>
                    </Row>
                    <Row justify="center">
                      <Col>
                        <div className="img-pagination" style={{ paddingBottom: "20px" }}>
                          <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={tblData.length}
                            onChange={handlePaginationChange}
                            showSizeChanger={true}
                            pageSizeOptions={['9', '18', '36', '72']}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col>
                    {!loadingTblData &&
                        <Empty
                          description={
                            lang === 1
                              ? <span style={{ fontWeight: 'bold' }}>वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।</span>
                              : <span style={{ fontWeight: 'bold' }}>Currently, There are no data to display.</span>
                          }
                        />
                      }
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
