import React from 'react'

export default function HeadPara2(props) {
  return (
    <>
      <div className='heading-paragraph'>
        <h3>{props.data.heading}</h3>
        {
          props.data.paragraph.map((items,i)=>(
            <p key={i} dangerouslySetInnerHTML={{ __html: items }}></p>
          ))
        }
        {/* {
         props.data.list.map((list,i)=>(
          <p key={i}> 
          <a
          href={require(`${process.env.REACT_APP_filepath}/${list.fileName}`)}
         
          target="_blank"
          rel="noreferrer"
        >
        {list.title}

        </a>
        </p>
      ))
        } */}
      </div>
    </>
  )
}
