
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState,useMemo } from "react";
import Loader from "../../global/Loader";
import { ProjectService } from "../../Services/ProjectService";
import { Form, Select, Table, Button } from "antd";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import Swal from "sweetalert2";

const CGRCProjectPreview = () => {
  const projectService = useMemo(() => new ProjectService(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [laguage, setLanguage] = useState([]);
  const [lang, setLang] = useState("1");

  const [form] = Form.useForm();
  const [projectAreasList, setProjectAreasList] = useState([]);
  const [loadingDDL, setLoadingDDL] = useState(true);
  const [projectTypeList, setProjectTypeList] = useState([]);
  const [initialValues, setInitialValues] = useState({ Language: "1", projectAreas: "-1", projectType: "-1" });
  const [pageLoad, setPageLoad] = useState(false);

  const viewDoc = async (doc) => {
    

    try{
      setPageLoad(true);
      if (doc.DocUniqueId !== null) {
        const res = await projectService.ViewDocument(doc.DocUniqueId );
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType});
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Doqument has not been uploaded!",
        });
      }
    }
    catch(error){
      console.error("Error fetching documents:", error);
    }
    finally{
      setPageLoad(false);
    }
  };



  const columns = [
    {
      title: "#",
      render: (record) => record.key,
      width: "3%",
    },
    {
      title: 'English/Hindi',
      render: (row) => {
        return (
          <>


            <div className="project-details mt-3">
              <h5><span className="fw-bold">{row.title} </span> </h5>
              <p><span className="fw-bold">{ lang==="2"?'Principal Investigator:':'प्रधान अन्वेषक:'} </span>{row.investigator}</p>
              <p><span className="fw-bold">{lang==="2"?'Tenure of the Project:':'परियोजना की अवधि:'} </span>{row.tenure}</p>

            </div>

          </>
        )
      }

    },

    {
      title: "View Details",
      render: (row) => {
        return (
          <>

            <Button
              onClick={() => viewDoc(lang==="2"?row.docEn:row.docHi)}
              className="btn btn-sm btn-outline-primary mt-2 "
            >
              <BsFileEarmarkPdfFill />
              View Details


            </Button>
          </>
        );
      },
      width: "15%",
    }
  ];

  useEffect(() => {

    setLanguage([
      {
        value: "1",
        name: "Hindi",
        label: "Hindi",
        nameHindi: "",
        type: "",
        extraField1: "",
      },
      {
        value: "2",
        name: "English",
        label: "English",
        nameHindi: "",
        type: "",
        extraField1: "",
      },
    ]);
    const getContentTypes = async (type) => {
      try {
          setLoadingDDL(true);
          if(type==="ProjectAreas"){
            const res = await projectService.getCommonList(type);
            setProjectAreasList(() => {
              let data = [
                {
                  value: '-1',
                  name: 'All',
                  label: 'All',
                  nameHindi: '',
                  type: '',
                  extraField1: ''
                }
              ];
              for (let i = 0; i < res.data.length; i++) {
                data.push(
                  {
                    value: res.data[i].value,
                    name: res.data[i].name,
                    label: res.data[i].label,
                    nameHindi: res.data[i].nameHindi,
                    type: res.data[i].type,
                    extraField1: res.data[i].extraField1
                  }
                )
              }
              return data;
            }) 
          }
          else if(type==="ProjectType"){
            const res = await projectService.getCommonList(type);
            setProjectTypeList(() => {
              let data = [
                {
                  value: '-1',
                  name: 'All',
                  label: 'All',
                  nameHindi: '',
                  type: '',
                  extraField1: ''
                }
              ];
              for (let i = 0; i < res.data.length; i++) {
                data.push(
                  {
                    value: res.data[i].value,
                    name: res.data[i].name,
                    label: res.data[i].label,
                    nameHindi: res.data[i].nameHindi,
                    type: res.data[i].type,
                    extraField1: res.data[i].extraField1
                  }
                )
              }
              return data;
            }) 
          }
         
      }
      catch (error) {
          setLoadingDDL(false);
      }
      finally {
          setLoadingDDL(false);
      }
  }
    getContentTypes("ProjectType");
    getContentTypes("ProjectAreas");

  }, [projectService]);

  useEffect(() => {

    
    const getOfficersList = async (projectAreas, projectType, language) => {
      try {
        setLoadingTblData(true);
  
        const res = await projectService.GetProjectListPublic(projectAreas, projectType, language);

        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            projectId: item.projectId,
            title: item.title,
            tenure: item.tenure,
            investigator: item.investigator,
            docEn: {
              DocUniqueId: item.enDocUniqueId,
              DocMimeType: item.enDocMimeType,
  
            },
            docHi: {
              DocMimeType: item.hiDocMimeType,
              DocUniqueId: item.hiDocUniqueId
            },
            isActive: item.isActive,
            isActiveName: item.activeName,
  
            publishDate: item.publishDate,
          }));
          return data;
        });
      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getOfficersList(form.getFieldValue("projectAreas"), form.getFieldValue("projectType"), form.getFieldValue("Language"));
   

  }, [projectService,initialValues,form]);


  

  const loadTableFilter = (projectAreas,projectType, language) => {
    setLang(language);
    setInitialValues({ Language: language, projectAreas: projectAreas, projectType: projectType });
  };



  return (
    <>
      {loadingTblData && <Loader />}
      {pageLoad && <Loader/>}
      <Container>
        <Row>
          <Col className="dashboard">
            <div className="title">
              <div className="title">
                <h3>Project Preview</h3>
              </div>
            </div>
            <div className="content">
              <Col lg={4} md={12} className="float-start">
                {/* <Link className="title-btn" to="/user/projects/projects-list">

                  <TbArrowBackUp /> Back
                </Link> */} 
              </Col>
              <Form form={form} initialValues={initialValues}>
                <Col lg={12} md={12} className="float-end">
                  <Row>
                  <Col md={4}>
                    <Form.Item name="projectAreas" label="Areas" rules={[
                      { required: true, message: '*Required.!' },
                    ]}>
                      <Select showSearch placeholder="Select Project Areas" optionFilterProp="children" // onSearch={onSearch}
                        options={projectAreasList} loading={loadingDDL}
                        onChange={(value) => loadTableFilter(value,form.getFieldValue("projectType"), form.getFieldValue("Language"))}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col md={4}>
                    <Form.Item name="projectType" label="Type" rules={[
                      { required: true, message: 'Project Type Required' },
                    ]}>
                      <Select showSearch placeholder="Select Project Type" optionFilterProp="children" // onSearch={onSearch}
                        options={projectTypeList} loading={loadingDDL}
                        onChange={(value) => loadTableFilter(form.getFieldValue("projectAreas"),value, form.getFieldValue("Language"))}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>
                  
                    <Col lg={4}>
                      <Form.Item name="Language" label="Language:">
                        <Select
                          showSearch
                          placeholder="Select Language"
                          // defaultValue={"1"}
                          optionFilterProp="children"
                          onChange={(value) => loadTableFilter(form.getFieldValue("projectAreas"),form.getFieldValue("projectType"), value)}
                          options={laguage}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(
                              input.toLowerCase()
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Form>
              <div className="tableList">
                <Table
                  bordered
                  columns={columns}
                  dataSource={tblData}
                  loading={loadingTblData}
                  // scroll={{ x: 1200, y: 600 }}
                  size="small"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CGRCProjectPreview;



