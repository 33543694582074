import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
import DownloadableList from "../../../component/DownloadableList";

export default function TechnologyTransfer() {
  const { t } = useTranslation();
  const TtData = t('TechnologyTransfer', { returnObjects: true });
  const menuData = t('ScienceAndTechnologyInterventionCell', { returnObjects: true })|| {};


  return (
    <>
      <BreadcrumbLayout title="Technology Transfer" />
      <Container fluid >
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="ScienceAndTechnologyInterventionCell" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
              <h3>{TtData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
            <div>
              {TtData.data.map((d,i) => (
                <div key={i} className="heading-paragraph">
                  {d.paragraph.map((items,i) => (
                    <p key={i} dangerouslySetInnerHTML={{ __html: items }}></p>
                  ))}
                 
                    <div className="downloadable-list2">
                    <DownloadableList _contentType = "29" _className="ms-4"/>
                </div>
                    

                  {d.paragraph2.map((items,i) => (
                    <p key={i} dangerouslySetInnerHTML={{ __html: items }}></p>
                  ))}
                </div>
              ))}
            </div>
            
          </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
