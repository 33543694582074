import axiosInstance from "../global/AxiosInterceptor";

export class UserService {
   

    ChageUserPassword = async (payload) => {
        return await axiosInstance.post('/User/chageuserpassword', payload)
    }


    GetUserDetailsById = async (userId) => {
        return await axiosInstance.get(`/User/getuserdetailsbyid/${userId}`); 
    }

    

}