import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './CurtainEffect.css';
// import Loader from '../global/Loader';


function CurtainEffect() {
  const [isOpen, setIsOpen] = useState(true); // Initially, the curtain is closed
  const navigate = useNavigate(); 

  const toggleCurtain = useCallback(() => {
    setIsOpen(prevIsOpen => !prevIsOpen); 
  }, []); 

  useEffect(() => {
    const handleKeyPress = (event) => {
      // if (event.key === 'Enter') { // Example: only toggle on 'Enter' key press
      //   toggleCurtain(); // Toggle the curtain when 'Enter' key is pressed
      // }
      toggleCurtain();
    };

    const inputElement = document.getElementById('hidden-keyboard-input');

    inputElement.addEventListener('keydown', handleKeyPress);
    inputElement.focus();

    return () => {
      inputElement.removeEventListener('keydown', handleKeyPress);
      inputElement.blur();
    };
  }, [toggleCurtain]);

  useEffect(() => {
    if (!isOpen) {
      const timer = setTimeout(() => {
        navigate('/'); 
       //  window.open('http://164.100.150.78/cgcost', '_self');
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isOpen, navigate]);

  return (
    <div className="curtain" onClick={toggleCurtain}>
      <div className="curtain__wrapper">
        <input type="checkbox" checked={isOpen} readOnly /> {/* Bind to the state */}
        <div className="curtain__panel curtain__panel--left"></div>
        <div className="curtain__panel curtain__panel--right"></div>
        <div className="curtain__prize">
          <h1>Welcome to </h1>
          <h1>Chhattisgarh Council of Science & Technology Website</h1>
          <h1>छत्तीसगढ़ विज्ञान एवं प्रौद्योगिकी परिषद की वेबसाइट में आपका स्वागत है</h1>
          {/* <Loader/> */}
        </div>
      </div>
      <input id="hidden-keyboard-input" type="text" style={{ opacity: 0, position: 'absolute', top: 0, left: 0 }} />
      {/* Hidden input to capture keyboard events */}
    </div>
  );
}

export default CurtainEffect;


// import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './CurtainEffect.css';

// function CurtainEffect() {
//   const [isOpen, setIsOpen] = useState(true); // Initially, the curtain is closed
//   const navigate = useNavigate(); // Initialize useNavigate

//   // Function to toggle the curtain state
//   const toggleCurtain = useCallback(() => {
//     setIsOpen(prevIsOpen => !prevIsOpen); // Toggle the curtain state
//   }, []); // No dependencies, so it won't change

//   // Effect to handle keydown events
//   useEffect(() => {
//     const handleKeyPress = (event) => {
//       console.log("Key pressed:", event.key); // Debugging log
//       toggleCurtain(); // Toggle the curtain when any key is pressed
//     };

//     // Add event listener for keydown event
//     window.addEventListener('keydown', handleKeyPress);

//     // Cleanup function to remove event listener when component unmounts
//     return () => {
//       window.removeEventListener('keydown', handleKeyPress);
//     };
//   }, [toggleCurtain]); // Include toggleCurtain in the dependency array

//   // Effect to navigate after curtain is toggled
//   useEffect(() => {
//     if (!isOpen) {
//       const timer = setTimeout(() => {
//         navigate('/'); // Navigate to the homepage after 3 seconds
//       }, 3000);

//       return () => clearTimeout(timer);
//     }
//   }, [isOpen, navigate]);

//   return (
//     <div
//       className="curtain"
//       onClick={toggleCurtain}
//       onKeyDown={(event) => {
//         if (event.key === 'Enter') { // Optional: handle Enter key if needed
//           toggleCurtain();
//         }
//       }}
//       tabIndex="0" // Ensure the div is focusable
//       role="button" // Optional: to make it more accessible
//     >
//       <div className="curtain__wrapper">
//         <input type="checkbox" checked={isOpen} readOnly /> {/* Bind to the state */}
//         <div className="curtain__panel curtain__panel--left"></div>
//         <div className="curtain__panel curtain__panel--right"></div>
//         <div className="curtain__prize">
//           <h1>Welcome to </h1>
//           <h1>Chhattisgarh Council of Science & Technology Website</h1>
//           <h1>छत्तीसगढ़ विज्ञान एवं प्रौद्योगिकी परिषद की वेबसाइट में आपका स्वागत है</h1>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CurtainEffect;




// import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './CurtainEffect.css';

// function CurtainEffect() {
//   const [isOpen, setIsOpen] = useState(true); // Initially, the curtain is closed
//   const navigate = useNavigate(); // Initialize useNavigate

//   const toggleCurtain = useCallback(() => {
//     setIsOpen(prevIsOpen => !prevIsOpen); // Toggle the curtain state
//   }, []); // No dependencies, so it won't change

//   useEffect(() => {
//     const handleKeyPress = () => {
//       toggleCurtain(); // Toggle the curtain when any key is pressed
//     };

//     // Add event listener for keydown event
//     window.addEventListener('keydown', handleKeyPress);

//     // Cleanup function to remove event listener when component unmounts
//     return () => {
//       window.removeEventListener('keydown', handleKeyPress);
//     };
//   }, [toggleCurtain]); // Include toggleCurtain in the dependency array

//   useEffect(() => {
//     if (!isOpen) {
//       const timer = setTimeout(() => {
//         navigate('/'); // Navigate to the homepage after 5 seconds
//       }, 3000);

//       return () => clearTimeout(timer);
//     }
//   }, [isOpen, navigate]);

//   return (
//     <div className="curtain" onClick={toggleCurtain}> {/* Click to toggle the curtain */}
//       <div className="curtain__wrapper">
//         <input type="checkbox" checked={isOpen} readOnly /> {/* Bind to the state */}
//         <div className="curtain__panel curtain__panel--left"></div>
//         <div className="curtain__panel curtain__panel--right"></div>
//         <div className="curtain__prize">
//           <h1>Welcome to </h1>
//           <h1>Chhattisgarh Council of Science & Technology Website</h1>
//           <h1>छत्तीसगढ़ विज्ञान एवं प्रौद्योगिकी परिषद की वेबसाइट में आपका स्वागत है</h1>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CurtainEffect;




// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './CurtainEffect.css';

// function CurtainEffect() {
//   const [isOpen, setIsOpen] = useState(true); // Initially, the curtain is closed
//   const navigate = useNavigate(); // Initialize useNavigate

//   const toggleCurtain = () => {
//     setIsOpen(!isOpen); // Toggle the curtain state
//   };

//   useEffect(() => {
//     const handleKeyPress = () => {
//       toggleCurtain(); // Toggle the curtain when any key is pressed
//     };

//     // Add event listener for keydown event
//     window.addEventListener('keydown', handleKeyPress);

//     // Cleanup function to remove event listener when component unmounts
//     return () => {
//       window.removeEventListener('keydown', handleKeyPress);
//     };
//   }, []); // Empty dependency array ensures this effect runs only once

//   useEffect(() => {
//     if (!isOpen) {
//       const timer = setTimeout(() => {
//         navigate('/'); // Navigate to the homepage after 5 seconds
//       }, 3000);

//       return () => clearTimeout(timer);
//     }
//   }, [isOpen, navigate]);

//   return (
//     <div className="curtain" onClick={toggleCurtain}> {/* Click to toggle the curtain */}
//       <div className="curtain__wrapper">
//         <input type="checkbox" checked={isOpen} readOnly /> {/* Bind to the state */}
//         <div className="curtain__panel curtain__panel--left"></div>
//         <div className="curtain__panel curtain__panel--right"></div>
//         <div className="curtain__prize">
//           <h1>Welcome to </h1>
//           <h1>Chhattisgarh Council of Science & Technology Website</h1>
//           <h1>छत्तीसगढ़ विज्ञान एवं प्रौद्योगिकी परिषद की वेबसाइट में आपका स्वागत है</h1>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CurtainEffect;






// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
// import './CurtainEffect.css';

// function CurtainEffect() {
//   const [isOpen, setIsOpen] = useState(true); // Initially, the curtain is closed
//   const navigate = useNavigate(); // Initialize useNavigate

//   const toggleCurtain = () => {
//     setIsOpen(!isOpen); // Toggle the curtain state
//   };

//   useEffect(() => {
//     if (!isOpen) {
//       const timer = setTimeout(() => {
//         navigate('/'); // Navigate to the homepage after 5 seconds
//       }, 1000); // 5000 milliseconds = 5 seconds

//       // Cleanup function to clear the timer if the component unmounts or isOpen changes
//       return () => clearTimeout(timer);
//     }
//   }, [isOpen, navigate]); // Dependency array ensures this runs when isOpen changes

//   return (
//     <div className="curtain" onClick={toggleCurtain}> {/* Click to toggle the curtain */}
//       <div className="curtain__wrapper">
//         <input type="checkbox" checked={isOpen} readOnly /> {/* Bind to the state */}
//         <div className="curtain__panel curtain__panel--left"></div>
//         <div className="curtain__panel curtain__panel--right"></div>
//         <div className="curtain__prize">
//           <h1>Welcome to Our Website</h1>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CurtainEffect;






// import React, { useState } from 'react';
// import './CurtainEffect.css';

// function CurtainEffect() {
//   const [isOpen, setIsOpen] = useState(true); // Initially, the curtain is closed

//   const toggleCurtain = () => {
//     setIsOpen(!isOpen); // Toggle the curtain state
//   };

//   return (
//     <div className="curtain" onClick={toggleCurtain}> {/* Click to toggle the curtain */}
//       <div className="curtain__wrapper">
//         <input type="checkbox" checked={isOpen} readOnly /> {/* Bind to the state */}
//         <div className="curtain__panel curtain__panel--left"></div>
//         <div className="curtain__panel curtain__panel--right"></div>
//         <div className="curtain__prize">
//           <h1>Welcome to Our Website</h1>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CurtainEffect;

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
// import './CurtainEffect.css';

// function CurtainEffect() {
//   const [isOpen, setIsOpen] = useState(true); // Initially, the curtain is closed
//   const navigate = useNavigate(); // Initialize useNavigate

//   const toggleCurtain = () => {
//     setIsOpen(!isOpen); // Toggle the curtain state
//   };

//   useEffect(() => {
//     if (!isOpen) {
//       navigate('/home'); // Navigate to the homepage when the curtain opens
//     }
//   }, [isOpen, navigate]); // Dependency array ensures this runs when isOpen changes

//   return (
//     <div className="curtain" onClick={toggleCurtain}> {/* Click to toggle the curtain */}
//       <div className="curtain__wrapper">
//         <input type="checkbox" checked={isOpen} readOnly /> {/* Bind to the state */}
//         <div className="curtain__panel curtain__panel--left"></div>
//         <div className="curtain__panel curtain__panel--right"></div>
//         <div className="curtain__prize">
//           <h1>Welcome to Our Website</h1>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CurtainEffect;

