import { Button, Divider, Input, Upload, message, Select, List, Switch } from "antd";
import Form from "antd/es/form";
import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col,Card } from "react-bootstrap";
import { PageContentService } from "../../Services/PageContentService";
import { readFileDataAsBase64 } from "../../global/FileConverter";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TbTrashX } from "react-icons/tb";
import { FilePdfOutlined } from '@ant-design/icons';
import { TbArrowBackUp } from "react-icons/tb";
import { Link } from "react-router-dom";
import { AuthenticationService } from "../../Services/AuthService";
const ContentEntry = () => {
  const [form] = Form.useForm();
  const [loadingFormSubmit, setLoadingFormSubmit] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const values = Form.useWatch([], form);
  const [docFileList, setDocFileList] = useState([]);
  const [docFileValue, setDocFileValue] = useState(null);
  const [contentTypeList, setContentTypeList] = useState([]);
  const [loadingNoticeTypes, setLoadingNoticeTypes] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [contentId, setContentId] = useState();
  const [title, setTitle] = useState("Add");
  useEffect(() => {
    const authService = new AuthenticationService();
    if (authService.getUserData?.role !== "3") {
      navigate('/user/unauthorized'); // Redirect to error page if role is not admin
    }
  }, [navigate]);
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormDisabled(true);
      },
      () => {
        setFormDisabled(false);
      }
    );
  }, [values, form]);
  const pageContentService = useMemo(() => new PageContentService(), []);
  useEffect(() => {
    const getContentTypes = async (type) => {
      try {
        setLoadingNoticeTypes(true);
        const res = await pageContentService.getCommonList(type);
        setContentTypeList(res.data)
      }
      catch (error) {
        setLoadingNoticeTypes(false);
      }
      finally {
        setLoadingNoticeTypes(false);
      }
    }
    getContentTypes("PageContentType");

  }, [pageContentService]);
  useEffect(() => {
    if (location?.state?.EditFlag === true) {
      form.setFieldsValue({
        contentType: location?.state?.data?.contentTypeId.toString(),
        titleEnglish: location?.state?.data?.titleEnglish,
        titleHindi: location?.state?.data?.titleHindi,
        isActive: (location?.state?.data?.isActive === 1) ? true : false
      })
      setContentId(location?.state?.data?.contentId)
      setTitle("edit")
    }
  }, [location?.state, form])

  useEffect(() => {
    const genRandomKey = async () => {
      if (docFileList && docFileList.length > 0) {
        try {
          let data = await readFileDataAsBase64(docFileList[0]);
          setDocFileValue(() => ({
            documentExtension: ".pdf",
            documentMimeType: "application/pdf",
            documentTag: "ContentDocs",
            documentType: 9,
            documentImageGroup: 2,
            stateId: 22,
            documentList: [
              {
                documentInByte: data,
                documentLabel: "ContentDocs",
              },
            ],
          }));
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }
    };
    genRandomKey();
  }, [docFileList]);
  const onRemoveDoc = (file) => {
    const index = docFileList.indexOf(file);
    const newFileList = docFileList.slice();
    newFileList.splice(index, 1);
    setDocFileList(newFileList);
  }
  const beforeUploadDoc = (file, docFileList) => {
    if (file.size / 1024 / 1024 > 10) {
      message.open({
        type: 'error',
        content: <> <strong>{file.name}</strong> is greater than 10MB.</>,
        duration: 4
      });
      return false;
    }
    if (file.type !== 'application/pdf') {
      message.open({
        type: 'error',
        content: <>Only .pdf files are allowed</>,
        duration: 4
      });
      return false;
    }
    if (docFileList.findIndex((itm) => { return (itm.name === file.name && itm.size === file.size && itm.type === file.type) }) !== 0) {
      message.open({
        type: 'error',
        content: <><strong>{file.name} already added.</strong></>,
        duration: 4
      });
      return false;
    }
    setDocFileList([file]);
    return false;
  }
  const SubmitDetails = async () => {
    try {
      setLoadingFormSubmit(true);
      const payload = {
        contentId: contentId,
        contentTypeId: form.getFieldValue("contentType"),
        titleEnglish: form.getFieldValue("titleEnglish"),
        titleHindi: form.getFieldValue("titleHindi"),
        blDocuments: docFileValue ? [docFileValue] : [],
        isActive: form.getFieldValue("isActive") === true ? 1 : 0,
        VerificationRemark: form.getFieldValue("verificationRemark")
      };
      let res;
      if (location?.state?.EditFlag === true) {
        res = await pageContentService.PageContentUpdate(payload);
      }
      else {
        res = await pageContentService.PageContentInsert(payload);
      }
      if (res.data.status) {
        Swal.fire({
          html: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        navigate("/user/manage/content-list");
      }
      else {
        Swal.fire({
          html: res.data.message,
          icon: "error"
        });
      }
    } catch (error) {
      console.error("Error at submitting Project details:", error);
    } finally {
      setLoadingFormSubmit(false);
    }
  };
  return (
    <Container className="project-entry-form mt-4">
      <Row className="justify-content-center">
        <Col lg={11}>
          <Card className="mb-4 shadow-sm">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h3>{title === "edit" ? "Update/Edit Page Doc" : "Add Page Doc"}</h3>
              <Link className="btn btn-secondary" to="/user/manage/content-list">
                <TbArrowBackUp /> Back
              </Link>
            </Card.Header>
            <Card.Body>
              <Form
                layout="vertical"
                form={form}
                name="OfficersDetaileForm"
                onFinish={SubmitDetails}
                initialValues={{ isActive: true }}
                scrollToFirstError
              >
                <Card className="mb-3">
                  <Card.Header className="fw-bold">Content Type</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item name="contentType" label="Select Content Type" rules={[
                          { required: true, message: 'Category Required' },
                        ]}>
                          <Select showSearch placeholder="Select Content Type" optionFilterProp="children" // onSearch={onSearch}
                            options={contentTypeList} loading={loadingNoticeTypes}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Header className="fw-bold">Subject/Title</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item name="titleEnglish" label="Subject/Title (English)" rules={[
                          { required: true, message: '*Required!' },
                          {
                            type: "string",
                            max: 500,
                            message: "Max length exceeded",
                          },
                        ]}>
                          <Input.TextArea showCount maxLength={500} placeholder="Enter Subject/Title (English)" autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item name="titleHindi" label="Subject/Title (Hindi)" rules={[
                          { required: true, message: '*Required!' },
                          {
                            type: "string",
                            max: 500,
                            message: "Max length exceeded",
                          },
                        ]}>
                          <Input.TextArea showCount maxLength={500} placeholder="Enter Subject/Title (Hindi)" autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Header className="fw-bold">Upload Doc</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item
                          name="projectDocEnglish"
                          label="Upload Doc"
                          rules={[
                            { required: !location?.state?.EditFlag, message: "*Required!" }
                          ]}
                        >
                          <div>
                            <Upload.Dragger name="files"
                              beforeUpload={beforeUploadDoc}
                              fileList={docFileList}
                              accept="application/pdf"
                              disabled={docFileList.length >= 1}
                              showUploadList={false}
                            >
                              <p className="ant-upload-drag-icon">
                                <FilePdfOutlined />
                              </p>
                              <p className="ant-upload-text">Click or drag file to this area to upload</p>
                              <p className="ant-upload-hint">Only PDF file with size less than 10MB is allowed</p>
                            </Upload.Dragger>
                            {(docFileList.length > 0) && <List className="mt-1"
                              itemLayout="horizontal"
                              dataSource={docFileList}
                              bordered
                              renderItem={(item, index) => (
                                <List.Item key={index}
                                  actions={[<Button key="list-loadmore-edit" className="btn btn-outline-danger" onClick={() => { onRemoveDoc(item) }}><TbTrashX /></Button>]}>
                                  <List.Item.Meta
                                    avatar={<AiOutlineFilePdf />}
                                    title={item.name}
                                    description={`File Type: ${item.type} | Size: ${(item.size / 1024).toFixed(2)}KB`}
                                  />
                                </List.Item>
                              )}
                            />}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Header className="fw-bold">Additional Details</Card.Header>
                  <Card.Body>
                    <Row gutter={16}>
                      <Col md={6}>
                        <Form.Item name="verificationRemark" label="Remark" rules={[
                          { required: location?.state?.EditFlag, message: 'Required!' },
                          {
                            type: "string",
                            max: 250,
                            message: "Max length exceeded",
                          },
                        ]}>
                          <Input.TextArea showCount maxLength={250} placeholder="Enter Remark" autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item name="isActive" label="Is Active:" valuePropName="checked"
                          rules={[
                            { required: true, message: 'Required!' }
                          ]}>
                          <Switch />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <div className="text-center mt-2">
                  <Divider />
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!formDisabled}
                    loading={loadingFormSubmit}
                  >
                    Submit ( {title === "edit" ? "Update" : "Save"} )
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContentEntry;
