import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";


import { useEffect, useState, useMemo } from "react";
import { PageContentService } from "../../Services/PageContentService";
import Loader from "../../global/Loader";
import Swal from "sweetalert2";
import { Table, Empty } from "antd";
import CitizensSidebar from "./CitizensSidebar";


export default function CouncilMagazine() {
  const { t, i18n } = useTranslation();
 
  const menuData = t("menu.e-citizens", { returnObjects: true, }) || {};

  const pageContentService = useMemo(() => new PageContentService(), []);
  const [pageSize, setPageSize] = useState(10); // Page size state
  const [tblDataDownloadPdf, setTblDataDownloadPdf] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);

  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const [pageLoading, setPageLoading] = useState(false);
  const viewDoc = async (doc) => {
    try {
      setPageLoading(true);
      if (doc.DocUniqueId !== null) {
        const res = await pageContentService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The document hasn't been uploaded yet. Please try again later.",
        });
      }
    }
    catch (error) {
      console.error("Error fetching documents:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unexpected error occurred. Please try again.",
      });
    }
    finally {
      setPageLoading(false);
    }


  };


  useEffect(() => {

    const getPageContetsList = async (contentType, language, Type) => {
      try {
        setLoadingTblData(true);
        const res = await pageContentService.GetPageContetListPublic(contentType, language);
        let data = res.data.table.map((item, index) => ({
          key: index + 1,
          contentTypeName: item.contentTypeName,
          contentId: item.contentId,
          title: item.title,

          verificationStatus: item.verificationStatus,
          statusName: item.statusName,
          isActive: item.isActive,
          isActiveName: item.activeName,
          verificationremark: item.verificationRemark,
          publishDate: item.publishDate,
          submitDate: item.submitDate,
          doc: {
            DocUniqueId: item.uniqueId,
            DocMimeType: item.documentMimeType,

          },
        }));

        if (Type === "DownloadPdf") {
          setTblDataDownloadPdf(data)
        }

      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };

    getPageContetsList(45, lang, "DownloadPdf");

  }, [lang, pageContentService]);

  const columns = [
    {
      title: "#",
      render: (record) => {
        return (
          <div className="text-center">{record.key + "."}</div>
        );
      },
      fixed: "left",
      width: "5%",
    },

    {
      title: lang === 2 ? "View/download" : "डाउनलोड/ देखें",
      render: (row) => {
        return (
          <>
            <div className="downloadPdf">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault(); // Prevents the default link behavior
                  viewDoc(row.doc);
                }}

              >
                {row.title}
              </a>
            </div>
          </>
        );
      },
      fixed: "left",
      width: "70%",
    }
  ];

  return (
    <>
      {pageLoading && <Loader />}
      {/* {loadingTblData && <Loader />} */}
      <BreadcrumbLayout title="Download Pdf" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <CitizensSidebar menuData={menuData} />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.council-magazine")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1 p-4">

                <div className="table-responsive-md tableList">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={tblDataDownloadPdf}
                    loading={loadingTblData}
                    // scroll={{ x: 600 }}
                    size="small"
                    pagination={{
                      pageSize,
                      showSizeChanger: true,
                      onShowSizeChange: (current, size) => setPageSize(size),
                      pageSizeOptions: ["5", "10", "20", "50", "100"],
                    }}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={lang === 1 ? <span style={{ fontWeight: 'bold' }}>वर्तमान में, प्रदर्शित करने के लिए कोई डेटा नहीं है ।</span> : <span style={{ fontWeight: 'bold' }}>Currently, There are no data to display.</span>}
                        />
                      )
                    }}
                  />
                </div>
              </Col>

            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
