import axiosInstance from "../global/AxiosInterceptor";

export class PosterGalleryService {
   

    PosterDetailsInsert = async (payload) => {
        return await axiosInstance.post('/PosterGallery/addnewposter', payload)
    }

    PosterDetailsUpdate = async (payload) => {
        return await axiosInstance.post('/PosterGallery/updateposterdetails', payload)
    }

    GetPosterDetails = async (payload) => {
        return await axiosInstance.post('/PosterGallery/getposterlist', payload);
    }

    GetPosterListPublic = async () => {
        return await axiosInstance.get(`/PosterGallery/getposterlistpublic`);
    }

    VerifyPosterDetails = async (payload) => {
        return await axiosInstance.post('/PosterGallery/posterdetailsverify', payload)
    }

    ViewDocument = async (uniqeId) => {
        return await axiosInstance.get('/Documents/getdocumentbyuniqueid/' + uniqeId, {
            responseType: 'blob'
        });
    };

    getCommonList = async (Type) => {
        return await axiosInstance.get(`/Common/CommonList/${Type}/2`); 
    }

}