import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara from "../../../component/HeadPara";
import { useTranslation } from "react-i18next";
export default function DGPSGuidelines() {
  const { t } = useTranslation();
  return (
    <>
      <BreadcrumbLayout title="DGPS Guidelines" />
      <Container className="outer-border">
        <Row>
          <Col xs={12} className="inner-border">
            <h3>{t("DGPSGuidelines.title")}</h3>
          </Col>
          <Col xs={12} className="mb-1 mt-1">
          <Col xs={12}  className="mb-1 mt-1 ms-3">
              <HeadPara
                
                paragraph={t("DGPSGuidelines.content")}
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
}
