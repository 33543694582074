import React from "react";

export default function TitleList(props) {
  
  return (
    <>
      <>
        <div className="title-list">
          <h3>{props.title}</h3>

          <ul>
            {
                props.list.map((list,i)=>(
                    <li key={i}>{list}</li>
                ))
            }
            </ul>
        </div>
      </>
    </>
  );
}
