import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";
import { Tooltip } from "antd";

const SwitchTextSize = (props) => {
  const { t } = useTranslation();

  const [fontSize, setFontSize] = useState(props.default || 100);

  useEffect(() => {
    const storedFontSize = props.store && props.store.getItem(props.storeKey);
    if (storedFontSize) {
      setFontSize(parseInt(storedFontSize, 10));
    }
  }, [props.store, props.storeKey]);

  const getTextSize = () => {
    return props.store && props.store.getItem(props.storeKey)
      ? parseInt(props.store.getItem(props.storeKey), 10)
      : fontSize;
  };

  const toggle = (type) => {
    let newFontSize = getTextSize();

    switch (type) {
      case "increase":
        newFontSize += props.step || 20;
        break;
      case "decrease":
        newFontSize -= props.step || 20;
        break;
      default:
        newFontSize = props.default || 100;
        break;
    }

    setFontSize(newFontSize);
    if (props.store) {
      props.store.setItem(props.storeKey, newFontSize.toString());
    }
  };

  return (
    <>
      <Nav.Item as="li">
        <Tooltip placement="bottom" title="Font size decrease" >
          <button
            disabled={fontSize <= (props.min || 60)}
            className="font-resizer"
            onClick={() => toggle("decrease")}
          >
            {t("subHeader.a-")}
          </button>
        </Tooltip>
      </Nav.Item>
      <Nav.Item as="li">
        <Tooltip placement="bottom" title="Font size normal" >
          <button className="font-resizer" onClick={() => toggle("default")}>
            {t("subHeader.a")}
          </button>
        </Tooltip>
      </Nav.Item>
      <Nav.Item as="li">
        <Tooltip placement="bottom" title="Font size increase" >
          <button
            disabled={fontSize >= (props.max || 180)}
            className="font-resizer"
            onClick={() => toggle("increase")}
          >
            {t("subHeader.a+")}
          </button>
        </Tooltip>
      </Nav.Item>



      <style>{`html { font-size: ${fontSize}${props.suffix || "%"} }`}</style>
    </>
  );
};

SwitchTextSize.propTypes = {
  default: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  suffix: PropTypes.string,
  store: PropTypes.object,
  storeKey: PropTypes.string,
};

SwitchTextSize.defaultProps = {
  default: 100,
  step: 20,
  min: 60,
  max: 180,
  suffix: "%",
  store: localStorage,
  storeKey: "SwitchTextSize",
};

export default SwitchTextSize;
