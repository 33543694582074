import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import img1 from "../../assets/images/Accessibility Options/img-1.png";
import img2 from "../../assets/images/Accessibility Options/img-2.png";
import img3 from "../../assets/images/Accessibility Options/img-3.png";
import img4 from "../../assets/images/Accessibility Options/img-4.png";
import img5 from "../../assets/images/Accessibility Options/img-5.png";
function AccessibilityOptions() {
  const { t } = useTranslation();
  const Data = t("AccessibilityOptions", { returnObjects: true });
  return (
    <>
      <BreadcrumbLayout title="Accessibility Options in browsers" />
      <Container fluid >
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div className="downloadable-list">
                  <h4>Microsoft Internet Explorer for Windows</h4>

                  <ul>
                    <li>Open your Internet Explorer Web Browser</li>
                    <li>Select 'Tools' from the menu at the top of your window.</li>
                    <li>
                      From the options which appear, select 'Internet options...'.
                    </li>
                    <li>Click on the 'General' tab.</li>
                    <li>Click on the button marked 'Accessibility'.</li>
                    <li>
                      To change your font size, select the checkbox marked 'Ignore
                      font sizes specified on Web pages'.
                    </li>
                    <li>
                      To remove background and font colours, select the checkbox
                      marked 'Ignore colours specified on web pages'.
                    </li>
                    <li>Click OK (and again if necessary).</li>
                    <li>Select 'View' from the menu at the top of your window.</li>
                    <li>From the options which appear, select 'Text Size'.</li>
                    <li>Select your desired text size.</li>
                    <li>Making Text larger in Mozilla Firefox 1.5 and 2</li>
                  </ul>
                </div>
                <div className="downloadable-list">
                  <h4>
                    Follow these steps to increase or decrease the size of the text
                    for the web page you are viewing:
                  </h4>

                  <ul>
                    <li>
                      Open the 'View' menu with the mouse or by press 'Alt' and 'V'
                      at the same time.
                    </li>
                    <li>
                      Select the 'Text Size' option with the mouse or by pressing
                      'Z'.
                    </li>
                    <li>
                      Increase or decrease the text size with the mouse or by using
                      the up and down arrow keys and pressing 'Enter'.
                    </li>
                    <li>
                      Alternatively you can press 'Ctrl' and '+' to increase the
                      text size, 'Ctrl' and ' - ' to decrease the text size. 'Ctrl'
                      and ' 0 ' returns you to the default 'normal' size.
                    </li>
                  </ul>
                </div>
                <div className="downloadable-list">
                  <h4>
                    Follow these steps to set a minimum text size for all pages you
                    visit:
                  </h4>

                  <ul>
                    <li>
                      Open the ' Tools ' menu with the mouse or press ' Alt ' + ' T
                      '.
                    </li>
                    <li>
                      Click on ' Options', or press ' O ' , to open the Options
                      dialog box.
                    </li>
                    <li>
                      Click the ' Content ' tab or use the arrow keys until it is
                      highlighted (in blue).
                    </li>
                    <li>
                      In the Fonts & Colours box click on ' Advanced ' , or press '
                      Alt ' + 'D' , to open the ' Fonts ' dialog box.see Fig 1
                      below)
                    </li>
                  </ul>
                  <div className='ass-image'>
                    <img src={img1} className="img-flude" alt='img1' />
                  </div>
                  <ul>
                    <li>
                      Click on ' Minimum font size ' box or press 'Alt '+ 'O ' . Use
                      the up and down arrows to select a new font size and press '
                      Enter' - see Fig 2 below.
                    </li>
                    <li>Click ' OK ' button or press ' Enter '.</li>
                    <li>
                      Click ' OK ' button or tab to ' OK ' button and press '
                      Enter‘.
                    </li>
                  </ul>
                  <div className='ass-image'>
                    <img src={img2} alt='' />
                  </div>
                </div>
                <div className="heading-paragraph">
                  <h3>Making text larger in Internet Explorer 7</h3>
                  <p>
                    For the first time in Internet Explorer a zoom feature is included which allows you to enlarge the whole browser window.
                    to do this press ' Ctrl ' + ' + ' to increase the zoom and ' Ctrl ' + ' - ' to decrease the zoom.
                    You can also change the text size. The way to do this does depend on how the site has been built.

                  </p>
                </div>
                <div className="downloadable-list">
                  <h4>
                    For many sites you only need to do the following:
                  </h4>

                  <ul>
                    <li>Open the ' Page ' menu with the mouse or by pressing ' Alt'+'P '.</li>
                    <li>Select the ' Text Size ' option with the mouse or by pressing ' X '. See Fig 1.</li>
                    <li>Choose your preferred text size by clicking on it or by using the up and down arrow keys to select it and then press ' Enter‘.</li>
                  </ul>
                  <div className='ass-image'>
                    <img src={img3} alt='' />
                  </div>
                </div>
                <div className="heading-paragraph">
                  <h3>Making text larger in Internet Explorer</h3>
                  <p>
                    The text on the website should now have changed to reflect your choice.
                    However some websites have fixed the size of their text ('hard-coded') and as a result these websites will not show the change you have just made. If you would like to use larger text on these sites follow the following steps:

                  </p>
                  <ul className="ms-5">
                    <li>Click on the ' Tools ' menu with the mouse or press ' Alt '+' O '.</li>
                    <li>Click on ' Internet Options ' option with the mouse or press ' O ' . You should now see the ' Internet Options ' box as shown in Fig 2 below.</li>

                  </ul>
                  <div className='ass-image'>
                    <img src={img4} alt='' />
                  </div>
                  <ul className="ms-5">
                    <li>Click on the ' Accessibility ' button which is highlighted with a red circle in Fig 2 with the mouse or press ' Alt '+ ' E '.</li>
                  </ul>
                  <div className='ass-image'>
                    <img src={img5} alt='' />
                  </div>
                  <ul className="ms-5">
                    <li>In the Accessibility box click the ' Ignore font sizes specified on web page ' checkbox to add a tick or press ' Alt '+' Z ' as shown in Fig 3.</li>
                    <li>Click the ' OK ' button to return to the Internet Options box.</li>
                    <li>Click on the ' OK ' button, or press ' Tab ' until ' OK ' is selected and then press ' Enter ' to return to Internet Explorer.</li>

                  </ul>

                </div>
                <div className="heading-paragraph">
                  <h3>How to Turn On Accessibility Features in Google Chrome</h3>
                  <h5>Magnifying your screen</h5>
                  <p>
                    This feature will increase and decrease the size of text and images on the screen for better visibility.
                  </p>
                  <ul className="ms-5">
                    <li>Press and hold down the CTRL key on your keyboard.</li>
                    <li>At the same time as holding the CTRL key:</li>
                    <li>press the + key to increase the size of the text and images or press the '-' key to decrease the size of the text and images.</li>
                    <li>Keep repeating Step 2 until you reach the desired size. You can return to the standard size by pressing CTRL + '0'</li>

                  </ul>


                </div>

              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default AccessibilityOptions;
