import React, { useEffect, useState, useMemo } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BreadcrumbLayout from "../../component/Breadcrumb";
import Loader from "../../global/Loader";
import { OfficerListService } from "../../Services/OfficerListService";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import demoUser from "../../assets/images/demo-user.jpg";
import AboutSidebar from "./AboutSideBar";
import loadingImg from "../../assets/images/loading-img.gif";
//import "./ListOfOfficers.css"; // Import custom CSS for card styling

export default function ListOfOfficers() {
  const { t, i18n } = useTranslation();
  const menuData =
    t("menu", {
      returnObjects: true,
    }) || {};
  const officerListService = useMemo(() => new OfficerListService(), []);
  const [tblData, setTblData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("language") === "en" ? 2 : 1);



  useEffect(() => {
    setLang(localStorage.getItem("language") === "en" ? 2 : 1);
  }, [i18n.language]);

  useEffect(() => {
    const getImageById = async (imgDocUniqueId, imgDocType) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await officerListService.ViewDocument(imgDocUniqueId);
          if (res.status === 200) {
            const fileBlob = new Blob([res.data], { type: imgDocType });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
      return demoUser;
    };

    const getOfficersList = async (lang) => {
      try {
        setPageLoading(true);
        const res = await officerListService.GetOfficerlistPublic(lang);
        const initialData = res.data.table.map((item, index) => ({
          key: index + 1,
          officerId: item.officerId,
          name: item.name,
          post: item.post,
          designation: item.designation,
          rsmDocUniqueId: item.rsmDocUniqueId,
          imgDocUniqueId: item.imgDocUniqueId,
          ImgUrl: loadingImg, // Set demo image initially
        }));

        setTblData(initialData);
        setPageLoading(false);

        initialData.forEach(async (item, index) => {
          const actualImgUrl = await getImageById(item.imgDocUniqueId, "");
          setTblData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = { ...updatedData[index], ImgUrl: actualImgUrl };
            return updatedData;
          });
        });
      } catch (error) {
        console.error("Error fetching officer list:", error);
      } finally {
        setPageLoading(false);
      }
    };

    getOfficersList(lang);
  }, [lang, officerListService]);

  
  const viewDoc = async (rsmDocUniqueId) => {
    try {
      setPageLoading(true);
      if (rsmDocUniqueId !== null) {
        const res = await officerListService.ViewDocument(rsmDocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: "application/pdf" });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: lang===2?"Oops...":"क्षमा करें...",
          text: lang===2?"The document hasn't been uploaded yet. Please try again later.!":"दस्तावेज़ अभी तक अपलोड नहीं किया गया है. कृपया बाद में पुन: प्रयास करें।!",
        });
      }
    } catch (error) {
      console.error("Error fetching resume:", error);
      Swal.fire({
        icon: "error",
        title: lang===2?"Oops...":"क्षमा करें",
        text: lang===2?"We encountered a problem while fetching the document. Please try again later.":"दस्तावेज़ प्राप्त करते समय कोई समस्या हुई। कृपया बाद में पुनः प्रयास करें।",
      });
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      {pageLoading && <Loader />}
      <BreadcrumbLayout title="List Of Officers" />
      <Container fluid>
        <Row>
          <AboutSidebar menuData={menuData} />
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("ListOfOfficers.title")}</h3>
              </Col>
              <Col xs={12} className="mb-3 mt-3 profile-card">
                <Row>
                  {tblData.map((officer, index) => (
                    <Col
                      md={4}
                      sm={6}
                      xs={12}
                      key={index}
                      className="mb-4 d-flex flex-column"
                      style={{ display: 'flex' }}
                    >
                      <div className="card flex-grow-1">
                        <div className="profile-container">
                          <img src={officer.ImgUrl} alt={officer.name} />
                        </div>
                        <div className="profile-info">
                          <h1>{officer.name}</h1>
                          <p className="job-title">{officer.post} </p>
                          <p className="desc">{officer.designation}</p>
                        </div>
                        <div className="profile-social">
                          <Link className="btn btn-primarye" onClick={() => viewDoc(officer.rsmDocUniqueId)} style={{ background: "#1a3385", color: "white" }} to="">
                            {lang === 2 ? 'View More' : 'और देखें'}                            
                          </Link>
                          

                        </div>
                        <div className="card-bottom"></div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

    </>
  );
}
