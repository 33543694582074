import axiosInstance from "../global/AxiosInterceptor";

export class OfficerListService {
   

    OfficerDetailsInsert = async (payload) => {
        return await axiosInstance.post('/OfficersList/officerdetailsinsert', payload)
    }

    OfficerDetailsUpdate = async (payload) => {
        return await axiosInstance.post('/OfficersList/officerdetailsupdate', payload)
    }

    GetOfficerDetails = async (payload) => {
        return await axiosInstance.post('/OfficersList/getofficereslist', payload);
    }

    GetOfficerlistPublic = async (language) => {
        return await axiosInstance.get(`/OfficersList/getofficereslistpublic/${language}`); // 1: Hindi, 2: English
    }

    GetMinisterGgList = async (language) => {
        return await axiosInstance.get(`/OfficersList/getministerdglist/${language}`); // 1: Hindi, 2: English
    }

    VerifyOfficerDetails = async (payload) => {
        return await axiosInstance.post('/OfficersList/officeredetailsverify', payload)
    }

    ViewDocument = async (uniqeId) => {
        return await axiosInstance.get('/Documents/getdocumentbyuniqueid/' + uniqeId, {
            responseType: 'blob'
        });
    };

}