import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
export default function ScienceAndTechnologyInterventionCell() {
  const { t } = useTranslation();
  const Data = t('ScienceAndTechnologyInterventionCell', { returnObjects: true })|| {};
  const menuData =
    t("menu.activities-of-council", {
      returnObjects: true,
    }) || {};
  return (
    <>
      <BreadcrumbLayout title="Science & Technology Intervention Cell" />
      <Container fluid>
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
              <h3>{Data.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
            <ul className="ms-5 nav-list">
              <li>
                <NavLink to="/activities-of-council/science-technology-intervention-cell/technology-village-programme">
                  {Data.link1}
                </NavLink>
              </li>
              <li>
                <NavLink to="/activities-of-council/science-technology-intervention-cell/technology-transfer">
                 
                {Data.link2}
                </NavLink>
              </li>
              <li>
                <NavLink to="/activities-of-council/science-technology-intervention-cell/information-technology-centre-for-s-t">
                 
                {Data.link3}
                </NavLink>
              </li>
            </ul>
          </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      
    </>
  );
}
