import axiosInstance from "../global/AxiosInterceptor";

export class ProjectService {
   

    ProjectDetailsInsert = async (payload) => {
        return await axiosInstance.post('/Projects/projectdtailsinsert', payload)
    }

    ProjectDetailsUpdate = async (payload) => {
        return await axiosInstance.post('/Projects/projectdtailsupdate', payload)
    }

    GetProjectDetails = async (payload) => {
        return await axiosInstance.post('/Projects/getProjectsList', payload);
    }

    GetProjectListPublic = async (projectAreas,projectType, language) => {
        return await axiosInstance.get(`/Projects/getprojectlistpublic/${projectAreas}/${projectType}/${language}`); // 1: Hindi, 2: English
    }

    VerifyProjectDetails = async (payload) => {
        return await axiosInstance.post('/Projects/projectdetailsverify', payload)
    }

    ViewDocument = async (uniqeId) => {
        return await axiosInstance.get('/Documents/getdocumentbyuniqueid/' + uniqeId, {
            responseType: 'blob'
        });
    };

    getCommonList = async (Type) => {
        return await axiosInstance.get(`/Common/CommonList/${Type}/2`); // 1: Hindi, 2: English
    }

}