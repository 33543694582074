import React from 'react'
import { useTranslation } from "react-i18next";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import HeadPara2 from "../../component/HeadPara2";
function Disclaimer() {
  const { t } = useTranslation();
  const DisclaimerData = t("Disclaimer", { returnObjects: true });
  return (
    <>
      <BreadcrumbLayout title="Disclaimer" />
      <Container fluid >
        <Row>
          <Col md={12} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{DisclaimerData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {DisclaimerData.data.map((items,i) => (
                  <HeadPara2 key={i} data={items} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Disclaimer
