import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function NewsAndEventsDetails() {
  const { t ,i18n} = useTranslation();
  const Data = t("NewsAndEvents", {
    returnObjects: true,
  }) || {};
  const menuData =
    t("menu.noticeboard", {
      returnObjects: true,
    }) || {};

  function getDataById(id) {
    const result = Data.content.find((item) => item.id === id);
    return result;
  }

  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get("id");
  const id =location?.state?.id;
  const dataFound = getDataById(id);
  return (
    <>
      <BreadcrumbLayout title="" />
      <Container >
        <Row>
          {dataFound ? (
            <>
               <Col md={3} className="sidebar order-last order-md-first">
            <Row>
              <Col className="menu-list">
                <ul>
                  
                    <NavLink to="/notice-board/news-events">
                    <li>  {menuData["news-events"]} </li>
                    </NavLink>
                    <NavLink to="/notice-board/archive-news">
                    <li> {i18n.language === "en" && (<>Archive News</>)}
                      {i18n.language === "hi" && (<>पुरालेख समाचार</>)} </li>
                    </NavLink>
                  
                  
                    <NavLink to="/notice-board/tenders">
                    <li> {menuData.tenders} </li>
                    </NavLink>
                  
                  
                    <NavLink to="/notice-board/tenders/archive-tender">
                    <li> {i18n.language === "en" && (<>Archive Tender</>)}
                      {i18n.language === "hi" && (<>पुरालेख निविदा</>)} </li>
                    </NavLink>
                  
                </ul>
              </Col>
            </Row>
          </Col>
              <Col md={9} className="content order-md-last order-first">
                <Row>
                  <Col xs={12} className="heading">
                    <h3>{dataFound.title}</h3>
                  </Col>
                  <Col xs={12} className="mb-1 mt-3">

                    {
                      dataFound.Content.map((c, i) => (
                        <div className="downloadable-list" key={i}>
                          <h4>{c.title}</h4>

                          <ul>
                            {c.down_list.map((item, index) => (
                              <li key={index}>
                                <a
                                  href={require(`${process.env.REACT_APP_filepath}/${item.fileName}`)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.title}
                                </a>
                              </li>
                            ))}
                          </ul>

                          <p><b>{c.noteDate}</b></p>
                        </div>
                      ))
                    }

                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Col xs={12} className="inner-border">
              <h3>Not found News and Event</h3>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}
