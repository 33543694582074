import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "./ATCSidebar";


export default function ActivitiesoftheCouncil() {
  const { t } = useTranslation();
  const ActivitiesoftheCouncil = t("ActivitiesoftheCouncil", {
    returnObjects: true,
  });
  const navigationData = t("sitemap.navigationData", { returnObjects: true });
  const desiredObject = [navigationData[3]];

  const renderSubMenu = (subItems) => {
    return (
      <ul>
        {subItems.map((subItem, index) => (
          <li key={index}>
            <a href={subItem.url}>{subItem.title}</a>
            {subItem.subItems && renderSubMenu(subItem.subItems)}
          </li>
        ))}
      </ul>
    );
  };
  const menuData = t("menu.activities-of-council", { returnObjects: true });

  return (
    <>
      <BreadcrumbLayout title="Activities of the Council" />
      <Container fluid >
        <Row>
          {/* Sidebar */}
          <ATCSidebar menuData={menuData} type="actofthecouncil" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{ActivitiesoftheCouncil.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                <div className="site-map ms-3">

                  {desiredObject.map((item, index) => (
                    <div key={index}>
                      {
                        item.subItems && renderSubMenu(item.subItems)
                      }

                    </div>
                  ))}

                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
