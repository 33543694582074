import { Suspense } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
// import SubHeader from "../component/SubHeader";
// import Header1 from "../component/Header";
import Footer from "../component/Footer";

import Header from '../component/_navBar/Header'
import Loader from "../global/Loader";
import ScrollToTop from '../component/ScrollToTop'
import SubHeader2 from "../component/SubHeader2";
import HomeBottomSlider from "../component/BottomSlider";
// import Test from "../component/Test";

function Layout() {
  return (
    <>

          <ScrollToTop/>
      {/* <SubHeader />
      <Header1 /> */}
      <SubHeader2/>
      <Header/>
      {/* <Test/> */}
      <div className="box-layout" id="main-content">
      <Container fluid="xxl" >
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Container>
        <HomeBottomSlider/>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
