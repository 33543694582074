import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
export default function WorldSpaceWeek() {
  const { t } = useTranslation();
  const WorldSpaceWeekData = t("WorldSpaceWeek", {
    returnObjects: true,
  });
  const menuData =
    t("menu.activities-of-council.popularisation-of-science", {
      returnObjects: true,
    }) || {};
  return (
    <>
      <BreadcrumbLayout title="World Space Week" />
      <Container fluid>
        <Row>
        {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{WorldSpaceWeekData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {WorldSpaceWeekData.content.map((items,i) => (
                  <HeadPara2 key={i} data={items} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
