import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import RenderList from "../../component/RenderList";
import HeadPara from "../../component/HeadPara";
import { useTranslation } from "react-i18next";
import AboutSidebar from "./AboutSideBar";
function Introduction() {
  const { t } = useTranslation();
  const IntroductionData =
    t("Introduction", {
      returnObjects: true,
    }) || {};
  const menuData =
    t("menu", {
      returnObjects: true,
    }) || {};
  return (
    <>
     <BreadcrumbLayout title="Introduction" />
  <Container fluid>
    <Row>
      {/* Sidebar */}
      <AboutSidebar menuData={menuData} />
      {/* Content Area */}
      <Col
        md={9}
        className="content order-md-last order-first"
        
      >
        <Row>
          <Col xs={12} className="heading mb-3">
            <h3 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
              {IntroductionData.title}
            </h3>
          </Col>

          <Col xs={12} className="mb-3 mt-2">
            {IntroductionData.ccostIntroduction &&
              Array.isArray(IntroductionData.ccostIntroduction) &&
              IntroductionData.ccostIntroduction.map((item, index) => (
                <HeadPara
                  key={index}
                  heading={item.title}
                  paragraph={item.description}
                />
              ))}
          </Col>

          <Col xs={12}>
            {IntroductionData.list && IntroductionData.listTitle && (
              <RenderList
                data={IntroductionData.list}
                title={IntroductionData.listTitle}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
</>

  );
}

export default Introduction;



