import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import { FaRegFilePdf } from "react-icons/fa6";
import { Pagination, Empty } from "antd";

import { useEffect, useState, useMemo } from "react";
import { NoticeServices } from "../../Services/NoticeService";
import Loader from "../../global/Loader";
import Swal from "sweetalert2";
import { FaLink } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import NewsEventSidebar from "./NewsEventSidebar";

export default function ArchiveNews() {
  const { t, i18n } = useTranslation();
  const menuData = t("menu.noticeboard", { returnObjects: true, }) || {};
  const noticeService = useMemo(() => new NoticeServices(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);
  const [pageLoading, setPageLoading] = useState(false);


  const viewDoc = async (doc) => {

    try {
      setPageLoading(true);

      if (doc.hyperlinkType === 1) {
        if (doc.uniqueId !== null) {
          const res = await noticeService.ViewNotificationDocument(doc.uniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: doc.documentMimeType });
            let dataUrl = window.URL.createObjectURL(fileBlob);
            window.open(dataUrl, "_blank");
          }
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "The document hasn't been uploaded yet. Please try again later.",
          });
        }
      }
      else if (doc.hyperlinkType === 2) {
        window.open(doc.url, "_blank");
      }

    }
    catch (error) {
      console.error("Error fetching documents:", error);
    }
    finally {
      setPageLoading(false);
    }





  };




  useEffect(() => {

    const getPageContetsList = async (noticeTypeId, language) => {
      try {
        setLoadingTblData(true);
        const res = await noticeService.GetArchiveNoticeListPublic(noticeTypeId, language);

        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            id: index + 1,
            key: index,
            noticeId: item.noticeId,
            subject: item.subject,
            date: {
              publishDate: item.publishDate,
            },
            hyperLink: {
              hyperlinkType: item.hyperlinkType,
              url: item.url,
              uniqueId: item.uniqueId,
              documentMimeType: item.documentMimeType,
            },
          }));
          return data;
        });

      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };

    getPageContetsList(1, lang);

  }, [lang, noticeService]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Set as per your requirement

  // Calculate the index of the first and last item to be displayed based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currenttblData = tblData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle pagination change
  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };


  return (
    <>
      {pageLoading && <Loader />}
      {loadingTblData && <Loader />}
      <BreadcrumbLayout title="Tenders" />
      <Container fluid >
        <Row>
          {/* Sidebar */}
          <NewsEventSidebar menuData={menuData} />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.archive-news")}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {
                  currenttblData.length > 0 ? <>

                    <Row>
                      {

                        currenttblData.map((list, i) => (
                          <Col lg={12} key={i}>
                            <a
                              style={{ textDecoration: "none" }}
                              href="/"
                              onClick={(e) => {
                                e.preventDefault(); // Prevents the default link behavior
                                viewDoc(list.hyperLink);

                              }}

                            >
                              <div className="current-box mt-2">
                                <div className="icon d-none d-sm-block ">
                                  {list.hyperLink.hyperlinkType === 2 ? <FaLink /> : <FaRegFilePdf />}

                                </div>
                                <div className="text-content">
                                  <Row className="d-flex justify-content-between">
                                    <Col className="col-12  info_head">
                                      {list.subject}
                                    </Col>

                                  </Row>
                                  <div className="download-content mt-2">
                                    <p>
                                      <span className="fw-bold">Date - {list.date.publishDate}</span>{" "}
                                      <span className="btn-sm btn-download fw-bold">
                                        <MdOutlineFileDownload />
                                        {lang === 1 ? "देखे" : "View"}
                                      </span>

                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </Col>
                        ))

                      }
                    </Row>
                    <Row>
                      <div className="">
                        <NavLink to="/notice-board/news-events" style={{ textDecoration: "none" }}>
                          <div className="current-box mt-1">
                            <div className="icon  ">
                              <FaLink />
                            </div>
                            <div className="text-content">
                              <Row className="d-flex justify-content-between">
                                <Col className="info_head">
                                  {/* <p style={{ textAlign: "justify" }}><b>{t("Tenders.paragraph")}</b></p> */}
                                  {lang === 1 ? "समाचार और कार्यक्रम" : "News and Events"}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </Row>
                    <Row justify="center">
                      <Col>
                        <div className='img-pagination' style={{ paddingBottom: "20px" }}>
                          <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={tblData.length}
                            onChange={handlePaginationChange}
                            showSizeChanger={true}
                            pageSizeOptions={['10', '20', '40', '80', '100']}
                          />
                        </div>
                      </Col>
                    </Row>
                  </> :
                    < Empty description={lang === 1 ? "वर्तमान में, कोई डेटा उपलब्ध नहीं है ।" : "Currently no data are available."} />
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
