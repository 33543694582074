

import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState,useMemo } from "react";
import Loader from "../../global/Loader";
import { OfficerListService } from "../../Services/OfficerListService";
import { Form, Select, Table, Button, Image } from "antd";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import Swal from "sweetalert2";
import demoUser from "../../assets/images/demo-user.jpg";

const OfficersListPreview = () => {
  const officerListService = useMemo(() => new OfficerListService(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [filterListData, setFilterListData] = useState([]);
  const [form] = Form.useForm();
  const [filterType, setFilterType] = useState({ filterType: "1" });
  const [pageload, setPageLoad] = useState(false);
  const viewDoc = async (rsmDocUniqueId) => {
    try{
      setPageLoad(true)
      if (rsmDocUniqueId !== null) {
        const res = await officerListService.ViewDocument(rsmDocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: "application/pdf" });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }  
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Resume has not been uploaded!",
        });
      }
    }
    catch(error){
      console.error("Error fetching resume:", error);
    }
    finally{
      setPageLoad(false);
    }
    

  };

  

 
  const columns = [
    {
      title: "#",
      align:'center',
      render: (record) => record.key,
      width: "5%",
    },
    {
      title: "Image",
      render: (row) => {
        return (
          <>
            <Image
              src={row.ImgUrl}
              alt="Imagesdghsd"
              style={{
                width: "115px",
                border: "1px solid black",
                padding: "5px",
                borderRadius: "5px",
              }}
            />
            
          </>
        );
      },
      width: "10%"
    },
    {
      title: "English/Hindi",
      render: (row) => {
        return (
          <>
            <p>
              <span className="fw-bold">{row.name} </span>{" "}
            </p>
            <p>{row.post}</p>
            <p>{row.designation}</p>
          </>
        );
      }
    },
    {
      title: "Resume",
      render: (row) => {
        return (
          <>
            
            <Button
              onClick={() => viewDoc(row.rsmDocUniqueId)}
              className="btn btn-sm btn-outline-primary mt-2"
            >
              <BsFileEarmarkPdfFill />
              View Resume
            </Button>
          </>
        );
      },
      width: "10%"
    },
  ];
 

  useEffect(() => {
    setFilterListData([
      {
        value: "1",
        name: "Hindi",
        label: "Hindi",
        nameHindi: "",
        type: "",
        extraField1: "",
      },
      {
        value: "2",
        name: "English",
        label: "English",
        nameHindi: "",
        type: "",
        extraField1: "",
      },
    ]);
    const getImageById = async (imgDocUniqueId, imgDocType) => {
      try {
        if (imgDocUniqueId !== null) {
          const res = await officerListService.ViewDocument(imgDocUniqueId);
          if (res.status=== 200) {
            const fileBlob = new Blob([res.data], { type: "image/jpeg" });
            const dataUrl = URL.createObjectURL(fileBlob);
            return dataUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
  
      return demoUser;
    };
    const getOfficersList = async (lang) => {
      try {
        setLoadingTblData(true);
        
        const res = await officerListService.GetOfficerlistPublic(lang);
        const imgUrlPromises = res.data.table.map((item) =>
          getImageById(item.imgDocUniqueId)
        );
        const imgUrls = await Promise.all(imgUrlPromises);
  
        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            officerId: item.officerId,
            name: item.name,
            post: item.post,
            designation: item.designation,
           
            isActive: item.isActive,
            isActiveName: item.activeName,
            rsmDocUniqueId: item.rsmDocUniqueId,
            imgDocUniqueId: item.imgDocUniqueId,   
            ImgUrl: imgUrls[index],
            publishDate: item.publishDate,
          }));
          return data;
        });
      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getOfficersList(form.getFieldValue("filterType"));
    
  }, [filterType,form,officerListService]);

  const loadTableFilter = (value) => {
    setFilterType({ filterType: value })
  };

 

  return (
    <>
    {pageload && <Loader/>}
      {loadingTblData && <Loader />}
      <Container>
        <Row>
          <Col className="dashboard">
            <div className="title">
              <div className="title">
                <h3>Officers List Preview</h3>
              </div>
            </div>
            <div className="content">
               <Col lg={4} md={12} className="float-end">
                <Form form={form} initialValues={filterType}>
                <Form.Item name="filterType" label="Select Language">
                  <Select
                    showSearch
                    placeholder="Select Language"
                   
                    optionFilterProp="children"
                    onChange={loadTableFilter}
                    options={filterListData}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(
                        input.toLowerCase()
                      )
                    }
                  />
                </Form.Item>
                </Form>
              </Col>
              <Col md={12}>
              <div className="tableList">
                <Table
                  bordered
                  columns={columns}
                  dataSource={tblData}
                  loading={loadingTblData}
                  scroll={{ x: 600}}
                  size="small"
                  pagination={{defaultPageSize: 10, hideOnSinglePage: true}}
                />
               
              </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OfficersListPreview;



