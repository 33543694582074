import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArchiveNewsDetailsData from "./ArchiveNewsDetailsData";
export default function ArchiveNewsDetails() {
  const { t, i18n } = useTranslation();

  const menuData = t("menu.noticeboard", { returnObjects: true, }) || {};



  const location = useLocation();


  const id = location?.state?.id;


  return (
    <>
      <BreadcrumbLayout title="" />
      <Container >
        <Row>

          <Col md={3} className="sidebar order-last order-md-first">
            <Row>
              <Col className="menu-list">
                <ul>

                  <NavLink to="/notice-board/news-events">
                    <li>  {menuData["news-events"]} </li>
                  </NavLink>
                  <NavLink to="/notice-board/archive-news">
                    <li> {i18n.language === "en" && (<>Archive News</>)}
                      {i18n.language === "hi" && (<>पुरालेख समाचार</>)} </li>
                  </NavLink>


                  <NavLink to="/notice-board/tenders">
                    <li> {menuData.tenders} </li>
                  </NavLink>


                  <NavLink to="/notice-board/tenders/archive-tender">
                    <li> {i18n.language === "en" && (<>Archive Tender</>)}
                      {i18n.language === "hi" && (<>पुरालेख निविदा</>)} </li>
                  </NavLink>

                </ul>
              </Col>
            </Row>
          </Col>
          <Col md={9} className="content order-md-last order-first">
            <Row>



              <ArchiveNewsDetailsData id={id} />


            </Row>
          </Col>

        </Row>
      </Container>
    </>
  );


}
