import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
export default function InformationTechnologyCentreForSAndT() {
  const { t } = useTranslation();
  const ITCData = t("InformationTechnologyCentreForSAndT", {
    returnObjects: true,
  });
  const menuData = t('ScienceAndTechnologyInterventionCell', { returnObjects: true })|| {};
  return (
    <>
      <BreadcrumbLayout title="Information Technology Centre for S & T" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="ScienceAndTechnologyInterventionCell" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
              <h3>{ITCData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
            {ITCData.data.map((items) => (
              <HeadPara2 data={items} />
            ))}
          </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
