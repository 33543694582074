
import { Spin } from 'antd';
const Loader = () => {
    return (  
      <div className="loader-overlay">
          <div className="loader-container">
              <Spin size="large" />
          </div>
      </div>
    )
}

export default Loader;
