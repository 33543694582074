import React from "react";
import { Col, Row, Container, Card } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import PhotoGallerySidebar from "./PhotoGallerySidebar";
import SampleVideo from '../../assets/video/SampleVideo.mp4';

export default function VideoGallery() {
  const { t } = useTranslation();
  const menuData = t("menu.media-gallery", { returnObjects: true }) || {};
  const videoList = t("videoGalleryData", { returnObjects: true }) || {};

  // const videoList = [
  //   {
  //     type: "youtube",
  //     url: "https://www.youtube.com/live/0TDsnNH4p7w",
  //     title: "एनआरएसए / एनआरएससी स्वर्ण जयंती समारोह NRSA / NRSC Golden Jubilee Celebrations",
  //     description: "The NRSC (erstwhile NRSA) is completing 50 years of its existence by 4th September, 2024. During the journey of the last 50 years, the Organisation has served the Nation by means of establishing a state of the art Earth Observation Data acquisition, processing, dissemination and application development Centre.",
  //   },
  //   {
  //     type: "local",
  //     url: SampleVideo,
  //     title: "Local Video 1",
  //     description: "This is a sample description for Local Video 1.",
  //   },
  //   {
  //     type: "youtube",
  //     url: "https://www.youtube.com/watch?v=Gh_Vurozfgo&t=4734s",
  //     title: "YouTube Video 2",
  //     description: "This is a sample description for YouTube Video 2.",
  //   },
  //   {
  //     type: "local",
  //     url: SampleVideo,
  //     title: "Local Video 2",
  //     description: "This is a sample description for Local Video 2.",
  //   },
  //   {
  //     type: "youtube",
  //     url: "https://www.youtube.com/watch?v=0Uws2dsIdYU",
  //     title: "YouTube Video 3",
  //     description: "This is a sample description for YouTube Video 3. It might be long enough to demonstrate the truncation feature.",
  //   },
  //   {
  //     type: "local",
  //     url: SampleVideo,
  //     title: "Local Video 3",
  //     description: "This is a sample description for Local Video 3.",
  //   },
  //   {
  //     type: "youtube",
  //     url: "https://www.youtube.com/watch?v=gunXGA5plG0",
  //     title: "YouTube Video 4",
  //     description: "This is a sample description for YouTube Video 4.",
  //   },
  //   {
  //     type: "local",
  //     url: SampleVideo,
  //     title: "Local Video 4",
  //     description: "This is a sample description for Local Video 4.",
  //   },
  // ];

  const extractVideoID = (url) => {
    const match = url.match(
        /^.*(?:youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|&v=|\/live\/)([^#&?]*).*/
    );
    return match && match[1].length === 11 ? match[1] : null;
};


  return (
    <>
      <BreadcrumbLayout title={t("breadcrumb.video-gallery")} />
      <Container fluid>
        <Row>
          <PhotoGallerySidebar menuData={menuData} />
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.video-gallery")}</h3>
              </Col>
              <Col xs={12} className="mt-3">
                <Row className="video-gallery">
                  {videoList.map((video, index) => (
                    <Col md={4} key={index} className="mb-3">
                      <Card className="h-100">
                        {video.type === "youtube" ? (
                           <div className="embed-responsive embed-responsive-16by9">
                           <iframe
                             className="embed-responsive-item"
                             src={`https://www.youtube.com/embed/${extractVideoID(video.url)}?autoplay=1`} // Ensure correct embed URL
                             allowFullScreen
                             title={video.title}
                             style={{ width: "100%", height: "200px" }}
                           ></iframe>
                         </div>
                        ) : (
                          <div className="embed-responsive embed-responsive-16by9">
                            <video
                              className="embed-responsive-item"
                              controls
                              // src={video.url}
                              src={SampleVideo}
                              style={{ width: "100%", height: "200px" }}
                            >
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        )}
                        <Card.Body>
                          <h6 className="text-multiline-truncate fw-bold" title={video.title}>
                            {video.title}
                          </h6>
                          <p className="text-multiline-truncate1 " style={{fontSize:"0.9rem",textAlign:"justify"}} title={video.description}>
                            {video.description}
                          </p>
                         
                        </Card.Body>
                      </Card>
                      
                    </Col>
                  ))}


                     
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
