import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import HeadPara2 from "../../../component/HeadPara2";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
import DownloadableList from "../../../component/DownloadableList";

export default function WesternIndiaScienceFair() {
  const { t } = useTranslation();
  const westernIndiaScienceFairData = t("westernIndiaScienceFair", { returnObjects: true, }) || {};
  const menuData = t("menu.activities-of-council.popularisation-of-science", { returnObjects: true, }) || {};

  return (
    <>
      <BreadcrumbLayout title="Western India Science Fair" />
      <Container fluid>
        <Row>
           {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="PopularisationOfScience" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{westernIndiaScienceFairData.title}</h3>
              </Col>
              <Col xs={12} className="mb-1 mt-1">
                {westernIndiaScienceFairData.content.map((items, i) => (
                  <HeadPara2 key={i} data={items} />
                ))}
                 {/* <div className="downloadable-list2 ms-4">
                 <DownloadableList _contentType = "16" _className=""/>
                </div> */}

                <div className="downloadable-list2">
                  <h4>{westernIndiaScienceFairData.participantsTitle}</h4>
                  <DownloadableList _contentType = "17" _className="ms-4"/>
                </div> 
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

    </>
  );
}
