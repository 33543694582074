

import axiosInstance from "../global/AxiosInterceptor";

export class NoticeServices {
   

    InsertNoticeDetails = async (payload) => {
        
        return await axiosInstance.post('/NoticeBoard/insertnoticedetails', payload);
    }

    UpdateNoticeDetails = async (payload) => {
        return await axiosInstance.post('/NoticeBoard/updatenoticedetails' , payload);
    }

    GetNoticeList = async (payload) => {
        return await axiosInstance.post('/NoticeBoard/getnoticelist' , payload);
    }

    ViewNotificationDocument = async (uniqeId) => {
        return await axiosInstance.get('/Documents/getdocumentbyuniqueid/' + uniqeId, {
            responseType: 'blob'
        });
    }

    VerifyNoticeBoard = async (payload) => {
        return await axiosInstance.post('/NoticeBoard/noticeboarddetailsverify', payload)
    }

    GetNoticeListPublic = async (noticeTypeId, language) => {
        return await axiosInstance.get(`/NoticeBoard/getnoticelistpublic/${noticeTypeId}/${language}`)
    }

    GetArchiveNoticeListPublic = async (noticeType, language) => {
        return await axiosInstance.get(`/NoticeBoard/getarchivenoticelistpublic/${noticeType}/${language}`)
    }
}