import axiosInstance from "../global/AxiosInterceptor";

export class ImageGalleryService {
   

   InsertNewImage = async (payload) => {
        return await axiosInstance.post('/ImageGallery/addnewimage', payload)
    }

    ImageDetailsUpdate = async (payload) => {
        return await axiosInstance.post('/ImageGallery/updateimagedetails', payload)
    }

    GetImageDetails = async (payload) => {
        return await axiosInstance.post('/ImageGallery/getimagelist', payload);
    }

    GetImageListPublic = async (galleryTypeId,language,imageCategoryType) => {
        return await axiosInstance.get(`/ImageGallery/getimagelistpublic/${galleryTypeId}/${language}/${imageCategoryType}`);
    }

    VerifyImageDetails = async (payload) => {
        return await axiosInstance.post('/ImageGallery/imagedetailsverify', payload)
    }

    ViewDocument = async (uniqeId) => {
        return await axiosInstance.get('/Documents/getdocumentbyuniqueid/' + uniqeId, {
            responseType: 'blob'
        });
    };

    getCommonList = async (Type,lang) => {
        return await axiosInstance.get(`/Common/CommonList/${Type}/${lang}`); 
    }

}