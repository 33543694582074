import React from 'react'
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import ATCSidebar from "../ATCSidebar";
import ProjectTable from '../../../component/ProjectTable';

export default function CompletedProjects() {
  const { t } = useTranslation();
  const menuData = t("menu.activities-of-council.other_projects", {
    returnObjects: true,
  }) || {};

  return (
    <>
      <BreadcrumbLayout title="Completed Project" />
      <Container fluid>
        <Row>
          {/* Sidebar */}
        <ATCSidebar menuData={menuData} type="OtherProjects" />
          {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.completed-project")}

                </h3>
              </Col>
              <Col xs={12} className='mb-1 mt-1'>

                <ProjectTable projectAreas="4" projectType="1" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
