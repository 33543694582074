import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
import Loader from "../../global/Loader";
import { PageContentService } from "../../Services/PageContentService";
import { Form, Select, Table } from "antd";
import Swal from "sweetalert2";


const ContentPreview = () => {
  const pageContentService = useMemo(() => new PageContentService(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [laguage, setLanguage] = useState([]);

  const [contentTypeList, setContentTypeList] = useState([]);
  const [loadingNoticeTypes, setLoadingNoticeTypes] = useState(true);
  const [pageLoad, setPageLoad] = useState(false);
  const [initialValues, setInitialValues] = useState({ contentType: "-1", Language: "1" })

  const [form] = Form.useForm();
  const viewDoc = async (doc) => {

    try {
      setPageLoad(true);
      if (doc.DocUniqueId !== null) {
        const res = await pageContentService.ViewDocument(doc.DocUniqueId);
        if (res.status=== 200) {
          const fileBlob = new Blob([res.data], { type: doc.DocMimeType });
          let dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Resume has not been uploaded!",
        });
      }
    }
    catch (error) {
      console.error("Error fetching documents:", error);
    }
    finally {
      setPageLoad(false);
    }
  };




  const columns = [
    {
      title: "#",
      align: 'center',
      render: (record) => record.key,
      fixed: "left",
      width: "5%",
    },
    {
      title: 'English/Hindi',
      render: (row) => {
        return (
          <>

            <a
              href="/"
              onClick={(e) => {
                e.preventDefault(); // Prevents the default link behavior
                viewDoc(row.doc);
              }}
              className="ant-btn ant-btn-link"
            >
              {row.title}
            </a>

          </>
        )
      },
      fixed: 'left',
      width: '50%'
    },
  ];

  useEffect(() => {
    setLanguage([
      {
        value: "1",
        name: "Hindi",
        label: "Hindi",
        nameHindi: "",
        type: "",
        extraField1: "",
      },
      {
        value: "2",
        name: "English",
        label: "English",
        nameHindi: "",
        type: "",
        extraField1: "",
      },
    ]);
    const getContentTypes = async (type) => {
      try {
        setLoadingNoticeTypes(true);
        const res = await pageContentService.getCommonList(type);
        setContentTypeList(() => {
          let data = [
            {
              value: '-1',
              name: 'All',
              label: 'All',
              nameHindi: '',
              type: '',
              extraField1: ''
            }
          ];
          for (let i = 0; i < res.data.length; i++) {
            data.push(
              {
                value: res.data[i].value,
                name: res.data[i].name,
                label: res.data[i].label,
                nameHindi: res.data[i].nameHindi,
                type: res.data[i].type,
                extraField1: res.data[i].extraField1
              }
            )
          }
          return data;
        })
      }
      catch (error) {
        setLoadingNoticeTypes(false);
      }
      finally {
        setLoadingNoticeTypes(false);
      }
    }

    getContentTypes("PageContentType");


  }, [pageContentService]);

  useEffect(() => {
    const getPageContetsList = async (contentType, language) => {
      try {
        setLoadingTblData(true);
        const res = await pageContentService.GetPageContetListPublic(contentType, language);
        setTblData(() => {
          let data = res.data.table.map((item, index) => ({
            key: index + 1,
            contentTypeName: item.contentTypeName,
            contentId: item.contentId,
            title: item.title,

            verificationStatus: item.verificationStatus,
            statusName: item.statusName,
            isActive: item.isActive,
            isActiveName: item.activeName,
            verificationremark: item.verificationRemark,
            publishDate: item.publishDate,
            submitDate: item.submitDate,
            doc: {
              DocUniqueId: item.uniqueId,
              DocMimeType: item.documentMimeType,

            },
          }));
          return data;
        });
      } catch (error) {
        setLoadingTblData(false);
      } finally {
        setLoadingTblData(false);
      }
    };
    getPageContetsList(form.getFieldValue("contentType"), form.getFieldValue("Language"))

  }, [pageContentService, form, initialValues]);




  const loadTableFilter = (contentType, language) => {


    setInitialValues({ contentType: contentType, Language: language })
  };



  return (
    <>
      {pageLoad && <Loader />}
      {loadingTblData && <Loader />}
      <Container>
        <Row>
          <Col className="dashboard">
            <div className="title">
              <div className="title">
                <h3>Content Preview</h3>
              </div>
            </div>
            <div className="content">
              <Row>
                <Col lg={2} md={6} className="float-start">
                  {/* <Link className="title-btn" to="/user/manage/content-list">

                  <TbArrowBackUp /> Back
                </Link> */}
                </Col>
                <Form form={form} initialValues={initialValues}>
                  <Col lg={10} md={12} className="float-end">
                    <Row>
                      <Col md={8}>
                        <Form.Item name="contentType" label="Select Content Type" rules={[
                          { required: true, message: 'Category Required' },
                        ]}>
                          <Select showSearch placeholder="Select Content Type" optionFilterProp="children" // onSearch={onSearch}
                            options={contentTypeList} loading={loadingNoticeTypes} onChange={(value) => loadTableFilter(value, form.getFieldValue("Language"))}
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={4}>
                        <Form.Item name="Language" label="Language:">
                          <Select
                            showSearch
                            placeholder="Select Language"
                            // defaultValue={"1"}
                            optionFilterProp="children"
                            onChange={(value) => loadTableFilter(form.getFieldValue("contentType"), value)}
                            options={laguage}
                            filterOption={(input, option) =>
                              (option?.label ?? "").toLowerCase().includes(
                                input.toLowerCase()
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Form>

              </Row>
              <Row>
                <div className="tableList">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={tblData}
                    loading={loadingTblData}
                   scroll={{ x: 700}}
                    size="small"
                  />
                </div>
              </Row>


            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContentPreview;



