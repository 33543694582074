import React from 'react'

export default function HeadPara(props) {

  return (
    <>
      <div className='heading-paragraph'>
        <h3>{props.heading}</h3>
        <p>{props.paragraph}</p>
      </div>
    </>
  )
}
